import user from '../../services/User'
import router from '../../router'

import {UserRoleEnum,} from '../../enums';

// initial state
const state = {
  users: [],
  loggedInUser: null,
  loggedInUserAvatarReload: 0,
  dialogNew: false,
  chosenUser: null
}

// getters
const getters = {
  getUsers: state => state.users,
  getUsersForSelect: state => [{id: '', name: ''}, ...state.users],
  getLoggedInUser: state => state.loggedInUser,
  getLoggedInUserAvatarReload: state => state.loggedInUserAvatarReload,
  userRole: state => (state.loggedInUser.is_admin == 1 || state.loggedInUser.is_admin == 11) ? 1 : state.loggedInUser.is_admin,
  isSuperAdmin: state => state.loggedInUser.is_admin == 11 ? true : false,
  isCompany: state => (state.loggedInUser.is_admin == 1 || state.loggedInUser.is_admin == 11) ? state.users.find(item => item['id'] == state.chosenUser).is_company : state.loggedInUser.is_company,
  dialogNew: state => state.dialogNew,
  getChosenUser: state => state.chosenUser,
  getRatePerKm: state => (state.loggedInUser.is_admin == 1 || state.loggedInUser.is_admin == 11) ? state.users.find(item => item['id'] == state.chosenUser).rate_per_km : state.loggedInUser.rate_per_km,
  getChosenUserRole: state => (state.loggedInUser.is_admin == 1 || state.loggedInUser.is_admin == 11) ? state.users.find(item => item['id'] == state.chosenUser).role : state.loggedInUser.is_admin,
  getNameById: state => (userId) => {
    if (state.users.find(u => u.id === userId) != undefined) {
      if (state.users.find(u => u.id === userId).is_admin == 1 && (state.loggedInUser.is_admin !== 1 || state.loggedInUser.is_admin !== 11)) {
        return 'Sekretariat';
      } else {
        let toReturn = '';
        if (state.users.find(u => u.id === userId).registration_form !== undefined && JSON.parse(state.users.find(u => u.id === userId).registration_form)?.personal.degree) {
          toReturn += JSON.parse(state.users.find(u => u.id === userId).registration_form).personal.degree + ' ';
        }
        toReturn += state.users.find(u => u.id === userId).name
        return toReturn;
      }
    } else {
      return ''
    }
  },
  getRoleById: state => (userId) => {
    if (state.users.find(u => u.id === userId) != undefined) {
      if (state.users.find(u => u.id === userId).is_admin == 11) {
        return 1;
      } else {
        return state.users.find(u => u.id === userId).is_admin;
      }
    } else {
      return ''
    }
  },
  getEmailAltById: state => (userId) => {
    if (state.users.find(u => u.id === userId) != undefined) {
        return state.users.find(u => u.id === userId).email_alt
    } else {
      return null;
    }
  },
  getFinishInfoFromLoggedInByCourseName: state => (courseName) => {
    return state.loggedInUser.studentCourses.find(x => x.name == courseName)
  },
  isMBA: state => state.loggedInUser.studentCourses?.find(x => x.courseId == 21) ? true : false
}

// actions
const actions = {
  getUsers ({dispatch, commit }) {
    user.getUsersByRole(UserRoleEnum.Professor).then(response => {
      dispatch('users/resetChosenUser', response.data.resetChosenUser, {root: true});
      dispatch('onlineMeetings/resetCredentials', null, {root: true});
      dispatch('contracts/getContracts', response.data.contracts, {root: true});
      commit('setUsers', response.data)
    })
  },
  getAdministrativeStaff ({dispatch, commit }) {
    user.getUsersByRole(UserRoleEnum.Administrative).then(response => {
      dispatch('users/resetChosenUser', response.data.resetChosenUser, {root: true});
      dispatch('contracts/getContracts', response.data.contracts, {root: true});
      commit('setUsers', response.data)
    })
  },
  getProfessorAndAdministrativeStaff ({dispatch, commit }) {
    user.getUsersByRole(UserRoleEnum.Administrative + ',' + UserRoleEnum.Professor).then(response => {
      dispatch('users/resetChosenUser', response.data.resetChosenUser, {root: true});
      dispatch('contracts/getContracts', response.data.contracts, {root: true});
      commit('setUsers', response.data)
    })
  },
  getUsersForIssueTracker ({dispatch, commit }) {
    user.getUsersByRole(UserRoleEnum.All).then(response => {
      dispatch('users/resetChosenUser', response.data.resetChosenUser, {root: true});
      dispatch('contracts/getContracts', response.data.contracts, {root: true});
      commit('setUsers', response.data)
    })
  },
  getUsersAvailableForChat ({ commit }) {
    return new Promise((resolve, reject) => {
      user.getUsersSimplifiedForChat().then(response => {
        if(response.status == 200) {
          commit('setUsers', response.data)
        } else {
          reject();
        }
      })
    }).catch((error) => { console.log(error) });
  },
  loginUser({dispatch, commit}, credentials) {
    return new Promise((resolve, reject) => {
      user.loginUser(credentials).then((response) => {
        if(response.data.user.registration_form !== null) {
          dispatch('registration/setRegistrationFromJson', response.data.user.registration_form, {root: true})
        }
        if (response.data.user.is_admin == 0) {
          dispatch('cars/setCars', response.data.cars, {root: true})
        }
        commit('setUser', response.data.user)
        if (response.data.user.is_admin == 1) {
          router.push('/users').catch(err => {})
        } else if(response.data.user.is_admin == 2) {
          router.push('/students').catch(err => {})
        } else if(response.data.user.is_admin == 3) {
          if(response.data.user.rodo == null) { // first login
            router.push('/change-password').catch(err => {});
          } else {
            router.push('/student-personal-data').catch(err => {});
          }
        } else if(response.data.user.is_admin == 11) {
          router.push('/statistics').catch(err => {});
        } else {
          router.push('/dashboard').catch(err => {})
        }
      }).catch((errors) => {
        reject(errors)
      })
    });
  },
  restoreEmail({commit}, credentials) {
    return new Promise((resolve, reject) => {
      user.restoreEmail(credentials.telephone).then((response) => {
        if(response.status == 200) {
          resolve(response.data.email)
        }
      }).catch((errors) => {
        reject(errors)
      })
    })
  },
  resetPassword({commit}, credentials) {
    return new Promise((resolve, reject) => {
      user.resetPassword(credentials.email).then((response) => {
        if(response.status == 200) {
          resolve(response.status)
        }
      }).catch((errors) => {
        reject(errors)
      })
    })
  },
  passwordChange({commit}, credentials) {
    return new Promise((resolve, reject) => {
      user.passwordChange(state.chosenUser?state.chosenUser:state.loggedInUser.id, credentials).then((response) => {
        if(response.status == 200) {
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    })
  },
  changeAdminGeneralSettings({commit}, credentials) {
    return new Promise((resolve, reject) => {
      user.changeAdminGeneralSetting(state.loggedInUser.id, credentials).then((response) => {
        if(response.status == 200) {
          commit('setUserEmailAndTelephone', credentials)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    })
  },
  setRatePerKm({commit}, setting) {
    return new Promise((resolve, reject) => {
      user.setRatePerKm(state.chosenUser, {rate: setting.rate*100 }).then((response) => {
        if(response.status == 200) {
          commit('setRatePerKm', setting.rate*100)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    })
  },
  setRodo({commit}) {
    return new Promise((resolve, reject) => {
      user.setRodo().then((response) => {
        if(response.status == 200) {
          commit('setRodo', new Date());
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  logoutUser ({ commit }) {
    user.logoutUser().then(response => {
      window.location.href = '/';
      commit('logoutUser', response)
    })
  },

  checkApiUptime ({ commit }) {
    return new Promise((resolve, reject) => {
      user.logoutUser().then(response => {
        if(response.status == 200) {
          resolve(200)
          commit('logoutUser', response);
        } else {
          reject(response.status)
        }
      }).catch(error => {
        reject(error)
      })
    }).catch((error) => { console.log(error) });
  },
  setAvatar({ commit }, formData) {
    return new Promise((resolve, reject) => {
      user.setAvatar(state.loggedInUser.id, formData).then(response => {
        if(response.status == 200) {
          resolve(200)
          commit('setLoggedInUserAvatarReload', Math.random(10))
        } else {
          reject(response.status)
        }
      })
    }).catch((error) => { console.log(error) });
  },
  createUser ({ commit }, newUser) {
    return new Promise((resolve, reject) => {
      user.createUser(newUser).then(response => {
        if(response.status == 200) {
          if(response.data.userExists) {
            reject('User exists');
          } else {
            resolve(200)
            commit('addUser', response.data)
          }
        } else {
          reject(response.status)
        }
      })
    }).catch((error) => { console.log(error) });
  },
  editUser ({ commit }, editedUser) {
    return new Promise((resolve, reject) => {
      user.editUser(editedUser).then(response => {
        commit('editUser', response.data)
        resolve(200)
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  deleteUsers ({ commit }, deletedUsers) {
    return new Promise((resolve, reject) => {
      user.deleteUsers(deletedUsers.map(function(item) { return item["id"] })).then(response => {
        console.log(response.status)
        if (response.status == 200) {
          commit('deleteUsers', deletedUsers)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setChosenUserBasic({dispatch, commit}, value) {
    commit('setChosenUser', value)
  },
  setChosenUser({dispatch, commit}, value) {
    commit('setChosenUser', value)
    let chosenUser = state.users.find(item => item['id'] == value)
    if(chosenUser.registration_form !== null) {
      dispatch('registration/setRegistrationFromJson', chosenUser.registration_form, {root: true})
    } else {
      dispatch('registration/clearRegistrationForm', true, {root: true})
    }
    router.push('/users/' + value)
  },
  setChosenAdministrative({dispatch, commit}, value) {
    commit('setChosenUser', value)
    let chosenUser = state.users.find(item => item['id'] == value)
    if(chosenUser.registration_form !== null) {
      dispatch('registration/setRegistrationFromJson', chosenUser.registration_form, {root: true})
    } else {
      dispatch('registration/clearRegistrationForm', true, {root: true})
    }
    router.push('/administrativeStaff/' + value)
  },
  resetChosenUser({commit}) {
    commit('setChosenUser', null)
  },
  setUserRegistrationForm({commit}, userData) {
    commit('setUserRegistrationForm', userData)
  },
  switchCompanyAccept({commit}) {
    return new Promise((resolve, reject) => {
      user.switchCompany().then(response => {
        console.log(response);
        // commit('switchCompany', response.data)
        user.logoutUser().then(response => {
          commit('logoutUser', response)
          router.push('/')
        })
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  }
}

// mutations
const mutations = {
  setUsers (state, users) {
    state.users = users
  },
  setUser (state, user) {
    state.loggedInUser = user
  },
  setRegistrationForm (state, registrationFormBool) {
    state.loggedInUser.registrationFormAccepted = registrationFormBool
  },
  logoutUser (state) {
    state.loggedInUser = ''
    state.chosenUser = null
  },
  setLoggedInUserAvatarReload (state, data) {
    state.loggedInUserAvatarReload = data
  },
  addUser (state, user) {
    state.users.push(user)
  },
  editUser (state, user) {
    console.log(user)
  },
  deleteUsers (state, users) {
    users.map(function(key) {
      const index = state.users.indexOf(key);
      state.users.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setChosenUser (state, user) {
    state.chosenUser = user
  },
  setRatePerKm (state, rate) {
    if(state.userRole) {
      state.users.find(item => item['id'] == state.chosenUser).rate_per_km = rate
    } else {
      state.loggedInUser.rate_per_km = rate
    }
  },
  setRodo (state, rodo) {
    state.loggedInUser.rodo = rodo
  },
  setUserRegistrationForm (state, userData) {
    if (userData.uid != state.loggedInUser.id) {
      state.users.find(item => item['id'] == state.chosenUser).registration_form = userData.registrationForm
      state.users.find(item => item['id'] == state.chosenUser).name = userData.name
      state.users.find(item => item['id'] == state.chosenUser).email = userData.email
      state.users.find(item => item['id'] == state.chosenUser).telephone = userData.telephone
    } else {
      state.loggedInUser.registration_form = userData.registrationForm
    }
  },
  setUserEmailAndTelephone (state, settings) {
    state.loggedInUser.email = settings.email
    state.loggedInUser.telephone = settings.telephone
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
