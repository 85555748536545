import meeting from '../../services/Meeting'
import moment from "moment";
import report from "../../services/Report";

// initial state
const state = {
  items: [],
  meetingsReport: 0,
  dialogNew: false
};

// getters
const getters = {
  getMeetings: state => state.items,
  getMeetingsReport: state => state.meetingsReport,
  dialogNew: state => state.dialogNew
};

// actions
const actions = {
  getMeetings ({ commit, rootState }, period) {
    meeting.getMeetings(period,rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      commit('setMeetings', response.data)
    })
  },
  createMeeting ({ commit, rootState }, newMeeting) {
    meeting.createMeeting(newMeeting, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      commit('addMeeting', response.data)
    })
  },
  editMeeting ({ commit }, editedMeeting) {
    meeting.editMeeting(editedMeeting).then(response => {
      commit('editMeeting', response.data)
    })
  },
  deleteMeeting ({ commit }, deletedMeetingId) {
    meeting.deleteMeeting(deletedMeetingId).then(response => {
      if (response.status == 200) {
        commit('deleteMeeting', deletedMeetingId)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  createReport ({ store, commit, rootState }, { statusType: statusType = 1, perUser: perUser = false} = {} ) {
    const startOfMonth = moment(rootState.monthSelected).startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment(rootState.monthSelected).endOf('month').format('YYYY-MM-DD');
    report.createReport({ date_from: startOfMonth, date_to: endOfMonth, status: statusType, type: "zjazd"}, perUser ? rootState.users.chosenUser : null).then(response => {
      commit('addMeetingReport', response.data)
    })
  },
  acceptReport ({ dispatch}) {
    return dispatch('createReport', { statusType: 2, perUser: true });
  },
  declineReport ({ dispatch }) {
    return dispatch('createReport', { statusType: 0, perUser: true });
  }
};

// mutations
const mutations = {
  setMeetings (state, meetings) {
    state.items = meetings.items
    state.meetingsReport = meetings.meeting_report
  },
  addMeeting (state, meeting) {
    state.items.push(meeting)
  },
  editMeeting (state, meeting) {
    let index = state.items.findIndex(m => m.id === parseInt(meeting.id))
    state.items[index].start = meeting.start
    state.items[index].end = meeting.end
  },
  deleteMeeting (state, meetingId) {
    var lists = state.items.filter(x => {
      return x.id != meetingId;
    });
    state.items = lists
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  addMeetingReport (state, meetingReportStatus) {
    state.meetingsReport = meetingReportStatus.status
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
