<template>
    <v-container>
      <v-card>
        <v-card-title>
          {{ mentor.firstname }} {{ mentor.lastname }}<v-btn class="overline ml-5" color="grey" text @click="$router.push('/mentors')"><v-icon left small>arrow_back</v-icon> powrót do listy</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="userRole == 1 || userRole == 8" class="mb-2 ml-4" color="primary" dark rounded @click="editItem()"><v-icon>mdi-pencil</v-icon> Edytuj</v-btn>
          <v-btn v-if="userRole == 3" class="mb-2 ml-4" color="primary" dark rounded @click="downloadBusinessCardByUrl(mentor.business_card)"><v-icon class="mr-2">description</v-icon> pobierz wizytówkę w pdf</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6" sm="12">
              <div><b>Imię</b></div>
              {{ mentor.firstname }}
            </v-col>
            <v-col md="6" sm="12">
              <div><b>Nazwisko</b></div>
              {{ mentor.lastname }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="12">
              <div><b>Telefon</b></div>
              {{ mentor.telephone }}
            </v-col>
            <v-col md="6" sm="12">
              <div><b>E-mail</b></div>
              {{ mentor.email }}
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="mentor.business_card" md="6" sm="12">
              <div><b>Wizytówka PDF</b></div>
              <a @click="downloadBusinessCardByUrl(mentor.business_card)">{{ mentor.firstname + ' ' + mentor.lastname  }}.pdf</a>
            </v-col>
<!--            <v-col md="6" sm="12" v-if="userRole == 1 || userRole == 8">-->
<!--              <v-file-input-->
<!--                  v-model="businessCard"-->
<!--                  :rules="rules"-->
<!--                  accept="application/pdf"-->
<!--                  :placeholder = getBusinessCardLabel-->
<!--                  prepend-icon="mdi-upload"-->
<!--                  :label=getBusinessCardLabel-->
<!--              ></v-file-input>-->
<!--            </v-col>-->
          </v-row>
          <v-row>
            <v-col v-if="mentor.document" md="6" sm="12">
              <div><b>Dokument</b></div>
              <a @click="downloadDocumentByUrl(mentor.document)">dokument.pdf</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div><b>Tagi</b></div>
              <div v-if="this.mentorsTags(this.mentor.id).length > 0"s>
                <v-chip
                    v-for="tag in this.mentorsTags(this.mentor.id)"
                    class="mr-4"
                    label
                >
                  {{ tag.name }}
                </v-chip>
              </div>
              <div v-else class="ma-4">
                Brak tagów
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div><b>Opis</b></div>
              <span style="white-space: pre;">{{ mentor.description }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialog" max-width="800px">
        <MentorAddEdit v-if="userRole == 1 || userRole == 8" :editedIndex="editedIndex" :editedItem="editedItem" formTitle="Edytuj mentora" @dialogClosed="dialog = false" />
      </v-dialog>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MentorAddEdit from "./MentorAddEdit.vue";

export default {
    name: "Mentor",
  components: {
    MentorAddEdit
  },
    data () {
      return {
        dialogEditSuccess: false,
        dialog: false
      }
    },
    methods: {
      ...mapActions({
        downloadBusinessCardByUrl: 'mentors/downloadBusinessCardByUrl',
        downloadDocumentByUrl: 'mentors/downloadDocumentByUrl',
      }),
      editItem () {
        this.editedIndex = this.mentors.indexOf(this.mentor);
        this.editedItem = Object.assign({}, this.mentor);
        this.editedItem.tags = this.mentorsTags(this.mentor.id).map(x => x.id);
        this.dialog = true;
      }
    },
    computed: {
      ...mapGetters({
        mentor: 'mentors/getMentorSelected',
        tags: 'tags/getTagsSearched',
        mentors: 'mentors/getMentors',
        mentorsTags: 'mentors/getMentorsTags',
        userRole: 'users/userRole',
      })
    }
  }
</script>

<style scoped>

</style>
