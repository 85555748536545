<template>
    <v-btn @click="generatePDFReport()" color="primary" dark rounded small>Rachunek kilometrówka</v-btn>
</template>

<script>
  import {createHelpers} from "vuex-map-fields";

  const { mapFields } = createHelpers({
    getterType: 'registration/getPersonal',
  });

  export default {
    name: "BillKm",
    computed: {
      ...mapFields({
        // personal
        personalSurname: 'personal.surname',
        personalName: 'personal.name',
        addressStreet: 'address.street',
        addressHouseNr: 'address.houseNr',
        addressHomeNr: 'address.homeNr',
        addressZip: 'address.zip',
        addressCity: 'address.city',
      }),
    },
    methods: {
      generatePDFReport () {
        var pdfMake = require('pdfmake/build/pdfmake.js');
        if (pdfMake.vfs == undefined){
          var pdfFonts = require('pdfmake/build/vfs_fonts.js');
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          content: [
            {
              alignment: 'justify',
              columns: [
                {
                  text: this.personalName + ' ' + this.personalSurname + '\n' + this.addressStreet + ' ' + this.addressHouseNr + '' + ((this.addressHomeNr != undefined) ? '/' + this.addressHomeNr : '') + '\n' + this.addressZip + ' ' + this.addressCity,
                  margin: [0, 0, 0, 40],
                },
                {
                  text: 'Płock, dnia: ................................ r.',
                  margin: [0, 0, 0, 40],
                  alignment: 'right',
                }
              ]
            },
            {
              stack: [
                'Rachunek',
              ],
              style: 'header'
            },
            {
              stack: [
                'do umowy – zlecenie nr ………………….. zawartej dnia …………………………..\n' +
                'z GTE GLOBAL TRADE AND EDUCATION Sp. z o.o. Al. J. Piłsudskiego 35 lok 3, Płock\n',
              ],
              lineHeight: 2,
              alignment: 'center',
              style: 'bold'
            },
            {
              stack: [
                'za: ',
              ],
              margin: [0, 0, 0, 10]
            },
            {
              stack: [
                'przejazd do i z miejsca prowadzenia zajęć dla słuchaczy studiów podyplomowych \n w okresie od…………………………. do …………………………………. ',
              ],
              lineHeight: 2,
              alignment: 'center',
              margin: [0, 0, 0, 20],
            },
            {
              stack: [
                'Do wypłaty kwota brutto: …………………………. zł (zgodnie z ewidencją przebiegu pojazdu) ',
              ],
              lineHeight: 2,
              margin: [0, 0, 0, 20],
            },
            {
              stack: [
                'Słownie: …………………………………………………………………………………',
              ],
              lineHeight: 2,
              margin: [0, 0, 0, 20],
            },
            {
              stack: [
                'Oświadczam, że otrzymane należności z tytułu używania prywatnego pojazdu, zgodnie z ww. umową zlecenie, nie zostały przeze mnie zaliczone do kosztów podatkowych oraz zostały poniesione w celu realizacji zawartej z Wnioskodawcą umowy zlecenie nr……... z dnia……...',
              ],
              lineHeight: 2,
              style: 'bold'
            },
            {
              alignment: 'justify',
              columns: [
                {
                  text: '',
                  margin: [0, 30, 0, 0],
                },
                {
                  text: '................................................................ ',
                  margin: [0, 30, 0, 0],
                  alignment: 'right',
                }
              ],
            },
            {
              alignment: 'justify',
              columns: [
                {
                  text: '',
                  margin: [0, 0, 0, 0],
                },
                {
                  text: 'Podpis wystawcy rachunku',
                  margin: [0, 0, 0, 20],
                  alignment: 'right',
                }
              ]
            },
            {
              stack: [
                'Do zapłaty przelewem na konto:  ................................................................',
              ],
              margin: [0, 0, 0, 20]
            },
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
              alignment: "center"
            },
            subheader: {
              fontSize: 12,
              margin: [0, 0, 0, 50],
              alignment: "center"
            },
            h3: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 20],
              alignment: "left",
              lineHeight: 2
            },
            defaultStyle: {
              lineHeight: 2
            },
            bold : {
              bold: true
            }
          }
        };
        pdfMake.createPdf(docDefinition).download('unikadra-rachunek-kilometrowka.pdf')
      }

    }
  }

</script>

<style scoped>

</style>
