import Api from './Api'

export default {
  getHistoryByUserId (uid) {
    return Api().get('/api/history/user/' + uid, { withCredentials: true })
  },
  getHistoryRecent() {
    return Api().get('/api/history', { withCredentials: true })
  },
  markAsRead (items) {
    return Api().post('/api/history/mark-as-read-multiple', items)
  },
}
