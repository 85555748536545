<template>
  <div>
    <v-dialog v-model="dialogReport" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Potwierdzenie</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                Oświadczam, iż dane zawarte w kwestionariuszu zleceniobiorcy (w szczególności dotyczy punktu II – DANE DO UBEZPIECZENIA) do umowy zlecenie:
                <v-radio-group v-model="settingsPersonalChanged" column>
                  <v-radio value="false">
                    <template v-slot:label>
                      <p>nie uległy zmianie</p>
                    </template>
                  </v-radio>
                  <v-radio value="true">
                    <template v-slot:label>
                      <p>uległy zmianie</p>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col sm="12" v-if="settingsPersonalChanged == 'true'">
                <v-btn rounded color="primary" @click="editSettings()">Edytuj kwestionariusz zleceniobiorcy</v-btn>
              </v-col>
              <v-col sm="12" v-if="settingsPersonalChanged != false">
                <v-btn rounded color="primary" outlined @click="downloadStatement()">Pobierz oświadczenie</v-btn>
              </v-col>
              <v-col sm="12" v-if="settingsPersonalChanged != false">
                lub
              </v-col>
              <v-col sm="12" v-if="settingsPersonalChanged != false">
                <v-btn rounded color="primary" outlined @click="createReport(); dialogReport = false">Wyślij raport</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-divider></v-divider>
    <div class="mt-4 mb-4 mr-4 d-flex justify-end" v-if="showSendForAcceptance">
      <span
          class="mr-4">Prześlij do akceptacji ewidencję przepracowanych godzin za miesiąc: {{ currentMonthReadable }}</span>
      <v-btn @click="createReportDialog" color="primary" dark rounded small>Prześlij do akceptacji</v-btn>
    </div>
    <div class="mt-4 mb-4 mr-4 d-flex justify-end" v-if="showWaitingForAcceptance">
      <span class="mr-4">Ewidencja przepracowanych godzin za miesiąc: {{ currentMonthReadable }} przesłana. Oczekiwanie na akceptację.</span>
    </div>
    <div class="mt-4 mb-4 mr-4" v-else-if="showGenerateReport">
      <v-row>
        <v-col class="d-flex justify-end" md="10">
          <div v-if="chosenUserRole == 0 || chosenUserRole == 5 || chosenUserRole == 1 || chosenUserRole == 7 || chosenUserRole == 8">
            <div class="mr-4 ml-4">
              <span class="mr-4 ml-4" v-if="showStatusAcceptedReport">
                <span class="mr-4">Ewidencja przepracowanych godzin za miesiąc: {{ currentMonthReadable }}</span>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon class="green--text" v-bind="attrs" v-on="on">checked</v-icon>
                                    </template>
                                    <span>Zaakceptowana</span>
                                  </v-tooltip>
                                </span>
              <span class="mr-4 ml-4" v-else-if="showStatusDeclinedReport">
                <span class="mr-4">Ewidencja przepracowanych godzin za miesiąc: {{ currentMonthReadable }}</span>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon class="red--text" v-bind="attrs" v-on="on">warning</v-icon>
                                    </template>
                                    <span>Odrzucona</span>
                                  </v-tooltip>
                                </span>
            </div>
            <div class="mr-4 ml-4" v-if="showAcceptOrDeclineReport">
              <span class="mr-4">Ewidencja przepracowanych godzin za miesiąc: {{ currentMonthReadable }}</span>
              <v-btn @click="acceptReport()" rounded class="success">akceptuj</v-btn>
              &nbsp
              <v-btn @click="declineReport()" rounded class="error">odrzuć</v-btn>
            </div>
            <!--                            <div class="mr-4 ml-4 font-weight-thin">Data przesłania do rozliczenia:</div>-->
          </div>
        </v-col>
        <v-col v-if="showGenerateReportButton" class="d-flex justify-end" md="2">
          <v-btn @click="generatePDFReport" color="primary" dark rounded small>Generuj plik PDF</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: 'TimesReportStatus',
  data: () => ({
    dialogReport: false,
    settingsPersonalChanged: false,
  }),
  props: {
    currentMonthReadable: {},
    username: {},
    contractDetails: {}
  },
  computed: {
    ...mapGetters({
      times: 'times/getTimes',
      timesReport: 'times/getTimesReport',
      userRole: 'users/userRole',
      monthSelected: 'monthSelected',
      chosenUserRole: 'users/getChosenUserRole'
    }),
    showSendForAcceptance () {
      return (this.times.length > 0 && this.timesReport == 0 && (this.userRole == 0 || this.userRole == 5 || this.userRole == 7 || this.userRole == 8)) ? true : false;
    },
    showWaitingForAcceptance () {
      return  (this.timesReport == 1 && (this.userRole == 0 || this.userRole == 5 || this.userRole == 7 || this.userRole == 8)) ? true : false;
    },
    showGenerateReport () {
      return (this.timesReport == 2 || this.userRole == 1) ? true : false;
    },
    showGenerateReportButton () {
      return (this.times.length > 0 && (this.timesReport == 2 || this.userRole == 1)) ? true : false;
    },
    showAcceptOrDeclineReport () {
      return (this.timesReport == 1 && this.userRole == 1) ? true : false;
    },
    showStatusAcceptedReport () {
      return (this.timesReport == 2) ? true : false;
    },
    showStatusDeclinedReport () {
      return (this.times.length > 0 && this.timesReport == 0) ? true : false;
    }
  },
  methods: {
    ...mapActions({
      createReport: 'times/createReport',
      acceptReport: 'times/acceptReport',
      declineReport: 'times/declineReport',
    }),
    editSettings () {
      this.$store.commit('setActiveSettingsTab', 0)
      this.$router.push('settings')
    },
    contractNumber() {
      let contracts = this.contracts.filter(c => c.uid == item.id);
      return moment(Math.max(...contracts.map(e => new Date(e.end)))).format('DD.MM.YYYY');
    },
    generatePDFReport () {
      var table = [];
      table.push(["DZIEŃ MIESIĄCA", "LICZBA GODZIN WYKONANIA UMOWY ZLECENIA", "MIEJSCOWOŚĆ", "UWAGI"]);
      var tableBody = table.concat(this.times.map(function (obj) {
        return [new Date(obj.date).toISOString().substr(0, 10), obj.hours, obj.city, obj.comment]
      }));
      tableBody.push(["Liczba godzin wykonywania umowy zlecenia", this.times.map(item => item.hours).reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0), "", ""])

      var pdfMake = require('pdfmake/build/pdfmake.js');
      if (pdfMake.vfs == undefined){
        var pdfFonts = require('pdfmake/build/vfs_fonts.js');
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      let title = "";
      if (this.contractDetails != null) {
        title = 'Ewidencja godzin wykonywania umowy  zlecenia\n nr ' + this.contractDetails.number + ' obowiązującej od dnia ' + this.contractDetails.start;
      } else {
        title = 'Ewidencja godzin wykonywania umowy zlecenia\nzawartej w dniu ……………………………….';
      }

      var docDefinition = {
        content: [
          {
            stack: [
                title
            ],
            style: 'header'
          },
          {
            text: 'Miesiąc: ' + this.monthSelected.substr(0, 7),
            margin: [0, 20, 0, 0],
          },
          {
            text: 'Nazwisko i imię Zleceniobiorcy: ' + this.username,
            margin: [0, 0, 0, 20],
          },
          {
            style: 'tableExample',
            table: {
              body: tableBody
            }
          },
          {
            stack: [{
              text: [
                '.................................................\n',
                'Podpis Zleceniobiorcy',
              ]
            }],
            style: 'footer'
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 80],
            alignment: "center"
          },
          footer: {
            alignment: "right",
            margin: [0, 20, 0, 20]
          }
        }
      };
      pdfMake.createPdf(docDefinition).download('unikadra-czasy-raport-'+this.monthSelected.substr(0, 7)+'.pdf')
    },
    downloadStatement() {
      var table = [];
      table.push(["DZIEŃ MIESIĄCA", "LICZBA GODZIN WYKONANIA UMOWY ZLECENIA", "MIEJSCOWOŚĆ"]);
      var tableBody = table.concat(this.times.map(function (obj) {
        return [new Date(obj.date).toISOString().substr(0, 10), obj.hours, obj.city]
      }));
      tableBody.push(["Liczba godzin wykonywania umowy zlecenia", this.times.map(item => item.hours).reduce((prev, next) => parseFloat(prev) + parseFloat(next)), ""])

      var pdfMake = require('pdfmake/build/pdfmake.js');
      if (pdfMake.vfs == undefined){
        var pdfFonts = require('pdfmake/build/vfs_fonts.js');
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var docDefinition = {
        content: [
          {
            stack: [
              'OŚWIADCZENIE',
            ],
            style: 'header'
          },
          {
            text: 'Oświadczam, iż dane zawarte w kwestionariuszu zleceniobiorcy (w szczególności dotyczy punktu II – DANE DO UBEZPIECZENIA) do umowy zlecenie nr ............................................ na dzień ' + new Date().toISOString().substr(0, 10),
            margin: [0, 0, 0, 20],
          },
          {
            text: (this.settingsPersonalChanged == 'false'? 'X ' : '') + 'nie uległy zmianie',
            margin: [0, 0, 0, 20],
            style: (this.settingsPersonalChanged == 'false'? 'bold' : '')
          },
          {
            text: (this.settingsPersonalChanged == 'true'? 'X ' : '') + 'uległy zmianie (jeśli dane w jakimkolwiek zakresie uległy zmianie, proszę ponownie wypełnić kwestionariusz zleceniobiorcy)',
            margin: [0, 0, 0, 20],
            style: (this.settingsPersonalChanged == 'true'? 'bold' : '')
          },
          {
            stack: [{
              text: [
                '.................................................\n',
                'Podpis Zleceniobiorcy',
              ]
            }],
            style: 'footer'
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 80],
            alignment: "center"
          },
          bold: {
            bold: true,
          },
          footer: {
            alignment: "right",
            margin: [0, 20, 0, 20]
          }
        }
      };
      pdfMake.createPdf(docDefinition).download('unikadra-oswiadczenie.pdf')
    },
    createReportDialog() {
      this.dialogReport = true;
    },
  }
}
</script>
