<template>
  <v-data-table
          :headers="headers"
          :items="users"
          sort-by="date"
          show-select
          :single-select=singleSelect
          class="elevation-1 ma-4"
          v-model="selected"
          :search="options.search"
          :options.sync="options"
          v-if="userRole == 1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista wszystkich wykładowców</v-toolbar-title>
        <v-divider
                class="mx-4"
                inset
                vertical
        ></v-divider>
        <v-text-field
            v-model="options.search"
            append-icon="search"
            label="Szukaj"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczonych wykładowców</v-btn>
        <v-dialog v-model="dialog" max-width="800px" @input="v => v || close()">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodaj nowego wykładowcę</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field v-model="editedItem.email" :error-messages="emailErrors" label="Email" required @blur="$v.editedItem.email.$touch()" ></v-text-field>
                    <span v-if="errorEmailUserExists" class="error--text">Taki email już istnieje</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field v-model="editedItem.email_alt" :hint="((editedItem.email_alt !== '') ? 'Zapisany zostanie adres: ' + editedItem.email_alt + '@podyplomowestudia.eu' : 'Po tym adresie powiążemy użytkownia z kontem Zoom.us')" label="Email w domenie podyplomowestudia.eu" suffix="@podyplomowestudia.eu"></v-text-field>
                    <span v-if="errorEmailUserExists" class="error--text">Taki email już istnieje</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @blur="$v.editedItem.name.$touch()" label="Imie i nazwisko"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field v-model="editedItem.telephone" :error-messages="telephoneErrors" required @blur="$v.editedItem.telephone.$touch()" label="Telefon"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="editedIndex == -1">
                  <v-col cols="12" sm="12" md="6">
                    <v-radio-group v-model="editedItem.is_company" row :error-messages="isCompanyErrors" required>
                      <v-radio value="0" class="d-flex align-start">
                        <template v-slot:label>
                          <p>Pracownik (kwestionariusz)</p>
                        </template>
                      </v-radio>
                      <v-radio value="1" class="d-flex align-start">
                        <template v-slot:label>
                          <p>Firma (dane firmy)</p>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="editedIndex !== -1">
                  <v-col cols="12" sm="12" md="6">
                    <v-switch
                        v-model="editedItem.active"
                        :false-value="0"
                        :true-value="1"
                        :label="`${editedItem.active ? 'Aktywny' : 'Nieaktywny'}`"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-7">
              <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span>porzuc dodawanie i wroc do listy wszystkich wykładowców</span></v-btn>
              <v-spacer></v-spacer>
              <v-btn class="primary" rounded @click="save">{{ formTitle }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Poprawnie dodałeś użytkownika</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    {{ editedItem.name }} {{ editedItem.email }} {{editedItem.telephone }}
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich wykładowców</v-btn>
                  </v-col>
                  <v-col sm="12">
                    lub
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherUser">Chcę dodać kolejnego użytkownika</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie wykładowcę</span>
                    <span v-else class="headline">Usunąłeś poprawnie wykładowców</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    <div v-if="selected.length > 0">
                      <div v-for="item in selected">
                        {{ item.name }} {{ item.email }} {{ item.telephone }}
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <Message :messageForRole="0" :receiver="selectedItems()" :showDialog="dialogMessage" @closeDialogMessage="closeDialogMessage" />
      </v-toolbar>
    </template>
    <template v-slot:item.contract="{ item }">
      <v-tooltip top v-if="!contractExists(item)">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
                   color="red accent-4"
                   icon="error"
          >
            <v-icon
                v-bind="attrs"
                v-on="on">
              event
            </v-icon>
          </v-badge>
        </template>
        <span>Brak umowy</span>
      </v-tooltip>
      <v-tooltip top v-if="contractExpiried(item)">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
                   color="orange accent-4"
                   icon="warning"
          >
            <v-icon
                v-bind="attrs"
                v-on="on">
              event
            </v-icon>
          </v-badge>
        </template>
        <span>{{ daysTillEndOfContract(item) }}.</span>
      </v-tooltip>
      <v-tooltip top v-else-if="contracts.filter(c => c.uid == item.id).length > 0 && item.active">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
              color="green accent-0"
              dot
              overlap
          >
            <v-icon
                v-bind="attrs"
                v-on="on">
              event
            </v-icon>
          </v-badge>
        </template>
        <span>Umowa od: {{ startOfContractDate(item) }} do: {{ endOfContractDate(item) }}</span>
      </v-tooltip>

      <!-- history -->
      <v-tooltip top v-if="history(item.id)">
        <template v-slot:activator="{ on, attrs }">
        <v-icon
            color="red"
            v-bind="attrs"
            v-on="on">
          warning
        </v-icon>
        </template>
        <span>W ciągu ostatniego miesiąca wprowadzono zmiany w kwestionariuszu osobowym</span>
      </v-tooltip>
    </template>
    <template v-slot:item.date="{ item }">
      {{ new Date(item.date).toISOString().substr(0, 10) }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
              @click="setChosenUser(item.id)"
              small
              class="mr-2"
      >
        zoom_in
      </v-icon>
      <v-btn icon>
        <v-icon
            @click="editItem(item)"
            small
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:no-data>
      Brak użytkownikow
    </template>
    <template v-slot:footer>
      <v-divider></v-divider>
      <div class="mt-4 mb-4 mr-4">
        <v-row>
          <v-col class="d-flex justify-end" md="12">
            <v-btn :color=buttonForRemove class="mr-4" dark rounded small @click="newMessage()">Napisz wiadomość</v-btn>
            <v-btn @click="generateContractsReport()" class="mr-4" color="primary" dark rounded small>Generuj raport - zakres umów</v-btn>
            <v-btn @click="generateUsersReport()" color="primary" dark rounded small>Generuj raport wszystkich wykładowców</v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {numeric, required} from "vuelidate/lib/validators";
import moment from 'moment';
import {ComponentWithPagingEnum} from "../enums";
import Message from "../components/Message.vue";

export default {
  components: {Message},
    mixins: [validationMixin],

    validations: {
      editedItem: {
        email: {required},
        name: {required},
        telephone: {required, numeric},
        is_company: {required}
      }
    },
    name: "Users",
    computed: {
      ...mapGetters({
        users: 'users/getUsers',
        dialogFromStore: 'users/dialogNew',
        loggedInUser: 'users/getLoggedInUser',
        contracts: 'contracts/getContracts',
        history: 'history/getHistoryRecent',
        getPage: 'paging/getPage',
        userRole: 'users/userRole',
      }),
      buttonForRemove () {
        if (this.selected.length > 0) {
          return 'primary'
        } else {
          return 'secondary'
        }
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Dodaj nowego wykładowcę' : 'Edytuj wykładowcę'
      },
      emailErrors () {
        const errors = [];
        if (!this.$v.editedItem.email.$dirty) return errors;
        !this.$v.editedItem.email.required && errors.push('Podanie emaila jest wymagane');
        return errors
      },
      nameErrors () {
        const errors = [];
        if (!this.$v.editedItem.name.$dirty) return errors;
        !this.$v.editedItem.name.required && errors.push('Podanie imienia i nazwiska jest wymagane');
        return errors
      },
      telephoneErrors () {
        const errors = [];
        if (!this.$v.editedItem.telephone.$dirty) return errors;
        !this.$v.editedItem.telephone.required && errors.push('Podanie numeru telefonu jest wymagane');
        !this.$v.editedItem.telephone.numeric && errors.push('Proszę podać wyłącznie cyfry, bez przerw, myślników i numeru kierunkowego');
        return errors
      },
      isCompanyErrors () {
        const errors = [];
        if (!this.$v.editedItem.is_company.$dirty) return errors;
        !this.$v.editedItem.is_company.required && errors.push('Podanie rodzaju umowy jest konieczne');
        return errors
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          return this.setDialogNew(value)
        }
      }
    },
    mounted () {
      this.setSelectedComponent(ComponentWithPagingEnum.users);
      this.options = this.getPage;
    },
    created() {
      this.$store.dispatch('users/getUsers');
      this.$store.dispatch('history/getHistoryRecent');
      this.$store.dispatch('notifications/getNotifications');
      this.$store.dispatch('subjects/getSubjects');
      // this.$store.dispatch('chat/getLastMessages');
    },
    data: () => ({
      selected: [],
      dialogNewSuccess: false,
      dialogRemoveSuccess: false,
      showContractTooltip: false,
      headers: [
        { text: 'Imię i nazwisko', value: 'name' },
        { text: '', value: 'contract' },
        { text: 'RODO', value: 'rodo' },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          value: 'email',
        },
        { text: 'Telefon', value: 'telephone' },
        { text: '', value: 'action', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        email: '',
        email_alt: '',
        telephone: '',
        user_role: 0,
        active: 1
      },
      defaultItem: {
        name: '',
        email: '',
        email_alt: '',
        telephone: '',
        user_role: 0,
        active: 1
      },
      singleSelect: false,
      errorEmailUserExists: false,
      options: {},
      dialogMessage: false,
    }),
    watch: {
      dialogNewSuccess (val) {
        val || setTimeout(() => {
          this.clearEditItem()
        }, 300)
      },
      options: {
        handler () {
          this.handlePage()
        },
        deep: true,
      },
    },
    methods: {
      ...mapActions({
        setDialogNew: 'users/setDialogNew',
        createUser: 'users/createUser',
        editUser: 'users/editUser',
        deleteUsers: 'users/deleteUsers',
        setChosenUserBasic: 'users/setChosenUserBasic',
        setChosenUser: 'users/setChosenUser',
        setPage: 'paging/setPage',
        setSelectedComponent: 'paging/setSelectedComponent',
        clearPage1: 'paging/clearPage',
      }),
      clearPage () {
        this.clearPage1();
        this.options.page = 1;
      },
      handlePage() {
        let { sortBy, sortDesc, page, itemsPerPage, search } = this.options;
        this.setPage({ sortBy: sortBy, sortDesc: sortDesc, page: page, itemsPerPage: itemsPerPage, search: search })
      },
      contractExists(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        if (contracts.length > 0) {
          return true;
        }
        return false;
      },
      contractExpiried(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        if (contracts.length && item.active) {
          return ((moment().add(1, 'M') > moment(Math.max(...contracts.map(e => new Date(e.end))))) ? true : false);
        } else {
          return false;
        }
      },
      daysTillEndOfContract(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        item.contract_to = Math.max(...contracts.map(e => new Date(e.end)));

        return moment(item.contract_to).diff(moment(), 'days') < 0 ? 'Skończyła się ' + moment().diff(moment(item.contract_to), 'days') + ' dni temu' : 'Kończy się za ' + moment(item.contract_to).diff(moment(), 'days') + ' dni';
      },

      startOfContractDate(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        return moment(Math.max(...contracts.map(e => new Date(e.start)))).format('DD.MM.YYYY');
      },
      endOfContractDate(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        return moment(Math.max(...contracts.map(e => new Date(e.end)))).format('DD.MM.YYYY');
      },
      editItem (item) {
        this.setChosenUserBasic(item.id)
        this.editedIndex = this.users.indexOf(item);
        this.editedItem = Object.assign({}, item.email_alt && item.email_alt.slice(-20) == 'podyplomowestudia.eu' ? {...item, email_alt: item.email_alt.slice(0, -21)} : item);
        this.dialog = true
      },
      clearEditItem() {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1
      },
      close () {
        this.dialog = false
        this.clearEditItem()
      },
      closeDialogNewSuccessAndAddAnotherUser () {
        this.dialogNewSuccess = false;
        this.setDialogNew(true)
        this.clearEditItem()
      },
      closeDialogRemoveSuccess () {
        this.dialogRemoveSuccess = false;
        this.selected = []
        this.clearEditItem()
      },
      save () {
        this.$v.$touch();

        function addSuffixIfRequired() {
          return this.editedItem.email_alt && this.editedItem.email_alt.slice(-20) !== 'podyplomowestudia.eu' ? {
            ...this.editedItem,
            email_alt: this.editedItem.email_alt + '@podyplomowestudia.eu'
          } : this.editedItem;
        }

        if (this.editedIndex > -1) {
          if (!this.$v.$invalid) {
            this.editUser(addSuffixIfRequired.call(this)).then(response => {
              if (response == 200) {
                this.$log.info('Saved edited user', response);
                Object.assign(this.users[this.editedIndex], this.editedItem);
                this.close()
              }
            }).catch(error => {
              this.$log.error('Saving edited user error', error)
            })
          }
        } else {
          if (!this.$v.$invalid) {
            this.createUser(addSuffixIfRequired.call(this)).then(response => {
              this.$log.info('Saved new user', response);
              this.close();
              this.dialogNewSuccess = true
            }).catch(error => {
              if(error == 'User exists') {
                this.errorEmailUserExists = true;
              }
              this.$log.error('Saving new user error', error)
            })
          }
        }
      },
      removeSelected () {
        if (this.selected.length == 0) {
          return false
        }
        if(confirm('Czy na pewno chcesz usunąć tego wykładowcę?')){
          this.deleteUsers(this.selected).then(response => {
            this.$log.info('Deleted user', response);
            this.dialogRemoveSuccess = true
          }).catch(error => {
            this.$log.error('Deleting user error', error)
          })
        }
      },
      generateContractsReport () {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          "LP;Imię i nazwisko;Umowa od;Umowa do",
          ...this.users.map(item => item.id + ';' + item.name + ';' + this.startOfContractDate(item) + ';' + this.endOfContractDate(item))
        ].join("\n")

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "wykladowcy-umowy-raport.csv");
        link.click();
      },
      generateUsersReport () {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          "LP;Imię i nazwisko;Telefon;Email",
          ...this.users.map(item => item.id + ';' + item.name + ';' + item.telephone + ';' + item.email)
        ].join("\n")

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "wykladowcy-raport.csv");
        link.click();
      },
      newMessage() {
        if (this.selected.length == 0) {
          return false
        }
        this.dialogMessage = true;
      },
      closeDialogMessage(val) {
        this.dialogMessage = val;
      },
      selectedItems() {
        let mapped = Object.assign({}, this.selected.map(x => x.email ));
        let mapped_uid = Object.assign({}, this.selected.map(x => x.id ));
        return { email: mapped, subject: '', uid: mapped_uid };
      },
    }
  }
</script>

<style scoped>
  .toPrint {
    display: none;
  }
</style>

