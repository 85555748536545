import trip from '../../services/Trip'
import report from '../../services/Report'
import moment from "moment";

// initial state
const state = {
  items: [],
  tripsReport: 0,
  dialogNew: false
};

// getters
const getters = {
  getTrips: state => state.items,
  getTripsReport: state => state.tripsReport,
  dialogNew: state => state.dialogNew
};

// actions
const actions = {
  getTrips ({ commit, rootState }, period) {
    trip.getTrips(period, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      commit('setTrips', response.data)
    })
  },
  createTrip ({ commit, rootState }, newTrip) {
    return new Promise((resolve, reject) => {
      trip.createTrip(newTrip, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
        if (response.status == 200) {
          commit('addTrip', response.data)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  editTrip ({ commit, rootState }, editedTrip) {
    return new Promise((resolve, reject) => {
      trip.editTrip(editedTrip, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
        if (response.status == 200) {
          commit('editTrip', response.data)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  deleteTrips ({ commit, rootState }, deletedTrips) {
    trip.deleteTrips(deletedTrips.map(function(item) { return item["id"] }), rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      console.log(response.status);
      if (response.status == 200) {
        commit('deleteTrips', deletedTrips)
      }
    })
  },
  cloneTrip ({ commit, rootState }, clonedTrip) {
    trip.cloneTrip(clonedTrip.id, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      if (response.status == 200) {
        commit('cloneTrip', response.data)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  createReport ({ store, commit, rootState }, { statusType: statusType = 1, perUser: perUser = false} = {} ) {
    const startOfMonth = moment(rootState.monthSelected).startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment(rootState.monthSelected).endOf('month').format('YYYY-MM-DD');
    report.createReport({ date_from: startOfMonth, date_to: endOfMonth, status: statusType, type: "przejazd"}, perUser ? rootState.users.chosenUser : null).then(response => {
      commit('addTripReport', response.data)
    })
  },
  acceptReport ({ dispatch}) {
    return dispatch('createReport', { statusType: 2, perUser: true });
  },
  declineReport ({ dispatch }) {
    return dispatch('createReport', { statusType: 0, perUser: true });
  }
};

// mutations
const mutations = {
  setTrips (state, trips) {
    state.items = trips.items;
    state.tripsReport = trips.trip_report
  },
  addTrip (state, trip) {
    state.items.push(trip)
  },
  editTrip (state, trip) {
    console.log(trip)
  },
  deleteTrips (state, trips) {
    trips.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  cloneTrip (state, trip) {
    state.items.push(trip)
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  addTripReport (state, tripReportStatus) {
    state.tripsReport = tripReportStatus.status
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
