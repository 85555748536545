<template>
    <v-badge
            overlap
            bottom
            color="orange"
    >
        <template v-slot:badge>
            <v-icon @click="editAvatar">edit</v-icon>
        </template>
        <v-avatar size="100">
            <v-img :src="avatar()" :alt="loggedInUser.name" aspect-ratio="1" :key="loggedInUserAvatarReload" />
        </v-avatar>
    </v-badge>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: 'AvatarDisplay',
    computed: {
      ...mapGetters({
        loggedInUser: 'users/getLoggedInUser',
        loggedInUserAvatarReload: 'users/getLoggedInUserAvatarReload'
      })
    },
    methods: {
      avatar () {
        return this.loggedInUser ? "/api/users/" + this.loggedInUser.id + "/avatar" : ''
      },
      editAvatar () {
        this.$store.commit('setActiveSettingsTab', 3)
        this.$router.push('settings')
      }
    }
  }
</script>
