<template>
    <v-container>
        <v-row no-gutters>
            <v-col sm="6">
                <v-text-field
                        v-model="surname"
                        label="Nazwisko"
                        dense
                        :error-messages="surnameErrors"
                        required
                        :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="name"
                        label="Imię"
                        dense
                        :error-messages="nameErrors"
                        required
                        :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-text-field
                        v-model="secondName"
                        label="Drugie imię"
                        dense
                        :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <v-menu
                ref="menuDayOfBirth"
                v-model="menuDayOfBirth"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dayOfBirth"
                    label="Data urodzenia"
                    prepend-icon="event"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="dayOfBirthErrors"
                    required
                    :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dayOfBirth"
                  :active-picker.sync="activePicker"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  :first-day-of-week="1"
                  @change="save"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="placeOfBirth"
                        label="Miejsce urodzenia"
                        dense
                        :error-messages="placeOfBirthErrors"
                        required
                        :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';
import {mapGetters} from "vuex";

const { mapFields } = createHelpers({
    getterType: 'registrationStudent/getPersonal',
    mutationType: 'registrationStudent/updatePersonal',
  });

  export default {
    name: "Personal",
    props: [
      'surnameErrors',
      'nameErrors',
      'placeOfBirthErrors',
      'dayOfBirthErrors',
    ],
    data: () => ({
      menuDayOfBirth: false,
      activePicker: null,
    }),
    watch: {
      menuDayOfBirth (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
    methods: {
      save (date) {
        this.$refs.menuDayOfBirth.save(date)
      },
    },
    computed: {
      ...mapGetters({
        student: 'studentCourses/getChosenStudentCourseData',
      }),
      ...mapFields([
        'personal.surname',
        'personal.name',
        'personal.secondName',
        'personal.placeOfBirth',
        'personal.dayOfBirth',
      ]),
    },
  }
</script>

<style scoped>

</style>
