<template>
  <v-card class="mb-4">
    <v-card-text>
      <v-row>
        <v-col sm="6">
          <div><b>Termin rozpoczęcia</b></div>
          {{ studentCourse.date }}
        </v-col>
        <v-col sm="6">
          <div><b>Kierunek studiów</b></div>
          {{ studentCourse.courseName }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div><b>Numer albumu</b></div>
          {{ studentCourse.album_nr }}
        </v-col>
        <v-col sm="6">
          <div><b>Miasto</b></div>
          {{ studentCourse.cityName }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div><b>Koszt czesnego</b></div>
          {{ studentCourse.price/100 }}zł
        </v-col>
        <v-col sm="6">
          <div><b>Słuchacza dodał</b></div>
          {{ studentCourse.coordinator }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div><b>Numer rachunku bankowego</b></div>
          {{ studentCourse.studentCourseBankAccount ? studentCourse.studentCourseBankAccount : studentCourse.bank_account
          }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="allowEdit" class="py-4 px-4">
      <v-spacer></v-spacer>
      <v-btn
          v-if="userRole !== 6"
          color="primary"
          rounded
          @click="editStudentCourse(studentCourse)"
      >
        Edytuj
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  name: 'StudentCourseView',
  props: {
    studentCourse: {},
    allowEdit: {}
  },
  methods: {
    editStudentCourse(studentCourse) {
      this.$emit('triggerEditStudentCourse', studentCourse)
    },
  },
  computed: {
    ...mapGetters({
      userRole: 'users/userRole',
    }),
  }
}
</script>
