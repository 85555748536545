<template>
  <v-data-table
      :headers="headers"
      :items="candidates"
      sort-by="date"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
      :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Kandydaci</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="search"
            label="Szukaj"
            single-line
            hide-details
        ></v-text-field>
      </v-toolbar>
      <v-toolbar flat color="white">
        <SelectPlaces @placeChanged="placeChanged"/>
        <SelectCourses @courseChanged="courseChanged"/>
        <v-spacer></v-spacer>
        <v-btn :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczonych kandydatów</v-btn>
        <v-dialog v-model="dialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col sm="6">
                    <v-menu
                        ref="menuDate"
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :return-value.sync="editedItem.date"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="editedItem.date"
                            label="Data zgłoszenia"
                            append-icon="event"
                            readonly
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.date" :first-day-of-week="1" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuDate = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menuDate.save(editedItem.date)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.username" :error-messages="usernameErrors" required @blur="$v.editedItem.username.$touch()" label="Imię i Nazwisko"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field v-model="editedItem.telephone" :error-messages="telephoneErrors" required @blur="$v.editedItem.telephone.$touch()" label="Telefon"></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field v-model="editedItem.email" :error-messages="emailErrors" required @blur="$v.editedItem.email.$touch()" label="Email"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                    <v-select v-model="editedItem.cid"
                              :items="courses"
                              item-value="id"
                              menu-props="auto"
                              label="Kierunek studiów"
                              single-line
                              :error-messages="courseErrors"
                              required
                              @blur="$v.editedItem.cid.$touch()"
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.name }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col sm="6">
                    <v-select v-model="editedItem.cid_2"
                              :items="courses"
                              item-value="id"
                              menu-props="auto"
                              label="Drugi kierunek"
                              single-line
                              @blur="$v.editedItem.cid_2.$touch()"
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.name }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                    <v-select v-model="editedItem.pid"
                              :items="places"
                              item-value="id"
                              menu-props="auto"
                              label="Ośrodek"
                              single-line
                              :error-messages="placeErrors"
                              required
                              @blur="$v.editedItem.pid.$touch()"
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.name }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-textarea v-model="editedItem.comment" label="Uwagi"></v-textarea>
                  </v-col>
                </v-row>
                <v-checkbox
                    v-model="editedItem.question_1"
                    :label="`Kierownik wycieczek szkolnych`"
                ></v-checkbox>
                <v-checkbox
                    v-model="editedItem.question_2"
                    :label="`Zarządzanie projektami współfinansowanymi ze środków UE`"
                ></v-checkbox>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-7">
              <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span>porzuc dodawanie i wroc do listy wszystkich kandydatów</span></v-btn>
              <v-spacer></v-spacer>
              <v-btn class="primary" rounded @click="save">{{ formTitle }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Poprawnie dodałeś użytkownika</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    {{ editedItem.name }} {{ editedItem.email }} {{editedItem.telephone }}
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich kandydatów</v-btn>
                  </v-col>
                  <v-col sm="12">
                    lub
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherUser">Chcę dodać kolejnego użytkownika</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie kandydata</span>
                    <span v-else class="headline">Usunąłeś poprawnie kandydatów</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    <div v-if="selected.length > 0">
                      <div v-for="item in selected">
                        {{ item.name }} {{ item.email }} {{ item.telephone }}
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
          @click="setChosenCandidate(item.id); $router.push('/candidates/' + item.id)"
          small
          class="mr-2"
      >
        zoom_in
      </v-icon>
      <v-btn icon>
        <v-icon
            @click="editItem(item)"
            small
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:no-data>
      Brak kandydatów
    </template>
    <template v-slot:footer>
      <v-divider></v-divider>
      <div class="mt-4 mb-4 mr-4">
        <v-row>
          <v-col class="d-flex justify-end" md="12">
            <v-btn @click="generateCandidatesReport()" color="primary" dark rounded small>Pobierz listę CSV</v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import SelectPlaces from "./SelectPlaces";
import SelectCourses from "./SelectCourses";

import moment from "moment";

export default {
  mixins: [validationMixin],

  validations: {
    editedItem: {
      date: {required},
      username: {required},
      telephone: {required},
      email: {required},
      cid: {required},
      pid: {required}
    }
  },
  name: "Candidates",
  components: { SelectPlaces, SelectCourses },
  filters: {
    moment: function (date) {
      return moment(date).format('MMMM YYYY');
    }
  },
  computed: {
    ...mapGetters({
      candidates: 'candidates/getCandidates',
      dialogFromStore: 'candidates/dialogNew',
      courses: 'courses/getCourses',
      places: 'places/getPlaces',
      placeSelected: 'places/getPlaceSelected',
      courseSelected: 'courses/getCourseSelected',
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodaj nowego kandydata' : 'Edytuj kandydata'
    },
    courseErrors () {
      const errors = [];
      if (!this.$v.editedItem.cid.$dirty) return errors;
      !this.$v.editedItem.cid.required && errors.push('Podanie kierunku studiów jest wymagane');
      return errors
    },
    placeErrors () {
      const errors = [];
      if (!this.$v.editedItem.pid.$dirty) return errors;
      !this.$v.editedItem.pid.required && errors.push('Podanie miejscowości jest wymagane');
      return errors
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
  },
  mounted () {
    // this.$store.dispatch('candidates/getCandidates')
  },
  data: () => ({
    selected: [],
    menuDate: false,
    dialogNewSuccess: false,
    dialogRemoveSuccess: false,
    headers: [
      { text: 'Nazwisko i imię', value: 'username' },
      { text: 'Telefon', value: 'telephone' },
      {
        text: 'Email',
        align: 'left',
        sortable: false,
        value: 'email',
      },
      { text: 'Kierunek', value: 'courseName' },
      { text: 'Miasto', value: 'cityName' },
      { text: '', value: 'action', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      date: '',
      username: '',
      telephone: '',
      email: '',
      cid: '',
      cid_2: '',
      pid: '',
      comment: '',
      question_1: '',
      question_2: ''
    },
    defaultItem: {
      date: '',
      username: '',
      telephone: '',
      email: '',
      cid: '',
      cid_2: '',
      pid: '',
      comment: '',
      question_1: '',
      question_2: ''
    },
    singleSelect: false,
    search: '',
    validate: false,
    validated: false,
    report: false,
  }),
  watch: {
    dialogNewSuccess (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
  },
  methods: {
    ...mapActions({
      setDialogNew: 'candidates/setDialogNew',
      createCandidate: 'candidates/createCandidate',
      editCandidate: 'candidates/editCandidate',
      deleteCandidates: 'candidates/deleteCandidates',
      setChosenCandidate: 'candidates/setChosenCandidate',
      resetChosenCandidate: 'candidates/resetChosenCandidate'
    }),
    editItem (item) {
      this.setChosenCandidate(item.id)
      this.editedIndex = this.candidates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true
    },
    clearEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1
      this.resetChosenCandidate()
    },
    close () {
      this.dialog = false
      this.clearEditItem()
    },
    closeDialogNewSuccessAndAddAnotherUser () {
      this.dialogNewSuccess = false;
      this.setDialogNew(true)
      this.clearEditItem()
    },
    closeDialogRemoveSuccess () {
      this.dialogRemoveSuccess = false;
      this.selected = []
      this.clearEditItem()
    },
    emit (eventName, value) {
      return new Promise((resolve, reject) => {
        this.validate = value
        this.$nextTick(resolve)
      }).catch((error) => { console.log(error) });
    },
    save: async function () {
      this.$v.$touch();
      if (this.editedIndex > -1) {
        if (!this.$v.$invalid) {
          this.editCandidate(this.editedItem).then(response => {
            if (response == 200) {
              this.$log.info('Saved edited user', response);
              this.close()
            }
          }).catch(error => {
            this.$log.error('Saving edited user error', error)
          })
        }
      } else {
        if (!this.$v.$invalid) {
          this.createCandidate(this.editedItem).then(response => {
            if (response == 200) {
              this.$log.info('Saved new student course', response);
              this.close();
              this.dialogNewSuccess = true
            }
          }).catch(error => {
            this.$log.error('Saving new user course error', error)
          })
        }
      }
    },
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć tego kandydata?')){
        this.deleteCandidates(this.selected).then(response => {
          this.$log.info('Deleted user', response);
          this.dialogRemoveSuccess = true
        }).catch(error => {
          this.$log.error('Deleting user error', error)
        })
      }
    },
    generateCandidatesReport () {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        "LP;Nazwisko i imię;Telefon;Email",
        ...this.candidates.map(item => item.id + ';' + item.username + ';' + item.telephone + ';' + item.email + ';' + item.courseName + ';' + (item.courseName2 !== null ? item.courseName2 : '') + ';' + item.date + ';' + item.cityName)
      ].join("\n")

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "studenci-raport.csv");
      link.click();
    },
    placeChanged(placeId) {
      this.$store.dispatch(
          'candidates/getCandidates',
          {
            'pid': placeId,
            'cid': this.courseSelected
          }
      );
    },
    courseChanged(courseId) {
      this.$store.dispatch(
          'candidates/getCandidates',
          {
            'pid': this.placeSelected,
            'cid': courseId
          }
      );
    }
  },
  created() {
    this.$store.dispatch('candidates/getCandidates', {});

    this.$store.dispatch('courses/getCourses');
    this.$store.dispatch('places/getPlaces');
  }
}
</script>

<style scoped>
.toPrint {
  display: none;
}
</style>

