import fileGroup from '../../services/FileGroup'

// initial state
const state = {
  items: [],
  dialogNew: false,
  fileGroupSelected: null,
  fileGroupSelectedRoles: [],
  fileGroupSemesters: []
};

// getters
const getters = {
  getFileGroups: state => state.items.sort((a, b) => { return b.id - a.id } ),
  getFileGroupsWithoutAllOption: state => [{ id: '', name: 'Kierunek', fileGroupFiles: []}, ...state.items],
  dialogNew: state => state.dialogNew,
  getFileGroupNameById: state => fileGroupId => state.items.find(p => p.id === fileGroupId) != undefined ? state.items.find(p => p.id === fileGroupId).name : '',
  getFileGroupSelected: state => state.fileGroupSelected,
  getRolesBySelectedGroupId: state => state.fileGroupSelectedRoles.map(x => x.roleid),
  getSemestersBySelectedGroupId: state => state.fileGroupSemesters.map(x => x.semester)
};

// actions
const actions = {
  getFileGroups ({ commit }) {
    fileGroup.getFileGroups().then(response => {
      commit('setFileGroups', response.data)
    })
  },
  createFileGroup ({ dispatch, commit }, newFileGroup) {
    newFileGroup['roles'] = state.fileGroupSelectedRoles;
    newFileGroup['semesters'] = state.fileGroupSemesters;
    fileGroup.createFileGroup(newFileGroup).then(response => {
      commit('addFileGroup', response.data);
      return new Promise((resolve, reject) => {
          dispatch('files/createFiles', response.data.id, {root: true}).then((response2) => {
            if (response2.status == 200) {
              resolve(200);
            }
          }).catch((errors) => {
            reject(errors)
          });
        }).catch((error) => { console.log(error) });
    })
  },
  editFileGroup ({dispatch, commit }, editedFileGroup) {
    return new Promise((resolve, reject) => {
      fileGroup.editFileGroup(editedFileGroup).then(response => {
        dispatch('files/createFiles', state.fileGroupSelected, {root: true}).then(response => {
          console.log(response.status);
          resolve(200);
        }).catch((errors) => {
          // console.log(errors.response.status);
          // console.log(errors.response.data.error);
          reject(errors.response.status)
        });
        commit('editFileGroup', response.data)
      })
    }).catch((error) => { console.log(error) });
  },
  deleteFileGroups ({ commit }, deletedFileGroups) {
    fileGroup.deleteFileGroups(deletedFileGroups.map(function(item) { return item["id"] })).then(response => {
      if (response.status == 200) {
        commit('deleteFileGroups', deletedFileGroups)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setFileGroupSelected ({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit('setFileGroupSelected', value);
      if(value == null) {
        commit('setFileGroupSelectedRoles', []);
      }
      resolve(200);
    }).catch((error) => { console.log(error) });
  },
  setFileGroupSelectedRoles({commit}, value) {
    commit('setFileGroupSelectedRoles', value)
  },
  setFileGroupSemesters({commit}, value) {
    commit('setFileGroupSemesters', value)
  },
  setGroupName({commit}, value) {
    fileGroup.editFileGroup({id: state.fileGroupSelected, name: value}).then(response => {
      return new Promise((resolve, reject) => {
        commit('setGroupName', response.data);
        resolve(200);
      }).catch((error) => { console.log(error) });
    });
  },
  setSemesters({commit}) {
    return new Promise((resolve, reject) => {
      fileGroup.editFileGroup({id: state.fileGroupSelected, semesters: state.fileGroupSemesters}).then(response => {
        commit('setFileGroupSemesters', response.data.semesters);
        resolve(200);
      });
    }).catch((error) => { console.log(error) });
  }
};

// mutations
const mutations = {
  setFileGroups (state, fileGroups) {
    state.items = fileGroups
  },
  addFileGroup (state, fileGroup) {
    state.items.push(fileGroup)
  },
  editFileGroup (state, fileGroup) {
    console.log(fileGroup)
  },
  deleteFileGroups (state, fileGroups) {
    fileGroups.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setFileGroupSelected (state, value) {
    state.fileGroupSelected = value
  },
  setFileGroupSelectedRoles (state, value) {
    state.fileGroupSelectedRoles = value
  },
  setFileGroupSemesters (state, value) {
    state.fileGroupSemesters = value
  },
  setGroupName (state, data) {
    state.items.find(p => p.id === state.fileGroupSelected).name = data.name;
    // state.items.find(p => p.id === state.fileGroupSelected).name = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
