import Api from './Api'

export default {
  createTrip (trip, userId = null) {
    return Api().post('/api/trips' + (userId?'?user='+userId:''), trip)
  },
  getTrips (period, userId = null) {
    return Api().get('/api/trips?from=' + period.start + '&to=' + period.end + (userId?'&user='+userId:''), { withCredentials: true })
  },
  editTrip (tripData, userId = null) {
    return Api().put('/api/trips/' + tripData.id + (userId?'?user='+userId:''), tripData)
  },
  deleteTrips (trips, userId = null) {
    return Api().post('/api/trips/delete-multiple' + (userId?'?user='+userId:''), trips)
  },
  cloneTrip (tripId, userId = null) {
    return Api().post('/api/trips/' + tripId + '/clone' + (userId?'?user='+userId:''))
  }
}
