import studentCourse from '../../services/StudentCourse'

// initial state
const state = {
  studentCourses: [],
  loggedInStudent: null,
  loggedInStudentAvatarReload: 0,
  dialogNew: false,
  chosenStudentCourse: null,
  chosenStudentCourseUser: null,
  stepUploadAlbums: 1,
  uploadAlbums: [],
  itemSelectedToPriceEdit: null,
  studentCoursesGrouped: {pid: [], cid: []},
}

// getters
const getters = {
  getStudentCourses: state => state.studentCourses,
  dialogNew: state => state.dialogNew,
  getChosenStudentCourseData: state => state.studentCourses.find(item => item.id == state.chosenStudentCourse),
  getChosenStudentCoursesData: state => state.studentCourses.filter(item => item.uid == state.chosenStudentCourseUser),
  getChosenStudentCourse: state => state.chosenStudentCourse,
  getChosenStudentCourseUser: state => state.chosenStudentCourseUser,
  getStepUploadAlbums: state => state.stepUploadAlbums,
  getUploadAlbums: state => state.uploadAlbums,
  getItemSelectedToPriceEdit: state => state.itemSelectedToPriceEdit,
  getItemSelectedToPriceEditPrice: state => state.itemSelectedToPriceEdit?.price,
  getStudentCoursesGrouped: state => state.studentCoursesGrouped
}

// actions
const actions = {
  getStudentCourses ({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      studentCourse.getStudentCourses(parameters).then(response => {
        if(response.status == 200) {
          commit('setStudentCourses', response.data);
          resolve();
        }
      }).catch(() => {
        commit('setStudentCourses', []);
        resolve();
      });
    }).catch((error) => { console.log(error) });
  },
  async createStudentCourse({ commit }, registrationFormStudent) {
    return await this.dispatch('registrationStudent/saveUser', {root: true}).then(response => {
      // if(response.data.userExists == 1) {
      //   return new Promise((resolve, reject) => {
      //     reject('user with this email exists');
      //   });
      // }
      if(response.status == 200) {
        let sc = Object.assign(registrationFormStudent, {uid : (response.data.userExists == 1 ? response.data.data.id : response.data.id) })
        return new Promise((resolve, reject) => {
          studentCourse.createStudentCourse(sc).then(response2 => {
            if(response2.status == 200 && response2.data.length > 0) {
              if (response.data.userExists == 1 && response2.data.length > 0) { // user exists and student course not 201
                commit("addStudentCourse", response2.data);
                resolve(201)
              } else if (response2.status == 409) { // user exists and student course exists 409
                resolve(409)
              } else { // user doesn't exists (and student course doesn't exists) - create both 200
                commit("addStudentCourse", response2.data);
                resolve(200)
              }
            } else {
              reject(response2.status)
            }
          }).catch((errors) => {
            reject(errors)
          })
        }).catch((error) => { console.log(error) });
      }
    })
  },
  async editStudentCourseAndUser({ commit }, editedStudent) {
    return await this.dispatch('registrationStudent/updateUser', {root: true}).then(response => {
      if (response.data.code == 'ER_DUP_ENTRY') {
        return new Promise((resolve, reject) => {
          reject('duplicate');
        }).catch((error) => { console.log(error) });
      }
      if(response.status == 200) {
        return new Promise((resolve, reject) => {
          studentCourse.editStudentCourse(editedStudent).then(response2 => {
            if(response2.status == 200) {
              commit('editStudentCourse', response2.data)
              resolve(200)
            } else {
              resolve(201);
            }
          }).catch((errors) => {
            reject(errors)
          })
        }).catch((error) => { console.log(error) });
      }
    })
  },
  async editUser({ commit }) {
    return await this.dispatch('registrationStudent/updateUser', {root: true}).then(response => {
      if (response.data.code == 'ER_DUP_ENTRY') {
        return new Promise((resolve, reject) => {
          reject('duplicate');
        }).catch((error) => { console.log(error) });
      }
      if(response.status == 200) {
        commit('editUser', response.registration_form)
        return new Promise((resolve) => {
          resolve(200)
        }).catch((error) => { console.log(error) });
      }
    });
  },
  editStudentCourse({ commit }, editedStudent) {
    return new Promise((resolve, reject) => {
      studentCourse.editStudentCourse(editedStudent).then(response2 => {
        if(response2.status == 200) {
          commit('editStudentCourse', response2.data)
          resolve(200)
        } else {
          resolve(201);
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  createCourse ({ commit }, newStudentCourse) {
    commit('addStudentCourse', newStudentCourse)
  },
  deleteStudentCourses ({ commit }, deletedStudentCourses) {
    return new Promise((resolve, reject) => {
      studentCourse.deleteStudentCourses(deletedStudentCourses.map(function(item) { return { id: item["id"], uid: item["uid"], cid: item["cid"] } })).then(response => {
        if (response.status == 200) {
          commit('deleteStudentCourses', deletedStudentCourses)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setItemSelectedToPriceEdit({commit}, value) {
    commit('setItemSelectedToPriceEdit', value)
  },
  setItemSelectedToPriceEditPrice({commit}, value) {
    commit('setItemSelectedToPriceEditPrice', value)
  },
  setChosenStudentCourse({dispatch, commit}, value) {
    commit('setChosenStudentCourse', value)

    let chosenStudentCourse = state.studentCourses.find(item => item['id'] == value)
    if(chosenStudentCourse.registration_form !== null && chosenStudentCourse.registration_form !== undefined) {
      dispatch('registrationStudent/setRegistrationFromJson', chosenStudentCourse.registration_form, {root: true})
    } else {
        dispatch('registrationStudent/clearRegistrationForm', true, {root: true})
    }
  },
  setChosenStudentCourseUser({dispatch, commit}, value) {
    commit('setChosenStudentCourseUser', value)

    let chosenStudentCourses = state.studentCourses.filter(item => item['uid'] == value)
    if(chosenStudentCourses[0].registration_form !== null && chosenStudentCourses[0].registration_form !== undefined) {
      dispatch('registrationStudent/setRegistrationFromJson', chosenStudentCourses[0].registration_form, {root: true})
    } else {
        dispatch('registrationStudent/clearRegistrationForm', true, {root: true})
    }
  },
  setStudentCourseFinish({commit}, setting) {
    return new Promise((resolve, reject) => {
      studentCourse.setStudentCourseFinish(setting.id, {
        thesis: setting.thesis,
        practice: setting.practice,
        promoter: setting.promoter,
      }).then((response) => {
        if(response.status == 200) {
          commit('setStudentCourseFinish', setting)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    })
  },
  resetChosenStudentCourse({dispatch, commit}) {
    // dispatch('registrationStudent/clearRegistrationForm', true, {root: true})
    commit('setChosenStudentCourse', null)
  },
  resetChosenStudentCourseUser({dispatch, commit}) {
    dispatch('registrationStudent/clearRegistrationForm', true, {root: true})
    commit('setChosenStudentCourseUser', null)
  },
  resetStudentCourses({dispatch, commit}) {
    commit('setStudentCourses', [])
  },
  uploadAlbumNumbers({ commit }, formData) {
    return new Promise((resolve, reject) => {
      studentCourse.uploadAlbumNumbers(formData).then(response => {
        if(response.status == 200) {
          commit('setUploadAlbums', response.data)
          resolve(200)
        } else {
          reject(response.status)
        }
      })
    }).catch((error) => { console.log(error) });
  },
  setAlbumNumbers({commit}) {
    return new Promise((resolve, reject) => {
      studentCourse.setAlbumNumbers(state.uploadAlbums).then((response) => {
        if(response.status == 200) {
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  setStepUploadAlbums ({ commit }, value) {
    commit('setStepUploadAlbums', value)
  },
  deleteFromUploadAlbums ({ commit }, id) {
    commit('deleteFromUploadAlbums', id)
  },
  saveSelectedPrice({ commit }) {
    return new Promise((resolve, reject) => {
      studentCourse.setPrice({'id': state.itemSelectedToPriceEdit.id, 'price': state.itemSelectedToPriceEdit.price}).then((response) => {
        if(response.status == 200) {
          commit('saveSelectedPrice')
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    });
  },
  getStudentCoursesGrouped ({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      studentCourse.getStudentCoursesGrouped(parameters).then(response => {
        if(response.status == 200) {
          commit('setStudentCoursesGrouped', { data: response.data, groupBy: parameters.groupBy });
          resolve();
        }
      }).catch(() => {
        commit('setStudentCoursesGrouped', []);
        resolve();
      });
    });
  },
}

// mutations
const mutations = {
  setStudentCourses (state, studentCourses) {
    state.studentCourses = studentCourses
  },
  setStudentCourse (state, user) {
    state.loggedInStudent = user
  },
  addStudentCourse (state, user) {
    for(let i = 0; i < user.length; i++) {
      state.studentCourses.push(user[i])
    }
    // Vue.set(state.studentCourses, state.studentCourses.length, user)
  },
  editStudentCourse (state, userData) {
    let studentCourse = state.studentCourses.find(item => item.id == state.chosenStudentCourse);
    studentCourse.cid = userData.cid
    studentCourse.cid_2 = userData.cid_2
    studentCourse.cityName = userData.cityName
    studentCourse.comment = userData.comment
    studentCourse.coordinator = userData.coordinator
    studentCourse.courseName = userData.courseName
    studentCourse.courseName2 = userData.courseName2
    studentCourse.date = userData.date
    studentCourse.email = userData.email
    studentCourse.pid = userData.pid
    studentCourse.price = userData.price
    studentCourse.registration_form = userData.registration_form
    studentCourse.telephone = userData.telephone
    studentCourse.uid = userData.uid
    studentCourse.username = userData.username
    studentCourse.bank_account = userData.bank_account
    studentCourse.is_admin = userData.is_admin
  },
  editUser (state, userData) {
    let studentCourse = state.studentCourses.find(item => item.uid == state.chosenStudentCourseUser);
    studentCourse.registration_form = JSON.stringify(userData);
    studentCourse.username = userData.personal.surname + ' ' + userData.personal.name;
  },
  deleteStudentCourses (state, studentCourses) {
    studentCourses.map(function(key) {
      const index = state.studentCourses.indexOf(key);
      state.studentCourses.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setItemSelectedToPriceEdit(state, value) {
    state.itemSelectedToPriceEdit = value
  },
  setItemSelectedToPriceEditPrice(state, value) {
    state.itemSelectedToPriceEdit.price = value
  },
  setChosenStudentCourse (state, studentCourse) {
    state.chosenStudentCourse = studentCourse
  },
  setChosenStudentCourseUser (state, user) {
    state.chosenStudentCourseUser = user
  },
  setStudentCourseFinish (state, selectedStudentCourse) {
    let studentCourse = state.studentCourses.find(item => item.id == selectedStudentCourse.id);
    studentCourse.thesis = selectedStudentCourse.thesis
    studentCourse.practice = selectedStudentCourse.practice
    studentCourse.promoter = selectedStudentCourse.promoter
  },
  setUploadAlbums(state, data) {
    state.uploadAlbums = data;
    state.stepUploadAlbums = 2;
  },
  setStepUploadAlbums(state, value) {
    state.stepUploadAlbums = value;
  },
  deleteFromUploadAlbums (state, deletedIndex) {
    const index = state.uploadAlbums.indexOf(deletedIndex);
    state.uploadAlbums.splice(index, 1);
  },
  saveSelectedPrice (state) {
    // new price
    let selected = state.studentCourses.find(x => x.id = state.itemSelectedToPriceEdit.id);
    selected.balance = selected.price - selected.paid;
  },
  setStudentCoursesGrouped (state, {data, groupBy}) {
    if(groupBy == 'pid') {
      state.studentCoursesGrouped.pid = data;
    } else if (groupBy == 'cid') {
      state.studentCoursesGrouped.cid = data;
    }

  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
