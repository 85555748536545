<template>
    <v-container>
        <v-card>
          <v-card-title>
            {{ studentCourses[0].username }}
            <v-btn color="grey" class="overline ml-5" text @click="$router.push('/accountant-students')"><v-icon small left>arrow_back</v-icon> powrót do listy wszystkich studentów</v-btn>
          </v-card-title>
          <v-card-text>
            <v-tabs
                v-model="tabActive"
                background-color="primary"
                dark
            >
              <v-tab
                  v-for="item in getItems"
                  :key="item.tab"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabActive">
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <Settlements/>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <StudentPersonalDataInfo
                        :bank_account=studentCourses[0].bank_account
                        :city=city
                        :dayOfBirth=dayOfBirth
                        :email=email
                        :homeNr=homeNr
                        :houseNr=houseNr
                        :name=name
                        :placeOfBirth=placeOfBirth
                        :secondName=secondName
                        :street=street
                        :surname=surname
                        :telephoneNr=telephoneNr
                        :zip=zip
                    />
<!--                    <v-container>-->
<!--                      <v-row>-->
<!--                        <v-col sm="12">-->
<!--                          <Messages/>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </v-container>-->
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <StudentCourseView
                        v-for="(studentCourse, index) in studentCourses" :key="index"
                        :student-course="studentCourse"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions class="d-flex justify-end mr-8">
          </v-card-actions>
        </v-card>
<!--      <Message @closeDialogMessage="closeDialogMessage" :showDialog="dialogMessage" :receiver="{ email: email }" />-->
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import Message from "../components/Message";
// import Messages from "./Messages";
import Settlements from "./Settlements";
import {createHelpers} from "vuex-map-fields";
import StudentPersonalDataInfo from "../components/StudentPersonalDataInfo";
import StudentCourseView from "./StudentCourseView.vue";

const { mapFields } = createHelpers({
  getterType: 'registrationStudent/getPersonal',
  mutationType: 'registrationStudent/updatePersonal',
});

export default {
  name: "StudentCourse",
  components: {
    StudentCourseView,
    Settlements,
    // Messages,
    // Message,
    StudentPersonalDataInfo,
  },
  data () {
      return {
        dialogNewCar: false,
        tab: null,
        dialogEditSuccess: false,
        validate: false,
        report: false,
        dialogMessage: false,
        tabActive: null
      }
    },
    methods: {
      ...mapActions({
      }),
      newMessage () {
        this.dialogMessage = true
      },

      registerFormPrinted() {
        this.$log.info('Printed registration form')
        this.report = false
      },
      closeDialogMessage(val) {
        this.dialogMessage = val;
      },
    },
    computed: {
      ...mapGetters({
        chosenStudentCourseId: 'studentCourses/getChosenStudentCourse',
        studentCourses: 'studentCourses/getChosenStudentCoursesData',
        getFormDataHistory: 'history/getFormDataHistory',
        userRole: 'users/userRole',
      }),
      ...mapFields({
        // personal
        surname: 'personal.surname',
        name: 'personal.name',
        secondName: 'personal.secondName',
        placeOfBirth: 'personal.placeOfBirth',
        dayOfBirth: 'personal.dayOfBirth',
        // address
        city: 'address.city',
        street: 'address.street',
        houseNr: 'address.houseNr',
        homeNr: 'address.homeNr',
        zip: 'address.zip',
        // contact
        email: 'contact.email',
        telephoneNr: 'contact.telephoneNr'
      }),
      getItems() {
        let tabItems = [];
        tabItems.push({ id: 1, tab: 'Wpłaty' });

        if (this.userRole !== 1) {
          tabItems.push({ id: 2, tab: 'Dane personalne' });
          tabItems.push({ id: 3, tab: 'Kierunki' });
        }
        return tabItems;
      }
    }
  }
</script>

