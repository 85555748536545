<template>
    <div>
      <RegisterFormGeneral :formDateErrors="formDateErrors" :fixedDate="fixedDate"></RegisterFormGeneral>

      <PersonalCompanyData
      ></PersonalCompanyData>

      <Company
          :nameErrors="nameErrors"
          :nipErrors="nipErrors"
          :accountNumberErrors="accountNumberErrors"
          :cityErrors="cityErrors"
          :streetErrors="streetErrors"
          :houseNrErrors="houseNrErrors"
          :zipErrors="zipErrors"
          :postErrors="postErrors"
      ></Company>

      <Contact
          :emailErrors="emailErrors"
          :mobileNrErrors="mobileNrErrors"
      ></Contact>
    </div>
</template>

<script>
import Company from "@/components/RegisterForm/Company";
import Contact from "@/components/RegisterForm/PersonalData/Contact";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {createHelpers} from "vuex-map-fields";
import {mapActions} from "vuex";
import RegisterFormGeneral from "./RegisterForm/RegisterFormGeneral";
import PersonalCompanyData from "@/components/RegisterForm/PersonalCompanyData";

const { mapFields } = createHelpers({
    getterType: 'registration/getCompany',
    mutationType: 'registration/updateCompany',
  });

  export default {
    name: 'RegisterFormCompany',
    props: ['validate', 'report', 'fixedDate'],
    components: {RegisterFormGeneral, PersonalCompanyData, Company, Contact },
    mixins: [validationMixin],
    validations: {
      registerFormDate: {required},
      // company
      name: {required},
      nip: {required},
      accountNumber: {required},
      city: {required},
      street: {required},
      houseNr: {required},
      zip: {required},
      post: {required},
      // contact
      email: {required},
      mobileNr: {required},
    },
    computed: {
      ...mapFields({
        // register form general
        registerFormDate: 'registerFormGeneral.formDate',
        // company
        name: 'company.name',
        nip: 'company.nip',
        accountNumber: 'company.accountNumber',
        // address
        city: 'company.city',
        street: 'company.street',
        houseNr: 'company.houseNr',
        homeNr: 'company.homeNr',
        zip: 'company.zip',
        post: 'company.post',
        // contact
        email: 'contact.email',
        telephoneNr: 'contact.telephoneNr',
        mobileNr: 'contact.mobileNr',
      }),
      formDateErrors () {
        const errors = [];
        if (!this.$v.registerFormDate.$dirty) return errors;
        !this.$v.registerFormDate.required && errors.push('Podanie daty kiedy formularz został wypełniony jest wymagane');
        return errors
      },
      nameErrors () {
        const errors = [];
        if (!this.$v.name.$dirty) return errors;
        !this.$v.name.required && errors.push('Podanie nazwy jest konieczne');
        return errors
      },
      nipErrors () {
        const errors = [];
        if (!this.$v.nip.$dirty) return errors;
        !this.$v.nip.required && errors.push('Podanie numeru NIP jest konieczne');
        return errors
      },
      accountNumberErrors () {
        const errors = [];
        if (!this.$v.accountNumber.$dirty) return errors;
        !this.$v.accountNumber.required && errors.push('Podanie numeru firmowego rachunku bankowego jest konieczne');
        return errors
      },
      cityErrors () {
        const errors = [];
        if (!this.$v.city.$dirty) return errors;
        !this.$v.city.required && errors.push('Podanie miasta jest konieczne');
        return errors
      },
      streetErrors () {
        const errors = [];
        if (!this.$v.street.$dirty) return errors;
        !this.$v.street.required && errors.push('Podanie ulicy jest konieczne');
        return errors
      },
      houseNrErrors () {
        const errors = [];
        if (!this.$v.houseNr.$dirty) return errors;
        !this.$v.houseNr.required && errors.push('Podanie numeru domu jest konieczne');
        return errors
      },
      zipErrors () {
        const errors = [];
        if (!this.$v.zip.$dirty) return errors;
        !this.$v.zip.required && errors.push('Podanie kodu pocztowego jest konieczne');
        return errors
      },
      postErrors () {
        const errors = [];
        if (!this.$v.post.$dirty) return errors;
        !this.$v.post.required && errors.push('Podanie poczty jest konieczne');
        return errors
      },
      emailErrors () {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.required && errors.push('Podanie adresu email jest konieczne');
        return errors
      },
      mobileNrErrors () {
        const errors = [];
        if (!this.$v.mobileNr.$dirty) return errors;
        !this.$v.mobileNr.required && errors.push('Podanie telefonu komórkowego jest konieczne.');
        return errors
      },
    },
    watch: {
      validate: function (newValue) {
        if (newValue === true) {
          this.save()
          this.$emit('registerFormValidated',true)
        }
      },
      report: function (newValue) {
        if (newValue === true) {
          this.generatePDFReport()
          this.$emit('registerFormPrinted',true)
        }
      }
    },
    methods: {
      ...mapActions({
        saveAll: 'registration/saveAll',
      }),
      save () {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.saveAll().then(response => {
            if (response) {
              this.$emit('registerFormSaved',true)
            }
          }).catch(error => {
            this.$log.error('Saving registration form error', error)
          })
        }
      },
      generatePDFReport () {
        var pdfMake = require('pdfmake/build/pdfmake.js');
        if (pdfMake.vfs == undefined){
          var pdfFonts = require('pdfmake/build/vfs_fonts.js');
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }

        let c = []
        c.push({text: 'Data: ' + this.registerFormDate, style: 'singleLine'})

        c.push({columns: [{text: 'Nazwa firmy', style: 'label'}] }, {columns: [{text: this.name, style: 'data'}] })
        c.push({columns: [{text: 'NIP', style: 'label'}] }, {columns: [{text: this.nip, style: 'data'}] })
        c.push({columns: [{text: 'Numer firmowego rachunku bankowego', style: 'label'}] }, {columns: [{text: this.accountNumber, style: 'data'}] })

        c.push({text: 'Dane adresowe', style: 'header' })
        c.push({columns: [{text: 'Miejscowość', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.city, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Ulica', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.street, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Numer domu', style: 'label'}, {text: 'Numer lokalu', style: 'label'}] }, {columns: [{text: this.houseNr, style: 'data'}, {text: this.homeNr, style: 'data'}] })
        c.push({columns: [{text: 'Kod pocztowy', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.zip, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Poczta', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.post, style: 'data'}, {text: '', style: 'data'}] })

        c.push({text: 'Dane kontaktowe', style: 'header' })
        c.push({columns: [{text: 'Email', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.email, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Telefon', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.telephoneNr, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Telefon komórkowy', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.mobileNr, style: 'data'}, {text: '', style: 'data'}] })

        c.push({text: '..............................', style: 'signature' })
        c.push({text: 'Podpis', style: 'signatureLabel' })

        var docDefinition = {
          content: c,
          styles: {
            header: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 20],
              alignment: "left"
            },
            label: {
              fontSize: 8,
              bold: false,
              alignment: "left"
            },
            data: {
              fontSize: 12,
              bold: false,
              alignment: "justify",
              margin: [0, 0, 0, 20],
            },
            footer: {
              alignment: "right",
              margin: [0, 20, 0, 20]
            },
            singleLine: {
              margin: [0, 0, 0, 20],
            },
            singleLineBold: {
              bold: true,
              margin: [0, 0, 0, 20],
            },
            signature: {
              alignment: "right",
              margin: [20, 0, 0, 0]
            },
            signatureLabel: {
              alignment: "right",
              margin: [0, 0, 30, 0],
              fontSize: 8,
              bold: false,
            }
          }
        };
        pdfMake.createPdf(docDefinition).download('unikadra-formularz-rejestracyjny-firma-raport.pdf')
      },
    }
  }
</script>

<style scoped>

</style>
