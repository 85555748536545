<template>
  <v-container>
    <v-card>
      <v-card-title>Komunikator</v-card-title>
      <v-card-text>
        <p v-if="loggedInUser.is_admin == 0">Dzięki tej funkcjonalności możesz korespondować ze studentami</p>
        <p v-if="loggedInUser.is_admin == 1">Dzięki tej funkcjonalności możesz korespondować ze studentami, wykładowcami, koordynatorami</p>
        <p v-if="loggedInUser.is_admin == 2">Dzięki tej funkcjonalności możesz korespondować ze studentami</p>
        <p v-if="loggedInUser.is_admin == 3">Dzięki tej funkcjonalności możesz korespondować z wykładowcami oraz koordynatorami</p>
        <v-autocomplete
            v-model="userSearch"
            :items="users"
            :search-input.sync="search"
            color="white"
            item-text="name"
            item-value="id"
            label="Wybierz odbiorcę (po imieniu/nazwisku)"
            placeholder="Wybierz odbiorcę (po imieniu/nazwisku)"
            append-icon="search"
            return-object
            clearable
        >
          <template v-slot:item="data">
            <span v-if="data.item.is_admin == 1 && loggedInUser.is_admin !== 1">
              {{ displayUserRoleName(data.item.is_admin) }}
            </span>
            <span v-else>
              {{ data.item.name }} ({{ displayUserRoleName(data.item.is_admin) }})
            </span>
          </template>
        </v-autocomplete>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <ChatRecentList @openUser="openUser"></ChatRecentList>
            </v-col>
            <v-col cols="12" md="8">
              <ChatCurrent v-if="userOpened" :user="userOpened"></ChatCurrent>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ChatCurrent from "../components/Chat/ChatCurrent";
import ChatRecentList from "../components/Chat/ChatRecentList";
import {mapActions, mapGetters} from "vuex";
import {UserRoleDescEnum} from "../enums";

export default {
  name: "Chat",
  components: {ChatRecentList, ChatCurrent},
  computed: {
    ...mapGetters({
      users: 'users/getUsers',
      loggedInUser: 'users/getLoggedInUser'
    })
  },
  methods: {
    ...mapActions({
      setChosenChatUser: 'chat/setChosenChatUser',
      getMessagesByUser: 'chat/getMessagesByUser',
      setWatchedChatUser: "notifications/setWatchedChatUser"

    }),
    openUser(chat) {
      let chatWithUserId = null;
      if(chat.uid_from == this.loggedInUser.id) {
        chatWithUserId = chat.uid_to;
      } else {
        chatWithUserId = chat.uid_from;
      }
      this.setChosenChatUser(chatWithUserId).then(response => {
        if(response == 200) {
          this.getMessagesByUser().then(response1 => {
            if(response1 == 200) {
              this.setWatchedChatUser(chatWithUserId)
              this.userOpened = Object.assign({}, this.users.find(x => x.id == chatWithUserId));
              this.userSearch = null;
            }
          });
        }
      });
    },
    displayUserRoleName(role) {
      return role == 1 ? 'Sekretariat' : UserRoleDescEnum[role == 5 ? 0 : role];
    }
  },
  data: () => ({
    userSearch: null,
    userOpened: null
  }),
  created() {
    this.$store.dispatch('users/getUsersAvailableForChat');
    this.$store.dispatch('chat/getLastMessages');
  },
  watch: {
    userSearch (val) {
      if (val != null) {
        this.setChosenChatUser(this.userSearch.id).then(response => {
          if(response == 200) {
            this.getMessagesByUser().then(response1 => {
              if(response1 == 200) {
                this.userOpened = Object.assign({}, this.users.find(x => x.id == this.userSearch.id));
                this.userSearch = null;
              }
            });
          }
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
