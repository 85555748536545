<template>
  <v-list subheader>
    <v-card>
      <v-card-title>Lista Twoich wiadomości</v-card-title>
      <v-card-text>
        <div
            v-for="(chat, index) in getRecent"
            :key="chat"
        >
          <v-list-item v-if="chatUserName(chat.uid)">
<!--            <v-list-item-avatar>-->
<!--              <v-img-->
<!--                  :alt="`${chat.name} avatar`"-->
<!--                  :src="avatar(chat.lastActiveItems[0].uuid)"-->
<!--              ></v-img>-->
<!--            </v-list-item-avatar>-->
            <v-list-item-content @click="$emit('openUser',chat.lastActiveItems[0])">
              <v-list-item-title v-text="chatUserName(chat.uid)" v-bind:class="[(chat.lastActiveItems[chat.lastActiveItems.length-1].status == 0 && chat.lastActiveItems[chat.lastActiveItems.length-1].uid_to == loggedInUser.id) ? 'bold' : 'normal']"></v-list-item-title>
<!--              <v-list-item-action-text v-text="chat.items[0].subtitle"></v-list-item-action-text>-->
            </v-list-item-content>
            <v-list-item-icon v-if="loggedInUser.id !== chat.lastActiveItems[chat.lastActiveItems.length-1].uid_from">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="black"
                        v-bind="attrs"
                        v-on="on"
                    >more_vert</v-icon>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title @click="markUserUnread(chat.uid)">Oznacz jako nieczytane</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </v-list-item-icon>
          </v-list-item>
        </div>
        <span v-if="getRecent !== null && getRecent.length == 0">Brak wiadomości</span>
      </v-card-text>
    </v-card>
  </v-list>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ChatRecentList",
  computed: {
    ...mapGetters({
      recent: 'chat/getRecipients',
      loggedInUser: 'users/getLoggedInUser',
      chatUserName: 'users/getNameById'
    }),
    getRecent() {
      return (this.loggedInUser.id !== undefined) ? this.recent(this.loggedInUser.id) : null;
    }
  },
  methods: {
    ...mapActions({
      markUserUnread: 'chat/markUserUnread',
    }),
    avatar (uuid) {
      return "/api/users/" + uuid + "/avatarByUuid"
    },
  },
  data: () => ({
  }),
}
</script>

<style scoped>
  .v-list-item { cursor: pointer; }
  .bold { font-weight: bold; }
  .normal { font-weight: normal; }
</style>
