import { render, staticRenderFns } from "./ChatRecentList.vue?vue&type=template&id=17a07a90&scoped=true"
import script from "./ChatRecentList.vue?vue&type=script&lang=js"
export * from "./ChatRecentList.vue?vue&type=script&lang=js"
import style0 from "./ChatRecentList.vue?vue&type=style&index=0&id=17a07a90&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a07a90",
  null
  
)

export default component.exports