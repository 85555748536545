<template>
  <v-data-table
      :headers="headers"
      :items="fileGroups"
      sort-by="name"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
      v-if="userRole == 1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista wszystkich grup plików</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn v-show="showExtraActions" :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone grupy plików</v-btn>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodawanie nowej grupy plików</v-btn>
            <v-btn v-else color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodaj</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="mb-0 pb-0">
                    <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @blur="$v.editedItem.name.$touch()" label="Grupa plików"></v-text-field>
                  </v-col>
                  <v-col v-if="editedItem.name !== getFileGroupNameById(getFileGroupSelected) && isEdited" class="mt-0 pt-0" cols="12" md="12" sm="12">
                    <v-btn class="primary" dark rounded @click="setGroupName(editedItem.name)">Zapisz nową nazwę</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols ="12">
                    <div v-for="file in getFiles" v-bind:key="file.id">
                      <File :data="file" :editedIndex="editedIndex"/>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="editedIndex == -1 || !isDuringUpdate">
                  <v-col sm="12" class="d-flex justify-start">
                    <v-btn class="primary" dark rounded @click="addFile">
                      <v-icon left>add</v-icon>dodaj kolejny plik
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols ="12">
                    <v-select
                        v-model="assignedUserRoles"
                        :disabled=isEdited
                        :error-messages="rolesErrors"
                        :items="allUserRoles"
                        attach
                        chips
                        item-text="value"
                        item-value="key"
                        label="Grupa użytkowników która będzie miała dostęp do tej grupy plików"
                        multiple required
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                        v-model="semesterChoices"
                        :items="allSemesters"
                        chips
                        item-text="value"
                        item-value="key"
                        label="Dostępne dla studentów z semestrów"
                        menu-props="auto"
                        multiple
                    >
                    </v-select>
                  </v-col>
                  <v-col v-if="isEdited && semestersChanged === true" class="mt-0 pt-0" cols="12" md="12" sm="12">
                    <v-btn class="primary" dark rounded @click="saveSemesters()">Zapisz zmiany w semestrach</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-7">
              <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">wroć do listy wszystkich plików</span></v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="editedIndex === -1" class="primary" rounded @click="save">Dodaj do grupy plików</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Poprawnie dodałeś nową grupę plików</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    {{ editedItem.name }}
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich grup</v-btn>
                  </v-col>
                  <v-col sm="12">
                    lub
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherFileGroup">Chcę dodać kolejną grupę plików</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogNewError" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Wystąpił błąd przy dodawaniu plików.</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="red--text">warning</v-icon>
                  </v-col>
                  <v-col sm="12">
                    {{ editedItem.name }}
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="dialogNewError = false">Zamknij</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie grupę plików</span>
                    <span v-else class="headline">Usunąłeś poprawnie grupy plików</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    <div v-if="selected.length > 0">
                      <div v-for="item in selected">
                        {{ item.name }}
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.lp="{ item }">
      {{ item.id }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        edit
      </v-icon>
    </template>
    <template v-slot:no-data>
      Brak grup plików
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import File from "../components/File";
import {SemesterChoicesEnum, UserRoleDescEnum} from "../enums";

export default {
  components: { File },
  mixins: [validationMixin],

  validations: {
    editedItem: {
      name: {required}
    },
    assignedUserRoles: {required}
  },
  name: "AdminFileGroupGroups",
  computed: {
    ...mapGetters({
      fileGroups: 'fileGroups/getFileGroups',
      dialogFromStore: 'fileGroups/dialogNew',
      getFileGroupNameById: 'fileGroups/getFileGroupNameById',
      getFileGroupSelected: 'fileGroups/getFileGroupSelected',
      getFiles: 'files/getFiles',
      isDuringUpdate: 'files/isDuringUpdate',
      userRole: 'users/userRole',
      assignedUserRolesFromStore: 'fileGroups/getRolesBySelectedGroupId',
      assignedSemestersFromStore: 'fileGroups/getSemestersBySelectedGroupId'
    }),
    allUserRoles() {
      return [ { 'key': 0, 'value': UserRoleDescEnum[0]}, { 'key': 2, 'value': UserRoleDescEnum[2]}, { 'key': 3, 'value': UserRoleDescEnum[3]}, { 'key': 5, 'value': UserRoleDescEnum[5]}, ];
    },
    allSemesters() {
      return SemesterChoicesEnum
    },
    isEdited() {
      return this.editedIndex > -1 ? true : false;
    },
    assignedUserRoles: {
      get(){
        return this.assignedUserRolesFromStore
      },
      set(value){
        let a = [];
        value.forEach(x => {
          if (x !== undefined)
            a.push({'roleid': x});
        });
        return this.setFileGroupSelectedRoles(a)
      }
    },
    semesterChoices: {
      get(){
        return this.assignedSemestersFromStore
      },
      set(value){
        let a = [];
        value.forEach(x => {
          if (x !== undefined)
            a.push({'semester': x});
        });
        this.semestersChanged = true;
        return this.setFileGroupSemesters(a)
      }
    },
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodawanie nowej grupy plików' : 'Edytuj grupę plików'
    },
    nameErrors () {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.required && errors.push('Podanie grupy plików jest wymagane');
      return errors
    },
    rolesErrors () {
      const errors = [];
      if (!this.$v.assignedUserRoles.$dirty) return errors;
      !this.$v.assignedUserRoles.required && errors.push('Podanie grupy odbiorców jest wymagane');
      return errors
    },
    showExtraActions () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
  },
  data: () => ({
    selected: [],
    menuDate: false,
    dialogNewSuccess: false,
    dialogNewError: false,
    dialogRemoveSuccess: false,
    headers: [
      { text: 'Grupa plików', value: 'name' },
      { text: '', value: 'action', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
      files: [],
      semesters: []
    },
    defaultItem: {
      name: '',
      files: [],
      semesters: []
    },
    singleSelect: false,
    semestersChanged: false
  }),
  watch: {
    dialogNewSuccess (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    dialog (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
  },
  methods: {
    ...mapActions({
      setDialogNew: 'fileGroups/setDialogNew',
      createFileGroup: 'fileGroups/createFileGroup',
      editFileGroup: 'fileGroups/editFileGroup',
      deleteFileGroups: 'fileGroups/deleteFileGroups',
      setFileGroupSelected: 'fileGroups/setFileGroupSelected',
      setFileGroupSelectedRoles: 'fileGroups/setFileGroupSelectedRoles',
      setFileGroupSemesters: 'fileGroups/setFileGroupSemesters',
      getFilesAction: 'files/getFiles',
      addFile: 'files/addFile',
      setGroupName: 'fileGroups/setGroupName',
      setSemesters: 'fileGroups/setSemesters'
    }),
    editItem (item) {
      this.editedIndex = this.fileGroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.setFileGroupSelected(item.id).then(() => {
        this.getFilesAction();
      });
    },
    saveSemesters() {
      this.setSemesters().then(response => {
        if (response == 200) {
          this.semestersChanged = false;
          this.$log.info('Saved semesters', response);
        }
      }).catch(error => {
        this.$log.error('Saving semesters error', error);
      })
    },
    close () {
      this.dialog = false
    },
    closeDialogNewSuccessAndAddAnotherFileGroup () {
      this.dialogNewSuccess = false;
      this.setDialogNew(true)
    },
    closeDialogRemoveSuccess () {
      this.dialogRemoveSuccess = false;
      this.selected = []
    },
    save () {
      this.$v.$touch();
      if (this.editedIndex > -1) {
        if (!this.$v.$invalid) {
          this.editFileGroup(this.editedItem).then(response => {
            this.$log.info('Saved edited fileGroup', response);
            Object.assign(this.fileGroups[this.editedIndex], this.editedItem);
            this.close()
            this.dialogNewSuccess = true;
          }).catch(error => {
            this.$log.error('Saving edited fileGroup error', error)
            this.dialogNewError = true;
          })
        }
      } else {
        if (!this.$v.$invalid) {
          this.createFileGroup(this.editedItem).then(response => {
            this.$log.info('Saved new fileGroup', response);
            this.close();
            this.dialogNewSuccess = true
          }).catch(error => {
            this.$log.error('Saving new fileGroup error', error)
          })
        }
      }
    },
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć poniższe grupy plików?')){
        this.deleteFileGroups(this.selected).then(response => {
          this.$log.info('Deleted fileGroup', response);
          this.dialogRemoveSuccess = true
        }).catch(error => {
          this.$log.error('Deleting fileGroup error', error)
        })
      }
    },
    clearEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1
      this.setFileGroupSelected(null).then(() => {
        this.getFilesAction();
      });
    },
  },
  created() {
    this.$store.dispatch('fileGroups/getFileGroups');
    this.$store.dispatch('users/getUsers');
  },
}
</script>

<style scoped>

</style>
