<template>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-if="userRole === 1 || userRole === 7" v-slot:activator="{ on }">
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="mb-2 ml-4" color="primary" rounded small v-on="on">Utwórz spotkanie</v-btn>
        <v-btn v-else class="mb-2 ml-4" color="primary" rounded small v-on="on">Utwórz</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Tworzenie nowego spotkania</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-text-field v-model="editedItem.topic" :error-messages="topicErrors" label="Temat spotkania" required @blur="$v.editedItem.topic.$touch()"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" sm="12">
                <v-menu
                    ref="menuDate"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :return-value.sync="editedItem.date"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="new Date(editedItem.date).toISOString().substr(0, 10)"
                        :error-messages="dateErrors"
                        append-icon="event"
                        label="Data spotkania"
                        readonly
                        required
                        type="date"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="editedItem.date"
                      :first-day-of-week="1"
                      :min="getTodayDate"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="menuDate = false">Anuluj</v-btn>
                    <v-btn color="primary" text @click="$refs.menuDate.save(editedItem.date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="12" >
                <v-menu
                    ref="menuTime"
                    v-model="menuTime"
                    :close-on-content-click="false"
                    :return-value.sync="editedItem.time"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                    @input="input"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="editedItem.time"
                        :error-messages="timeErrors"
                        append-icon="query_builder"
                        label="Godzina spotkania"
                        readonly
                        required
                        type="time"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      ref="timepicker"
                      v-model="editedItem.time"
                      :allowed-minutes="allowedStep"
                      class="mt-4"
                      format="24hr"
                      no-title
                  >
                    <v-card-actions>
                        <v-btn color="primary" text @click="menuTime = false">Anuluj</v-btn>
                        <v-btn color="primary" text @click="$refs.menuTime.save(editedItem.time); $refs.timepicker.selectingHour = true">OK</v-btn>
                      </v-card-actions>
                  </v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-select v-model="editedItem.duration"
                          :error-messages="durationErrors"
                          :items="durations"
                          hide-details
                          item-text="value"
                          item-value="key"
                          label="Czas trwania"
                          menu-props="auto"
                          required
                          single-line
                          @blur="$v.editedItem.duration.$touch()"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                    v-model="courseSelected"
                    :error-messages="courseErrors"
                    :items="courses"
                    chips
                    dense
                    item-text="name"
                    item-value="id"
                    label="Kierunek"
                    multiple
                    required
                    small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="editedItem.sid"
                          :items="subjects"
                          hide-details
                          item-text="name"
                          item-value="id"
                          label="Przedmiot"
                          menu-props="auto"
                          single-line
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="userRole == 1 || userRole == 7">
              <v-col cols="12" md="6">
                <v-autocomplete v-model="editedItem.uid"
                          :items="getUsersForSelect"
                          chips
                          dense
                          hint="Jeśli nie Dziekanat ma być prowadzącym spotkanie, proszę wybrać wykładowcę"
                          item-text="name"
                          item-value="id"
                          label="Prowadzący"
                          persistent-hint
                          small-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select v-model="editedItem.semester"
                          :items="getAvailableSemestersForSelectedCourse"
                          hide-details
                          item-text="name"
                          item-value="id"
                          label="Semestr"
                          menu-props="auto"
                          single-line
                          @change="toggle"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                    v-model="editedItem.users"
                    :error-messages="usersErrors"
                    :hint="'Zaznaczono ' + editedItem.users.length + ' z dostępnych ' + getAvailableUsers.length + ' studentów'"
                    :items="getAvailableUsers"
                    chips
                    dense
                    item-text="username"
                    item-value="uid"
                    label="Uczestnicy"
                    multiple
                    persistent-hint
                    required
                    small-chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                        :input-value="data.item.username"
                        close
                        @click:close="removeUser(data.item)"
                    >
                      {{ data.item.username}}
                    </v-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                        v-show="getAvailableUsers.length > 0"
                        ripple
                        @click="toggle"
                    >
                      <v-list-item-action>
                        <v-icon :color="editedItem.users.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Zaznacz wszyskich</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12">
                <hr />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" col="12">
                <h4>Zabezpieczenia (pola opcjonalne)</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" md="12">
                <v-checkbox
                    v-model="editedItem.waiting_room"
                    dense
                    hint="Tylko użytkownicy dopuszczeni przez prowadzącego mogą dołączyć do spotkania"
                    label="Poczekalnia"
                    persistent-hint
                ></v-checkbox>
              </v-col>
              <v-col col="12" md="12">
                <v-checkbox
                    v-model="passwordRequired"
                    dense
                    hint="Tylko użytkownicy posiadający link lub kod dostępu mogą dołączyć do spotkania"
                    label="Kod dostępu"
                    persistent-hint
                ></v-checkbox>
                <v-text-field v-if="passwordRequired" v-model="editedItem.password" hint="Proszę podać kod dostępu" persistent-hint></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12">
                <h4>Wideo (pola opcjonalne)</h4>
                <p class="v-messages">ta funkcjonalność pozwala określić kto może włączyć wideo podczas trwania spotkania. Możesz wyłączyć, wtedy Słuchacze nie będą mogli prezentować swoich twarzy.</p>
                <v-radio-group v-model="editedItem.host_video" row>
                  <template v-slot:label>
                    <div class="v-label">Prowadzący</div>
                  </template>
                  <v-radio
                      key="true"
                      :value=true
                      label="włączone"
                  ></v-radio>
                  <v-radio
                      key="true"
                      :value=false
                      label="wyłączone"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group v-model="editedItem.participant_video" row>
                  <template v-slot:label>
                    <div class="v-label">Słuchacze</div>
                  </template>
                  <v-radio
                      key="true"
                      :value=true
                      label="włączone"
                  ></v-radio>
                  <v-radio
                      key="true"
                      :value=false
                      label="wyłączone"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-7">
          <v-btn class="overline" color="grey" text @click="close"><v-icon left small>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuć dodawanie i wróć do listy wszystkich spotkań</span></v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="isBusy" class="primary" rounded @click="save"> <v-icon dark right>event_available</v-icon> Utwórz spotkanie</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  mixins: [validationMixin],

  validations: {
    editedItem: {
      topic: {required},
      date: {required},
      time: {required},
      cid: {required},
      duration: {required},
      users: {required}
    }
  },
  name: "OnlineMeetingCreate",
  computed: {
    ...mapGetters({
      dialogFromStore: 'onlineMeetings/dialogNew',
      courses: 'courses/getCourses',
      courseSelectedFromStore: 'courses/getCourseSelected',
      subjects: 'subjects/getSubjectsBySelectedCourseId',
      getAvailableSemestersForSelectedCourse: 'subjects/getAvailableSemestersForSelectedCourse',
      userRole: 'users/userRole',
      getUsersForSelect: 'users/getUsersForSelect'
      // getAvailableUsers: 'studentCourses/getStudentCourses'
    }),
    allUsers () {
      return this.editedItem.users.length === this.getAvailableUsers.length
    },
    icon () {
      if (this.allUsers) return 'mdi-close-box'
      return 'mdi-checkbox-blank-outline'
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
    getAvailableUsers() {
      // select right date from studentCourse based on selected semester
      let dateSemester = '';
      if (moment().format('M') >= 3 && moment().format('M') < 10) {
        dateSemester = moment().format('YYYY') + '-03-01';
      }
      if (moment().format('M') >= 10) {
        dateSemester = moment().format('YYYY') + '-10-01';
      }
      if (moment().format('M') < 3) {
        dateSemester = moment().subtract('1 years').format('YYYY') + '-10-01';
      }
      if (this.editedItem.semester == 2) {
        dateSemester = moment(dateSemester).subtract(moment(dateSemester).format('M') == 10 ? 7 : 5, 'M').format('YYYY-MM-DD');
      }
      if (this.editedItem.semester == 3) {
        dateSemester = moment(dateSemester).subtract(12, 'M').format('YYYY-MM-DD');
      }
      const seen = new Set();
      return this.$store.getters["studentCourses/getStudentCourses"].filter(x => {
        if (x.date === dateSemester) {
          const duplicate = seen.has(x.uid);
          seen.add(x.uid);
          return !duplicate;
        }
      });
    },
    getTodayDate() {
      return moment().format('YYYY-MM-DD');
    },
    topicErrors () {
      const errors = [];
      if (!this.$v.editedItem.topic.$dirty) return errors;
      !this.$v.editedItem.topic.required && errors.push('Podanie tematu spotkania jest wymagane');
      return errors
    },
    dateErrors () {
      const errors = [];
      if (!this.$v.editedItem.date.$dirty) return errors;
      !this.$v.editedItem.date.required && errors.push('Podanie daty jest wymagane');
      return errors
    },
    timeErrors () {
      const errors = [];
      if (!this.$v.editedItem.time.$dirty) return errors;
      !this.$v.editedItem.time.required && errors.push('Podanie godziny jest wymagane');
      return errors
    },
    courseErrors () {
      const errors = [];
      if (!this.$v.editedItem.cid.$dirty) return errors;
      !this.$v.editedItem.cid.required && errors.push('Podanie kierunku jest wymagane');
      return errors
    },
    usersErrors () {
      const errors = [];
      if (!this.$v.editedItem.users.$dirty) return errors;
      !this.$v.editedItem.users.required && errors.push('Wybranie przynajmniej jednego studenta jest wymagane');
      return errors
    },
    durationErrors () {
      const errors = [];
      if (!this.$v.editedItem.duration.$dirty) return errors;
      !this.$v.editedItem.duration.required && errors.push('Podanie długości trwania spotkania jest konieczne');
      return errors
    },
    durations() {
      return [
        {key: 15, value: '15 minut'},
        {key: 30, value: '30 minut'},
        {key: 45, value: '45 minut'},
        {key: 45, value: '45 minut'},
        {key: 60, value: '1 godzina'},
        {key: 90, value: '1,5 godziny'},
        {key: 120, value: '2 godziny'},
      ]
    },
    courseSelected: {
      get() {
        return this.courseSelectedFromStore
      },
      set(value){
        this.editedItem.cid = value;
        if (value.length > 0) {
          this.$store.dispatch(
              'studentCourses/getStudentCourses',
              {
                'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
                'cid': value
              }
          ).then(() => {
            this.toggle();
          });
        } else {
          this.$store.dispatch(
              'studentCourses/resetStudentCourses'
          ).then(() => {
            this.toggle();
          });
        }
        return this.setCourseSelected(value);
      }
    },
  },
  data: () => ({
    editedItem: {
      topic: '',
      date: new Date().toISOString().substr(0, 10),
      time: '',
      duration: 60,
      users: [],
      semester: 1,
      cid: [],
      sid: '',
      waiting_room: false,
      password: '',
      host_video: true,
      participant_video: true
    },
    defaultItem: {
      topic: '',
      date: new Date().toISOString().substr(0, 10),
      time: '',
      duration: 60,
      users: [],
      semester: 1,
      cid: [],
      sid: '',
      waiting_room: false,
      password: '',
      host_video: true,
      participant_video: true
    },
    menuTime: false,
    passwordRequired: false,
    isBusy: false
  }),
  methods: {
    ...mapActions({
      setDialogNew: 'onlineMeetings/setDialogNew',
      createOnlineMeeting: 'onlineMeetings/createOnlineMeeting',
      setCourseSelected: 'courses/setCourseSelected',
      resetStudentCourses: 'studentCourses/resetStudentCourses'
    }),
    close () {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.setCourseSelected(null);
        this.resetStudentCourses();
        this.$v.editedItem.$reset();
      })
    },
    save () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isBusy = true;
        this.editedItem = {...this.editedItem, 'cid': this.editedItem.cid[0]} // only first course name to put on a list
        this.createOnlineMeeting(this.editedItem).then(response => {
          this.$log.info('Saved new online meeting', response);
          this.close();
          this.isBusy = false;
          this.$emit('meetingCreated', true)
        }).catch(error => {
          this.$log.error('Saving new online meeting error', error)
          this.isBusy = false;
          this.close();
          this.$emit('meetingError', true)
        })
      }
    },
    allowedHours: v => v % 2,
    allowedMinutes: v => v >= 10 && v <= 50,
    allowedStep: m => m % 10 === 0,
    toggle () {
      if (this.allUsers) {
        this.editedItem.users = []
      } else {
        this.editedItem.users = this.getAvailableUsers.slice().map(x => x.uid);
      }
    },
    removeUser(item) {
      const index = this.editedItem.users.indexOf(item.uid)
      if (index >= 0) this.editedItem.users.splice(index, 1)
    }
  },
  input (val) {
    !val && (this.$refs.timepicker.selectingHour = true)
  },
}
</script>

<style scoped>

</style>
