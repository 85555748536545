import { render, staticRenderFns } from "./SelectCourses.vue?vue&type=template&id=bf4d519c&scoped=true"
import script from "./SelectCourses.vue?vue&type=script&lang=js"
export * from "./SelectCourses.vue?vue&type=script&lang=js"
import style0 from "./SelectCourses.vue?vue&type=style&index=0&id=bf4d519c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf4d519c",
  null
  
)

export default component.exports