<template>
  <div id="app">
    <v-app id="inspire">
      <component :is="layout">
        <router-view />
      </component>
    </v-app>
  </div>
</template>

<script>
  const default_layout = "default";
  import SocketioService from './services/socketio.service.js';

  export default {
    name: 'App',

    components: {
    },
    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
      }
    },
    created() {
      SocketioService.setupSocketConnection();
    },
    beforeUnmount() {
      SocketioService.disconnect();
    }
  };
</script>
<style>
  @import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

  .v-application {
    font-family: 'Montserrat', sans-serif;
  }

  a {
    text-decoration: underline;
  }
  .v-btn {
    text-transform: none !important;
  }
  h1 {
    font-size: 1em;
    color: var(--v-primary-base);
  }
</style>
