<template>
  <v-select v-model="subjectSelected"
            :item-text="item => item.name + (item.after_join == null ? '' : ' (na semestrze ' + item.semester + ')')"
            :items="itemSubjects"
            :label=subjectSelected
            chips
            class="ml-4"
            deletable-chips
            item-value="id"
            menu-props="auto"
            placeholder="Wszystkie przedmioty"
            single-line
  >
  </v-select>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'SelectSubjects',
  methods: {
    ...mapActions({
      setSubjectSelected: 'subjects/setSubjectSelected',
    })
  },

  computed: {
    ...mapGetters({
      subjectSelectedFromStore: 'subjects/getSubjectSelected',
      subjects: 'subjects/getSubjectsBySelectedSingleCourseId'
    }),
    itemSubjects() {
      let subjects = Object.assign(this.subjects, this.subjects)
      subjects.push({ 'id': '', 'name': 'Wszystkie przedmioty'})
      return subjects;
    },
    subjectSelected: {
      get() {
        return this.subjectSelectedFromStore
      },
      set(value){
        this.$emit('subjectChanged', value)
        return this.setSubjectSelected(value)
      }
    },
  }
}
</script>
<style scoped>
::v-deep .v-chip {
  height: auto !important;
}

::v-deep .v-chip .v-chip__content {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  white-space: pre-wrap;
}
</style>
