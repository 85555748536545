<template>
  <div>
    <v-file-input
        v-model="file"
        :error-messages="fileErrors"
        :rules="rules"
        accept=".csv"
        placeholder="Wczytaj plik csv z numerami albumów"
        prepend-icon="insert_drive_file"
        label="Plik .csv ze studentami zawierający numery albumów"
        required
    ></v-file-input>
    <v-container>
      <v-row>
        <v-col class="justify-end d-flex">
          <v-btn color="primary" dark rounded @click="save()" class="mb-4">Zobacz podgląd tego co zostanie dodane</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "UploadAlbumNumbers",
  mixins: [validationMixin],
  validations: {
    file: { required }
  },
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
    ],
    file: null
  }),
  methods: {
    ...mapActions({
      uploadAlbumNumbers: 'studentCourses/uploadAlbumNumbers'
    }),
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const formData = new FormData()
        formData.append('file', this.file)
        this.uploadAlbumNumbers(formData).then(response => {
          if (response == 200) {
          }
        }).catch(error => {
          this.$log.error('Saving new csv error', error)
        })
      }
    }
  },
  computed: {
    fileErrors () {
      const errors = []
      if (!this.$v.file.$dirty) return errors
      !this.$v.file.required && errors.push('Proszę wczytać plik csv. Na liście studentów kliknij "Pobierz listę CSV" aby pobrać listę. Uzupełnij numery albumów i tak zapisany plik .csv wczytaj tutaj.')
      return errors
    },
  }
}
</script>

<style scoped>

</style>
