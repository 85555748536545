<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <v-menu
            ref="menu"
            v-model="menuFormDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="formDate"
                label="Data"
                prepend-icon="event"
                readonly
                v-on="on"
                :error-messages="formDateErrors"
                required
                :disabled="fixedDate"
            ></v-text-field>
          </template>
          <v-date-picker
              ref="picker"
              v-model="formDate"
              :max="new Date().toISOString().substr(0, 10)"
              :first-day-of-week="1"
              min="1940-01-01"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';
import moment from "moment";

const { mapFields } = createHelpers({
  getterType: 'registration/getRegisterFormGeneral',
  mutationType: 'registration/updateRegisterFormGeneral',
});

export default {
  name: "RegisterFormGeneral",
  data: () => ({
    menuFormDate: false,
  }),
  props: [
    'formDateErrors',
    'fixedDate'
  ],
  computed: {
    ...mapFields([
      'registerFormGeneral.formDate'
    ])
  },
  mounted() {
    if(this.fixedDate == true) {
      this.formDate = moment().format('yyyy-MM-DD');
    }
  }
}
</script>

<style scoped>

</style>
