<template>
    <v-container>
        <PlaceOfWork :data="data"></PlaceOfWork>
        <v-row>
            <v-col sm="6">
                <v-menu
                        ref="menuDateFrom"
                        v-model="menuDateFrom"
                        :close-on-content-click="false"
                        :return-value.sync="data.dateFrom"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="data.dateFrom"
                                label="Zatrudniony jestem od"
                                append-icon="event"
                                readonly
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="data.dateFrom" :first-day-of-week="1" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuDateFrom = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menuDateFrom.save(data.dateFrom)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col sm="6">
                <v-menu
                        ref="menuDateTo"
                        v-model="menuDateTo"
                        :close-on-content-click="false"
                        :return-value.sync="data.dateTo"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="data.dateTo"
                                label="Zatrudniony jestem do"
                                append-icon="event"
                                readonly
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="data.dateTo" :first-day-of-week="1" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuDateTo = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menuDateTo.save(data.dateTo)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import PlaceOfWork from "@/components/RegisterForm/InsuranceForm/PlaceOfWork";

export default {
    components: {PlaceOfWork},
    name: 'PlaceOfTemporaryWork',
    props: {
      data: { name: '', city: '', zip: '', street: '', houseNr: '', dateFrom: new Date().toISOString().substr(0, 10), dateTo: new Date().toISOString().substr(0, 10)}
    },
    data: () => ({
      menuDateFrom: false,
      menuDateTo: false,
    })
  }
</script>
