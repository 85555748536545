import Api from '../Api'

export default {
  createIssue (issue) {
    return Api().post('/issuetrackerapi/issues', issue)
  },
  getIssues () {
    return Api().get('/issuetrackerapi/issues', { withCredentials: true }).catch((error)=>{ console.log(error)})
  },
  editIssue (issueDataId, issueData) {
    return Api().put('/issuetrackerapi/issues/' + issueDataId, issueData)
  },
  deleteIssues (issues) {
    return Api().post('/issuetrackerapi/issues/delete-multiple', issues)
  },
  getFileByUrl (url) {
    return Api().get('/issuetrackerapi/files/url/' + url, { withCredentials: true, responseType: 'arraybuffer' })
  },
}
