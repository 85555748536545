import place from '../../services/Place'

// initial state
const state = {
  items: [],
  dialogNew: false,
  placeSelected: null
};

// getters
const getters = {
  getPlaces: state => state.items,
  getPlacesAssignedToUser: state => state.items.filter(p => p.coordinator_username != null),
  dialogNew: state => state.dialogNew,
  getPlaceNameById: state => placeId => state.items.find(p => p.id === placeId) != undefined ? state.items.find(p => p.id === placeId).name : '',
  getPlaceSelected: state => state.placeSelected,
  getCoordinators: state => {
    let result = []
    state.items.forEach(function(item) {
      if (item.coordinator_username) {
        let splitted = item.coordinator_username.split(',')
        splitted.forEach(function(i) {
          result.push(i);
        });
      }
    })
    return result;
  }
};

// actions
const actions = {
  getPlaces ({ commit }) {
    place.getPlaces().then(response => {
      commit('setPlaces', response.data)
    })
  },
  createPlace ({ commit }, newPlace) {
    place.createPlace(newPlace).then(response => {
      commit('addPlace', response.data)
    })
  },
  editPlace ({ commit }, editedPlace) {
    place.editPlace(editedPlace).then(response => {
      commit('editPlace', response.data)
    })
  },
  deletePlaces ({ commit }, deletedPlaces) {
    place.deletePlaces(deletedPlaces.map(function(item) { return item["id"] })).then(response => {
      if (response.status == 200) {
        commit('deletePlaces', deletedPlaces)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setPlaceSelected ({ commit }, value) {
    commit('setPlaceSelected', value)
  },
};

// mutations
const mutations = {
  setPlaces (state, places) {
    state.items = places
  },
  addPlace (state, place) {
    state.items.push(place)
  },
  editPlace (state, place) {
    console.log(place)
  },
  deletePlaces (state, places) {
    places.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setPlaceSelected (state, value) {
    state.placeSelected = value
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
