<template>
    <div>
        <v-row>
            <v-col sm="12" md="6">
                <v-text-field
                        v-model="email"
                        :error-messages="emailErrors"
                        required
                        @blur="$v.email.$touch()"
                        label="Email"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12" md="6">
                <v-text-field
                        v-model="telephone"
                        :error-messages="telephoneErrors"
                        required
                        @blur="$v.telephone.$touch()"
                        label="Telefon"
                />
            </v-col>
        </v-row>
        <v-container>
            <v-row>
                <v-col class="justify-end d-flex">
                    <v-btn color="primary" dark rounded @click="save" class="mb-4">Zapisz</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
            <v-card>
                <v-card-title>
                    <v-container class="text-center">
                        <v-row>
                            <v-col sm="12">
                                <span class="headline">Zmiany zapisane</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-container class="text-center">
                        <v-row>
                            <v-col sm="12">
                                <v-icon x-large class="green--text">check_circle_outline</v-icon>
                            </v-col>
                            <v-col sm="12">
                                <v-btn rounded color="primary" outlined @click="closeDialogNewSuccess">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "AdminGeneralSettings",
    data() {
      return {
        email: '',
        telephone: '',
        loader: null,
        loading: false,
        dialogNewSuccess: false
      }
    },
    mixins: [validationMixin],
    validations: {
      email: {
        required,
      },
      telephone: {
        required,
      }
    },
    mounted() {
      this.email = this.loggedInUser.email
      this.telephone = this.loggedInUser.telephone
    },
    computed: {
      ...mapGetters({
        loggedInUser: 'users/getLoggedInUser',
      }),
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.required && errors.push('Podanie adresu email jest wymagane')
        return errors
      },
      telephoneErrors () {
        const errors = []
        if (!this.$v.telephone.$dirty) return errors
        !this.$v.telephone.required && errors.push('Podanie telefonu jest wymagane')
        return errors
      }
    },
    methods: {
      ...mapActions({
        changeAdminGeneralSetting: 'users/changeAdminGeneralSettings'
      }),
      save () {
        this.$v.$touch()
        if (!this.$v.email.$invalid && !this.$v.telephone.$invalid) {
          this.loader = 'loading'
          this.loading = true
          this.changeAdminGeneralSetting({email: this.email, telephone: this.telephone}).then(response => {
            if(response == 200) {
              this.$log.info('General settings changed ', response)
              this.loading = false
              this.$v.$reset()
              this.passwordNew = ''
              this.repeatPassword = ''
              this.dialogNewSuccess = true
            }
          }, error => {
            this.error = true
            this.loading = false
            this.$log.error('Password change error ', error)
          })
        }
      },
      closeDialogNewSuccess () {
        this.dialogNewSuccess = false
      }
    }
  }
</script>

<style scoped>

</style>

