<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <v-text-field
                        v-model="data.name"
                        label="Nazwa"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="data.city"
                        label="Miejscowość"
                        dense
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-text-field
                        v-model="data.zip"
                        label="Kod pocztowy"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="data.street"
                        label="Ulica"
                        dense
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-text-field
                        v-model="data.houseNr"
                        label="Numer budynku"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    name: 'PlaceOfWork',
    props: {
      data: { name: '', city: '', zip: '', street: '', houseNr: ''}
    }
  }
</script>
