import subject from '../../services/Subject'

// initial state
const state = {
  items: [],
  itemsToDelete: [],
  subjectSelected: null
};

// getters
const getters = {
  getSubjects: state => state.items,
  getSubjectsAssignedToUser: state => state.items.filter(p => p.uid != null),
  dialogNew: state => state.dialogNew,
  getSubjectNameById: state => subjectId => state.items.find(p => p.id === subjectId) != undefined ? state.items.find(p => p.id === subjectId).name : '',
  getSubjectSelected: state => state.subjectSelected,
  getSubjectsBySelectedCourseId: (state, getters, rootState) => {
    if (rootState.courses.courseSelected !== null || Array.isArray(rootState.courses.courseSelected) && rootState.courses.courseSelected.length > 0) {
      return state.items.filter(x => x.cid == rootState.courses.courseSelected);
    } else {
      return []
    }
  },
  getSubjectsBySelectedSingleCourseId: (state, getters, rootState) => {
    if (rootState.courses.courseSingleSelected !== null) {
      return state.items.filter(x => x.cid == rootState.courses.courseSingleSelected);
    } else {
      return []
    }
  },
  getAvailableSemestersForSelectedCourse: (state, getters, rootState) => {
    if (rootState.courses.courseSelected !== null || Array.isArray(rootState.courses.courseSelected) && rootState.courses.courseSelected.length > 0 && rootState.courses.courseSelected.length < 2) {
      return state.items.filter(x => x.cid == rootState.courses.courseSelected).filter((value, index, self) => {
        return self.findIndex(v => v.semester === value.semester) === index;
      }).map(x => {
        return x.semester
      }).sort();
    } else {
      return []
    }
  },
  getAvailableSemestersForSelectedSingleCourse: (state, getters, rootState) => {
    if (rootState.courses.courseSingleSelected !== null) {
      return state.items.filter(x => x.cid == rootState.courses.courseSingleSelected).filter((value, index, self) => {
        return self.findIndex(v => v.semester === value.semester) === index;
      }).map(x => {
        return x.semester
      }).sort();
    } else {
      return []
    }
  },
};

// actions
const actions = {
  clearSubjects({ commit }) {
    commit('setSubjectSelected', null);
  },
  getSubjectsBySelectedCourseId ({ commit, rootState }) {
    if (rootState.courses.courseSelected !== null || Array.isArray(rootState.courses.courseSelected) && rootState.courses.courseSelected.length > 0) {
      subject.getSubjectsByCourseId(rootState.courses.courseSelected).then(response => {
        commit('setSubjects', response.data)
      })
    } else {
      commit('setSubjects', { items: [] });
    }
  },
  getSubjects ({ commit, rootState }) {
    subject.getSubjects().then(response => {
      commit('setSubjects', response.data)
    })
  },
  createSubject ({ commit }, newSubject) {
    return new Promise((resolve, reject) => {
      subject.createSubject(newSubject).then(response => {
        resolve(200);
        // commit('addSubject', response.data)
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  createSubjects ({ commit, rootState }, courseId) {
    return new Promise((resolve, reject) => {
      if (state.items.length > 0) {
        subject.createSubjects({
          items: state.items.filter(item => (item.cid === null || item.cid == rootState.courses.courseSelected)).map(item => item.cid === null ? {
            ...item,
            cid: courseId
          } : item)
        }).then(response => {
          resolve(200);
          // commit('addSubject', response.data)
        }).catch((errors) => {
          reject(errors)
        })
      } else {
        resolve(200);
      }
    }).catch((error) => { console.log(error) });
  },
  editSubject ({ commit }, editedSubject) {
    subject.editSubject(editedSubject).then(response => {
      commit('editSubject', response.data)
    })
  },
  deleteSubjects () {
    if(state.itemsToDelete.length > 0) {
      subject.deleteSubjects(state.itemsToDelete);
    }
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  deleteCourseSubject({commit}, value) {
    commit('deleteCourseSubject', value )
  },
  addCourseSubject({commit, rootState}, value) {
    value.cid = rootState.courses.courseSelected;
    commit('addCourseSubject', value )
  },
  setSubjectSelected ({ commit, rootState }, value) {
    return new Promise((resolve, reject) => {
      commit('setSubjectSelected', value);
      resolve(200);
    }).catch((error) => { console.log(error) });
  },
};

// mutations
const mutations = {
  setSubjects (state, subjects) {
    state.items = subjects.items
  },
  addSubject (state, subject) {
    state.items.push(subject)
  },
  editSubject (state, subject) {
    console.log(subject)
  },
  deleteCourseSubject (state, subjectId) {
    state.itemsToDelete.push(subjectId);
    const index = state.items.findIndex(x => x.id == subjectId);
      state.items.splice(index, 1);
  },
  // deleteSubjects (state, subjects) {
  //   subjects.map(function(key) {
  //     const index = state.items.findIndex(x => x.id == key.id);
  //     state.items.splice(index, 1);
  //   });
  // },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  addCourseSubject(state, value) {
    state.items.push({
      id: '',
      name: '',
      uid: '',
      semester: '',
      cid: value.cid
    });
  },
  setSubjectSelected (state, value) {
    state.subjectSelected = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
