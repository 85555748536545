import {ComponentWithPagingEnum} from '../../enums';

// initial state
const state = {
  selectedComponent: ComponentWithPagingEnum.users,
  pages: [
    { 'component': ComponentWithPagingEnum.evaluations, page: 1, sortBy: [], sortDesc: [], itemsPerPage: 10, search: ''},
    { 'component': ComponentWithPagingEnum.protocols, page: 1, sortBy: [], sortDesc: [], itemsPerPage: 10, search: ''},
    { 'component': ComponentWithPagingEnum.studentCourses, page: 1, sortBy: [], sortDesc: [], itemsPerPage: 10, search: ''},
    { 'component': ComponentWithPagingEnum.users, page: 1, sortBy: [], sortDesc: [], itemsPerPage: 10, search: ''},
    { 'component': ComponentWithPagingEnum.administrativeStaff, page: 1, sortBy: [], sortDesc: [], itemsPerPage: 10, search: ''},
    { 'component': ComponentWithPagingEnum.courses, page: 1, sortBy: [], sortDesc: [], itemsPerPage: 10, search: ''},
    { 'component': ComponentWithPagingEnum.accountantStudents, page: 1, sortBy: [], sortDesc: [], itemsPerPage: 10, search: ''},
    { 'component': ComponentWithPagingEnum.mentors, page: 1, sortBy: [], sortDesc: [], itemsPerPage: 10, search: ''},
  ]
}

// getters
const getters = {
  getPage: state => state.pages.find(x => x.component == state.selectedComponent),
}

// actions
const actions = {
  clearPage({ commit }) {
    commit('setPage', {page: 1, sortBy: [], sortDesc: [], itemsPerPage: 10 } );
  },
  setPage({dispatch, commit}, value) {
    commit('setPage', value);
  },
  setSelectedComponent({dispatch, commit}, value) {
    commit('setSelectedComponent', value)
  }
}

// mutations
const mutations = {
  setPage (state, values) {
    state.pages.find(x => x.component == state.selectedComponent).sortBy = values.sortBy
    state.pages.find(x => x.component == state.selectedComponent).sortDesc = values.sortDesc
    state.pages.find(x => x.component == state.selectedComponent).page = values.page
    state.pages.find(x => x.component == state.selectedComponent).itemsPerPage = values.itemsPerPage
    state.pages.find(x => x.component == state.selectedComponent).search = values.search
  },
  setSelectedComponent (state, selectedComponent) {
    state.selectedComponent = selectedComponent;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
