<template>
  <div style="width: 100%">
    <div class="mt-4 mb-4 mr-4 d-flex justify-end" v-if="showSendForAcceptance">
      <span
          class="mr-4">Prześlij do akceptacji kalendarz zjazdów za miesiąc: {{ currentMonthReadable }}</span>
      <v-btn @click="createReport()" color="primary" dark rounded small>Prześlij do akceptacji</v-btn>
    </div>
    <div class="mt-4 mb-4 mr-4 d-flex justify-end" v-if="showWaitingForAcceptance">
      <span class="mr-4">Kalendarz zjazdów za miesiąc: {{ currentMonthReadable }} przesłany. Oczekiwanie na akceptację.</span>
    </div>
    <div class="mt-4 mb-4 mr-4" v-else-if="showGenerateReport">
      <v-row>
        <v-col class="d-flex justify-end" md="10">
          <div>
            <div class="mr-4 ml-4">Kalendarz zjazdów za miesiąc: {{ currentMonthReadable }}
              <span class="mr-4 ml-4" v-if="showStatusAcceptedReport">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon class="green--text" v-bind="attrs" v-on="on">checked</v-icon>
                                    </template>
                                    <span>Zaakceptowany</span>
                                  </v-tooltip>
                                </span>
              <span class="mr-4 ml-4" v-else-if="showStatusDeclinedReport">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon class="red--text" v-bind="attrs" v-on="on">warning</v-icon>
                                    </template>
                                    <span>Odrzucony</span>
                                  </v-tooltip>
                                </span>
            </div>
            <div class="mr-4 ml-4" v-if="showAcceptOrDeclineReport">
              <v-btn @click="acceptReport()" rounded class="success">akceptuj</v-btn>
              &nbsp
              <v-btn @click="declineReport()" rounded class="error">odrzuć</v-btn>
            </div>
            <!--                            <div class="mr-4 ml-4 font-weight-thin">Data przesłania do rozliczenia:</div>-->
          </div>
        </v-col>
        <v-col class="d-flex justify-end" md="2">
          <v-btn @click="generatePDFReport" color="primary" dark rounded small>Generuj plik PDF</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'MeetingsReportStatus',
  data: () => ({
    settingsPersonalChanged: false,
  }),
  props: {
    currentMonthReadable: {}
  },
  computed: {
    ...mapGetters({
      meetings: 'meetings/getMeetings',
      meetingsReport: 'meetings/getMeetingsReport',
      userRole: 'users/userRole',
      monthSelected: 'monthSelected',
      ratePerKmFromStore: 'users/getRatePerKm',
    }),
    showSendForAcceptance () {
      return (this.meetings.length > 0 && this.meetingsReport == 0 && (this.userRole == 0 || this.userRole == 5)) ? true : false;
    },
    showWaitingForAcceptance () {
      return  (this.meetings.length > 0 && this.meetingsReport == 1 && (this.userRole == 0 || this.userRole == 5)) ? true : false;
    },
    showGenerateReport () {
      return (this.meetings.length > 0 && (this.meetingsReport == 2 || this.userRole == 1)) ? true : false;
    },
    showAcceptOrDeclineReport () {
      return (this.meetingsReport == 1 && this.userRole == 1) ? true : false;
    },
    showStatusAcceptedReport () {
      return (this.meetingsReport == 2) ? true : false;
    },
    showStatusDeclinedReport () {
      return (this.meetingsReport == 0) ? true : false;
    }
  },
  methods: {
    ...mapActions({
      createReport: 'meetings/createReport',
      acceptReport: 'meetings/acceptReport',
      declineReport: 'meetings/declineReport',
    }),
    editSettings () {
      this.$store.commit('setActiveSettingsTab', 0)
      this.$router.push('settings')
    },
    generatePDFReport () {
      var table = [];
      table.push(["Od", "Do", "Miasto", "Uwagi"]);

      function passItem() {
        return function (obj) {
          return [new Date(obj.start).toISOString().substr(0, 10), new Date(obj.end).toISOString().substr(0, 10), obj.cityName, obj.comment]
        }
      }
      var body = table.concat(this.meetings.map(passItem()));

      var pdfMake = require('pdfmake/build/pdfmake.js');
      if (pdfMake.vfs == undefined){
        var pdfFonts = require('pdfmake/build/vfs_fonts.js');
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var docDefinition = {
        content: [
          {
            stack: [
              'Zjazdy za miesiąc ' + this.monthSelected.substr(0, 7),
            ],
            style: 'header'
          },
          {
            style: 'tableExample',
            table: {
              body: body
            }
          }
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 80],
            alignment: "center"
          }
        }
      };
      pdfMake.createPdf(docDefinition).download('unikadra-report-'+this.monthSelected+'.pdf')
    }
  }
}
</script>
