import Api from './Api'

export default {
  createTime (time, userId = null) {
    return Api().post('/api/times' + (userId?'?user='+userId:''), time)
  },
  getTimes (period, userId = null) {
    return Api().get('/api/times?from=' + period.start + '&to=' + period.end + (userId?'&user='+userId:''), { withCredentials: true })
  },
  editTime (timeData) {
    return Api().put('/api/times/' + timeData.id, timeData)
  },
  deleteTimes (times) {
    return Api().post('/api/times/delete-multiple', times)
  }
}
