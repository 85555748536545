import car from '../../services/Car'

// initial state
const state = {
  items: [],
  dialogNew: false
}

// getters
const getters = {
  getCars: state => state.items,
  dialogNew: state => state.dialogNew,
  getCarById: state => id => state.items.find(x => x.id = id)
}

// actions
const actions = {
  getCars ({ commit, rootState }) {
    car.getCars(rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      commit('setCars', response.data)
    })
  },
  createCar ({ commit, rootState }, newCar) {
    car.createCar(newCar, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      commit('addCar', response.data)
    })
  },
  editCar ({ commit }, editedCar) {
    car.editCar(editedCar).then(response => {
      commit('editCar', response.data)
    })
  },
  deleteCar ({ commit, rootState }, deletedCar) {
    return new Promise((resolve, reject) => {
      car.deleteCar(deletedCar.map(function(item) { return item["id"] }), rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
        console.log(response.status)
        if (response.status == 200) {
          commit('deleteCars', deletedCar)
          resolve(response)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setCars({commit}, cars) {
    commit('setCars', cars)
  },
}

// mutations
const mutations = {
  setCars (state, cars) {
    state.items = cars
  },
  addCar (state, car) {
    state.items.push(car)
  },
  editCar (state, car) {
    console.log(car)
  },
  deleteCars (state, cars) {
    cars.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
