import Api from '../Api'

export default {
  createMentor (mentor) {
    return Api().post('/mentoringapi/mentors', mentor, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getMentors () {
    return Api().get('/mentoringapi/mentors', { withCredentials: true })
  },
  editMentor (mentorDataId, mentorData) {
    return Api().put('/mentoringapi/mentors/' + mentorDataId, mentorData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  deleteMentors (mentors) {
    return Api().post('/mentoringapi/mentors/delete-multiple', mentors)
  },
  getFileByUrl (url) {
    return Api().get('/mentoringapi/mentors/url/' + url, { withCredentials: true, responseType: 'arraybuffer' })
  },
  getMentorsTags () {
    return Api().get('/mentoringapi/mentorsTags', { withCredentials: true })
  }
}
