<template>
    <v-select v-model="yearSelected"
              :items="years()"
              item-text="value"
              item-value="key"
              menu-props="auto"
              hide-details
              :label=yearSelected.value
              single-line
              class="ml-4"
    >
    </v-select>
</template>
<script>
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: 'SelectYears',
    methods: {
      ...mapActions({
        setYearSelected: 'setYearSelected',
      }),
      years () {
        moment.locale('pl');
        const years = [''];
        const dateStart = moment("2020-01-01");
        const dateEnd = moment();
        while (dateEnd.diff(dateStart, 'years') >= 0) {
          years.push({ 'key': dateStart.format('YYYY-MM-DD'), 'value': dateStart.locale('pl').format('YYYY')});
          dateStart.add(1, 'year')
        }
        return years.reverse()
      },
    },
    computed: {
      ...mapGetters({
        yearSelectedFromStore: 'yearSelected',
      }),
      yearSelected: {
        get() {
          if (this.yearSelectedFromStore.key == undefined) {
            return { 'key': moment(this.yearSelectedFromStore).format('YYYY-MM-DD'), 'value': moment(this.yearSelectedFromStore).locale('pl').format('YYYY')}
          } else {
            return this.yearSelectedFromStore
          }
        },
        set(value){
          return this.setYearSelected(value)
        }
      },
    }
  }
</script>
