<template>
    <div>
        <v-row>
            <v-col sm="12" md="6">
                <v-text-field
                        v-model="passwordNew"
                        :error-messages="passwordNewErrors"
                        required
                        @blur="$v.passwordNew.$touch()"
                        label="Nowe hasło"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12" md="6">
                <v-text-field
                        v-model="repeatPassword"
                        :error-messages="repeatPasswordErrors"
                        required
                        @blur="$v.repeatPassword.$touch()"
                        label="Powtórz nowe hasło"
                />
            </v-col>
        </v-row>
        <v-container>
            <v-row>
                <v-col class="justify-end d-flex">
                    <v-btn color="primary" dark rounded @click="save" class="mb-4">Zapisz nowe hasło</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
            <v-card>
                <v-card-title>
                    <v-container class="text-center">
                        <v-row>
                            <v-col sm="12">
                                <span class="headline">Hasło zmienione</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-container class="text-center">
                        <v-row>
                            <v-col sm="12">
                                <v-icon x-large class="green--text">check_circle_outline</v-icon>
                            </v-col>
                            <v-col sm="12">
                                <v-btn rounded color="primary" outlined @click="closeDialogNewSuccess">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, sameAs, minLength } from 'vuelidate/lib/validators'
  import {mapActions} from "vuex";

  export default {
    name: "PasswordChange",
    data() {
      return {
        passwordNew: '',
        repeatPassword: '',
        loader: null,
        loading: false,
        dialogNewSuccess: false
      }
    },
    mixins: [validationMixin],
    validations: {
      passwordNew: {
        required,
        minLength: minLength(6)
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('passwordNew')
      }
    },
    computed: {
      passwordNewErrors () {
        const errors = []
        if (!this.$v.passwordNew.$dirty) return errors
        !this.$v.passwordNew.minLength && errors.push('Nowe hasło musi mieć przynajmniej 6 znakow')
        !this.$v.passwordNew.required && errors.push('Podanie nowego hasła jest wymagane')
        return errors
      },
      repeatPasswordErrors () {
        const errors = []
        if (!this.$v.repeatPassword.$dirty) return errors
        !this.$v.repeatPassword.required && errors.push('Proszę powtórzyć nowe hasło')
        !this.$v.repeatPassword.sameAsPassword && errors.push('Musisz wpisać dwa razy, to samo, nowe hasło')
        return errors
      }
    },
    methods: {
      ...mapActions({
        passwordChange: 'users/passwordChange'
      }),
      save () {
        this.$v.$touch()
        if (!this.$v.passwordNew.$invalid && !this.$v.repeatPassword.$invalid) {
          this.loader = 'loading'
          this.loading = true
          this.passwordChange({passwordNew: this.passwordNew}).then(response => {
            if(response == 200) {
              this.$log.info('Password changed ', response)
              this.loading = false
              this.$v.$reset()
              this.passwordNew = ''
              this.repeatPassword = ''
              this.dialogNewSuccess = true
            }
          }, error => {
            this.error = true
            this.loading = false
            this.$log.error('Password change error ', error)
          })
        }
      },
      closeDialogNewSuccess () {
        this.dialogNewSuccess = false
      }
    }
  }
</script>

<style scoped>

</style>
