<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="cars"
            sort-by="brand"
            show-select
            :single-select=singleSelect
            class="elevation-1 ma-4"
            v-model="selected"
        >
        <template v-slot:top>
            <v-dialog v-model="dialog" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field v-model="editedItem.brand" :error-messages="brandErrors" required @blur="$v.editedItem.brand.$touch()" label="Marka"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field v-model="editedItem.model" :error-messages="modelErrors" required @blur="$v.editedItem.model.$touch()" label="Model"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field v-model="editedItem.engine" :error-messages="engineErrors" required @blur="$v.editedItem.engine.$touch()" label="Pojemność silnika w cm3"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field v-model="editedItem.registration_nr" :error-messages="registrationNrErrors" required @blur="$v.editedItem.registration_nr.$touch()" label="Numer rejestracyjny"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-7">
                        <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do listy wszystkich pojazdow</span></v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded @click="save">Dodaj pojazd</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogNewSuccess" max-width="800px">
                <v-card>
                    <v-card-title>
                        <v-container class="text-center">
                            <v-row>
                                <v-col sm="12">
                                    <span class="headline">Poprawnie dodałeś pojazd</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-card-text>
                        <v-divider></v-divider>
                        <v-container class="text-center">
                            <v-row>
                                <v-col sm="12">
                                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                </v-col>
                                <v-col sm="12">
                                    {{ editedItem.brand }} {{ editedItem.model }} {{ editedItem.engine }} {{ editedItem.registration_nr }}
                                </v-col>
                                <v-col sm="12">
                                    <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich pojazdów</v-btn>
                                </v-col>
                                <v-col sm="12">
                                    lub
                                </v-col>
                                <v-col sm="12">
                                    <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherCar">Chcę dodać kolejny pojazd</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
                <v-card>
                    <v-card-title>
                        <v-container class="text-center">
                            <v-row>
                                <v-col sm="12">
                                    <span class="headline">Usunąłeś poprawnie pojazd</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-card-text>
                        <v-divider></v-divider>
                        <v-container class="text-center">
                            <v-row>
                                <v-col sm="12">
                                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                </v-col>
                                <v-col sm="12">
                                    <div v-if="selected.length > 0">
                                        <div v-for="item in selected">
                                            {{ item.brand }} {{ item.model }} {{ item.engine }} {{ item.registration_nr }}
                                        </div>
                                    </div>
                                </v-col>
                                <v-col sm="12">
                                    <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
        <template v-slot:item.date="{ item }">
            {{ new Date(item.date).toISOString().substr(0, 10) }}
        </template>
        <template v-slot:item.status="{ item }">
            <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon
                    small
                    class="mr-2"
            >
                zoom_in
            </v-icon>
        </template>
        <template v-slot:no-data>
            Brak pojazdów
        </template>
        </v-data-table>
        <v-container >
            <v-row>
                <v-col cols="12" sm="12" md="9" lg="10" class="d-flex justify-end">
                    <v-btn primary outlined rounded class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń pojazd</v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="2" class="d-flex justify-end">
                    <v-btn color="primary" dark rounded class="mb-2 ml-4" @click="addNewCar()">Dodaj nowy pojazd</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import {validationMixin} from "vuelidate";
  import {required, numeric} from "vuelidate/lib/validators";

  export default {
    mixins: [validationMixin],

    validations: {
      editedItem: {
        brand: {required},
        model: {required},
        engine: {required, numeric},
        registration_nr: {required}
      }
    },
    name: "Cars",
    computed: {
      ...mapGetters({
        cars: 'cars/getCars',
        dialogFromStore: 'cars/dialogNew'
      }),
      formTitle () {
        return this.editedIndex === -1 ? 'Dodaj nowy pojazd' : 'Edytuj pojazd'
      },
      brandErrors () {
        const errors = [];
        if (!this.$v.editedItem.brand.$dirty) return errors;
        !this.$v.editedItem.brand.required && errors.push('Podanie modelu pojazdu jest wymagane');
        return errors
      },
      modelErrors () {
        const errors = [];
        if (!this.$v.editedItem.model.$dirty) return errors;
        !this.$v.editedItem.model.required && errors.push('Podanie modelu pojazdu jest wymagane');
        return errors
      },
      engineErrors () {
        const errors = [];
        if (!this.$v.editedItem.engine.$dirty) return errors;
        !this.$v.editedItem.engine.required && errors.push('Podanie pojemności silnika jest wymagane');
        !this.$v.editedItem.engine.numeric && errors.push('Proszę podać wyłącznie cyfry');
        return errors
      },
      registrationNrErrors () {
        const errors = [];
        if (!this.$v.editedItem.registration_nr.$dirty) return errors;
        !this.$v.editedItem.registration_nr.required && errors.push('Podanie numeru rejestracyjnego jest wymagane');
        return errors
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          return this.setDialogNew(value)
        }
      },
    },
    mounted () {
      this.$store.dispatch('cars/getCars')
    },
    data: () => ({
      selected: [],
      dialogNewSuccess: false,
      dialogRemoveSuccess: false,
      headers: [
        {
          text: 'Marka',
          align: 'left',
          sortable: false,
          value: 'brand',
        },
        { text: 'Model', value: 'model' },
        { text: 'Pojemność silnika w cm3', value: 'engine' },
        { text: 'Numer rejestracyjny', value: 'registration_nr' },
        { text: '', value: 'action', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        brand: '',
        model: '',
        engine: '',
        registration_nr: ''
      },
      defaultItem: {
        brand: '',
        model: '',
        engine: '',
        registration_nr: ''
      },
      singleSelect: true
    }),
    watch: {
      dialogNewSuccess (val) {
        val || setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1
        }, 300)
      },
    },
    methods: {
      ...mapActions({
        setDialogNew: 'cars/setDialogNew',
        createCar: 'cars/createCar',
        editCar: 'cars/editCar',
        deleteCar: 'cars/deleteCar'
      }),
      editItem (item) {
        this.editedIndex = this.cars.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true
      },
      close () {
        this.dialog = false
      },
      closeDialogNewSuccessAndAddAnotherCar () {
        this.dialogNewSuccess = false;
        this.setDialogNew(true)
      },
      closeDialogRemoveSuccess () {
        this.dialogRemoveSuccess = false;
        this.selected = []
      },
      save () {
        this.$v.$touch();
        if (this.editedIndex > -1) {
          if (!this.$v.$invalid) {
            this.editCar(this.editedItem).then(response => {
              this.$log.info('Saved edited car', response);
              Object.assign(this.cars[this.editedIndex], this.editedItem);
              this.close()
            }).catch(error => {
              this.$log.error('Saving edited car error', error)
            })
          }
        } else {
          if (!this.$v.$invalid) {
            this.createCar(this.editedItem).then(response => {
              this.$log.info('Saved new car', response);
              this.close();
              this.dialogNewSuccess = true
            }).catch(error => {
              this.$log.error('Saving new car error', error)
            })
          }
        }
      },
      addNewCar() {
        this.dialog = true
      },
      removeSelected () {
        if (this.selected.length == 0) {
          return false
        }
        if(confirm('Czy na pewno chcesz usunąć ten pojazd?')){
          this.deleteCar(this.selected).then(response => {
            this.$log.info('Deleted car ', response);
            this.dialogRemoveSuccess = true
          }).catch(error => {
            this.$log.error('Deleting car error', error)
          })
        }
      }
    }
  }

</script>

<style scoped>

</style>
