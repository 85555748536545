<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ issue.title }} <v-btn class="overline ml-5" color="grey" text @click="$router.push('/issue-tracker')"><v-icon left small>arrow_back</v-icon> powrót do listy</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="(userRole == 1 && issue.uid !== loggedInUser.id) || (issue.status == 1 || issue.status == 2)" class="mb-2 ml-4" color="primary" dark rounded @click="changeStatus(issue)">
          <v-icon v-if="issue.status == 1">mdi-check</v-icon> {{ changeStatusButtonName(issue) }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6" sm="12">
            <div><b>Data i godzina zgłoszenia</b></div>
            {{ issue.timestamp }}
          </v-col>
          <v-col md="6" sm="12">
            <div><b>Typ zgłoszenia</b></div>
            {{ IssueTypeDescEnum[issue.type] }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div><b>Status</b></div>
            <v-badge
                :color=IssueStatusColorEnum[issue.status]
                dot
                inline
                left
                overlap
            ></v-badge>{{ IssueStatusDescEnum[issue.status] }}
          </v-col>
          <v-col v-if="userRole == 1">
            <div><b>Priorytet</b></div>
            {{ IssuePriorityDescEnum[issue.priority] }}
          </v-col>
          <v-col v-if="userRole == 1">
            <div><b>Zgłoszone przez</b></div>
            {{ getNameById(issue.uid) }}
          </v-col>
          <v-col v-if="userRole == 1">
            <div><b>Dotyczy</b></div>
            {{ getNameById(issue.uid_issue) }}
          </v-col>

        </v-row>
        <v-row>
          <v-col cols="12">
            <div><b>Opis</b></div>
            <span>{{ issue.description }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="issue.files" md="6" sm="12">
            <div><b>Załącznone pliki</b></div>
            <div v-for="(file) in issue.files.split(',')">
              <a @click="downloadFileByUrl(file)">{{ file }}</a>
            </div>
          </v-col>
        </v-row>
        <IssueChatCurrent :issue="issue" :showRespondTextarea=showRespondTextarea @hideRespondButtons="hideRespondButtons"></IssueChatCurrent>

        <v-container v-if="this.showRespondButtons && this.issue.status == 1 && issue.uid == loggedInUser.id">
          <v-card>
            <v-card-title class="justify-center">Czy przesłana przez nas odpowiedź jest dla Ciebie zadowolająca?</v-card-title>
            <v-card-actions class="justify-center pb-4">
              <v-btn class="mr-4" color="primary" rounded @click="openResponseTextarea()">NIE</v-btn>
              <v-btn color="primary" rounded @click="closeIssue()">TAK</v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ComponentWithPagingEnum, IssueStatusDescEnum,} from '../../enums';
import {IssueStatusColorEnum,IssueTypeDescEnum, IssuePriorityDescEnum} from '../../enums'
import IssueChatCurrent from "../../components/IssueTracker/IssueChatCurrent.vue";


export default {
  name: "Issue",
  components: {
    IssueChatCurrent
  },
  data () {
    return {
      dialogEditSuccess: false,
      dialog: false,
      showRespondTextarea: false,
      showRespondButtons: true
    }
  },
  methods: {
    ...mapActions({
      downloadFileByUrl: 'issues/downloadFileByUrl',
      changeStatusStore: 'issues/changeStatus',
      setChosenChatUser: 'chat/setChosenChatUser',
      getMessagesByUser: 'chat/getMessagesByUser',
      setWatchedChatUser: "notifications/setWatchedChatUser"
    }),
    changeStatus(issue) {
      switch(issue.status) {
        case 0 :
          this.changeStatusStore(1);
          break;
        case 1 :
          this.changeStatusStore(2);
          break;
        case 2 :
          this.changeStatusStore(0);
          break;
      }
    },
    changeStatusButtonName (issue) {
      switch(issue.status) {
        case 0 :
          return 'rozpocznij rozwiązywanie';
        case 1 :
          return 'uznaj zgłoszenie za rozwiązane';
        case 2 :
          return 'otwórz zgłoszenie raz jeszcze';
      }
    },
    openResponseTextarea() {
      this.showRespondTextarea = true;
      this.showRespondButtons = false;
    },
    closeIssue() {
      this.issue.status = 2;
      this.changeStatusStore(2);
    },
    hideRespondButtons() {
      this.showRespondButtons = false;
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/getUsers',
      loggedInUser: 'users/getLoggedInUser',
      getNameById: 'users/getNameById'
    }),
    IssueStatusDescEnum() {
      return IssueStatusDescEnum
    },
    IssueStatusColorEnum() {
      return IssueStatusColorEnum
    },
    IssueTypeDescEnum() {
      return IssueTypeDescEnum
    },
    IssuePriorityDescEnum() {
      return IssuePriorityDescEnum
    },
    ...mapGetters({
      issue: 'issues/getIssueSelected',
      issues: 'issues/getIssues',
      userRole: 'users/userRole'
    })
  },
  mounted () {
  },
}
</script>

<style scoped>

</style>
