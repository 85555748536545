<template>
  <div>
    <v-dialog v-model="dialogUser" max-width="800px" persistent @input="v => v || closeDialogUser()">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn
              icon
              @click="closeEditDialogUser"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <RegisterFormStudent :report="report" :validate="validate"
                               @registerFormPrinted="registerFormPrinted" @registerFormSaved="registerFormSaved"
                               @registerFormValidated="registerFormValidated" @validateStudentData="validateStudentData"/>

          <StudentCourseView
              v-for="(studentCourse, index) in studentCourses"
              :key="index"
              :allow-edit="false"
              :student-course="studentCourse"
          />

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-7">
          <v-btn class="overline" color="grey" text @click="closeEditDialogUser">
            <v-icon left small>arrow_back</v-icon>
            <span>porzuć dodawanie i wróć do listy wszystkich słuchaczy</span></v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary" rounded @click="save">{{ formTitle }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewSuccess" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Poprawnie zapisałeś użytkownika</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon class="green--text" x-large>check_circle_outline</v-icon>
              </v-col>
              <v-col sm="12">
                Poprawnie zapisano użytkownika.
              </v-col>
              <v-col sm="12">
                <v-btn color="primary" rounded @click="dialogNewSuccess = false">Powrót do listy wszystkich słuchaczy
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewErrorUserExists" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Błąd podczas dodawania użytkownika</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon class="red--text" x-large>warning</v-icon>
              </v-col>
              <v-col sm="12">
                Wystąpił błąd. Użytkownik o takim adresie email już istnieje w bazie.
              </v-col>
              <v-col sm="12">
                <v-btn color="primary" rounded @click="dialogNewErrorUserExists = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewError" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Błąd podczas dodawania użytkownika</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon class="red--text" x-large>warning</v-icon>
              </v-col>
              <v-col sm="12">
                Wystąpił błąd. Proszę upewnić się, że zmiany zostały zapisane.
              </v-col>
              <v-col sm="12">
                <v-btn color="primary" rounded @click="dialogNewError = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import RegisterFormStudent from "./RegisterFormStudent"
import StudentCourseView from "../views/StudentCourseView"
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import moment from "moment";

export default {
  mixins: [validationMixin],
  name: 'UserEdit',
  components: {RegisterFormStudent, StudentCourseView},
  data: () => ({
    validated: false,
    dialogNewSuccess: false,
    dialogNewError: false,
    dialogNewErrorUserExists: false
  }),
  props: {
    closeEditDialogUser: {},
    dialogUser: {},
    formTitle: {},
    priceChoices: {},
    report: {},
    selected: {},
    semesterChoices: {},
    validate: {}
  },
  computed: {
    ...mapGetters({
      userRole: 'users/userRole',
      studentCourses: 'studentCourses/getChosenStudentCoursesData'
    }),
    returnCurrentYear() {
      return moment().format('YYYY');
    },
    isBetweenDates() {
      var startDate   = moment("11/11/" + moment().format('YYYY'), "DD/MM/YYYY");
      var endDate     = moment("31/12/" + moment().format('YYYY'), "DD/MM/YYYY");

      return moment().isBetween(startDate, endDate);
    }
  },
  methods: {
    ...mapActions({
      createStudentCourse: 'studentCourses/createStudentCourse',
      editUser: 'studentCourses/editUser',
    }),
    emit (eventName, value) {
      return new Promise((resolve, reject) => {
        this.validate = value
        this.$nextTick(resolve)
      }).catch((error) => { console.log(error) });
    },
    registerFormValidated(value) {
      this.validate = false
      this.validated = value
    },
    save: async function () {
      await this.emit('validateStudentData', true)
      if (this.validated) {
        this.editUser().then(response => {
          if (response == 200) {
            this.$log.info('Saved edited user', response);
            this.dialogNewSuccess = true;
            this.closeEditDialogUser();
          }
          if (response == 403) {
            this.dialogNewErrorUserExists = true;
            this.closeEditDialogUser();
          }

        }).catch(error => {
          if (error.response && error.response.status == 403) {
            this.dialogNewErrorUserExists = true;
          } else {
            this.dialogNewError = true;
            this.$log.error('Saving edited user error', error)
          }
        })
      }

    },
    getSemesterForRole() {
        // edit
        return this.semesterChoices;
    }
  }
}
</script>
