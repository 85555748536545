<template>
  <v-container>
    <v-card>
      <v-card-text>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-text-field
              v-on:keyup="setHasChanged(data.id)"
              v-model="data.name"
              label="Nazwa pliku"
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-file-input
              @change="setHasChanged(data.id)"
              v-if="data.url === null || data.url === '' || data.url instanceof Object"
              v-model="data.url"
              :rules="rules"
              accept="application/msword, .zip, .docx, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, application/xlsx"
              placeholder="Prześlij plik"
              prepend-icon="file_upload"
              label="Plik"
              show-size
              dense
          ></v-file-input>
          <div v-else>
            <v-btn color="dark" rounded @click="getFileByUrl(data.id)">
              <v-icon left>file_download</v-icon>Pobierz plik
            </v-btn>
            <v-icon left class="ml-3" @click="clearFile()">delete</v-icon>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-textarea
              v-on:keyup="setHasChanged(data.id)"
              v-model="data.description"
              label="Opis pliku"
              dense
              auto-grow
          ></v-textarea>
        </v-col>
      </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="editedIndex !== -1 && data.hasChanged === true" class="mr-4" color="dark primary" rounded @click="updateFileData(data.id)">Zapisz zmianę</v-btn>
        <v-btn v-if="editedIndex !== -1 && data.hasChanged === true" color="dark" rounded @click="removeFile(data.id)">Usuń plik</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogNewError" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Błąd podczas dodawania/edycji pliku</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon x-large class="red--text">warning</v-icon>
              </v-col>
              <v-col sm="12">
                Wygląda na to, że nie obsługujemy takiego rozszerzenia pliku.
              </v-col>
              <v-col sm="12">
                <v-btn rounded color="primary" @click="dialogNewError = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteSuccess" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Plik usunięty</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon x-large class="greeb--text">checked</v-icon>
              </v-col>
              <v-col sm="12">
                Plik został usunięty
              </v-col>
              <v-col sm="12">
                <v-btn rounded color="primary" @click="dialogDeleteSuccess = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "File",
  props: [ 'data', 'editedIndex' ],
  data: () => ({
    dialogNewError: false,
    dialogDeleteSuccess: false,
    rules: [
      value => !value || value.size < 5000000 || 'Maksymalna wielkość pliku to 5 MB!',
    ],
  }),
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    ...mapActions({
      getFileByUrl: 'files/getFileByUrl',
      setHasChanged: 'files/setHasChanged',
      updateFile: 'files/updateFile',
      deleteFile: 'files/deleteFile'
    }),
    updateFileData(dataId) {
      this.updateFile(dataId).then(response => {
        if (response == 200) {
          this.$log.info('Saved new file ', response);
        }
      }).catch(error => {
        this.$log.error('Saving edited file error', error);
        if(error.response && error.response.status == 422) {
          this.dialogNewError = true;
        }
      });
    },
    removeFile(dataId) {
      if(confirm("Czy na pewno chcesz usunąć ten plik?")) {
        this.deleteFile(dataId).then(response => {
          if (response == 200) {
            this.dialogDeleteSuccess = true;
            this.$log.info('Deleting file ', response);
          }
        }).catch(error => {
          this.$log.error('Deleting file error', error);
        });
      }
    },
    clearFile() {
      this.data.url = null;
    }
  }
}
</script>

<style scoped>

</style>
