import {io} from 'socket.io-client';
import store from "../store/index"

class SocketioService {
  socket;
  constructor() {}

  setupSocketConnection() {
    this.socket = io(process.env.VUE_APP_API_NOTIFICATION);
    this.socket.emit('my message', 'Hello there from Vue.');

    this.socket.on('my broadcast', (data) => {
      if (data == 'server: New chat message') {
        store.dispatch('notifications/getNotifications');
        store.dispatch('chat/getLastMessages');
        store.dispatch('chat/getMessagesByUser');
      }
      if (data == 'server: New issue chat message') {
        store.dispatch('notifications/getNotifications');
        store.dispatch('chat/getMessagesByIssue');
      }
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export default new SocketioService();
