import course from '../../services/Course'

// initial state
const state = {
  items: [],
  dialogNew: false,
  courseSelected: null,
  courseSingleSelected: null,
};

// getters
const getters = {
  getCourses: state => state.items.filter(p => p.active === 1),
  getCoursesWithoutAllOption: state => [{ id: '', name: 'Kierunek', courseSubjects: []}, ...state.items],
  dialogNew: state => state.dialogNew,
  getCourseNameById: state => courseId => state.items.find(p => p.id === courseId) != undefined ? state.items.find(p => p.id === courseId).name : '',
  getCourseSelected: state => state.courseSelected,
  getCourseSingleSelected: state => state.courseSingleSelected,
  getSelectedCourseData: state => state.items.find(x => x.id == state.courseSelected),
  getSelectedCourseSingleData: state => state.items.find(x => x.id == state.courseSingleSelected)
};

// actions
const actions = {
  getCourses ({ commit }) {
    return new Promise((resolve, reject) => {
      course.getCourses().then(response => {
        if(response && response.status == 200) {
          commit('setCourses', response.data);
          resolve(response);
        } else {
          reject()
        }
      })
    }).catch((error) => { console.log(error) });
  },
  createCourse ({ dispatch, commit }, newCourse) {
    course.createCourse(newCourse).then(response => {
        return new Promise((resolve, reject) => {
          dispatch('subjects/createSubjects', response.data.id, {root: true}).then((response2) => {
            if (response2.status == 200) {
              commit('addCourse', response.data);
              resolve(200);
            }
          }).catch((errors) => {
            reject(errors)
          });
        }).catch((error) => { console.log(error) });
    })
  },
  editCourse ({dispatch, commit }, editedCourse) {
    course.editCourse(editedCourse).then(response => {
      dispatch('subjects/deleteSubjects', null, {root: true});
      dispatch('subjects/createSubjects', null, {root: true});
      commit('editCourse', response.data)
    })
  },
  deleteCourses ({ commit }, deletedCourses) {
    course.deleteCourses(deletedCourses.map(function(item) { return item["id"] })).then(response => {
      if (response.status == 200) {
        commit('deleteCourses', deletedCourses)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setCourseSelected ({ dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      commit('setCourseSelected', value);
      resolve(200);
    }).catch((error) => { console.log(error) });
  },
  setCourseSingleSelected ({ dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      commit('setCourseSingleSelected', value);
      resolve(200);
    }).catch((error) => { console.log(error) });
  }
};

// mutations
const mutations = {
  setCourses (state, courses) {
    state.items = courses
  },
  addCourse (state, course) {
    state.items.push(course)
  },
  editCourse (state, course) {
    console.log(course)
  },
  deleteCourses (state, courses) {
    courses.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setCourseSelected (state, value) {
    state.courseSelected = value
  },
  setCourseSingleSelected (state, value) {
    state.courseSingleSelected = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
