<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ course.name }}
        <v-btn color="grey" class="overline ml-5" text @click="$router.push('/admin/courses')"><v-icon small left>arrow_back</v-icon> powrót do listy wszystkich kierunków</v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col sm="12">
              <div v-if="subjects.length > 0">
                <v-container>
                  <v-row>
                    <v-col sm="5">
                      <div><b>Przedmiot</b></div>
                    </v-col>
                    <v-col sm="2">
                      <div><b>Wykładowca</b></div>
                    </v-col>
                    <v-col sm="1">
                      <div><b>Semestr</b></div>
                    </v-col>
                    <v-col sm="2">
                      <div><b>Zaliczenie/Egzamin</b></div>
                    </v-col>
                    <v-col sm="2">
                      <div><b>Uwagi</b></div>
                    </v-col>
                  </v-row>
                  <v-row v-for="item in subjects">
                    <v-col sm="5">
                      {{ item.name }}
                    </v-col>
                    <v-col sm="2">
                      {{ userNameById(item.uid) }}
                    </v-col>
                    <v-col sm="1">
                      {{ item.semester }}
                    </v-col>
                    <v-col sm="2">
                      {{ item.exam == 0 ? 'Zaliczenie' : 'Egzamin' }}
                    </v-col>
                    <v-col sm="2">
                      {{ afterJoin(item.after_join) }}
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <HistorySubject />
      </v-card-text>
      <v-card-actions class="d-flex justify-end mr-8">
        <v-btn @click="generateCourseCSV()" color="primary" dark rounded class="mb-4">Eksportuj jako CSV</v-btn>
        <v-btn @click="generateCoursePDF()" color="primary" dark rounded class="mb-4">Eksportuj jako PDF</v-btn>
      </v-card-actions>
    </v-card>
    <Message @closeDialogMessage="closeDialogMessage" :showDialog="dialogMessage" :receiver="{ email: email }" />
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {createHelpers} from "vuex-map-fields";
import Message from "../components/Message";
import HistorySubject from "./HistorySubject";

const { mapFields } = createHelpers({
  getterType: 'registrationStudent/getPersonal',
  mutationType: 'registrationStudent/updatePersonal',
});

export default {
  name: "AdminCourse",
  components: {Message, HistorySubject},
  data () {
    return {
      dialogEditSuccess: false,
      validate: false,
      report: false,
      dialogMessage: false
    }
  },
  methods: {
    ...mapActions({
      // getSubjectsAction: 'subjects/getSubjects',
    }),
    newMessage () {
      this.dialogMessage = true
    },

    generateCourseCSV () {
      let csvContent = "data:text/csv;charset=utf-8,";

      csvContent += "Przedmiot;Wykładowca;Semestr;Zaliczenie/Egzamin;Uwagi\n";
      let table = [];
      csvContent += table.concat(this.subjects.map(obj => {
        return obj.name + ';' + this.userNameById(obj.uid) + ';' + (obj.semester == null ? '' : obj.semester) + ';' + (obj.exam == 0 ? 'Zaliczenie' : 'Egzamin') + ';' + this.afterJoin(obj.after_join) +  ';'
      })).join('\n')

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", 'unikadra-kierunek-' + this.course.id + ".csv");
      link.click();
    },

    generateCoursePDF () {
      var table = [];
      table.push(["Przedmiot", "Wykładowca", "Semestr", "Zaliczenie/Egzamin", "Uwagi"]);

      var body = table.concat(this.subjects.map(obj => {
        return [obj.name, this.userNameById(obj.uid), obj.semester, (obj.exam == 0 ? 'Zaliczenie' : 'Egzamin'), this.afterJoin(obj.after_join) ]
      }));

      var pdfMake = require('pdfmake/build/pdfmake.js');
      if (pdfMake.vfs == undefined){
        var pdfFonts = require('pdfmake/build/vfs_fonts.js');
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var docDefinition = {
        content: [
          {
            stack: [
              'Kierunek: ' + this.course.name,
            ],
            style: 'header'
          },
          {
            style: 'tableExample',
            table: {
              body: body
            }
          }
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 80],
            alignment: "center"
          },
          tableExample: {
            alignment: "left"
          }
        }
      };
      pdfMake.createPdf(docDefinition).download('unikadra-kierunek-'+this.course.id+'.pdf')
    },

    closeDialogMessage(val) {
      this.dialogMessage = val;
    },
    afterJoin(afterJoin) {
      if (afterJoin == 0) {
        return 'do 2023.10'
      } else if (afterJoin == 1) {
        return 'od 2024.03'
      } else {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters({
      chosenCourseId: 'courses/getCourseSelected',
      course: 'courses/getSelectedCourseData',
      subjects: 'subjects/getSubjectsBySelectedCourseId',
      userNameById: 'users/getNameById',
      users: 'users/getUsers'
    }),
    ...mapFields({
      // personal
      surname: 'personal.surname',
      name: 'personal.name',
      secondName: 'personal.secondName',
      placeOfBirth: 'personal.placeOfBirth',
      dayOfBirth: 'personal.dayOfBirth',
      // address
      city: 'address.city',
      street: 'address.street',
      houseNr: 'address.houseNr',
      homeNr: 'address.homeNr',
      zip: 'address.zip',
      // contact
      email: 'contact.email',
      telephoneNr: 'contact.telephoneNr'
    })
  },
  created() {
    // this.getSubjectsAction();
  }
}
</script>

