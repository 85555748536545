<template>
    <v-btn @click="generatePDFReport()" color="primary" dark rounded small>Rachunek wykładowcy</v-btn>
</template>

<script>
  import {createHelpers} from "vuex-map-fields";

  const { mapFields } = createHelpers({
    getterType: 'registration/getPersonal',
  });

  export default {
    name: "BillUser",
    computed: {
      ...mapFields({
        // personal
        personalSurname: 'personal.surname',
        personalName: 'personal.name',
        addressStreet: 'address.street',
        addressHouseNr: 'address.houseNr',
        addressHomeNr: 'address.homeNr',
        addressZip: 'address.zip',
        addressCity: 'address.city',
      }),
    },
    methods: {
      generatePDFReport () {
        var pdfMake = require('pdfmake/build/pdfmake.js');
        if (pdfMake.vfs == undefined){
          var pdfFonts = require('pdfmake/build/vfs_fonts.js');
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          content: [
            {
              alignment: 'justify',
              columns: [
                {
                  text: this.personalName + ' ' + this.personalSurname + '\n' + this.addressStreet + ' ' + this.addressHouseNr + '' + ((this.addressHomeNr != undefined) ? '/' + this.addressHomeNr : '') + '\n' + this.addressZip + ' ' + this.addressCity,
                  margin: [0, 0, 0, 40],
                },
                {
                  text: 'Płock, dnia: ................................ r.',
                  margin: [0, 0, 0, 40],
                  alignment: 'right',
                }
              ]
            },
            {
              stack: [
                'Rachunek nr:................ ',
              ],
              style: 'header'
            },
            {
              stack: [
                '(do Umowy nr................ ................)',
              ],
              style: 'subheader'
            },
            {
              stack: [
                'Dla: ',
              ],
              margin: [0, 0, 0, 10]
            },
            {
              stack: [
                'GTE GLOBAL TRADE AND EDUCATION SP. Z O.O.\n' +
                '09-407 PŁOCK, AL. MARSZ. JÓZEFA PIŁSUDSKIEGO 35, KL. II, LOK. 3\n' +
                'NIP 5711715517\n',
              ],
              style: 'h3'
            },
            {
              stack: [
                'za wykonanie prac w okresie od ................ r. do ................ r. zgodnie z Umową zlecenia nr................. z dnia ................. r.  ',
              ],
              lineHeight: 2,
            },
            {
              stack: [
                'Stwierdzam, że praca została wykonana według warunków w/w umowy.',
              ],
            },
            {
              alignment: 'justify',
              columns: [
                {
                  text: '................................................................ ',
                  margin: [0, 30, 0, 0],
                },
                {
                  text: '................................................................ ',
                  margin: [0, 30, 0, 0],
                  alignment: 'right',
                }
              ]
            },
            {
              alignment: 'justify',
              columns: [
                {
                  text: '(podpis zleceniobiorcy)',
                  margin: [0, 0, 0, 0],
                },
                {
                  text: '(podpis zleceniodawcy)',
                  margin: [0, 0, 0, 0],
                  alignment: 'right',
                }
              ]
            },
            {
              stack: [
                'Rozliczenie umowy: ',
              ],
              margin: [0, 40, 0, 10]
            },
            {
              stack: [
                'Kwota wynagrodzenia brutto: ................................................................',
              ],
              margin: [0, 0, 0, 20]
            },
            {
              stack: [
                'Słownie: ................................................................',
              ],
              margin: [0, 0, 0, 20]
            },
            {
              stack: [
                'Sposób płatności: ',
              ],
              margin: [0, 0, 0, 10]
            },
            {
              stack: [
                'Przelew proszę wykonać na konto:',
              ],
              margin: [0, 0, 0, 20]
            },
            {
              style: 'tableExample',
              table: {
                widths: [100, 400],
                heights: [30, 30],
                body: [
                  ['Nazwa banku', ''],
                  ['Numer rachunku', ''],
                ]
              }
            },
            {
              stack: [
                '(podpis zleceniobiorcy/wystawcy rachunku)',
              ],
              alignment: 'right',
              margin: [0, 30, 0, 0]
            },

          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
              alignment: "center"
            },
            subheader: {
              fontSize: 12,
              margin: [0, 0, 0, 50],
              alignment: "center"
            },
            h3: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 20],
              alignment: "left",
              lineHeight: 2
            },
            defaultStyle: {
              lineHeight: 2
            }
          }
        };
        pdfMake.createPdf(docDefinition).download('unikadra-rachunek-wykladowcy.pdf')
      }

    }
  }
</script>

<style scoped>

</style>
