import Api from './Api'

export default {
  createBill (bill, userId = null) {
    return Api().post('/api/bills' + (userId?'?user='+userId:''), bill, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getBills (period, userId = null) {
    return Api().get('/api/bills?from=' + period.start + '&to=' + period.end + (userId?'&user='+userId:''), { withCredentials: true })
  },
  editBill (billData, userId = null) {
    return Api().put('/api/bills/' + billData.id + (userId?'?user='+userId:''), billData)
  },
  deleteBills (bills, userId = null) {
    return Api().post('/api/bills/delete-multiple' + (userId?'?user='+userId:''), bills)
  },
  cloneBill (billId, userId = null) {
    return Api().post('/api/bills/' + billId + '/clone' + (userId?'?user='+userId:''))
  }
}
