<template>
  <div>
    <v-toolbar color="white" flat>
      <v-toolbar-title>Tagi</v-toolbar-title>
      <v-divider
          class="mx-4"
          inset
          vertical
      ></v-divider>
      <v-text-field
          v-model="search"
          append-icon="search"
          hide-details
          label="Szukaj"
          single-line
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-form @submit.prevent="save">
        <v-text-field
            v-model="editedItem.name"
            :error-messages="nameErrors"
            hide-details label="Nowy tag"
            required
            @blur="$v.editedItem.name.$touch()"
        ></v-text-field>
        <v-btn class="primary" rounded @click="save">Dodaj Tag</v-btn>
      </v-form>
    </v-toolbar>
    <div v-if="tagsSearched(this.search).length > 0" class="ma-4">
      <v-chip
          v-for="tag in tagsSearched(this.search)"
          class="ma-2"
          label
          @click="remove(tag)"
      >
        {{ tag.name }}
        <v-icon right>
          mdi-delete
        </v-icon>
      </v-chip>
    </div>
    <div v-else class="ma-4">
      Brak tagów
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    editedItem: {
      name: { required },
    }
  },
  name: "Tags",
  computed: {
    ...mapGetters({
      tagsSearched: 'tags/getTagsSearched',
      userRole: 'users/userRole',
    }),

    formTitle () {
      return this.editedIndex === -1 ? 'Dodawanie nowej placówki' : 'Edytuj placówkę'
    },
    nameErrors () {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.required && errors.push('Podanie nazwy jest wymagane');
      return errors
    },
    showExtraActions () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  data: () => ({
    dialogRemoveSuccess: false,
    search: '',
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
  }),
  methods: {
    ...mapActions({
      createTag: 'tags/createTag',
      deleteTag: 'tags/deleteTag',
    }),
    closeDialogRemoveSuccess () {
      this.dialogRemoveSuccess = false;
    },
    save () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createTag(this.editedItem).then(response => {
          this.$log.info('Saved new tag', response);
          this.editedItem.name = '';
          this.$v.$reset();

        }).catch(error => {
          this.$log.error('Saving new tag error', error)
        })
      }
    },
    remove (tag) {
      if(confirm('Czy na pewno chcesz usunąć poniższy tag: ' + tag.name + '?')){
        this.deleteTag(tag.id).then(response => {
          this.$log.info('Deleted tag', response);
          this.dialogRemoveSuccess = true
        }).catch(error => {
          this.$log.error('Deleting tag error', error)
        })
      }
    },
  },
  created() {
    this.$store.dispatch('tags/getTags');
  },
}
</script>

<style scoped>
  form {
    display: flex;
  }
</style>
