<template>
    <v-row align="center" justify="center">
      <v-col>
        <div class="error text-center pa-4">
          <h2>Aktualnie trwa przerwa techniczna.</h2>
          <h3>Pracujemy nad rozwiązaniem problemu</h3>
        </div>
      </v-col>
    </v-row>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "500",
  methods: {
    ...mapActions({
      checkApiUptime: 'users/checkApiUptime',
    })
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.checkApiUptime().then(response => {
        if(response == 200) {
          window.location.href = '/';
        }
      }).catch(error => {
        console.log(error);
      });
    }, 3000)
  },
  data() {
    return {
      timer: null
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
</style>
