<template>
  <v-data-table
      :headers="headers"
      :items="settlements"
      sort-by="name"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista wszystkich wpłat</v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      Brak wpłat
    </template>
    <template v-slot:item.amount="{ item }">
      {{ item.amount/100 }} PLN
    </template>
    <template v-slot:item.studentCourseBankAccount="{ item }">
      {{ item.studentCourseBankAccount ? item.studentCourseBankAccount : item.bank_account }}
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Settlements",
  computed: {
    ...mapGetters({
      settlements: 'settlements/getSettlements'
    }),
  },
  props: {
    uid: null
  },
  data: () => ({
    selected: [],
    menuDate: false,
    headers: [
      { text: 'Kwota', value: 'amount' },
      { text: 'Data', value: 'date' },
      { text: 'Kierunek', value: 'courseName' },
      { text: 'Rachunek', value: 'studentCourseBankAccount' },
    ],
  }),
  methods: {
    ...mapActions({
    }),
  },
  created() {
    this.$store.dispatch('settlements/getSettlementsByUserId');
  },
}
</script>

<style scoped>

</style>
