<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <template v-slot:default="{ open }">
                <v-row no-gutters>
                    <v-col cols="12" class="title">
                        V. Wynagrodzenie
                    </v-col>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <Salary/>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
  import Salary from "./Salary";

  export default {
    name: "SalaryData",
    components: {Salary},
    data: () => ({
    })
  }
</script>

