<template>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-if="userRole === 1 || userRole === 7" v-slot:activator="{ on }">
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="mb-2 ml-4" color="primary" rounded small v-on="on">Kreator spotkań</v-btn>
        <v-btn v-else class="mb-2 ml-4" color="primary" rounded small v-on="on">Utwórz</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Kreator nowego spotkania</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row v-if="userRole == 1 || userRole == 7">
              <v-col cols="12" md="8">
                <v-autocomplete v-model="editedItem.uid"
                                :error-messages="uidErrors"
                                :items="getUsersForSelect"
                                chips
                                dense
                                hint="Wykładowca prowadzący spotkanie"
                                item-text="name"
                                item-value="id"
                                label="Prowadzący"
                                persistent-hint
                                required
                                small-chips
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-autocomplete v-model="editedItem.duration"
                          :error-messages="durationErrors"
                          :items="durations"
                          dense
                          item-text="value"
                          item-value="key"
                          label="Czas trwania"
                          menu-props="auto"
                          persistent-hint
                          required
                          @blur="$v.editedItem.duration.$touch()"
                          @change="updateAllOnlineMeetingsWithGeneralSettings()"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12">
                  <OnlineMeetingCreateGroupRow
                      v-for="(item, index) in onlineMeetings"
                      :edited-item="item"
                      :key-index="index"
                      @closeRow="closeRow(index)"
                      @isValid="isValid"
                  ></OnlineMeetingCreateGroupRow>
              </v-col>
            </v-row>
            <v-row class="align-content-end" col="12">
              <v-spacer></v-spacer>
              <v-btn v-if="onlineMeetings.length === 0" color="primary" rounded @click="addMeeting()">Dodaj informacje</v-btn>
              <v-btn v-else color="primary" rounded @click="addMeeting()">Skopiuj do kolejnego wiersza</v-btn>
            </v-row>
            <v-row>
              <v-col col="12">
                <hr />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" col="12">
                <h4>Zabezpieczenia (pola opcjonalne)</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" md="12">
                <v-checkbox
                    v-model="editedItem.waiting_room"
                    dense
                    hint="Tylko użytkownicy dopuszczeni przez prowadzącego mogą dołączyć do spotkania"
                    label="Poczekalnia"
                    persistent-hint
                    @change="updateAllOnlineMeetingsWithGeneralSettings()"
                ></v-checkbox>
              </v-col>
              <v-col col="12" md="12">
                <v-checkbox
                    v-model="passwordRequired"
                    dense
                    hint="Tylko użytkownicy posiadający link lub kod dostępu mogą dołączyć do spotkania"
                    label="Kod dostępu"
                    persistent-hint
                ></v-checkbox>
                <v-text-field
                    v-if="passwordRequired"
                    v-model="editedItem.password"
                    hint="Proszę podać kod dostępu"
                    persistent-hint
                    @change="updateAllOnlineMeetingsWithGeneralSettings()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12">
                <h4>Wideo (pola opcjonalne)</h4>
                <p class="v-messages">ta funkcjonalność pozwala określić kto może włączyć wideo podczas trwania spotkania. Możesz wyłączyć, wtedy Słuchacze nie będą mogli prezentować swoich twarzy.</p>
                <v-radio-group
                    v-model="editedItem.host_video"
                    row
                    @change="updateAllOnlineMeetingsWithGeneralSettings()"
                >
                  <template v-slot:label>
                    <div class="v-label">Prowadzący</div>
                  </template>
                  <v-radio
                      key="true"
                      :value=true
                      label="włączone"
                  ></v-radio>
                  <v-radio
                      key="true"
                      :value=false
                      label="wyłączone"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group
                    v-model="editedItem.participant_video"
                    row
                    @change="updateAllOnlineMeetingsWithGeneralSettings()"
                >
                  <template v-slot:label>
                    <div class="v-label">Słuchacze</div>
                  </template>
                  <v-radio
                      key="true"
                      :value=true
                      label="włączone"
                  ></v-radio>
                  <v-radio
                      key="true"
                      :value=false
                      label="wyłączone"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-7">
          <v-btn class="overline" color="grey" text @click="close"><v-icon left small>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuć dodawanie i wróć do listy wszystkich spotkań</span></v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="isBusy" class="primary" rounded @click="save"> <v-icon dark right>event_available</v-icon> Utwórz spotkania</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import OnlineMeetingCreateGroupRow from "./OnlineMeetingCreteGroupRow.vue";
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import moment from "moment";

export default {
  mixins: [validationMixin],
  name: "OnlineMeetingCreateGroup",
  components: { OnlineMeetingCreateGroupRow },
  validations: {
    editedItem: {
      duration: {required},
      uid: {required}
    }
  },
  data: () => ({
    editedItem: {
      uid: null,
      topic: '',
      date: moment().weekday(6).format('YYYY-MM-DD'),
      time: '09:15',
      users: [],
      semester: 1,
      cid: '',
      sid: '',
      duration: 90,
      waiting_room: false,
      password: '',
      host_video: true,
      participant_video: true
    },
    defaultItem: {
      uid: null,
      topic: '',
      date: moment().weekday(6).format('YYYY-MM-DD'),
      time: '09:15',
      users: [],
      semester: 1,
      cid: '',
      sid: '',
      duration: 90,
      waiting_room: false,
      password: '',
      host_video: true,
      participant_video: true
    },
    onlineMeetings: [],
    passwordRequired: false,
    isBusy: false,
    childValid: []
  }),
  methods: {
    ...mapActions({
      setDialogGroupNew: 'onlineMeetings/setDialogGroupNew',
      createOnlineMeetings: 'onlineMeetings/createOnlineMeetings',
      setCourseSelected: 'courses/setCourseSelected',
      resetStudentCourses: 'studentCourses/resetStudentCourses'
    }),
    close () {
      this.dialog = false;
      this.onlineMeetings = [];
      this.childValid = [];

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.setCourseSelected(null);
        this.resetStudentCourses();
        this.$v.editedItem.$reset();
      })
    },
    closeRow (keyIndex) {
      this.onlineMeetings.splice(keyIndex, 1);
      delete(this.childValid[keyIndex]);
    },
    isValid(emited) {
      if (emited[0]) {
        this.childValid[emited[1]] = emited[0];
      } else {
        this.childValid.splice(emited[1], 1);
      }
      console.log(this.childValid);

    },
    save () {
      this.$v.$touch();
      if (this.onlineMeetings.length == 0) {
        this.addMeeting();
      }
      // console.log(this.childValid)
      // console.log(this.childValid.length + ' ' + this.onlineMeetings.length)
      // console.log(this.childValid.every(element => element === true));
      // console.log(this.onlineMeetings);
      // return;
      if (!this.$v.$invalid && this.onlineMeetings.length > 0 && this.childValid.length == this.onlineMeetings.length &&  this.childValid.every(element => element === true)) {
        this.isBusy = true;
        // console.log(this.onlineMeetings);
        // this.isBusy = false;
        // return;
        this.createOnlineMeetings(this.onlineMeetings).then(response => {
          this.$log.info('Saved new online meeting', response);
          this.close();
          this.isBusy = false;
          this.$emit('meetingCreated', true)
        }).catch(error => {
          this.$log.error('Saving new online meeting error', error)
          this.isBusy = false;
          this.close();
          this.$emit('meetingError', true)
        })
      }
    },
    addMeeting() {
      this.$v.$touch();
      // console.log('invalid' + this.$v.$invalid);
      if (!this.$v.$invalid) {
        // take last as reference
        if (this.onlineMeetings.length > 0) {
          let lastItem = this.onlineMeetings.slice(-1);
          this.onlineMeetings.push(
              Object.assign({},
                  {
                    ...this.editedItem,
                    time: moment(lastItem[0].time, ['h:m a', 'H:m']).add((lastItem[0].duration) + 15, 'minutes').format('HH:mm'),
                    cid: lastItem[0].cid,
                    sid: lastItem[0].sid,
                    date: lastItem[0].date
                  }
              )
          );
        } else {
          this.onlineMeetings.push(Object.assign({}, this.editedItem));
        }
      }
    },
    updateAllOnlineMeetingsWithGeneralSettings() {
      this.onlineMeetings.forEach(onlineMeeting => {
        onlineMeeting.duration = this.editedItem.duration;
        onlineMeeting.waiting_room = this.editedItem.waiting_room;
        onlineMeeting.password = this.editedItem.password;
        onlineMeeting.host_video = this.editedItem.host_video;
        onlineMeeting.participant_video = this.editedItem.participant_video;
      });
    }
  },
  computed: {
    ...mapGetters({
      dialogFromStore: 'onlineMeetings/dialogGroupNew',
      courses: 'courses/getCourses',
      courseSelectedFromStore: 'courses/getCourseSelected',
      subjects: 'subjects/getSubjectsBySelectedCourseId',
      getAvailableSemestersForSelectedCourse: 'subjects/getAvailableSemestersForSelectedCourse',
      userRole: 'users/userRole',
      getUsersForSelect: 'users/getUsersForSelect'
    }),
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogGroupNew(value)
      }
    },
    durations() {
      return [
        {key: 15, value: '15 minut'},
        {key: 30, value: '30 minut'},
        {key: 45, value: '45 minut'},
        {key: 45, value: '45 minut'},
        {key: 60, value: '1 godzina'},
        {key: 90, value: '1,5 godziny'},
        {key: 120, value: '2 godziny'},
      ]
    },
    durationErrors () {
      const errors = [];
      if (!this.$v.editedItem.duration.$dirty) return errors;
      !this.$v.editedItem.duration.required && errors.push('Podanie długości trwania spotkania jest konieczne');
      return errors
    },
    uidErrors () {
      const errors = [];
      if (!this.$v.editedItem.uid.$dirty) return errors;
      !this.$v.editedItem.uid.required && errors.push('Podanie prowadzącego jest konieczne');
      return errors
    },
  },

}
</script>

<style scoped>

</style>
