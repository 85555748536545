<template>
    <v-container>
        <v-row>
            <v-col sm="12">
              <v-select
                  v-model="degree"
                  :items="degreeChoices"
                  label="Stopień naukowy"
              ></v-select>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';

const { mapFields } = createHelpers({
    getterType: 'registration/getPersonal',
    mutationType: 'registration/updatePersonal',
  });

  export default {
    name: "PersonalCompanyData",
    props: [
    ],
    data: () => ({
      degreeChoices: ['adw.', 'inż.', 'mgr', 'mgr inż.', 'dr', 'dr hab.', 'dr inż.', 'dr hab.inż', 'prof.', 'prof. dr hab.inż.'],
    }),
    computed: {
      ...mapFields([
        'personal.degree'
      ]),
    },
  }
</script>

<style scoped>

</style>
