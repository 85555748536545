<template>
    <div>
        <v-row>
            <v-col sm="12">
                <div class="title"><strong>Dane adresowe</strong></div>
            </v-col>
        </v-row>
        <v-container>
            <v-row no-gutters>
                <v-col sm="6">
                    <v-text-field
                            v-model="province"
                            label="Województwo"
                            dense
                            :error-messages="provinceErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="district"
                            label="Powiat"
                            dense
                            :error-messages="districtErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="municipality"
                            label="Gmina"
                            dense
                            :error-messages="municipalityErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="city"
                            label="Miejscowość"
                            dense
                            :error-messages="cityErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="street"
                            label="Ulica"
                            dense
                            :error-messages="streetErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="houseNr"
                            label="Numer domu"
                            dense
                            :error-messages="houseNrErrors"
                            required
                    ></v-text-field>
                </v-col>
                <v-col sm="6">
                    <v-text-field
                            v-model="homeNr"
                            label="Numer lokalu"
                            dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="zip"
                            label="Kod pocztowy"
                            dense
                            :error-messages="zipErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="post"
                            label="Poczta"
                            dense
                            :error-messages="postErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields } = createHelpers({
    getterType: 'registration/getAddress',
    mutationType: 'registration/updateAddress',
  });

  export default {
    name: "Address",
    data: () => ({
    }),
    props: [
      'provinceErrors',
      'districtErrors',
      'municipalityErrors',
      'cityErrors',
      'streetErrors',
      'houseNrErrors',
      'zipErrors',
      'postErrors'
    ],
    computed: {
      ...mapFields([
        'address.province',
        'address.district',
        'address.municipality',
        'address.city',
        'address.street',
        'address.houseNr',
        'address.homeNr',
        'address.zip',
        'address.post',
      ]),
    }
  }
</script>

<style scoped>

</style>
