<template>
    <v-select
        v-model="place"
        :items="places"
        menu-props="auto"
        label="Miasto"
        hide-details
        append-outer-icon="map"
        single-line
        item-text="name"
        item-value="id"
        @change="returnValue"
        :error-messages="placeErrors"
        required
    ></v-select>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: 'AvailableLocations',
    props: ['placeErrors', 'place'],
    computed: {
      ...mapGetters({
        places: 'places/getPlaces',
      }),
    },
    created() {
      this.$store.dispatch('places/getPlaces');
    },
    methods: {
        returnValue(value) {
          this.$emit('placeChanged',value)
        }
    }
  }
</script>

<style scoped>

</style>
