<template>
  <div class="back">
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-layout align-center justify-center row wrap>
        <v-col md="12" sm="12">
          <v-row>
            <slot />
          </v-row>
        </v-col>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style scoped>
body {
  background-color: #D4DAFF;
}
.back {
  background-image: url('../assets/error_500.png');
  background-size: cover;
  height: 100%;
}
</style>
