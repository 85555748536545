<template>
    <PersonalData
            :surnameErrors="surnameErrors"
            :nameErrors="nameErrors"
            :placeOfBirthErrors="placeOfBirthErrors"
            :dayOfBirthErrors="dayOfBirthErrors"

            :cityErrors="cityErrors"
            :streetErrors="streetErrors"
            :houseNrErrors="houseNrErrors"
            :zipErrors="zipErrors"

            :emailErrors="emailErrors"
            :telephoneNrErrors="telephoneNrErrors"
    ></PersonalData>
</template>

<script>
import PersonalData from "@/components/RegisterFormStudent/PersonalData";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {createHelpers} from "vuex-map-fields";
import {mapActions} from "vuex";

const { mapFields } = createHelpers({
    getterType: 'registrationStudent/getPersonal',
    mutationType: 'registrationStudent/updatePersonal',
  });

  export default {
    name: 'RegisterForm',
    props: ['validate', 'report'],
    components: {PersonalData },
    mixins: [validationMixin],
    validations: {
      // personal
      surname: {required},
      name: {required},
      placeOfBirth: {required},
      dayOfBirth: {required},
      //address
      city: {required},
      street: {required},
      houseNr: {required},
      zip: {required},
      // contact
      email: {required},
      telephoneNr: {required},
    },
    computed: {
      ...mapFields({
        // personal
        surname: 'personal.surname',
        name: 'personal.name',
        secondName: 'personal.secondName',
        placeOfBirth: 'personal.placeOfBirth',
        dayOfBirth: 'personal.dayOfBirth',
        // address
        city: 'address.city',
        street: 'address.street',
        houseNr: 'address.houseNr',
        homeNr: 'address.homeNr',
        zip: 'address.zip',
        // contact
        email: 'contact.email',
        telephoneNr: 'contact.telephoneNr',
        bank_account: 'contact.bank_account',
      }),
      surnameErrors () {
        const errors = [];
        if (!this.$v.surname.$dirty) return errors;
        !this.$v.surname.required && errors.push('Podanie nazwiska jest konieczne');
        return errors
      },
      nameErrors () {
        const errors = [];
        if (!this.$v.name.$dirty) return errors;
        !this.$v.name.required && errors.push('Podanie imienia jest konieczne');
        return errors
      },
      placeOfBirthErrors () {
        const errors = [];
        if (!this.$v.placeOfBirth.$dirty) return errors;
        !this.$v.placeOfBirth.required && errors.push('Podanie miejsca urodzenia jest konieczne');
        return errors
      },
      dayOfBirthErrors () {
        const errors = [];
        if (!this.$v.dayOfBirth.$dirty) return errors;
        !this.$v.dayOfBirth.required && errors.push('Podanie daty urodzenia jest konieczne');
        return errors
      },
      cityErrors () {
        const errors = [];
        if (!this.$v.city.$dirty) return errors;
        !this.$v.city.required && errors.push('Podanie miasta jest konieczne');
        return errors
      },
      streetErrors () {
        const errors = [];
        if (!this.$v.street.$dirty) return errors;
        !this.$v.street.required && errors.push('Podanie ulicy jest konieczne');
        return errors
      },
      houseNrErrors () {
        const errors = [];
        if (!this.$v.houseNr.$dirty) return errors;
        !this.$v.houseNr.required && errors.push('Podanie numeru domu jest konieczne');
        return errors
      },
      zipErrors () {
        const errors = [];
        if (!this.$v.zip.$dirty) return errors;
        !this.$v.zip.required && errors.push('Podanie kodu pocztowego jest konieczne');
        return errors
      },
      emailErrors () {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.required && errors.push('Podanie adresu email jest konieczne');
        return errors
      },
      telephoneNrErrors () {
        const errors = [];
        if (!this.$v.telephoneNr.$dirty) return errors;
        !this.$v.telephoneNr.required && errors.push('Podanie telefonu jest konieczne.');
        return errors
      }
    },
    watch: {
      validate: function (newValue) {
        if (newValue === true) {
          this.save()
        }
      },
      report: function (newValue) {
        if (newValue === true) {
          this.generatePDFReport()
          this.$emit('registerFormPrinted',true)
        }
      }
    },
    methods: {
      ...mapActions({
        saveAll: 'registrationStudent/saveAll',
      }),
      save () {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.$emit('registerFormValidated',true)
          // this.saveAll().then(response => {
          //   if (response) {
          //     this.$emit('registerFormSaved',true)
          //   }
          // }).catch(error => {
          //   this.$log.error('Saving registration form error', error)
          // })
        } else {
          this.$emit('registerFormValidated',false)
        }
      },
      generatePDFReport () {

      }
    }
  }
</script>

<style scoped>

</style>
