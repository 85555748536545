<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col sm="6">
          <div><b>Nazwisko</b></div>
          {{ surname }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div><b>Imię</b></div>
          {{ name }}
        </v-col>
        <v-col sm="6">
          <div><b>Drugie imię</b></div>
          {{ secondName }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div><b>Data urodzenia</b></div>
          {{ dayOfBirth }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div><b>Miejsce urodzenia</b></div>
          {{ placeOfBirth }}
        </v-col>
      </v-row>
    </v-container>
    <hr/>
    <v-container>
      <v-row>
        <v-col sm="6">
          <div><b>Email</b></div>
          {{ email }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div><b>Telefon</b></div>
          {{ telephoneNr }}
        </v-col>
      </v-row>
      <v-row v-if="bank_account">
        <v-col sm="6">
          <div><b>Konto bankowe</b></div>
          {{ bank_account }}
        </v-col>
      </v-row>
    </v-container>
    <hr/>
    <v-container>
      <v-row>
        <v-col sm="6">
          <div><b>Miasto</b></div>
          {{ city }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div><b>Kod pocztowy</b></div>
          {{ zip }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div><b>Ulica</b></div>
          {{ street }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <div><b>Numer budynku</b></div>
          {{ houseNr }}
        </v-col>
        <v-col sm="6">
          <div><b>Numer lokalu</b></div>
          {{ homeNr }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'StudentPersonalDataInfo',
  props: [
    'surname',
    'name',
    'secondName',
    'dayOfBirth',
    'placeOfBirth',
    'city',
    'street',
    'houseNr',
    'homeNr',
    'zip',
    'email',
    'telephoneNr',
    'bank_account',
  ],
}
</script>
