import Api from './Api'

export default {
  createOnlineMeeting (onlineMeeting, userId = null) {
    return Api().post('/meetingapi/meetings' + (userId?'?userId='+userId:''), onlineMeeting)
  },
  createOnlineMeetings (onlineMeetings, userId = null) {
    return Api().post('/meetingapi/meetings/multiple' + (userId?'?userId='+userId:''), onlineMeetings)
  },
  getOnlineMeetings (cid = null, userId = null) {
    return Api().get('/meetingapi/meetings/course' + ((cid !== null) ? ('/' + cid) : '') +(userId?'?userId='+userId:''), { withCredentials: true })
  },
  editOnlineMeeting (onlineMeetingData) {
    return Api().put('/meetingapi/meetings/' + onlineMeetingData.id, onlineMeetingData)
  },
  deleteOnlineMeetings (onlineMeetings) {
    return Api().post('/meetingapi/meetings/delete-multiple', onlineMeetings)
  },
  getOnlineMeetingsClient(userId = null) {
    return Api().get('/meetingapi/clients'+(userId?'?userId='+userId:''), { withCredentials: true })
  },
  createOnlineMeetingClient (onlineMeeting, userId = null) {
    return Api().post('/meetingapi/clients' + (userId?'?userId='+userId:''), onlineMeeting)
  },
  updateOnlineMeetingClient (onlineMeeting, userId) {
    return Api().put('/meetingapi/clients/' + userId, onlineMeeting)
  },
}
