import Api from './Api'

export default {
  createCourse (course) {
    return Api().post('/api/courses', course)
  },
  getCourses () {
    return Api().get('/api/courses', { withCredentials: true }).catch(() => {})
  },
  editCourse (courseData) {
    return Api().put('/api/courses/' + courseData.id, courseData)
  },
  deleteCourses (courses) {
    return Api().post('/api/courses/delete-multiple', courses)
  }
}
