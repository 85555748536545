import chat from '../../services/Chat'
import socket from '../../services/socketio.service.js'
// initial state
const state = {
  lastActiveItems: [],
  items: [],
  chosenChatUser: null
};

// getters
const getters = {
  getMessages: state => state.items,
  getRecipients: state => loggedInUserId => [...state.lastActiveItems.reduce((r, { datetime, status, text, uid_from, uid_to, uuid_to }) => {
    let recepientUid = (loggedInUserId == uid_to) ? uid_from : uid_to;

    r.has(recepientUid) || r.set(recepientUid, {
      uid: recepientUid,
      status: status,
      lastMessageDate: datetime,
      lastActiveItems: []
    });
    r.get(recepientUid).lastActiveItems.unshift({ datetime, status, text, uid_from, uid_to, uuid_to });

    return r;
  }, new Map).values()],
  historyWithUser: state => state.items.filter(x => (x.uid_from == state.chosenChatUser || x.uid_to == state.chosenChatUser)),
  historyByIssue: (state, getters, rootState) => state.items.filter(x => (x.issue_id == rootState.issues.issueSelected.id)),
  dialogNew: state => state.dialogNew,
  getChosenChatUser: state => state.chosenChatUser,
  isNewMessage: state => state.items.filter(x => (x.status == 0)).length,
  isNewMessageWithUser: state => state.items.filter(x => (x.uid_from == state.chosenChatUser || x.uid_to == state.chosenChatUser)).length,
};

// actions
const actions = {
  getMessagesByUser ({ commit }) {
    return new Promise((resolve, reject) => {
      chat.getMessagesByUser(state.chosenChatUser).then(response => {
        commit('setMessages', response.data);
        resolve(200);
      });
    }).catch((error) => { console.log(error) });
  },
  getLastMessages ({ commit }) {
    return new Promise((resolve, reject) => {
      chat.getLastActiveMessages().then(response => {
        if(response && response.status == 200) {
          commit('setLastActiveMessages', response.data)
          resolve(200);
        } else {
          reject()
        }
      })
    }).catch((error) => { console.log(error) });
  },

  createChatMessage ({ commit }, newMessage) {
    return new Promise((resolve, reject) => {
      chat.createChatMessage(newMessage).then(response => {
        commit('addMessage', { name_to: newMessage.name_to, ...response.data })
        socket.socket.emit('my message', 'New chat message');
        resolve(200);
      }).catch((errors) => {
        reject(errors)
      })
    })
  },

  createMultipleChatMessage ({ commit }, newMessage) {
    return new Promise((resolve, reject) => {
      chat.createMultipleChatMessage(newMessage).then(response => {
        socket.socket.emit('my message', 'New chat message');
        resolve(200);
      }).catch((errors) => {
        reject(errors)
      })
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setChosenChatUser({dispatch, commit}, value) {
    return new Promise((resolve, reject) => {
      chat.markUserAsRead(value).then(() => {
        commit('setChosenChatUser', value);
        resolve(200)
      }).catch(() => {
      });
    }).catch((error) => { console.log(error) });
  },
  getFileByUrl ({ commit }, url) {
    chat.getFileByUrl(url).then(response => {
      var blob = new Blob([response.data]);

      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = url;
      link.click();
      link.remove();
    })
  },
  getIssueTrackerFileByUrl ({ commit }, url) {
    chat.getIssueTrackerFileByUrl(url).then(response => {
      var blob = new Blob([response.data]);

      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = url;
      link.click();
      link.remove();
    })
  },
  markUserUnread({commit}, userId) {
    return new Promise((resolve, reject) => {
      chat.markUserUnread(userId).then(() => {
        commit('markUserUnread', userId);
        resolve(200)
      }).catch(() => {
      });
    }).catch((error) => { console.log(error) });
  },

  getMessagesByIssue ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      chat.getMessagesByIssue(rootState.issues.issueSelected.id).then(response => {
        commit('setMessages', response.data);
        resolve(200);
      });
    }).catch((error) => { console.log(error) });
  },

  createIssueChatMessage ({ commit }, newMessage) {
    return new Promise((resolve, reject) => {
      chat.createIssueChatMessage(newMessage).then(response => {
        commit('addMessage', response.data )
        socket.socket.emit('my message', 'New issue chat message');
        resolve(200);
      }).catch((errors) => {
        reject(errors)
      })
    })
  },
};

// mutations
const mutations = {
  setMessages (state, chat) {
    state.items = chat
  },
  setLastActiveMessages (state, chat) {
    state.lastActiveItems = chat
  },
  addMessage (state, message) {
    state.items.push(message)
  },
  deleteChat (state, chat) {
    chat.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setChosenChatUser (state, user) {
    state.chosenChatUser = user
    state.items.map(x => { return (x.status == 0 && (x.uid_from == user || x.uid_to == user)) ? x.status = 1 : x } )
    state.lastActiveItems.map(x => { return (x.status == 0 && (x.uid_from == user || x.uid_to == user)) ? x.status = 1 : x } )
  },
  markUserUnread (state, userId) {
    state.lastActiveItems.map(x => { return (x.status == 1 && (x.uid_from == userId)) ? x.status = 0 : x } )
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
