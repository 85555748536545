<template>
<div>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.20.6 (2024.11.06)</v-card-title>
    <v-card-text>
      - Przedłużenie możliwości dodawania słuchaczy przez koordynatorów do 11.11
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.20.5 (2024.10.10)</v-card-title>
    <v-card-text>
      - Włącznie "Zgłoś problem" dla użytkowników: Admin, Wykładowca, Koordynator, Księgowy
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.20.4 (2024.10.07)</v-card-title>
    <v-card-text>
      - usunięcie województwa z danych studenta
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.20.3 (2024.10.04)</v-card-title>
    <v-card-text>
      - zablokowanie "Zgłoś problem" dla użytkowników innych niż admin
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.20.2 (2024.06.21)</v-card-title>
    <v-card-text>
      - Dziennik pokazuje studentów z trzech poprzednich semestrów bez wyboru naboru
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.20.1 (2024.06.16)</v-card-title>
    <v-card-text>
      - wyświetlanie filtrów dla "Dziennik (nowy)" tak aby mieściły się na mniejszych monitorach
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.20.0 (2024.06.11)</v-card-title>
    <v-card-text>
      - dodanie możliwości wystawiania ocen dla łączonych semestrów (dla wykładowców)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.19.7 (2024.06.04)</v-card-title>
    <v-card-text>
      - dodanie możliwości wystawiania ocen dla łączonych semestrów (prototyp dla Dziekanatu)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.19.6 (2024.06.01)</v-card-title>
    <v-card-text>
      - zablokowanie wysyłania wiadomości do Sekretariatu przez Studenta (ale możliwość odbioru wiadomości od Sekretariatu)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.19.5 (2024.05.26)</v-card-title>
    <v-card-text>
      - zmiana brakujacych: "Akademia Nauk Stosowanych" w miejsce byłej "Wyższa Szkoły"
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.19.4 (2024.05.20)</v-card-title>
    <v-card-text>
      - odblokowanie naboru 2024-10
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.19.3 (2024.05.14)</v-card-title>
    <v-card-text>
      - możliwość edycji prowadzących w przedmiotach (fix)
      - ograniczenie możliwości wysyłania korespondencji przez chat od studentów dla sekretariatu (fix)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.19.2 (2024.05.12)</v-card-title>
    <v-card-text>
      - pojawianie się jednocześnie zakładek "Nabór" oraz "Semestr 3" w "Dzienniku" studenta (fix)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.19.1 (2024.05.11)</v-card-title>
    <v-card-text>
      - odblokowanie jednemu wykładowcy możliwości logowania czasu pracy i przejazdów za poprzedni miesiąc
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.19.0 (2024.04.25)</v-card-title>
    <v-card-text>
      - scalenie kierunków po 2024-03-01<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.11 (2024.03.25)</v-card-title>
    <v-card-text>
      - odblokowanie możliwości dodawania studentów dla koordynatorów <br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.10 (2024.03.22)</v-card-title>
    <v-card-text>
      - czasowe zablokowanie możliwości dodawania studentów dla koordynatorów <br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.9 (2024.03.14)</v-card-title>
    <v-card-text>
      - na prośbę Sekretariatu, ograniczenie widoczności Dziennika dla studentów z naboru 2024-03<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.8 (2024.02.22)</v-card-title>
    <v-card-text>
      - dodanie do pobranej listy studentów informacji o zobowiązaniach finansowych<br />
      - aktualizacja biblioteki axios do najnowszej wersji
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.7 (2024.02.02)</v-card-title>
    <v-card-text>
      - rola superAdmin ma ograniczenia w widoczności wykadowców (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.6 (2024.01.23)</v-card-title>
    <v-card-text>
      - dodanie dodatkowej kwoty (4900zł) do listy zapisowej w związku z dodaniem nowego kierunku<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.5 (2024.01.18)</v-card-title>
    <v-card-text>
      - koordynaror na skutek błędu, nie może oznaczać zaliczeń studentowi (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.4 (2024.01.18)</v-card-title>
    <v-card-text>
      - Pomoc techniczna: wysyłanie powiadomień o odpowiedzi (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.3 (2024.01.15)</v-card-title>
    <v-card-text>
      - odblokowanie naboru 03.2024 dla koordynatorów
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.2 (2024.01.11)</v-card-title>
    <v-card-text>
      - nie działa link do statystyk z poziomu menu dla roli SuperAdmin (fix)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.1 (2024.01.11)</v-card-title>
    <v-card-text>
      - dodanie w statystykach daty ostatniego wgranego raportu<br />
      - dodanie brakujących uprawnień dla roli SuperAdmin<br />
      - nie wyświetla się nagłówek i stopka dla roli admin przy odpowiedzi na zgłoszony problem (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.18.0 (2024.01.09)</v-card-title>
    <v-card-text>
      - issue tracker - narzędzie do zgłaszania błędów<br />
      - odblokowanie jednemu wykładowcy możliwości logowania czasu pracy i przejazdów za poprzedni miesiąc
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.17.0 (2024.01.08)</v-card-title>
    <v-card-text>
      - statystyki dla roli SuperAdmin<br />
      - raporty z wpłatami nie rozpoznają użytkowników (fix)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.16.3 (2023.12.12)</v-card-title>
    <v-card-text>
      - dodanie możliwości dodania dokumentu dla Mentora (zakładka Mentoring)<br />
      - czas za zeszły miesiąc i przejazdy za zeszły miesiąc można raportować tylko do 5 dnia kolejnego miesiąca
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.16.2 (2023.12.01)</v-card-title>
    <v-card-text>
      - możliwość odrzucenia ewidencji czasu pracy przez Sekretariat jeśli nie było żadnych czasów zaraportowanych
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.16.1 (2023.11.30)</v-card-title>
    <v-card-text>
      - przywrócenie możliwości wysłania do studenta przez Sekretariat wiadomości o pierwszym logowaniu
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.16.0 (2023.11.29)</v-card-title>
    <v-card-text>
      - nowa zakładka Mentoring (zarządzanie Mentorami i tagami) dla wykładowcy MBA i studentów MBA<br />
      - możliwość wysłania zbiorowej wiadomości do wykładowców
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.19 (2023.11.20)</v-card-title>
    <v-card-text>
      - odblokowanie jednemu wykładowcy możliwości logowania czasu pracy i przejazdów za poprzedni miesiąc
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.18 (2023.11.15)</v-card-title>
    <v-card-text>
      - dodanie brakujących tytułów naukowych do danych personalnych wykładowców
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.17 (2023.11.15)</v-card-title>
    <v-card-text>
      - odblokowanie jednemu wykładowcy możliwości logowania czasu pracy i przejazdów za poprzedni miesiąc
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.16 (2023.11.09)</v-card-title>
    <v-card-text>
      - obsłużenie błędu, kiedy studentowi brakuje wygenerowanego dziennika (fix)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.15 (2023.11.07)</v-card-title>
    <v-card-text>
      - przedłuzenie składania raportów za czas pracy i przejazdy do 11 dnia miesiąca
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.14 (2023.11.04)</v-card-title>
    <v-card-text>
      - Przejazdy za zeszły miesiąc można raportować tylko do 5 dnia kolejnego miesiąca<br />
      - Przy raportowaniu przejazdu, wybierając datę, można kliknąć też w ikonkę kalendarza (nie tylko w datę) i również otworzy się kalendarz
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.13 (2023.11.03)</v-card-title>
    <v-card-text>
      - Czas za zeszły miesiąc można raportować tylko do 5 dnia kolejnego miesiąca<br />
      - Przy raportowaniu czasu, wybierając datę, można kliknąć też w ikonkę kalendarza (nie tylko w datę) i również otworzy się kalendarz
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.12 (2023.11.02)</v-card-title>
    <v-card-text>
      - Rola "Zoom administrator" ma dostęp do logowania "Czasu pracy" i może wysłać dane do akceptacji (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.11 (2023.11.02)</v-card-title>
    <v-card-text>
      - Rola "Zoom administrator" ma dostęp do logowania "Czasu pracy"<br />
      - Nowo dodane grupy plików pojawiają się dla studentów na górze listy
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.10 (2023.10.13)</v-card-title>
    <v-card-text>
      - Możliwość pobrania listy studentów w postaci .pdf (dodanie nagłówka)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.9 (2023.10.12)</v-card-title>
    <v-card-text>
      - Możliwość pobrania listy studentów w postaci .pdf<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.8 (2023.10.12)</v-card-title>
    <v-card-text>
      - Dodanie stopni naukowych dr hab., dr hab.inż, prof. dr hab.inż.<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.7 (2023.10.05)</v-card-title>
    <v-card-text>
      - Użytkownik z rolą "Zoom administrator" pojawia się na liście pracowników administracyjnych<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.6 (2023.10.05)</v-card-title>
    <v-card-text>
      - ZOOM API: Nowy "Kreator spotkań"<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.5 (2023.09.28)</v-card-title>
    <v-card-text>
      - ZOOM API: dodanie nowej roli dla użytkownika "Administrator ZOOM"<br />
      - ZOOM API: uniemożliwienie wykładocom dodawania i usuwania spotkań<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.4 (2023.09.26)</v-card-title>
    <v-card-text>
      - ZOOM API: dodanie możliwości usuwania spotkań<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.3 (2023.09.23)</v-card-title>
    <v-card-text>
      - autoryzacja użytkowników ZOOM API przez adres email zamiast id/secret<br />
      - umożliwienie logowania przez alternatywny email @podyplomowestudia<br />
      - wyświetlanie ocen studentowi ktory jest jednocześnie profesorem (fix)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.2 (2023.09.08)</v-card-title>
    <v-card-text>
      - możliwość edycji kierunku studiów przy słuchaczu (w ramach tego samego naboru)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.1 (2023.09.07)</v-card-title>
    <v-card-text>
      - możliwość wyboru kilku semestrów w zakładce Słuchacze<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.15.0 (2023.09.06)</v-card-title>
    <v-card-text>
      - integracja z ZOOM<br />
      - dostęp do plików per semestr<br />
      - wyświetlanie na listach wyboru wykładowcom tylko kierunków które prowadzą, studentom tylko kierunków które studiują
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.18 (2023.08.04)</v-card-title>
    <v-card-text>
      - przedłużenie generowania protokołów z sesji 2 do 10.08<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.17 (2023.08.02)</v-card-title>
    <v-card-text>
      - dodanie informacji o studiowanych kierunkach w oknie edycji słuchacza (ołówek)<br />
      - wpisywanie dowolnej wysokości czesnego przez dziekanat<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.16 (2023.08.02)</v-card-title>
    <v-card-text>
      - rozróżnienie "wygaśnięcia sesji" od "przerwy technicznej" i wyświetlenie odpowiedniej strony błędu 401 lub 500<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.15 (2023.08.01)</v-card-title>
    <v-card-text>
      - studenci widzą informację o zaliczniu, praktykach i wybranym promotorze<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.14 (2023.08.01)</v-card-title>
    <v-card-text>
      - możliwość oznaczenia wiadomości jako "nieprzeczytana" w czacie<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.13 (2023.07.31)</v-card-title>
    <v-card-text>
      - nie wyświetlają się wiadomości dla dziekanatu w Komunikatorze (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.12 (2023.07.27)</v-card-title>
    <v-card-text>
      - w rozliczeniach można wyszukiwać po imieniu i nazwisku oraz wyeksportować listę studentów<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.11 (2023.07.26)</v-card-title>
    <v-card-text>
      - dodanie stopnia naukowego w kwestionariuszu osobowym również dla wykładowców którzy prowadzą działalność gospodarczą<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.10 (2023.07.26)</v-card-title>
    <v-card-text>
      - dodanie stopnia naukowego w kwestionariuszu osobowym i użycie go przy protokołach i karcie okresowych osiągnięć<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.9 (2023.07.21)</v-card-title>
    <v-card-text>
      - zamiana formatu daty urodzenia oraz rozdzielenie imienia i nazwiska na dwie kolumny przy pobraniu listy studentów<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.8 (2023.07.19)</v-card-title>
    <v-card-text>
      - księgowy ma dostęp do danych personalnych i informacji o naborach studenta<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.7 (2023.07.18)</v-card-title>
    <v-card-text>
      - numery kont (dla naboru i dla studenta) widoczne na liście studentów<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.6 (2023.07.06)</v-card-title>
    <v-card-text>
      - przedłużenie generowania protokołów z sesji 1 do 11.07<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.5 (2023.06.26)</v-card-title>
    <v-card-text>
      - zapisanie formularza osobowego wykładowcy/pracownika administracji, tylko po walidacji wszystkich pól<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.4 (2023.06.23)</v-card-title>
    <v-card-text>
      - nowy wzór karty osiągnięć studenta<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.3 (2023.06.21)</v-card-title>
    <v-card-text>
      - Studenci/Rozliczenia - dodanie informacji email i telefon na liście z opcją skopiowania do schowka<br />
      - Wyszukiwana fraza filtrujaca listę jest zapamiętywana po powrocie ze szczegółu (wykładowcy/słuchacza/oceny)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.2 (2023.06.21)</v-card-title>
    <v-card-text>
      - Rozliczenia - dodanie numeru rachunku na liście<br />
      - Rozliczenia - lepsze zaznaczenie pól do edycji<br />
      - Rozliczenia - dodanie informacji o możliwych formatach plików do wczytania<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.1 (2023.06.21)</v-card-title>
    <v-card-text>
      - Wpłaty można też filtrować po imieniu i nazwisku - wcześniej tylko po email (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.14.0 (2023.06.21)</v-card-title>
    <v-card-text>
      - księgowy może wgrać na raz paczkę w postaci pliku zip<br />
      - rozdzielenie danych osobowych słuchacza od jego naborów (grupowanie na liście studentów)<br />
      - nowy sposób prezentacji ocen - podział na kierunki, poprawki dla wszystkich ról: dziekanat/profesor/student<br />
      - konto bankowe przypisane do zapisu (każdy kierunek/nabór ma swój) a nie do studenta<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.13.0 (2023.04.04)</v-card-title>
    <v-card-text>
      - zapamiętywanie wyszukiwanych fraz filtrujących wyniki
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.12.1 (2023.04.03)</v-card-title>
    <v-card-text>
      - zamknięcie zapisów na semestr 03.2023
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.12.0 (2023.03.30)</v-card-title>
    <v-card-text>
      - możliwość zmiany ceny kursu przez księgowego<br />
      - uploadowanie CSV z wpłatami przez księgowego<br />
      - dla wykładowcy poprawa raportu z "przejazdów za miesiąc" aby wszystkie dane mieściły się na dokumencie; autouzupełnianie danych pojazdu jeśli wszystkie przejazdy tym samym autem
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.11.1 (2023.03.20)</v-card-title>
    <v-card-text>
      - grupy plików również dla roli "Wykładowca i student"
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.11.0 (2023.03.15)</v-card-title>
    <v-card-text>
      - grupy załączonych plików można przypisać do wybranych roli użytkowników<br />
      - aktywacja roli księgowego
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.9 (2023.03.10)</v-card-title>
    <v-card-text>
      - dodanie meta tagów zabraniających przeglądarce cachowanie strony
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.8 (2023.03.10)</v-card-title>
    <v-card-text>
      - przedłużenie czasu na wygenerowanie głównego raportu sesji zimowej do 11 marca włącznie.
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.7 (2023.03.08)</v-card-title>
    <v-card-text>
      - przedłużenie czasu na wygenerowanie głównego raportu sesji zimowej do 10 marca.
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.6 (2023.03.01)</v-card-title>
    <v-card-text>
      - logowanie po wciśnięciu klawisza enter
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.5 (2023.02.26)</v-card-title>
    <v-card-text>
      - protokoły z sesji pierwszej i poprawkowej generowane tylko w konkretnych odstępach czasowych
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.4 (2023.02.15)</v-card-title>
    <v-card-text>
      - usunięcie "z protokołu" z karty osiągnięć<br />
      - wykładowca który jest jednocześnie studentem widzi w komunikatorze wiadomości (fix)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.3 (2023.02.03)</v-card-title>
    <v-card-text>
      - dodanie widoku "Problem techniczny"
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.2 (2023.01.27)</v-card-title>
    <v-card-text>
      - prośba o aktualizację danych, tylko raz w roku po 1 września
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.1 (2023.01.23)</v-card-title>
    <v-card-text>
      - poprawki layoutowe komunikadtora
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.10.0 (2023.01.22)</v-card-title>
    <v-card-text>
      - komunikator<br />
      - notyfikacje<br />
      - odblokowanie semestru 2023-03<br />
      - poprawiony widok mobilny dla ocen
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.9.1 (2022.12.13)</v-card-title>
    <v-card-text>
      - niewylogowywanie użytkownika przez 1h<br />
      - dostosowanie Karty osiągnięć do najnowszych wymagań<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.9.0 (2022.12.07)</v-card-title>
    <v-card-text>
      - karta osiągnięć słuchacza<br />
      - upload plików z dodatkowymi rozszerzeniami<br />
      - wspólna sesja dla aplikacji głownej i chatowej<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.8.2 (2022.11.09)</v-card-title>
    <v-card-text>
      - domyślnie pierwsza wiadomość na liście wyboru to "pierwsze logowanie" - usunięcie drugiej wiadomości o numerze rachunku<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.8.1 (2022.11.07)</v-card-title>
    <v-card-text>
      - mnożenie ilości kilometrów jeśli zaznaczono "Jeśli wracasz w to samo miejsce" w widoku administratora "Przejazdy"<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.8.0 (2022.11.05)</v-card-title>
    <v-card-text>
      - Dodawanie numerów albumów z pliku .csv<br />
      - możliwość kopiowania numeru konta bankowego dla studenta<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.7.1 (2022.11.02)</v-card-title>
    <v-card-text>
      - W dniach 31.10 godzina 23:59 - 31.12 23:59, zostaje zablokowana możliwość dodawania nowych słuchaczy dla koordynatorów<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.7.0 (2022.10.29)</v-card-title>
    <v-card-text>
      - dodanie protokołów (zapisywanie, odczytywanie)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.6.0 (2022.10.20)</v-card-title>
    <v-card-text>
      - dodanie dziennika w zakładce detal słuchacza<br />
      - poprawa lini pod logiem<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.30 (2022.10.06)</v-card-title>
    <v-card-text>
      - mnożenie ilości kilometrów w raporcie i na liście, kiedy zaznaczono "Jeśli wracasz w to samo miejsce, zaznacz pole. Pomnożymy ilośc kilometrow x2"<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.29 (2022.09.29)</v-card-title>
    <v-card-text>
      - zmiana tytułu strony<br />
      - przywrócenie studentów z K_____na<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.28 (2022.09.27)</v-card-title>
    <v-card-text>
      - zmiana nazw zakładek w panelu słuchacza<br />
      - dodanie nowych kwot przy wyborze czesnego<br />
      - umożliwienie edycji nazwy grupy plików
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.27 (2022.09.10)</v-card-title>
    <v-card-text>
      - historia zmian formularza rejestracyjnego - opis pól 'value' (fix)<br />
      - wykładowca po 1 września każdego roku, musi potwierdzić formularz osobowy
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.26 (2022.08.05)</v-card-title>
    <v-card-text>
      - dodanie nowej kwoty do listy przy dodawaniu studenta<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.25 (2022.07.29)</v-card-title>
    <v-card-text>
      - zaawansowana możliwość edycji placówek<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.24 (2022.07.20)</v-card-title>
    <v-card-text>
      - uwględnienie numeru rachunku przy generowaniu raportu<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.23 (2022.07.09)</v-card-title>
    <v-card-text>
      - numer semestru wyświetlany przy wyborze przedmiotu dla kierunku (przy wystawianiu ocen)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.22 (2022.07.07)</v-card-title>
    <v-card-text>
      - dodane nowe ceny kursów<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.21 (2022.07.05)</v-card-title>
    <v-card-text>
      - czasami w dzienniku, pojawiają się podwójne przedmioty po wpisaniu oceny (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.20 (2022.06.30)</v-card-title>
    <v-card-text>
      - student który jest wykładowcą ma dostęp do swoich czasów pracy (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.19 (2022.06.17)</v-card-title>
    <v-card-text>
      - poprawki do błędów zgłoszonych w dniu 10.06.2022 dotyczące konkretnych studentów/wykładowców<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.18 (2022.06.10)</v-card-title>
    <v-card-text>
      - dostęp koordynatorów do wykładowców (tylko imię i nazwisko)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.17 (2022.06.10)</v-card-title>
    <v-card-text>
      - zaliczenie/praktyki/promotor dla obu kierunków<br />
      - zawężanie listy promotorów po wpisaniu pierwszych liter<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.16 (2022.05.31)</v-card-title>
    <v-card-text>
      - dodanie drugiego imienia i miejsca urodzenia do raportu<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.15 (2022.05.30)</v-card-title>
    <v-card-text>
      - raport ocen generowany przez dizekanat, zawiera imię i nazwisko egzaminatora<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.14 (2022.05.21)</v-card-title>
    <v-card-text>
      - obsługa historycznych wykładowców<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.13 (2022.05.10)</v-card-title>
    <v-card-text>
      - dodanie naboru 10.2022<br />
      - dziekanat może dodawać oceny<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.12 (2022.05.04)</v-card-title>
    <v-card-text>
      - zapisywanie stronicowania<br />
      - zaliczenie/praktyki/promotor<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.11 (2022.03.21)</v-card-title>
    <v-card-text>
      - dodawanie ocen (poprawka)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.10 (2022.03.17)</v-card-title>
    <v-card-text>
      - możliwość dodania oceny bezpośrednio z poziomu listy<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.9 (2022.03.12)</v-card-title>
    <v-card-text>
      - dodanie brakujących dzienników<br />
      - nieaktywny przycisk "Zapisz" podczas zapisywania oceny<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.8 (2022.03.07)</v-card-title>
    <v-card-text>
      - dodanie brakujących dzienników
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.7 (2022.03.06)</v-card-title>
    <v-card-text>
      - Ustawienie prostszego (wyrazowego) hasła przy resetowaniu hasła
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.6 (2022.03.05)</v-card-title>
    <v-card-text>
      - Poprawa raportów z ocenami (likwidacja powtarzających się wpisów)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.5 (2022.02.24)</v-card-title>
    <v-card-text>
      - Zmienić treść maila na „Użyj swojego adresu email”<br />
      - Przyspieszyć wysyłanie maili (co 1 sek zamiast co 2sek)<br />
      - Na liście dziennika podwójne dane - fix<br />
      - Zmienić tytuł maila z numerem konta<br />
      - W stopkach maili zmienić "Wyższa Szkoła…" na "Akademia…"<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.4 (2022.02.08)</v-card-title>
    <v-card-text>
      - Dodanie daty urodzenia do generowanej listy studentów<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.3 (2022.02.01)</v-card-title>
    <v-card-text>
      - Student/Wykładowca może generować raport widoczny dla dziekanatu(fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.2 (2022.01.18)</v-card-title>
    <v-card-text>
      - blokada możliwości wybrania dziennika dla dziekanatu bez ustawienia żadnego filtra (ze względów wydajnościowych)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.1 (2022.01.17)</v-card-title>
    <v-card-text>
      - poprawka do szukania/sortowania dzienników<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.5.0 (2022.01.16)</v-card-title>
    <v-card-text>
      - Rola "Księgowy", dodawanie raportów z wpłat, wyliczanie należności<br />
      - funkcjonalność "Dziennik" - generowanie dzienników, wystawianie ocen przez wykładowców, podgląd ocen przez studenta<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.4.0 (2021.12.27)</v-card-title>
    <v-card-text>
      - Wykładowca może być różnież studentem<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.3.1 (2021.12.13)</v-card-title>
    <v-card-text>
      - export kierunków w csv (wraz z poprawką)<br />
      - dodanie naboru marzec 2022<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.3.0 (2021.12.02)</v-card-title>
    <v-card-text>
      - przełączanie wykładowcow między Pracownikiem a Firmą<br />
      - generowanie raportu z Kierunku studiów<br />
      - możliwośc usunięcia danego przedmiotu w Kierunku<br />
      - możliwość ustawienia wykładowcy jako nieaktywnego (z którym zakończono  współpracę)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.2.4 (2021.11.10)</v-card-title>
    <v-card-text>
      - dłuższy interwał przy wysyłaniu maili<br />
      - student nie może się zalogować (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.2.3 (2021.11.03)</v-card-title>
    <v-card-text>
      - dane do ubezpieczenia - możliwość zaznaczenia więcej niż jednego<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.2.2 (2021.10.25)</v-card-title>
    <v-card-text>
      - historia zmian w formularzu kontaktowym studenta<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.2.1 (2021.10.07)</v-card-title>
    <v-card-text>
      - nie widać nr budynku Urzędu Skarbowego w I części formularza (fix)<br />
      - w pkt V, przestawia sie imie z nazwiskiem przy generowaniu PDF (fix)<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.2.0 (2021.10.02)</v-card-title>
    <v-card-text>
      - wykładowca może być firmą<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.1.1 (2021.09.29)</v-card-title>
    <v-card-text>
      - formatka maila z numerem konta dostosowana do naboru Październik 2021<br />
      - wyświetlanie numeru mieszkania dla Studenta<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.1.0 (2021.09.22)</v-card-title>
    <v-card-text>
      - dodawanie/edycja/usuwanie plików i grup plików przez dziekanat<br />
      - przeglądanie/pobieranie plików przez studentów<br />
      - wyświetlanie i wysyłanie numeru rachunku (fix)<br />
      - historia zmian formularza kontaktowego<br />
      - RODO dla studentów<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>2.0.0 (2021.08.20)</v-card-title>
    <v-card-text>
      - konta dla słuchaczy<br />
      - wysyłka hasła tymczasowego dla słuchacza<br />
      - logowanie słuchacza<br />
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>1.8.1 (2021.08.18)</v-card-title>
    <v-card-text>
      - koordynatorzy nie mogą wpisać na nowy nabór osób, które już studiują (fix)<br />
      - dopisanie Pana Andrzeja Ł. jako osoby wpisującej dane studentów w Siedlcach/Białej Podlaskiej<br />
      - usunięcie kierunków studiów: Zarządzanie innowacjami społecznymi.<br />
      - brak możliwości edytowania danych słuchacza na poziomie sekretariatu (fix)<br />
      - możliwość wyszukiwania po drugim kierunku (wyświetlanie drugiego kierunku na liście studentów)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>1.8.0 (2021.08.15)</v-card-title>
    <v-card-text>
      - dodawanie/edycja przedmiotów dla kierunków<br />
      - akceptacja RODO dla wykładowców<br />
      - podgląd RODO dla dziekanatu
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>1.7.1 (2021.08.09)</v-card-title>
    <v-card-text>
      - dodawanie/edycja/usuwanie kierunków
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>1.7.0 (2021.08.04)</v-card-title>
    <v-card-text>
      - automatyczne dodawanie i edycja numerów kont<br />
      - dostosowanie szablonu email do semestru 10.2021<br />
      - kierunki studiów w zapisywaniu nowych studentów - lista kierunków nie jest aktualna (fix)<br />
      - pole "imię ojca" - do usunięcia<br />
      - termin rozpoczęcia studiów w zapisywaniu słuchaczy - w liście rozwijanej tylko Październik 2021.<br />
      - po dodaniu każdego słuchacza wylogowywuje z systemu na stronę startową (fix)<br />
      - liczba przepracowanych godzin, ilość km - usunięte dla koordynatora<br />
      - usunąć zakładkę "samochody w ewidencji" oraz "rachunki" dla koordyatora
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>1.6.0 (2021.07.16)</v-card-title>
    <v-card-text>
      - możliwość dowolnego łączenia miejscowości i koordynatorów
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>1.5.2 (2021.07.14)</v-card-title>
    <v-card-text>
      - dane do ubezpieczenia, nie generuje zakładu pracy (fix)<br />
      - dodanie strony changelog
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>1.5.1 (2021.06.28)</v-card-title>
    <v-card-text>
      z pozycji wykładowcy nie można wygenerować raportu miesięcznego (fix)
    </v-card-text>
  </v-card>
  <v-card class="pa-4 ma-4 mx-lg-auto" width="600px">
    <v-card-title>1.5.0 (2021.06.27)</v-card-title>
    <v-card-text>
      - generowanie brakującego raportu dla „zakres umów”<br />
      - rola „Pracownik administracyjny” z wszystkimi funkcjonalnościami
    </v-card-text>
  </v-card>
</div>
</template>
<script>
export default {
  name: "Changelog"
}
</script>

<style scoped>

</style>
