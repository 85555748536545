<template>
    <v-container>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="city"
                        label="Miejscowość"
                        dense
                        :error-messages="cityErrors"
                        required
                        :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <v-text-field
                v-model="zip"
                label="Kod pocztowy"
                dense
                :error-messages="zipErrors"
                required
                :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="street"
                        label="Ulica"
                        dense
                        :error-messages="streetErrors"
                        required
                        :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="houseNr"
                        label="Numer domu"
                        dense
                        :error-messages="houseNrErrors"
                        required
                        :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-text-field
                        v-model="homeNr"
                        label="Numer lokalu"
                        dense
                        :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';
import {mapGetters} from "vuex";

const { mapFields } = createHelpers({
    getterType: 'registrationStudent/getAddress',
    mutationType: 'registrationStudent/updateAddress',
  });

  export default {
    name: "Address",
    data: () => ({
    }),
    props: [
      'cityErrors',
      'streetErrors',
      'houseNrErrors',
      'zipErrors',
    ],
    computed: {
      ...mapGetters({
        student: 'studentCourses/getChosenStudentCourseData',
      }),
      ...mapFields([
        'address.city',
        'address.zip',
        'address.street',
        'address.houseNr',
        'address.homeNr',
      ]),
    }
  }
</script>

<style scoped>

</style>
