import Api from './Api'

export default {
  createUser (userData) {
    return Api().post('/api/users', userData)
  },
  loginUser (credentials) {
    return Api().post('/api/users/login', credentials)
  },
  logoutUser () {
    return Api().get('/api/users/logout')
  },
  restoreEmail (telephone) {
    return Api().get('/api/users/restoreEmail?telephone=' + telephone)
  },
  resetPassword (email) {
    return Api().get('/api/users/resetPassword?email=' + email)
  },
  getUsersByRole (role) {
    return Api().get('/api/users/role/' + role)
  },
  getUsersSimplifiedForChat () {
    return Api().get('/api/users/for-chat')
  },
  editUser (userData) {
    return Api().put('/api/users/' + userData.id, userData)
  },
  deleteUser (userId) {
    return Api().delete('/api/users/' + userId)
  },
  deleteUsers (users) {
    return Api().post('/api/users/delete-multiple', users)
  },
  saveRegistrationForm (userData) {
    return Api().post('/api/users/' + userData.uid + '/registrationForm/create', userData)
  },
  setAvatar (userId, formData) {
    return Api().post('/api/users/' + userId + '/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getAvatar (userId) {
    return Api().get('/api/users/' + userId + '/avatar')
  },
  passwordChange (userId, credentials) {
    return Api().put('/api/users/' + userId + '/password', credentials)
  },
  changeAdminGeneralSetting (userId, credentials) {
    return Api().put('/api/users/' + userId + '/generalSettings', credentials)
  },
  setRatePerKm (userId, rate) {
    return Api().put('/api/users/' + userId + '/ratePerKm', rate)
  },
  setRodo () {
    return Api().put('/api/users/rodo')
  },
  switchCompany () {
    return Api().put('/api/users/switchCompany')
  },
  getStatistics () {
    return Api().get('/api/users/statistics')
  },
  getActiveSessions () {
    return Api().get('/api/activeSessions')
  },
}
