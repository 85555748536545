<template>
  <v-data-table
      :headers="headers"
      :items="history"
      sort-by="date"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista zmian wykładowców</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone zmiany</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      Brak zmian
    </template>
    <template v-slot:item.sid="{ item }">
      {{ subjectName(item.sid) }}
    </template>
    <template v-slot:item.uid="{ item }">
      {{ userName(item.uid) }}
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "HistorySubject",
  computed: {
    ...mapGetters({
      history: 'historySubject/getFormDataHistory',
      subjectName: 'subjects/getSubjectNameById',
      userName: 'users/getNameById'
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
  },
  props: {
  },
  data: () => ({
    selected: [],
    headers: [
      { text: 'Przedmiot', value: 'sid' },
      { text: 'Wykładowca', value: 'uid' },
      { text: 'Był wykładowcą w semestrze', value: 'date' },
    ],
  }),
  methods: {
    ...mapActions({
      deleteHistorySubject: 'historySubject/delete',
    }),
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć ten wpis? W usuniętym semestrze wykładowcą zostanie aktualny wykładowca.')){
        this.deleteHistorySubject(this.selected).then(response => {
          this.$log.info('Deleted history', response);
          this.dialogRemoveSuccess = true
        }).catch(error => {
          this.$log.error('Deleting history error', error)
        })
      }
    },
  },
  created() {
    this.$store.dispatch('historySubject/getHistoryByCourseId');
  },
}
</script>

<style>
.alpaca-p > .alpaca-line,
.alpaca-p > .alpaca-k,
.alpaca-p > .alpaca-string,
.alpaca-p > .alpaca-f {
  display: none;
}

.alpaca-upd > .alpaca-k,
.alpaca-upd > .alpaca-string,
.alpaca-add > .alpaca-k,
.alpaca-add > .alpaca-string,
.alpaca-del > .alpaca-k,
.alpaca-del > .alpaca-string {
  display: block;
}
</style>
