<template>
    <v-data-table
            :headers="headers"
            :items="times"
            sort-by="start"
            show-select
            :single-select=singleSelect
            class="elevation-1 ma-4"
            v-model="selected"
            v-if="userRole == 0 || userRole == 1 || userRole == 5 || userRole == 7 || userRole == 8"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Lista wszystkich godzin pracy</v-toolbar-title>
                <SelectMonths/>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn v-show="showExtraActions" color="primary" :disabled="!selected.length || !addingPossible" rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone czasy pracy</v-btn>
                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" :disabled="!addingPossible" rounded small class="mb-2 ml-4" v-on="on">Dodawanie nowego czasu pracy</v-btn>
                        <v-btn v-else color="primary" :disabled="!addingPossible" rounded small class="mb-2 ml-4" v-on="on">Dodaj</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-menu
                                                ref="menuDate"
                                                v-model="menuDate"
                                                :close-on-content-click="false"
                                                :return-value.sync="editedItem.date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="new Date(editedItem.date).toISOString().substr(0, 10)"
                                                        label="Data pracy"
                                                        append-icon="event"
                                                        readonly
                                                        v-on="on"
                                                        @click:append="on.click"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editedItem.date" :first-day-of-week="1" :allowed-dates="(val) => allowedDates(val)" no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menuDate = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.menuDate.save(editedItem.date)">OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6" v-if="!online">
                                        <v-text-field v-model="editedItem.city" :error-messages="cityErrors" required @blur="$v.editedItem.city.$touch()" label="Miejscowość"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-checkbox
                                          v-model="online"
                                          label="on-line"
                                      ></v-checkbox>
                                    </v-col>
                                 </v-row>
                                 <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.hours" :error-messages="hoursErrors" required @blur="$v.editedItem.hours.$touch()" label="Ilość godzin"></v-text-field>
                                    </v-col>
                                 </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-textarea v-model="editedItem.comment" label="Uwagi"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-7">
                            <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do listy wszystkich godzin pracy</span></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="primary" rounded @click="save">Dodaj czas pracy</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogNewSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span class="headline">Poprawnie dodałeś nowy czas pracy</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        {{ editedItem.lp }} {{editedItem.date }} | {{ editedItem.city }} | {{ editedItem.hours }}
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich czasów pracy</v-btn>
                                    </v-col>
                                    <v-col sm="12">
                                        lub
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherTime">Chcę dodać kolejny czas pracy</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie przejazd</span>
                                        <span v-else class="headline">Usunąłeś poprawnie czas pracy</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        <div v-if="selected.length > 0">
                                            <div v-for="item in selected">
                                                {{ item.lp }} {{ item.date }} | {{ item.city }}  | {{ item.hours }} godzin
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.lp="{ item }">
            {{ item.id }}
        </template>
        <template v-slot:item.date="{ item }">
            {{ new Date(item.date).toISOString().substr(0, 10) }}
        </template>
        <template v-slot:item.status="{ item }">
            <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon
                    v-if="addingPossible"
                    small
                    class="mr-2"
                    @click="editItem(item)"
            >
                edit
            </v-icon>
        </template>
        <template v-slot:no-data>
            Brak czasów
        </template>
        <template v-slot:footer>
          <TimesReportStatus :username="username" :contractDetails="contractDetails" :current-month-readable="currentMonthReadable"/>
        </template>
    </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import moment from "moment";
import SelectMonths from "./SelectMonths";
import TimesReportStatus from "./TimesReportStatus";

export default {
    components: {TimesReportStatus, SelectMonths },
    mixins: [validationMixin],

    validations: {
      editedItem: {
        city: {required},
        hours: {required}
      }
    },
    name: "Times",
    computed: {
      ...mapGetters({
        times: 'times/getTimes',
        timesReport: 'times/getTimesReport',
        dialogFromStore: 'times/dialogNew',
        monthSelected: 'monthSelected',
        userRole: 'users/userRole',
        loggedInUser: 'users/getLoggedInUser',
        chosenUserId: 'users/getChosenUser',
        users: 'users/getUsers',
        contracts: 'contracts/getContracts',
      }),
      formTitle () {
        return this.editedIndex === -1 ? 'Dodawanie nowego czasu pracy' : 'Edytuj czas pracy'
      },
      cityErrors () {
        const errors = [];
        if (!this.$v.editedItem.city.$dirty) return errors;
        !this.$v.editedItem.city.required && errors.push('Podanie miejscowości jest wymagane');
        return errors
      },
      hoursErrors () {
        const errors = [];
        if (!this.$v.editedItem.hours.$dirty) return errors;
        !this.$v.editedItem.hours.required && errors.push('Podanie liczby godzin jest wymagane');
        return errors
      },
      showExtraActions () {
        return this.$vuetify.breakpoint.mdAndUp
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          return this.setDialogNew(value)
        }
      },
      addingPossible () {
        return (this.timesReport == 0 || this.userRole == 1) ? true : false;
      },
      username() {
        if (this.userRole == 1) {
          let user = this.users.find(x => x['id'] === this.chosenUserId)
          if (user) {
            return user['name']
          }
        } else {
          return this.loggedInUser.name
        }
      },
      contractDetails() {
        if (this.contracts) {
          return this.contracts.find(x => (x.start <= this.monthSelected && x.end >= this.monthSelected))
        } else {
          return null;
        }

      },
      currentMonthReadable () {
        return moment(this.monthSelected).format('MMMM YYYY')
      }
    },
    data: () => ({
      selected: [],
      menuDate: false,
      dialogNewSuccess: false,
      dialogRemoveSuccess: false,
      headers: [
        { text: 'Data', value: 'date' },
        { text: 'Miasto', value: 'city' },
        { text: 'Ilość godzin', value: 'hours' },
        { text: '', value: 'action', sortable: false },
      ],
      editedIndex: -1,
      online: false,
      editedItem: {
        lp: '',
        date: new Date().toISOString().substr(0, 10),
        city: '',
        hours: '',
        comment: '',
      },
      defaultItem: {
        lp: '',
        date: new Date().toISOString().substr(0, 10),
        city: '',
        hours: '',
        comment: '',
      },
      singleSelect: false
    }),
    watch: {
      dialogNewSuccess (val) {
        val || setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.date = this.monthSelected;
          this.editedIndex = -1
        }, 300)
      },
      online () {
        if (this.editedItem.city == 'on-line') {
          this.editedItem.city = '';
        }
      }
    },
    methods: {
      ...mapActions({
        setDialogNew: 'times/setDialogNew',
        createTime: 'times/createTime',
        editTime: 'times/editTime',
        deleteTimes: 'times/deleteTimes',
        createReport: 'times/createReport',
        acceptReport: 'times/acceptReport',
        declineReport: 'times/declineReport',
      }),
      allowedDates(val) {
        if(this.userRole == 1) {
          return true;
        } else {
          // possible last month
          if (moment().date() <= 5 || this.loggedInUser.id === 6656) {
            return (moment(val).month() === moment().month()) || (moment(val).month() === moment().subtract(1, 'month').month());
          } else { // only current month
            return moment(val).month() === moment().month();
          }
        }
        // return new Date(val).getMonth() === parseInt(moment(this.monthSelected).format("M"))-1 && new Date(val).getFullYear() === parseInt(moment(this.monthSelected).format("YYYY"))
      },
      editItem (item) {
        this.editedIndex = this.times.indexOf(item);
        this.editedItem = Object.assign({}, item);
        if (this.editedItem.city == 'on-line') {
          this.online = true;
        } else {
          this.online = false;
        }
        this.dialog = true
      },
      close () {
        this.dialog = false;
        this.online = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.date = this.monthSelected;
          this.editedIndex = -1
        })
      },
      closeDialogNewSuccessAndAddAnotherTime () {
        this.dialogNewSuccess = false;
        this.setDialogNew(true)
      },
      closeDialogRemoveSuccess () {
        this.dialogRemoveSuccess = false;
        this.selected = []
      },
      save () {
        this.$v.$touch();
        if (this.online == true) {
          this.editedItem.city = 'on-line';
        }
        if (this.editedIndex > -1) {
          if (!this.$v.$invalid) {
            this.editTime(this.editedItem).then(response => {
                this.$log.info('Saved edited time', response);
                Object.assign(this.times[this.editedIndex], this.editedItem);
                this.close()
            }).catch(error => {
              this.$log.error('Saving edited time error', error)
            })
          }
        } else {
          if (!this.$v.$invalid) {
            this.createTime(this.editedItem).then(response => {
                this.$log.info('Saved new time', response);
                this.close();
                this.dialogNewSuccess = true
            }).catch(error => {
              this.$log.error('Saving new time error', error)
            })
          }
        }
      },
      removeSelected () {
        if (this.selected.length == 0) {
          return false
        }
        if(confirm('Czy na pewno chcesz usunąć poniższy czas pracy?')){
          this.deleteTimes(this.selected).then(response => {
            this.$log.info('Deleted time', response);
            this.dialogRemoveSuccess = true
          }).catch(error => {
            this.$log.error('Deleting time error', error)
          })
        }
      }
    },
    created() {
      this.$store.dispatch('times/getTimes', { 'start': moment(this.monthSelected).startOf('month').format('YYYY-MM-DD'), 'end': moment(this.monthSelected).endOf('month').format('YYYY-MM-DD')});
      // this.$store.dispatch('setMonthSelected', moment().startOf('month').format('YYYY-MM-DD'));
      this.editedItem.date = this.monthSelected;

      this.$store.dispatch('contracts/getContracts');

      this.unwatch = this.$store.watch(
        (state, getters) => getters.monthSelected,
        (newValue) => {
          this.editedItem.date = this.monthSelected;
          this.$store.dispatch(
            'times/getTimes',
            {
              'start': moment(newValue).startOf('month').format('YYYY-MM-DD'),
              'end': moment(newValue).endOf('month').format('YYYY-MM-DD') }
          );
        },
      );
    },
    // There is one caveat once using Vuex watch: it returns an unwatch function that should be called in your beforeDestroy hook if you want to stop the watcher. If you don't call this function, the watcher will still be invoked which is not the desired behavior.
    beforeDestroy() {
      this.unwatch();
    }
  }
</script>

