<template>
  <div v-if="settlementsStatistics[0]">
    <p>Saldo ogólne: {{ moneyFormat(settlementsStatistics[0].due) }}PLN</p>
    <p>Wpłacono: {{ moneyFormat(settlementsStatistics[0].paid) }} PLN</p>
    <p>Nierozliczono: {{ moneyFormat((settlementsStatistics[0].due) - (settlementsStatistics[0].paid)) }} PLN</p>
    <p>Ostatni wgrany raport: {{ settlementsStatistics[0].lastReport }}</p>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import settlement from "../../services/Settlement";

export default {
  name: "SettlementStatistics",
  data: () => ({
    settlementsStatistics: []
  }),
  components: {  },
  computed: {
    ...mapGetters({
      multiSemesterSelected: 'multiSemesterSelected',
      courseSelected: 'courses/getCourseSelected',
      placeSelected: 'places/getPlaceSelected',
    }),
  },
  watch: {
    multiSemesterSelected() {
      this.semesterChanged()
    },
    placeSelected() {
      this.placeChanged();
    },
    courseSelected() {
      this.courseChanged();
    }
  },
  methods: {
    placeChanged() {
      this.getStudentSettlementsStatistics();
    },
    semesterChanged() {
      this.getStudentSettlementsStatistics();
    },
    courseChanged() {
      this.getStudentSettlementsStatistics();
    },
    getStudentSettlementsStatistics () {
      return new Promise((resolve, reject) => {
        settlement.getStudentSettlementsStatistics(          {
          'date': this.multiSemesterSelected ? this.multiSemesterSelected.join(',') : '',
          'pid': this.placeSelected,
          'cid': this.courseSelected
        }).then(response => {
          if(response.status == 200) {
            this.settlementsStatistics = response.data;
            resolve();
          }
        }).catch(() => {
          resolve();
        });
      }).catch((error) => { console.log(error) });
    },
    moneyFormat(value) {
      if (value !== null) {
        return new Intl.NumberFormat('en-DE').format(value/100);
      } else {
        return 0;
      }
    }
  },
  created() {
    this.getStudentSettlementsStatistics();
  },
}
</script>

<style>
</style>
