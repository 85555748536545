import Api from './Api'

export default {
  getHistoryByCourseId (cid) {
    return Api().get('/api/historySubject/course/' + cid, { withCredentials: true })
  },
  deleteMultiple (items) {
    return Api().post('/api/historySubject/delete-multiple', items)
  },
}
