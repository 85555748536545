<template>
  <LineChartGenerator :chartData="chartDataTrends"></LineChartGenerator>
</template>

<script>
import {mapGetters} from "vuex";
import LineChartGenerator from "./LineChartGenerator.vue";
import studentCourse from "../../services/StudentCourse";

export default {
  name: "StudentCourseTrend",
  data: () => ({
    studentCoursesTrends: []
  }),
  components: { LineChartGenerator },
  computed: {
    ...mapGetters({
      getStudentCoursesGrouped: 'studentCourses/getStudentCoursesGrouped'
    }),
    chartDataTrends() {
      const uniqueDates = [...new Set(this.studentCoursesTrends.map(item => item.date))].sort((a,b)=>new Date(a).getTime() - new Date(b).getTime());

      let chartData = {labels: [], datasets: []};
      chartData.labels = uniqueDates;

      // count amount of students for each period
      let allStudents = [];
      this.studentCoursesTrends.forEach(x => {
        if (isNaN(allStudents[x.date])) {
          allStudents[x.date] = 0;
        }
        allStudents[x.date] += x.amount;
      });
      // console.log(allStudents);
      // console.log(this.studentCoursesTrends);

      let i = 0;
      this.getStudentCoursesGrouped.cid.forEach(course => {
        i++;
        if (i > 15) {
          return;
        }

        let generatedColor = '#' + Math.floor(((course.name).length + course.id) * 45 + 10).toString(16);
        // console.log(this.studentCoursesTrends.filter(x => x.id == course.id).sort((a,b)=>new Date(a.date).getTime() - new Date(b.date).getTime()).map(x => {return ((x.amount / allStudents[x.date]) * 100)}));

        chartData.datasets.push({
          backgroundColor: generatedColor,
          borderColor: generatedColor,
          label: course.name,
          data: this.studentCoursesTrends.filter(x => x.id == course.id).sort((a,b)=>new Date(a.date).getTime() - new Date(b.date).getTime()).map(x => {
           // console.log('dla ' + x.name + ' ' + x.date + ' jest ' + x.amount + ' dzielone przez ' + allStudents[x.date] + ' daje ' + (x.amount / allStudents[x.date]) * 100);
            return ((x.amount / allStudents[x.date]) * 100)
          })
        });

      });

      return chartData;
    },
  },
  methods: {
    getStudentCoursesTrends () {
      return new Promise((resolve, reject) => {
        studentCourse.getStudentCoursesTrends().then(response => {
          if(response.status == 200) {
            this.studentCoursesTrends = response.data;
            resolve();
          }
        }).catch(() => {
          resolve();
        });
      }).catch((error) => { console.log(error) });
    }
  },
  created() {
    this.getStudentCoursesTrends();
  },
}
</script>

<style>
</style>
