const UserRoleEnum = Object.freeze({
    Professor: 0,
    Admin: 1,
    Coordinator: 2,
    Student: 3,
    Administrative: 4,
    ProfessorStudent: 5,
    Accountant: 6,
    Zoom: 7,
    ProfessorMBA: 8,
    All: 10,
    SuperAdmin: 11
});

export default UserRoleEnum;

