<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <template v-slot:default="{ open }">
                <v-row no-gutters>
                    <v-col cols="12" class="title">
                        IV. Pozostałe dane
                    </v-col>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <Other />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
  import Other from "./Other";

  export default {
    name: "OtherData",
    components: {Other}
  }
</script>

