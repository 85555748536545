<template>
    <div>
        <v-file-input
                v-model="avatar"
                :rules="rules"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Wczytaj zdjęcie"
                prepend-icon="mdi-camera"
                label="Zdjęcie"
        ></v-file-input>
        <v-container>
            <v-row>
                <v-col class="justify-end d-flex">
                    <v-btn color="primary" dark rounded @click="save()" class="mb-4">Zapisz zmiany</v-btn>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    name: "Avatar",
    data: () => ({
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      avatar: null
    }),
    methods: {
      ...mapActions({
        setAvatar: 'users/setAvatar'
      }),
      save() {
        const formData = new FormData()
        formData.append('file', this.avatar)
        this.setAvatar(formData).then(response => {
          if(response == 200) {
          }
        }).catch(error => {
          this.$log.error('Saving new avatar error', error)
        })
      }
    }
  }
</script>

<style scoped>

</style>
