<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="editedItem.firstname" :error-messages="firstnameErrors" label="Imię" required @blur="$v.editedItem.firstname.$touch()"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="editedItem.lastname" :error-messages="lastnameErrors" label="Nazwisko" required @blur="$v.editedItem.lastname.$touch()"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="editedItem.email" :error-messages="emailErrors" label="Email" @blur="$v.editedItem.email.$touch()"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="editedItem.telephone" :error-messages="telephoneErrors" label="Telefon" @blur="$v.editedItem.telephone.$touch()"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-file-input
                v-model="businessCard"
                :label=displayBusinessCardLabel
                :placeholder=displayBusinessCardLabel
                :rules="rules"
                accept="application/pdf"
                prepend-icon="mdi-camera"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-file-input
                v-model="mbaDocument"
                :label=displayDocumentLabel
                :placeholder=displayDocumentLabel
                :rules="rules"
                accept="image/*"
                prepend-icon="description"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-autocomplete
                v-model="editedItem.tags"
                :error-messages="tagsErrors"
                :items="tags"
                chips
                item-text="name"
                item-value="id"
                label="Tags"
                multiple
                small-chips
                @blur="$v.editedItem.tags.$touch()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-textarea
                v-model="editedItem.description"
                auto-grow
                dense
                label="Opis"
                outlined
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="pa-7">
      <v-btn class="overline" color="grey" text @click="close"><v-icon left small>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do listy wszystkich mentorów</span></v-btn>
      <v-spacer></v-spacer>
      <v-btn class="primary" rounded @click="save">{{ editedIndex === -1 ? 'Dodaj mentora': 'Zapisz zmiany'}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "MentorAddEdit",
  props: ['formTitle', 'editedItem', 'editedIndex'],
  data: () => ({
    businessCardPlaceholder: "Dodaj wizytówkę w formacie PDF",
    documentPlaceholder: "Dodaj dokument w formacie JPG/PNG",
    businessCard: '',
    mbaDocument: '',
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
  }),
  defaultItem: {
    firstname: '',
    lastname: '',
    telephone: '',
    email: '',
    description: '',
    business_card: '',
    document: '',
    tags: [],
    active: 1
  },
  mixins: [validationMixin],

  validations: {
    editedItem: {
      firstname: { required },
      lastname: { required },
      telephone: { required },
      email: { required, email },
      tags: { required }
    }
  },
  watch: {
    dialogNewSuccess(val) {
      val || setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1
      }, 300)
    },
    dialog(val) {
      val || setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1
      }, 300)
    },
  }, computed: {
    ...mapGetters({
      tags: 'tags/getTags'
    }),
    firstnameErrors () {
      const errors = [];
      if (!this.$v.editedItem.firstname.$dirty) return errors;
      !this.$v.editedItem.firstname.required && errors.push('Podanie imienia jest wymagane');
      return errors
    },
    lastnameErrors () {
      const errors = [];
      if (!this.$v.editedItem.lastname.$dirty) return errors;
      !this.$v.editedItem.lastname.required && errors.push('Podanie nazwiska jest wymagane');
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.editedItem.email.$dirty) return errors
      !this.$v.editedItem.email.email && errors.push('Podany adres e-mail jest niepoprawny')
      !this.$v.editedItem.email.required && errors.push('Podanie e-maila jest wymagane')
      return errors
    },
    telephoneErrors () {
      const errors = [];
      if (!this.$v.editedItem.telephone.$dirty) return errors;
      !this.$v.editedItem.telephone.required && errors.push('Podanie telefonu jest wymagane');
      return errors
    },
    tagsErrors () {
      const errors = [];
      if (!this.$v.editedItem.tags.$dirty) return errors;
      !this.$v.editedItem.tags.required && errors.push('Podanie przynajmniej jednego tagu jest wymagane');
      return errors
    },
    showExtraActions () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    displayBusinessCardLabel() {
      return (this.editedItem.business_card ? 'Dodaj inną wizytówkę w formacie PDF' : this.businessCardPlaceholder);
    },
    displayDocumentLabel() {
      return (this.editedItem.mbaDocument ? 'Dodaj inny dokument' : this.documentPlaceholder);
    }
  },
  methods: {
    ...mapActions({
      createMentor: 'mentors/createMentor',
      editMentor: 'mentors/editMentor',
    }),
    save () {
      this.$v.$touch();

      const formData = new FormData()
      formData.append('firstname', this.editedItem.firstname);
      formData.append('lastname', this.editedItem.lastname);
      formData.append('email', this.editedItem.email);
      formData.append('telephone', this.editedItem.telephone);
      formData.append('description', this.editedItem.description);
      formData.append('tags', this.editedItem.tags);

      if (this.businessCard) {
        formData.append('business_card', this.businessCard);
      }
      if (this.mbaDocument) {
        formData.append('document', this.mbaDocument);
      }

      if (this.editedIndex > -1) {
        if (!this.$v.$invalid) {
          formData.append('id', this.editedItem.id);
          this.editMentor({id: this.editedItem.id, formData: formData}).then(response => {
            this.$log.info('Saved edited mentor', response.data.id);
            // Object.assign(this.mentors[this.editedIndex], this.editedItem);
            this.close();
          }).catch(error => {
            this.$log.error('Saving edited mentor error', error)
          })
        }
      } else {
        if (!this.$v.$invalid) {
          this.createMentor(formData).then(response => {
            this.$log.info('Saved new mentor', response.data.id);
            this.close();
            this.dialogNewSuccess = true
          }).catch(error => {
            this.$log.error('Saving new avatar error', error)
          })
        }
      }
    },
    close () {
      this.$emit('dialogClosed',true);
      this.$store.dispatch('mentors/getMentorsTags');
      this.businessCard = '';
      this.mbaDocument = '';
      this.$v.$reset();
    },
  }
}
</script>

<style scoped>

</style>
