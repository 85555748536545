import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from './plugins/vuetify';
import VueLogger from 'vuejs-logger';

import Default from "./layouts/Default.vue"
import Login from "./layouts/Login.vue"
import Error from "./layouts/Error.vue"

Vue.component('default-layout', Default);
Vue.component('login-layout', Login);
Vue.component('error-layout', Error);

Vue.config.productionTip = false;

const options = {
  isEnabled: true,
  logLevel : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};

Vue.use(VueLogger, options);

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

Vue.filter('translateProtocolType', function (type) {
  switch (type) {
    case 1:
      return 'pierwsza';
    case 2:
      return 'poprawkowa';
    case 0:
      return 'pierwsza/poprawkowa';
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
