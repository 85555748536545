<template>
    <v-card>
        <v-card-title>Rachunki</v-card-title>
        <v-card-text>
            <BillKm class="mr-4" />
            <BillUser />
        </v-card-text>
    </v-card>
</template>

<script>
  import BillKm from "./BillKm";
  import BillUser from "./BillUser";

  export default {
    name: "BillForms",
    components: { BillKm, BillUser },

  }
</script>

<style scoped>

</style>
