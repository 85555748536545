<template>
    <v-container>
        <v-card>
            <v-tabs
                    v-model="tabActive"
                    background-color="primary"
                    dark
                    :key="activeSettingsTabFromStore"
            >
                <v-tab
                        v-for="item in items"
                        :key="item.tab"
                >
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabActive">
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <AdminGeneralSettings />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <PasswordChangeOldRequired />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Avatar/>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import AdminGeneralSettings from "../components/AdminGeneralSettings";
  import Avatar from "../components/Avatar/Avatar";
  import PasswordChangeOldRequired from "../components/PasswordChangeOldRequired";

  export default {
    name: "Settings",
    components: {Avatar, PasswordChangeOldRequired, AdminGeneralSettings },
    data () {
      return {
        items: [
          { id: 1, tab: 'Dane ogólne' },
          { id: 2, tab: 'Zmiana hasła' },
          { id: 3, tab: 'Zmień zdjęcie' },
          { id: 4, tab: 'Zoom' },
        ],
        dialogNewCar: false,
        tab: null,
        validate: false
      }
    },
    methods: {
      ...mapActions({
      }),
      save() {
        this.validate = true
      },
    },
    computed: {
      ...mapGetters({
        activeSettingsTabFromStore: 'getActiveSettingsTab',
      }),
      tabActive: {
        get () {
          return this.activeSettingsTabFromStore
        },
        set (value) {
          this.$store.commit('setActiveSettingsTab', value)
        }
      }
    },
    created() {
    }
  }
</script>

<style scoped>

</style>
