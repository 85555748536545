import message from '../../services/Message'

// initial state
const state = {
  items: []
};

// getters
const getters = {
  getMessages: state => state.items,
  getMessagesAssignedToUser: state => state.items.filter(p => p.uid != null),
  dialogNew: state => state.dialogNew,
  getMessageNameById: state => messageId => state.items.find(p => p.id === messageId) != undefined ? state.items.find(p => p.id === messageId).name : '',
};

// actions
const actions = {

  getMessages ({ commit, rootState }) {
    message.getMessagesByUserId(rootState.studentCourses.chosenStudentCourseUser).then(response => {
      commit('setMessages', response.data)
    })
  },
  createMessage ({ commit }, newMessage) {
    return new Promise((resolve, reject) => {
      message.createMessage(newMessage).then(response => {
        resolve(200);
        // commit('addMessage', response.data)
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  editMessage ({ commit }, editedMessage) {
    message.editMessage(editedMessage).then(response => {
      commit('editMessage', response.data)
    })
  },
  deleteMessages ({ commit }, deletedMessages) {
    message.deleteMessages(deletedMessages.map(function(item) { return item["id"] })).then(response => {
      if (response.status == 200) {
        commit('deleteMessages', deletedMessages)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  }
};

// mutations
const mutations = {
  setMessages (state, messages) {
    state.items = messages
  },
  addMessage (state, message) {
    state.items.push(message)
  },
  editMessage (state, message) {
    console.log(message)
  },
  deleteMessages (state, messages) {
    messages.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
