<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <div>Ilość aktywnych ośrodków</div>
          <div><b>{{ userStatistics.places }}</b></div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-text>
          <div>Ilość aktywnych koordynatorów</div>
          <div><b>{{ userStatistics.coordinators }}</b></div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-text>
          <div>Ilość pracowników administracyjnych z aktywną umową</div>
          <div><b>{{ userStatistics.administratives }}</b></div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-text>
          <div>Ilość wykładowców</div>
          <div><b>{{ userStatistics.professors }}</b></div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-text>
          <div>Ilość przedmiotów</div>
          <div><b>{{ userStatistics.subjects }}</b></div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-text>
          <div>Ilość kierunków</div>
          <div><b>{{ userStatistics.courses }}</b></div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-text>
          <div>Ilość aktywnych sesji aktualnie</div>
          <div><b>{{ activeSessions }}</b></div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import user from "../../services/User";

export default {
  name: "UserStatistics",
  data: () => ({
    userStatistics: [],
    activeSessions: 0
  }),
  components: {  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    getUserStatistics () {
      return new Promise((resolve, reject) => {
        user.getStatistics().then(response => {
          if(response.status == 200) {
            this.userStatistics = response.data;
            resolve();
          }
        }).catch(() => {
          resolve();
        });
      }).catch((error) => { console.log(error) });
    },
    getActiveSessions() {
      return new Promise((resolve, reject) => {
        user.getActiveSessions().then(response => {
          if(response.status == 200) {
            this.activeSessions = response.data.amount;
            resolve();
          }
        }).catch(() => {
          resolve();
        });
      }).catch((error) => { console.log(error) });
    }
  },
  created() {
    this.getUserStatistics().then(() => {
      this.getActiveSessions();
    });
  },
}
</script>

<style>
</style>
