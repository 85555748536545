<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <div>
                  <span v-show="insuranceTypeErrors.length > 0" class="error--text" v-if="insuranceTypeErrors">{{ insuranceTypeErrors[0] }}</span>
                    <v-checkbox v-model="insuranceType" value="value-1" class="d-flex align-start">
                        <template v-slot:label>
                            <p>1. {{ displayInsurance('value-1') }}</p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-2" class="d-flex align-start">
                        <template v-slot:label>
                            <p>2. {{ displayInsurance('value-2') }} <br/>Podaj proszę nazwę, adres zakładu/zakładów
                                pracy:</p>
                        </template>
                    </v-checkbox>
                    <div v-show="insuranceType.indexOf('value-2') !== -1">
                        <div v-for="placeOfWork in placesOfWork" v-bind:key="placeOfWork.id">
                            <PlaceOfWork :data="placeOfWork"/>
                        </div>
                        <v-container>
                            <v-row>
                                <v-col sm="12" class="d-flex justify-end">
                                    <v-btn primary @click="addPlaceOfWork">Dodaj kolejny zakład pracy</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                    <v-checkbox v-model="insuranceType" value="value-3" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>3. {{ displayInsurance('value-3') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-4" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>4. {{ displayInsurance('value-4') }} Podaj proszę nazwę,
                                adres zakładu/zakładów pracy.</p>
                        </template>
                    </v-checkbox>
                    <div v-show="insuranceType.indexOf('value-4') !== -1">
                        <div v-for="placeOfTemporaryWork in placesOfTemporaryWork" v-bind:key="placeOfTemporaryWork.id">
                            <PlaceOfTemporaryWork :data="placeOfTemporaryWork"/>
                        </div>
                        <v-container>
                            <v-row>
                                <v-col sm="12" class="d-flex justify-end">
                                    <v-btn primary @click="addPlaceOfTemporaryWork()">Dodaj kolejny zakład pracy</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                    <v-checkbox v-model="insuranceType" value="value-5" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>5. {{ displayInsurance('value-5') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-6" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>6. {{ displayInsurance('value-6') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-7" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>7. {{ displayInsurance('value-7') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-8" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>8. {{ displayInsurance('value-8') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-9" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>9. {{ displayInsurance('value-9') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-10" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>10. {{ displayInsurance('value-10') }} </p>
                        </template>
                    </v-checkbox>
                    <PensionerNFZ v-show="insuranceType.indexOf('value-10') !== -1" />
                    <v-checkbox v-model="insuranceType" value="value-11" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>11. {{ displayInsurance('value-11') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-12" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>12. {{ displayInsurance('value-12') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-13" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>13. {{ displayInsurance('value-13') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-14" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>14. {{ displayInsurance('value-14') }} </p>
                        </template>
                    </v-checkbox>
                    <Student v-show="insuranceType.indexOf('value-14') !== -1"></Student>
                    <v-checkbox v-model="insuranceType" value="value-15" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>15. {{ displayInsurance('value-15') }} </p>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="insuranceType" value="value-16" label="" class="d-flex align-start">
                        <template v-slot:label>
                            <p>16. {{ displayInsurance('value-16') }} </p>
                        </template>
                    </v-checkbox>
                    <OtherInsurance v-show="insuranceType.indexOf('value-16') !== -1"></OtherInsurance>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapMutations} from "vuex";
import {createHelpers, mapMultiRowFields} from 'vuex-map-fields';

import PensionerNFZ from "@/components/RegisterForm/InsuranceForm/PensionerNFZ"
import Student from "@/components/RegisterForm/InsuranceForm/Student"
import OtherInsurance from "@/components/RegisterForm/InsuranceForm/OtherInsurance"
import PlaceOfTemporaryWork from "@/components/RegisterForm/InsuranceForm/PlaceOfTemporaryWork"
import PlaceOfWork from "@/components/RegisterForm/InsuranceForm/PlaceOfWork"

import InsuranceTypes from "@/components/RegisterForm/InsuranceForm/InsuranceTypes";

const { mapFields } = createHelpers({
    getterType: 'registration/getInsurance',
    mutationType: 'registration/updateInsurance',
  });

  export default {
    name: 'Insurance',
    props: [
      'insuranceTypeErrors'
    ],
    components: {PensionerNFZ, PlaceOfTemporaryWork, PlaceOfWork, Student, OtherInsurance},
    methods: {
      ...mapMutations('registration', ['addPlaceOfWork', 'addPlaceOfTemporaryWork']),
      displayInsurance (value) {
        return InsuranceTypes[value]
      },
      updateInsuranceType() {
        console.log(this.insuranceType);
      }
    },
    computed: {
      ...mapMultiRowFields('registration', { placesOfWork: 'insurance.placesOfWork', placesOfTemporaryWork: 'insurance.placesOfTemporaryWork'} ),
      ...mapFields([
        'insurance.insuranceType'
      ])
    },

  }
</script>
