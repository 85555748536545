<template>
  <p>
    Zapisanych osób: <b>{{ sumAll() }}</b> <span v-if="studentCoursesGroups.find(x => x.count > 1)">w tym studiujących</span>
    <span v-if="studentCoursesGroups.find(x => x.count == 2)"> 2 kierunki: <b>{{ studentCoursesGroups.find(x => x.count == 2)?.amount }}</b></span>
    <span v-if="studentCoursesGroups.find(x => x.count == 3)">, 3 kierunki: <b>{{ studentCoursesGroups.find(x => x.count == 3)?.amount }}</b></span>
    <span v-if="studentCoursesGroups.find(x => x.count == 4)">, 4 kierunki <b>{{ studentCoursesGroups.find(x => x.count == 4)?.amount }}</b></span>
  </p>
</template>

<script>
import {mapGetters} from "vuex";
import studentCourse from "../../services/StudentCourse";

export default {
  name: "StudentCourseGeneral",
  data: () => ({
    studentCoursesGroups: []
  }),
  components: {  },
  computed: {
    ...mapGetters({
      multiSemesterSelected: 'multiSemesterSelected',
      courseSelected: 'courses/getCourseSelected',
      placeSelected: 'places/getPlaceSelected',
    }),
  },
  watch: {
    multiSemesterSelected() {
      this.semesterChanged()
    },
    placeSelected() {
      this.placeChanged();
    },
    courseSelected() {
      this.courseChanged();
    }
  },
  methods: {
    placeChanged() {
      this.getStudentCoursesGroups();
    },
    semesterChanged() {
      this.getStudentCoursesGroups();
    },
    courseChanged() {
      this.getStudentCoursesGroups();
    },
    getStudentCoursesGroups () {
      return new Promise((resolve, reject) => {
        studentCourse.getStudentCoursesGroups(          {
          'date': this.multiSemesterSelected ? this.multiSemesterSelected.join(',') : '',
          'pid': this.placeSelected,
          'cid': this.courseSelected,
          'groupBy': 'pid'
        }).then(response => {
          if(response.status == 200) {
            this.studentCoursesGroups = response.data;
            resolve();
          }
        }).catch(() => {
          resolve();
        });
      }).catch((error) => { console.log(error) });
    },
    sumAll() {
      return this.studentCoursesGroups.reduce((partialSum, a) => partialSum + a?.amount, 0);
    },
  },
  created() {
    this.getStudentCoursesGroups();
  },
}
</script>

<style>
</style>
