import Api from './Api'

export default {
  createSettlement (userId, formData) {
    return Api().post('/api/settlements/' + userId, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  addSettlementMapping (settlementId, mapping) {
    return Api().post('/api/settlements/mapping/' + settlementId, mapping)
  },
  getStudentSettlements (settlementId) {
    return Api().get('/api/settlements/' + settlementId)
  },
  getSettlementsByUserId (uid) {
    return Api().get('/api/settlements/user/' + uid, { withCredentials: true })
  },
  getSettlements () {
    return Api().get('/api/settlements/')
  },
  getFileByUrl (url) {
    return Api().get('/api/files/url/' + url, { withCredentials: true })
  },
  deleteSettlements (files) {
    return Api().post('/api/settlements/delete-multiple', files)
  },
  deleteSettlement (fileId) {
    return Api().delete('/api/settlements/' + fileId)
  },
  getStudentSettlementsStatistics (parameters = null) {
    return Api().get('/api/settlements/statistics?' + (parameters.date ? '&date=' + parameters.date : '') + (parameters.pid ? '&pid=' + parameters.pid : '') + (parameters.cid ? '&cid=' + parameters.cid : ''), { withCredentials: true })
  },
}
