<template>
  <div>
    <div class="d-flex justify-center pb-4">
      <v-img src="../assets/logo_logowanie.png" max-width="150"></v-img>
    </div>
    <v-card :width=this.width class="rounded-card">
      <v-card-title>
        <h1>Zmień hasło</h1>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="submit">
          <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              v-model="passwordNew"
              :error-messages="passwordNewErrors"
              required
              @blur="$v.passwordNew.$touch()"
              label="Nowe hasło"
              @click:append="show = !show"
          />
          <p>
            Hasło powinno zawierać:
            <ul>
              <li :class="this.passwordNew && this.$v.passwordNew.minLength ? 'green--text' : ''">minimum 8 znaków</li>
              <li :class="this.$v.passwordNew.containsNumber ? 'green--text' : ''">minimum jedną cyfrę</li>
              <li :class="this.$v.passwordNew.containsSpecialCharacter ? 'green--text' : ''">jeden znak specjalny (!@#$%^&*?)</li>
            </ul>
          </p>
          <v-text-field
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              v-model="repeatPassword"
              :error-messages="repeatPasswordErrors"
              required
              @blur="$v.repeatPassword.$touch()"
              label="Powtórz nowe hasło"
              @click:append="show2 = !show2"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex pa-2 justify-end">
        <v-btn rounded :loading="loading" @click="save" color="secondary" class="text-right mr-2">Zapisz nowe hasło</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {minLength, required, sameAs} from 'vuelidate/lib/validators'
import router from "../router";

export default {
  data() {
    return {
      passwordNew: '',
      repeatPassword: '',
      loader: null,
      loading: false,
      dialogNewSuccess: false,
      show: false,
      show2: false,
    }
  },
  mixins: [validationMixin],
  validations: {
    passwordNew: {
      required,
      minLength: minLength(8),
      containsNumber: function(value) {
        return /[0-9]/.test(value)
      },
      containsSpecialCharacter: function(value) {
        return /[!@#$%^&*?]/.test(value)
      },
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs('passwordNew')
    }
  },
  methods: {
    ...mapActions({
      passwordChange: 'users/passwordChange'
    }),
    save () {
      this.$v.$touch()
      if (!this.$v.passwordNew.$invalid && !this.$v.repeatPassword.$invalid) {
        this.loader = 'loading'
        this.loading = true
        this.passwordChange({passwordNew: this.passwordNew}).then(response => {
          if(response == 200) {
            this.$log.info('Password changed ', response)
            this.loading = false
            this.$v.$reset()
            this.passwordNew = ''
            this.repeatPassword = ''
            router.push('/student-personal-data');
          }
        }, error => {
          this.error = true
          this.loading = false
          this.$log.error('Password change error ', error)
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'users/getLoggedInUser',
    }),
    passwordNewErrors () {
      const errors = []
      if (!this.$v.passwordNew.$dirty) return errors
      !this.$v.passwordNew.minLength && errors.push('Nowe hasło musi mieć przynajmniej 8 znakow')
      !this.$v.passwordNew.containsNumber && errors.push('Nowe hasło musi zawierać przynajmniej jedeną cyfrę')
      !this.$v.passwordNew.containsSpecialCharacter && errors.push('Podanie znaku specjalnego jest wymagane')
      !this.$v.passwordNew.required && errors.push('Podanie nowego hasła jest wymagane')
      return errors
    },
    repeatPasswordErrors () {
      const errors = []
      if (!this.$v.repeatPassword.$dirty) return errors
      !this.$v.repeatPassword.required && errors.push('Proszę powtórzyć nowe hasło')
      !this.$v.repeatPassword.sameAsPassword && errors.push('Musisz wpisać dwa razy, to samo, nowe hasło')
      return errors
    },
    width () {
      return this.$vuetify.breakpoint.xsOnly ? "350" : "450"
    }
  },
  created() {
    if(!this.loggedInUser) {
      router.push('/');
    }
  }
}
</script>

<style scoped>
</style>
