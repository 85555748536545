import Api from './Api'

export default {
  getNotifications () {
    return Api().get('/notificationapi', { withCredentials: true }).catch((error)=>{ console.log(error)})
  },
  deleteNotifications (messages) {
    return Api().post('/notificationapi/delete-multiple', messages);
  },
  markWatched () {
    return Api().put('/notificationapi/watched').catch(error => { console.log(error) });
  },
  markWatchedForUser (userId) {
    return Api().put('/notificationapi/watched/' + userId).catch(error => { console.log(error) });
  }
}
