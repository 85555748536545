<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <p>1. Mam ustalone <strong>prawo do renty rodzinnej</strong></p>
                <v-radio-group v-model="family" row>
                    <v-radio value="true" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Tak</p>
                        </template>
                    </v-radio>
                    <v-radio value="false" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Nie</p>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <p>2. Mam przyznaną <strong>rentę socialną</strong></p>
                <v-radio-group v-model="social" row>
                    <v-radio value="true" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Tak</p>
                        </template>
                    </v-radio>
                    <v-radio value="false" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Nie</p>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <p>3. <strong>Mam orzeczenie stopnia niepełnosprawności</strong></p>
                <v-radio-group v-model="disabled" row>
                    <v-radio value="true" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Tak</p>
                        </template>
                    </v-radio>
                    <v-radio value="false" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Nie</p>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-select v-show="disabled == 'true'"
                          v-model="level"
                          :items="disabilityLevel"
                          label="Stopień"
                ></v-select>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  import {createHelpers} from 'vuex-map-fields';

  const { mapFields } = createHelpers({
    getterType: 'registration/getOther',
    mutationType: 'registration/updateOther',
  });

  export default {
    name: 'Other',
    data: () => ({
      disabilityLevel: ['lekki', 'umiarkowany', 'znaczny']
    }),
    computed: {
      ...mapFields([
        'other.family',
        'other.social',
        'other.disabled',
        'other.level'
      ])
    }
  }
</script>
