<template>
<div v-if="getLoggedInUser.id != undefined">

  <v-container class="fill-height">

    <v-row v-if="history.length > 0" align="end" class="fill-height pb-2">
      <v-col class="pl-0 pr-0">
        <v-card color="#EFF3F7" height="300px" outlined>
          <v-card-text>
            <v-list color="#EFF3F7">
              <div v-for="(itemHistory, indexHistory) in history"
                   :key="indexHistory"
                   :class="itemHistory.uid_from == getLoggedInUser.id ? 'd-flex flex-row align-center my-4 chat-message' : 'd-flex flex-row align-center my-2 chat-message'"
              >
                <span :class="itemHistory.uid_from == getLoggedInUser.id ? 'my-message black--text pa-4' : 'reply-message black--text pa-4' ">
                  <div class="d-flex justify-space-between mb-4">
                    <span class="message-title">
                      <span v-if="userRole == 1">
                        <span v-if="itemHistory.uid_to !== null">Administracja UNIKADRA</span>
                        <span v-else>{{ chatUserName(itemHistory.uid_from) }}</span>
                      </span>
                      <span v-else>
                        <span v-if="itemHistory.uid_to !== null">Administracja UNIKADRA</span>
                        <span v-else>{{ getLoggedInUser.name }}</span>
                      </span>
                    </span>
                    <span class="small text-end">[{{ itemHistory.datetime }}]</span>
                  </div>
                  <div v-html="displayMessage(itemHistory)"></div>


                  <v-btn v-if="itemHistory.file" color="dark" rounded @click="getFile(itemHistory.file)">
                    <v-icon left>file_download</v-icon>Pobierz załącznik
                  </v-btn>
                </span>
              </div>
            </v-list>
            <br /><br /><br />
            <span class="bottomOfChat"></span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-if="(history.length > 0 && history[history.length-1].uid_from !== getLoggedInUser.id && this.showRespondTextarea || (issue.uid !== getLoggedInUser.id))" class="ma-0 pa-0">
      <v-row v-if="userRole == 1 && issue.uid !== getLoggedInUser.id">
        <v-col>
          <span v-html="displayAdminTop"></span>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <div class="d-flex flex-row align-end">
            <v-textarea
                v-model="msg"
                :counter=1000-value
                :rules="rules"
                :value="value"
                label="Miejsce na Twoją odpowiedź"
                outlined
            ></v-textarea>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="userRole == 1 && issue.uid !== getLoggedInUser.id">
        <v-col class="text-right pt-0">
          <span v-html="displayAdminBottom"></span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="d-flex flex-row justify-space-between">
          <div>
            <v-btn
                v-if="selectedFile == undefined"
                :loading="isSelecting"
                color="primary"
                depressed
                rounded
                @click="onUploadButtonClick"
            >
              <v-icon left>
                cloud_upload
              </v-icon>
              Dołącz plik
            </v-btn>
            <input
                ref="uploader"
                accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                class="d-none"
                type="file"
                @change="onFileChanged"
            >
            <div v-if="this.selectedFile !== undefined">
              Zostanie dołączony: {{ this.selectedFile.name }} ({{ this.selectedFile.type }}, wielkość: {{ (this.selectedFile.size/1000000).toFixed(2) }}MB)
              <v-btn color="error" dark rounded @click="removeSelected">Usuń</v-btn>
            </div>
          </div>
          <div>
            <v-btn :disabled="msg.length == 0 && selectedFile == undefined" class="ml-4" color="primary" dark rounded @click="send">Prześlij odpowiedź<v-icon right>mdi-send</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "IssueChatCurrent",
  data: () => ({
    rules: [v => v.length <= 1000 || 'Maksymalnie wiadomość może zawierać 1000 znaków'],
    value: '',
    chat: [],
    msg: '',
    loading: false,
    selectedFile: undefined
  }),
  props: [
    'user',
    'issue',
    'showRespondTextarea'
  ],
  computed: {
    ...mapGetters({
      historyByIssue: 'chat/historyByIssue',
      getLoggedInUser: 'users/getLoggedInUser',
      getChosenChatUser: 'chat/getChosenChatUser',
      userRole: 'users/userRole',
      chatUserName: 'users/getNameById',
      getRoleById: 'users/getRoleById'
    }),
    history() {
      return this.historyByIssue;
    },
    displayAdminTop() {
      return '<p></p><b><span style="font-size: 34px;">👋</span> Dzień dobry!</b></p>';
    },
    displayAdminBottom() {
      return '<p class="mt-4 text-end">Pozdrawiamy serdecznie,<br /><b>zespół techniczny Unikadry</b></p>';
    }
  },
  methods: {
    ...mapActions({
      createIssueChatMessage: 'chat/createIssueChatMessage',
      getFileByUrl: 'chat/getFileByUrl',
      getIssueTrackerFileByUrl: 'chat/getIssueTrackerFileByUrl',
      getMessagesByIssue: 'chat/getMessagesByIssue'

    }),
    avatar (uuid) {
      return "/api/users/" + uuid + "/avatarByUuid"
    },
    send: function(){
      this.loading = true;
      let message = {};

      if (this.selectedFile !== undefined) {
        message = new FormData();
        message.append('file', this.selectedFile);
        message.append('msg', this.msg);
        message.append('issue_id', this.issue.id);
        if (this.getLoggedInUser.id !== this.issue.uid) {
          message.append('uid_to', this.issue.uid);
        }
      } else {
        message = {msg: this.msg, issue_id: this.issue.id };
        if (this.getLoggedInUser.id !== this.issue.uid) {
          message = {...message, 'uid_to': this.issue.uid};
        }

      }
      this.scrollToBottom(true);
      this.createIssueChatMessage(message).then(response => {
        if(response == 200) {
          this.$log.info('Msg changed ', response);
          this.loading = false;
          this.msg = '';
          this.selectedFile = undefined;
        }
      }, error => {
        this.error = true
        this.loading = false
        this.$log.error('Msg change error ', error)
      })

    },
    onUploadButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      // console.log(this.selectedFile);
      // do something
    },
    removeSelected() {
      this.selectedFile = undefined;
    },
    scrollToBottom(smooth) {
      if(this.getLoggedInUser.id != undefined && this.history.length > 0) {
        if (this.history[this.history.length-1].uid_from == this.getLoggedInUser.id) {
          this.$emit('hideRespondButtons',true);
        }
        if (this.$el.getElementsByClassName('bottomOfChat')) {
          const el = this.$el.getElementsByClassName('bottomOfChat')[0]
          if (el) {
            if (smooth == true) {
              el.scrollIntoView({behavior: 'smooth'});
            } else {
              el.scrollIntoView();
            }
            window.scrollTo(0, 0);
          }
        }
      } else {
        this.$emit('hideRespondButtons',true);
      }
    },
    getFile(url) {
      return this.getIssueTrackerFileByUrl(url);
    },
    displayMessage(itemHistory) {
      if (itemHistory.uid_to !== null) {
        const top = this.displayAdminTop;
        const bottom = this.displayAdminBottom;
        return top + itemHistory.text + bottom;
      } else {
        return itemHistory.text;
      }
    }
  },
  created() {
    this.getMessagesByIssue().then(response1 => {
      if(response1 == 200) {
        setTimeout(x => {
          this.scrollToBottom(false);
        }, 1000);
      }
    });

  },
  updated() {
    if(this.getLoggedInUser) {
      this.scrollToBottom(false);
    }
  }
}
</script>

<style scoped>
  span.my-message {
    background-color: #C6E4FF;
    border-radius: 15px 0 0 0;
    white-space: pre-wrap;
    width: 100%;
  }
  span.reply-message {
    background-color: #FFFFFF;
    border-radius: 0 15px 0 0;
    white-space: pre-wrap;
    width: 100%;
  }

  .v-card {
    display: flex !important;
    flex-direction: column;
  }

  .v-card__text {
    flex-grow: 1;
    overflow: auto;
  }

  .small {
    font-size: 10px;
    color: gray;
  }

  .message-title {
    color: #6373AE;
  }
</style>
