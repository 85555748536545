<template>
    <v-data-table
            :headers="headers"
            :items="trips"
            sort-by="date"
            show-select
            :single-select=singleSelect
            class="elevation-1 ma-4"
            v-model="selected"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Lista wszystkich przejazdów za miesiąc</v-toolbar-title>
                <SelectMonths/>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn v-show="$vuetify.breakpoint.mdAndUp" color="primary" :disabled="selected.length != 1 || !addingPossible" rounded small class="mb-2 ml-4" @click="cloneSelected()"><v-icon small>file_copy</v-icon>Duplikuj zaznaczony przejazd</v-btn>
                <v-btn v-show="$vuetify.breakpoint.mdAndUp" color="primary" :disabled="!selected.length || !addingPossible" rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczony przejazd</v-btn>
                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" :disabled="!addingPossible" rounded small class="mb-2 ml-4" v-on="on">Dodaj nowy przejazd</v-btn>
                        <v-btn v-else color="primary" :disabled="!addingPossible" rounded small class="mb-2 ml-4" v-on="on">Dodaj</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-select v-model="editedItem.cid"
                                                  :items="cars"
                                                  item-value="id"
                                                  menu-props="auto"
                                                  hide-details
                                                  label="Wybierz pojazd"
                                                  single-line
                                                  :error-messages="carErrors"
                                                  required
                                                  @blur="$v.editedItem.cid.$touch()"
                                        >
                                            <template v-slot:selection="{ item }">
                                                {{ item.brand }} {{ item.model }} - {{ item.registration_nr }}
                                            </template>
                                            <template v-slot:item="{ item }">
                                                {{ item.brand }} {{ item.model }} - {{ item.registration_nr }}
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.start" :error-messages="startErrors" required @blur="$v.editedItem.start.$touch()" label="Miejsce wyjazdu"></v-text-field>
                                    </v-col>
                                 </v-row>
                                 <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.destination" :error-messages="destinationErrors" required @blur="$v.editedItem.destination.$touch()" label="Miejsce przyjazdu"></v-text-field>
                                    </v-col>
                                 </v-row>
                                 <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.km" label="Ilosc przebytych KM"></v-text-field>
                                    </v-col>
                                     <v-col cols="10" sm="12" md="4" class="mb-6">
                                         <v-checkbox v-model="editedItem.return_to_start" label="Jeśli wracasz w to samo miejsce, zaznacz pole. Pomnożymy ilośc kilometrow x2" />
                                     </v-col>
                                     <v-col cols="2" md="2" class="d-flex">
                                         <v-tooltip right max-width="300">
                                             <template v-slot:activator="{ on }">
                                                 <v-icon v-on="on" dark color="secondary">info</v-icon>
                                             </template>
                                             <span>Dotyczy przypadkow kiedy podroz z miejsca "X" do miejsca "Y" zakłada powrot w to samo miejsce. W przypadku gdy dokonujesz innego przejazdu, utworz nowy przejazd</span>
                                         </v-tooltip>
                                     </v-col>
                                 </v-row>
                                 <v-row>
                                     <v-col cols="12" sm="12" md="4">
                                         <v-menu
                                                 ref="menuDate"
                                                 v-model="menuDate"
                                                 :close-on-content-click="false"
                                                 :return-value.sync="editedItem.date"
                                                 transition="scale-transition"
                                                 offset-y
                                                 min-width="290px"
                                         >
                                             <template v-slot:activator="{ on }">
                                                 <v-text-field
                                                         v-model="new Date(editedItem.date).toISOString().substr(0, 10)"
                                                         label="Data przejazdu"
                                                         append-icon="event"
                                                         readonly
                                                         v-on="on"
                                                         @click:append="on.click"
                                                 ></v-text-field>
                                             </template>
                                             <v-date-picker v-model="editedItem.date" :first-day-of-week="1" :allowed-dates="(val) => allowedDates(val)" no-title scrollable>
                                                 <v-spacer></v-spacer>
                                                 <v-btn text color="primary" @click="menuDate = false">Cancel</v-btn>
                                                 <v-btn text color="primary" @click="$refs.menuDate.save(editedItem.date)">OK</v-btn>
                                             </v-date-picker>
                                         </v-menu>
                                     </v-col>
                                 </v-row>
                                 <v-row v-if="editedItem.return_to_start">
                                    <v-col cols="10" sm="10" md="4">
                                        <v-menu
                                                ref="menuReturnDate"
                                                v-model="menuReturnDate"
                                                :close-on-content-click="false"
                                                :return-value.sync="editedItem.return_date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="new Date(editedItem.return_date).toISOString().substr(0, 10)"
                                                        label="Data powrotu"
                                                        append-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editedItem.return_date" :first-day-of-week="1" no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menuReturnDate = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.menuReturnDate.save(editedItem.return_date)">OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                     <v-col cols="2" sm="2" md="2" class="d-flex align-bottom">
                                         <v-tooltip right>
                                             <template v-slot:activator="{ on }">
                                                 <v-icon v-on="on" dark color="secondary">info</v-icon>
                                             </template>
                                             <span>Pole jest aktywne kiedy wybierzesz pole mnożnika x2</span>
                                         </v-tooltip>
                                     </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="ratePerKm"  disabled label="Stawka za 1km"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-textarea v-model="editedItem.comment" label="Uwagi"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-7">
                            <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-show="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do listy wszystkich przejazdow</span></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="primary" rounded @click="save">Dodaj przejazd</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogNewSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span class="headline">Poprawnie dodałeś przejazd</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        {{ editedItem.start }} {{ editedItem.destination }} {{editedItem.date }} | {{ editedItem.km }}km
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich przejazdów</v-btn>
                                    </v-col>
                                    <v-col sm="12">
                                        lub
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherTrip">Chcę dodać kolejny przejazd</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie przejazd</span>
                                        <span v-else class="headline">Usunąłeś poprawnie przejazdy</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        <div v-if="selected.length > 0">
                                            <div v-for="item in selected">
                                                {{ item.start }} {{ item.destination }} {{ item.date }} | {{ item.km }}km
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.date="{ item }">
            {{ new Date(item.date).toISOString().substr(0, 10) }}
        </template>
        <template v-slot:item.status="{ item }">
            <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
        </template>
      <template v-slot:item.km="{ item }">
        {{ item.return_to_start == "1" ? (2 * item.km) : item.km  }}
      </template>
        <template v-slot:item.action="{ item }">
            <v-icon
                    v-if="addingPossible"
                    small
                    class="mr-2"
                    @click="editItem(item)"
            >
                edit
            </v-icon>
        </template>
        <template v-slot:no-data>
            Brak przejazdow
        </template>
        <template v-slot:footer>
          <TripsReportStatus :personalSurname="personalSurname" :personalName="personalName" :addressStreet="addressStreet" :addressHouseNr="addressHouseNr" :addressHomeNr="addressHomeNr" :addressZip="addressZip" :addressCity="addressCity" :ratePerKm="ratePerKm" :username="username" :current-month-readable="currentMonthReadable"/>
        </template>
    </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import moment from "moment";
import SelectMonths from "./SelectMonths";
import TripsReportStatus from "./TripsReportStatus";

import {createHelpers} from "vuex-map-fields";

const { mapFields } = createHelpers({
    getterType: 'registration/getPersonal',
  });

  export default {
    components: { TripsReportStatus, SelectMonths },
    mixins: [validationMixin],

    validations: {
      editedItem: {
        cid: {required},
        start: {required},
        destination: {required}
      }
    },
    name: "Trips",
    computed: {
      ...mapGetters({
        trips: 'trips/getTrips',
        tripsReport: 'trips/getTripsReport',
        dialogFromStore: 'trips/dialogNew',
        monthSelected: 'monthSelected',
        ratePerKmFromStore: 'users/getRatePerKm',
        cars: 'cars/getCars',
        userRole: 'users/userRole',
        loggedInUser: 'users/getLoggedInUser',
      }),
      ...mapFields({
        // personal
        personalSurname: 'personal.surname',
        personalName: 'personal.name',
        addressStreet: 'address.street',
        addressHouseNr: 'address.houseNr',
        addressHomeNr: 'address.homeNr',
        addressZip: 'address.zip',
        addressCity: 'address.city',
      }),
      ratePerKm () {
        return this.ratePerKmFromStore/100
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Dodaj nowy przejazd' : 'Edytuj przejazd'
      },
      carErrors () {
        const errors = [];
        if (!this.$v.editedItem.cid.$dirty) return errors;
        !this.$v.editedItem.cid.required && errors.push('Podanie pojazdu jest wymagane');
        return errors
      },
      startErrors () {
        const errors = [];
        if (!this.$v.editedItem.start.$dirty) return errors;
        !this.$v.editedItem.start.required && errors.push('Podanie miejsca wyjazdu jest wymagane');
        return errors
      },
      destinationErrors () {
        const errors = [];
        if (!this.$v.editedItem.destination.$dirty) return errors;
        !this.$v.editedItem.destination.required && errors.push('Podanie miejsca przyjazdu jest wymagane');
        return errors
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          if (this.cars.length == 0 && (this.userRole == 0 || this.userRole == 5)) {
            this.setCarDialogNew(true);
            this.$store.commit('setActiveSettingsTab', 2)
            this.$router.push('/settings')
          } else {
            return this.setDialogNew(value)
          }
        }
      },
      addingPossible () {
        if (this.tripsReport == 0 || this.userRole == 1) {
          return true
        } else {
          return false
        }
      },
      currentMonthReadable () {
        return moment(this.monthSelected).format('MMMM YYYY')
      }
    },
    created() {
      this.$store.dispatch('trips/getTrips', { 'start': moment(this.monthSelected).startOf('month').format('YYYY-MM-DD'), 'end': moment(this.monthSelected).endOf('month').format('YYYY-MM-DD')});
      this.$store.dispatch('cars/getCars');
      this.editedItem.date = this.monthSelected;
      // this.$store.dispatch('setMonthSelected', moment().startOf('month').format('YYYY-MM-DD'));

      this.unwatch = this.$store.watch(
        (state, getters) => getters.monthSelected,
        (newValue) => {
          this.editedItem.date = this.monthSelected;
          this.$store.dispatch(
            'trips/getTrips',
            {
              'start': moment(newValue).startOf('month').format('YYYY-MM-DD'),
              'end': moment(newValue).endOf('month').format('YYYY-MM-DD') }
          );
        },
      );
    },
    // There is one caveat once using Vuex watch: it returns an unwatch function that should be called in your beforeDestroy hook if you want to stop the watcher. If you don't call this function, the watcher will still be invoked which is not the desired behavior.
    beforeDestroy() {
      this.unwatch();
    },
    data: () => ({
      selected: [],
      menuDate: false,
      menuReturnDate: false,
      dialogNewSuccess: false,
      dialogRemoveSuccess: false,
      headers: [
        {
          text: 'Skąd',
          align: 'left',
          sortable: false,
          value: 'start',
        },
        { text: 'Dokąd', value: 'destination' },
        { text: 'Przejechane KM', value: 'km' },
        { text: 'Data przejazdu', value: 'date' },
        { text: '', value: 'action', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        cid: '',
        start: '',
        destination: '',
        km: 0,
        return_to_start: false,
        date: new Date().toISOString().substr(0, 10),
        return_date: new Date().toISOString().substr(0, 10),
        comment: ''
      },
      defaultItem: {
        cid: '',
        start: '',
        destination: '',
        km: 0,
        return_to_start: false,
        date: new Date().toISOString().substr(0, 10),
        return_date: new Date().toISOString().substr(0, 10),
        comment: ''
      },
      singleSelect: false
    }),
    watch: {
      dialogNewSuccess (val) {
        val || setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.date = this.monthSelected;
          this.editedIndex = -1
        }, 300)
      },
    },
    methods: {
      ...mapActions({
        setDialogNew: 'trips/setDialogNew',
        createTrip: 'trips/createTrip',
        editTrip: 'trips/editTrip',
        deleteTrips: 'trips/deleteTrips',
        cloneTrip: 'trips/cloneTrip',
        createReport: 'trips/createReport',
        setCarDialogNew: 'cars/setDialogNew',
      }),
      allowedDates(val) {
        if(this.userRole == 1) {
          return true;
        } else {
          // possible last month
          if (moment().date() <= 5 || this.loggedInUser.id === 6656) {
            return (moment(val).month() === moment().month()) || (moment(val).month() === moment().subtract(1, 'month').month());
          } else { // only current month
            return moment(val).month() === moment().month();
          }
        }
      },
      editItem (item) {
        this.editedIndex = this.trips.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true
      },

      close () {
        this.dialog = false
      },
      closeDialogNewSuccessAndAddAnotherTrip () {
        this.dialogNewSuccess = false;
        this.setDialogNew(true)
      },
      closeDialogRemoveSuccess () {
        this.dialogRemoveSuccess = false;
        this.selected = []
      },
      save () {
        this.$v.$touch();
        if (this.editedIndex > -1) {
          if (!this.$v.$invalid) {
            this.editTrip(this.editedItem).then(response => {
              if (response == 200) {
                this.$log.info('Saved edited trip', response);
                Object.assign(this.trips[this.editedIndex], this.editedItem);
                this.close()
              }
            }).catch(error => {
              this.$log.error('Saving edited trip error', error)
            })
          }
        } else {
          if (!this.$v.$invalid) {
            this.createTrip(this.editedItem).then(response => {
                if (response == 200) {
                  this.$log.info('Saved new trip', response);
                  this.close();
                  this.dialogNewSuccess = true
                }
            }).catch(error => {
              this.$log.error('Saving new trip error', error)
            })
          }
        }
      },
      removeSelected () {
        if (this.selected.length == 0) {
          return false
        }
        if(confirm('Czy na pewno chcesz usunąć ten przejazd?')){
          this.deleteTrips(this.selected).then(response => {
            this.$log.info('Deleted trip', response);
            this.dialogRemoveSuccess = true
          }).catch(error => {
            this.$log.error('Deleting trip error', error)
          })
        }
      },
      cloneSelected () {
        if (!this.selected.length > 0) {
          return false
        }
        this.cloneTrip(this.selected[0]).then(response => {
          this.$log.info('Cloned trip', response)
        }).catch(error => {
          this.$log.error('Cloning trip error', error)
        })
      }
    }
  }
</script>

<style scoped>
    .toPrint {
        display: none;
    }
</style>
