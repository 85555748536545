<template>
    <v-container>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="schoolName"
                        label="Nazwa uczelni"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="address"
                        label="Adres"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <v-text-field
                        v-model="idNumber"
                        label="Numer legitymacji"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields } = createHelpers({
    getterType: 'registration/getStudent',
    mutationType: 'registration/updateStudent',
  });

  export default {
    name: 'Student',
    computed: {
      ...mapFields([
        'student.schoolName',
        'student.address',
        'student.idNumber',
      ]),
    }
  }
</script>
