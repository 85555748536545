<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <div class="title"><strong>Dane firmy</strong></div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="6">
        <v-text-field
            v-model="name"
            label="Nazwa"
            dense
            :error-messages="nameErrors"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-text-field
            v-model="city"
            label="Miejscowość"
            dense
            :error-messages="cityErrors"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-text-field
            v-model="street"
            label="Ulica"
            dense
            :error-messages="streetErrors"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-text-field
            v-model="houseNr"
            label="Numer domu"
            dense
            :error-messages="houseNrErrors"
            required
        ></v-text-field>
      </v-col>
      <v-col sm="6">
        <v-text-field
            v-model="homeNr"
            label="Numer lokalu"
            dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-text-field
            v-model="zip"
            label="Kod pocztowy"
            dense
            :error-messages="zipErrors"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-text-field
            v-model="post"
            label="Poczta"
            dense
            :error-messages="postErrors"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-text-field
            v-model="nip"
            label="NIP"
            :error-messages="nipErrors"
            dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-text-field
            v-model="accountNumber"
            label="Numer firmowego rachunku bankowego"
            :error-messages="accountNumberErrors"
            dense
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'registration/getCompany',
  mutationType: 'registration/updateCompany',
});

export default {
  name: "Company",
  props: [
    'nameErrors',
    'nipErrors',
    'accountNumberErrors',
    'cityErrors',
    'streetErrors',
    'houseErrors',
    'zipErrors',
    'postErrors',
  ],
  data: () => ({
  }),
  computed: {
    ...mapFields([
      'company.name',
      'company.nip',
      'company.accountNumber',
      'company.city',
      'company.street',
      'company.houseNr',
      'company.homeNr',
      'company.zip',
      'company.post',
    ]),
  },
}
</script>

<style scoped>

</style>
