import Api from './Api'

export default {
  getEvaluations (parameters = null) {
    return Api().get('/api/evaluations?' + (parameters.date ? '&date=' + parameters.date : '') + (parameters.cid ? '&cid=' + parameters.cid : ''), { withCredentials: true })
  },
  getEvaluationsJoined (parameters = null) {
    return Api().get('/api/evaluations/joined?' + (parameters.date ? '&date=' + parameters.date : '') + (parameters.cid ? '&cid=' + parameters.cid : '') + (parameters.sid ? '&sid=' + parameters.sid : '') + (parameters.half ? '&half=' + parameters.half : ''), { withCredentials: true })
  },
  getEvaluationsByUserId (userId) {
    return Api().get('/api/evaluations/user/' + userId, { withCredentials: true })
  },
  saveGrade (evaluationSubject) {
    return Api().post('/api/evaluations/saveGrade', evaluationSubject)
  }
}
