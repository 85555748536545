import evaluation from '../../services/Evaluation'

// initial state
const state = {
  evaluations: [],
  evaluationsByUserId: [],
  loggedInStudent: null,
  loggedInStudentAvatarReload: 0,
  dialogNew: false,
  chosenEvaluation: null,
  protocolTypeSelected: null,
  evaluationExpandCourse: []
}

// getters
let sortByUniqueNames = function () {
  return state.evaluationsByUserId.sort((a, b) => {
    let fa = a.semester,
      fb = b.semester;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  }).filter((tag, index, array) => array.findIndex(t => t.name == tag.name) == index)
};
const getters = {
  getEvaluations: state => state.evaluations.map((item, key) => ({...item, key})),
  getEvaluationsBySubjectId: state => subjectId => state.evaluations.filter(x => x.subjectId == subjectId).map((item, key) => ({...item, key})),
  getEvaluationsByUserId: state => state.evaluationsByUserId,
  getEvaluationsByUserIdUniqueNames: state => sortByUniqueNames(),
  getEvaluationsByUserIdUniqueSemester: state => state.evaluationsByUserId.filter((tag, index, array) => array.findIndex(t => t.name == tag.name && t.semester == tag.semester) == index).sort((a, b) => {
    let fa = a.semester,
      fb = b.semester;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  }),
  getEvaluationsByUserIdUniqueSemesters: state => courseId => state.evaluationsByUserId.filter(x => x.cid == courseId).filter((tag, index, array) => array.findIndex(t => t.name == tag.name && t.semester == tag.semester) == index).sort((a, b) => {
    let fa = a.semester,
      fb = b.semester;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  }),
  getEvaluationsByUserIdAndEvaluation: state => eid => state.evaluationsByUserId.filter(x => x.eid == eid),
  getEvaluationsByUserIdAndCourse: state => cid => state.evaluationsByUserId.filter(x => x.cid == cid),
  dialogNew: state => state.dialogNew,
  getChosenEvaluationData: state => state.evaluations.find(item => item.id == state.chosenEvaluation),
  getChosenEvaluation: state => state.chosenEvaluation,
  getSemesterNumberByChosenEvaluation: state => state.evaluations.find(item => item.id == state.chosenEvaluation).semester,
  protocolTypeSelected: state => state.protocolTypeSelected,
  getEvaluationExpandCourse: state => state.evaluationExpandCourse
}

// actions
const actions = {
  clearEvaluations({ commit }) {
    commit('setEvaluations', []);
  },
  getEvaluations ({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      evaluation.getEvaluations(parameters).then(response => {
        if(response.status == 200) {
          commit('setEvaluations', response.data);
          resolve();
        }
      }).catch(() => {
        commit('setEvaluations', []);
        resolve();
      });
    }).catch((error) => { console.log(error) });
  },
  getEvaluationsJoined ({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      evaluation.getEvaluationsJoined(parameters).then(response => {
        if(response.status == 200) {
          commit('setEvaluations', response.data);
          resolve();
        }
      }).catch(() => {
        commit('setEvaluations', []);
        resolve();
      });
    }).catch((error) => { console.log(error) });
  },
  getEvaluationsByUserId ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      evaluation.getEvaluationsByUserId(userId).then(response => {
        if(response.status == 200) {
          commit('setEvaluationsByUserId', response.data);
          resolve();
        }
      }).catch(() => {
        commit('setEvaluationsByUserId', []);
        resolve();
      });
    }).catch((error) => { console.log(error) });
  },
  saveGrade ({ commit }, evaluationSubject) {
    return new Promise((resolve, reject) => {
      evaluation.saveGrade(evaluationSubject).then(response => {
        if(evaluationSubject.uid == undefined || evaluationSubject.uid == null) { // user was selected, so deal only with sid
          if (response.data.message == 'Evaluation subject was deleted successfully!') {
            resolve(200);
            commit('setEvaluationByUserId', {...evaluationSubject, id: null, grade: ''})
          } else {
            resolve(200);
            commit('setEvaluationByUserId', response.data)
          }
        } else { // user was not selected, uid need to be passed
          if (response.data.message == 'Evaluation subject was deleted successfully!') {
            resolve(200);
            commit('setEvaluationGeneralList', {...evaluationSubject, esid: null, grade: ''})
          } else {
            resolve(200);
            commit('setEvaluationGeneralList', {...evaluationSubject, esid: response.data.id })
          }
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setChosenEvaluation({dispatch, commit}, value) {
    commit('setChosenEvaluation', value)

    let chosenEvaluation = state.evaluations.find(item => item['id'] == value)
    if(chosenEvaluation.registration_form !== null && chosenEvaluation.registration_form !== undefined) {
      dispatch('registrationStudent/setRegistrationFromJson', chosenEvaluation.registration_form, {root: true})
    } else {
      dispatch('registrationStudent/clearRegistrationForm', true, {root: true})
    }
  },
  resetChosenEvaluation({dispatch, commit}) {
    dispatch('registrationStudent/clearRegistrationForm', true, {root: true})
    commit('setChosenEvaluation', null)
    commit('setEvaluationExpandCourse', null)
  },
  setProtocolTypeSelected({commit}, value) {
    commit('setProtocolTypeSelected', value)
  }
}

// mutations
const mutations = {
  setEvaluations (state, evaluations) {
    state.evaluations = evaluations
  },
  setEvaluationsByUserId (state, evaluations) {
    state.evaluationsByUserId = evaluations
    let chosenEvaluation = state.evaluationsByUserId.find(item => item['eid'] == state.chosenEvaluation);
    if (chosenEvaluation) {
      state.evaluationExpandCourse = sortByUniqueNames().findIndex(x => x.cid == chosenEvaluation.cid);
    }
  },
  setEvaluationByUserId (state, evaluationSubject) {
    let index = state.evaluationsByUserId.findIndex(x => x.subject_id == evaluationSubject.sid);
    state.evaluationsByUserId[index].id = evaluationSubject.id;
    state.evaluationsByUserId[index].esid = evaluationSubject.id;
    state.evaluationsByUserId[index].grade = evaluationSubject.grade;
  },
  setEvaluationGeneralList (state, evaluationSubject) {
    let index = state.evaluations.findIndex(x => x.subjectId == evaluationSubject.sid && x.uid == evaluationSubject.uid);
    state.evaluations[index].id = evaluationSubject.eid;
    state.evaluations[index].esid = evaluationSubject.esid;
    state.evaluations[index].grade = evaluationSubject.grade;
  },
  setEvaluation (state, user) {
    state.loggedInStudent = user
  },
  addEvaluation (state, user) {
    state.evaluations.push(user)
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setChosenEvaluation (state, user) {
    state.chosenEvaluation = user
  },
  setEvaluationExpandCourse (state, expandedIndex) {
    state.evaluationExpandCourse = expandedIndex;
  },
  setProtocolTypeSelected (state, type) {
    state.protocolTypeSelected = type
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
