<template>
  <v-data-table
      v-model="selected"
      :headers="headers"
      :items="meetings"
      :loading="loading"
      :single-select=singleSelect
      class="elevation-1 ma-4"
      show-select
      :custom-sort="customSort"
  >
    <template v-slot:top>
      <v-toolbar color="white" flat>
        <v-toolbar-title>Nadchodzące spotkania</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn v-if="userRole === 1 || userRole === 7" :disabled="!selected.length" class="mb-2 ml-4" color="primary" rounded small @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone spotkania</v-btn>
        <OnlineMeetingCreateGroup @meetingCreated="meetingCreated" @meetingError="meetingError"></OnlineMeetingCreateGroup>
        <OnlineMeetingCreate @meetingCreated="meetingCreated" @meetingError="meetingError"></OnlineMeetingCreate>
        <OnlineMeetingCreateSuccess :dialog-new-success="dialogNewSuccess" @closedDialog="dialogNewSuccess = false"></OnlineMeetingCreateSuccess>
        <OnlineMeetingCreateError :dialog-error="dialogError" @closedDialog="dialogError = false"></OnlineMeetingCreateError>
      </v-toolbar>
      <v-toolbar color="white" flat>
        <v-row>
          <v-col cols="12" md="6">
            <SelectCourses @courseChanged="courseChanged"/>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>

    <template v-slot:item.start_date="{ item }">
      {{ new Date(item.settings.start_time).toISOString().substr(0, 10) }}
    </template>
    <template v-slot:item.start_time="{ item }">
      {{ getStartTime(item.settings.start_time) }}
    </template>
    <template v-slot:item.start_url="{ item }">
      <a v-if="item.start_url" :href="item.start_url" target="_blank">{{ 'Rozpocznij spotkanie' }}</a>
      <a v-if="item.settings.join_url" :href="item.settings.join_url" class="ml-4" target="_blank">{{ 'Dołącz jako uczestnik' }}</a>
    </template>
    <template v-slot:item.course="{ item }">
      {{ getCourseNameById(item.cid) }}
    </template>
    <template v-slot:item.subject="{ item }">
      {{ getSubjectNameById(item.sid) }}
    </template>
    <template v-slot:item.uid="{ item }">
      {{ item.username ? item.username : 'Sekretariat' }}
    </template>
    <template v-slot:no-data>
      Brak spotkań
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import OnlineMeetingCreate from "../components/OnlineMeeting/OnlineMeetingCreate.vue";
import OnlineMeetingCreateGroup from "../components/OnlineMeeting/OnlineMeetingCreateGroup.vue";
import OnlineMeetingCreateSuccess from "../components/OnlineMeeting/OnlineMeetingCreateSuccess.vue";
import OnlineMeetingCreateError from "../components/OnlineMeeting/OnlineMeetingCreateError.vue";
import SelectCourses from "./SelectCourses.vue";
import moment from "moment";


export default {
  name: "OnlineMeetings",
  components: { OnlineMeetingCreate, OnlineMeetingCreateGroup, OnlineMeetingCreateSuccess, OnlineMeetingCreateError, SelectCourses },
  computed: {
    ...mapGetters({
      meetings: 'onlineMeetings/getMeetings',
      getCourseNameById: 'courses/getCourseNameById',
      getSubjectNameById: 'subjects/getSubjectNameById',
      getNameById: 'users/getNameById',
      userRole: 'users/userRole',
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
  },
  data: () => ({
    loading: true,
    selected: [],
    headers: [
      { text: 'Data', value: 'start_date' },
      { text: 'Godzina rozpoczęcia', value: 'start_time', sortable: false },
      { text: 'Kierunek', value: 'course', sortable: false},
      { text: 'Przedmiot', value: 'subject', sortable: false},
      { text: 'Rok/semestr', value: 'semester'},
      { text: 'Prowadzący', value: 'uid', sortable: false},
      { text: 'Link', value: 'start_url', sortable: false}
    ],
    dialogNewSuccess: false,
    dialogError: false
  }),
  created() {
    if (this.userRole === 3) {
      this.$store.dispatch('subjects/getSubjects');
    }

    if (this.userRole == 7 || this.userRole == 1) {
      this.$store.dispatch('users/getUsers');
    }

    this.$store.dispatch('courses/getCourses');
    this.$store.dispatch('onlineMeetings/getMeetings').then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions({
      deleteOnlineMeetings: 'onlineMeetings/deleteOnlineMeetings'
    }),
    meetingCreated(value) {
      this.dialogNewSuccess = value;
    },
    meetingError(value) {
      this.dialogError = value;
    },
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć te spotkania?')){
        this.deleteOnlineMeetings(this.selected).then(response => {
          this.$log.info('Deleted online meeting', response);
          this.dialogRemoveSuccess = true
        }).catch(error => {
          this.$log.error('Deleting online meeting error', error)
        })
      }
    },
    courseChanged(courseIds) {
      this.loading = true;
      this.$store.dispatch(
          'onlineMeetings/getMeetings',
          {
            courseIds: courseIds
          }
      ).then(() => {
        this.loading = false;
      });
    },
    getStartTime(startTime) {
      return moment(startTime).format('HH:mm')
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "start_date") {
          // console.log(a);
          if (!isDesc) {
            return moment(a.settings.start_time).diff(b.settings.start_time);
          } else {
            return moment(b.settings.start_time).diff(a.settings.start_time);
          }
        } else if (index[0] == "semester") {
          if (!isDesc) {
            return a.semester - b.semester;
          } else {
            return b.semester - a.semester;
          }
        }
      });
      return items;
    }
  }
}
</script>

<style scoped>

</style>
