<template>
    <v-container>
        <v-card v-if="evaluationDataVisible == 'true'">
          <v-card-title>
            Dziennik / {{ evaluationData.username }}
            <v-btn color="grey" class="overline ml-5" text @click="$router.push('/evaluations')"><v-icon small left>arrow_back</v-icon> powrót do listy wszystkich studentów</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col v-for="(evaluation, index)  in getEvaluationsByUserIdUniqueNames" :key="evaluation" class="mb-0">
                  <div>Kierunek {{ index + 1 }}: {{ evaluation.name }}</div>
                  <div>semestr: {{ evaluation.date }}</div>
                </v-col>
                <v-col>
                  <div><v-icon left>mail</v-icon> {{ evaluationData.email }}<span v-if="userRole !== 1">xxxxxx</span></div>
                  <div><v-icon left>phone</v-icon> <span v-if="userRole !== 1">xxxxxx</span>{{ evaluationData.telephone }}</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="mt-0 mr-0 text-right">
                  <span v-for="(evaluation, index) in getEvaluationsByUserIdUniqueNames" :key="evaluation">
                    <v-btn class="mb-4 ml-4" color="primary" dark rounded @click="generateAchievementCard(evaluation, true)">Karta okresowych osiągnięć kierunku {{ index + 1 }}</v-btn>
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      <v-card>
        <v-expansion-panels
        v-model="getEvaluationExpandCourse"
        >
          <ProfessorEvaluationsCourse
              v-for="item in getEvaluationsByUserIdUniqueNames"
              :key="item.semester"
              :evaluation-data-visible="evaluationDataVisible"
              :get-evaluations-by-user-id-unique-semesters="getEvaluationsByUserIdUniqueSemesters"
              :item="item"
              :logged-in-user="loggedInUser"
              :tab-active="tabActive"
          />
        </v-expansion-panels>
      </v-card>
      <Message @closeDialogMessage="closeDialogMessage" :showDialog="dialogMessage" :receiver="{ email: email }" />
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {createHelpers} from "vuex-map-fields";
import Message from "../components/Message";
import ProfessorEvaluationsCourse from "./ProfessorEvaluationsCourse.vue";

const moment = require('moment')


const { mapFields } = createHelpers({
  getterType: 'registrationStudent/getPersonal',
  mutationType: 'registrationStudent/updatePersonal',
});

export default {
  name: "ProfessorEvaluations",
  props: {'uid': Number, 'evaluationDataVisible': Boolean },
  components: {ProfessorEvaluationsCourse, Message },
  data () {
      return {
        tab: null,
        dialogMessage: false,
        tabActive: 0,
        changedGrade: [],
        isBusy: false
      }
    },
    methods: {
      ...mapActions({
        studentEvaluations: 'evaluations/getEvaluationsByUserId',
        saveGrade: 'evaluations/saveGrade',
        resetChosenEvaluation: 'evaluations/resetChosenEvaluation'
      }),
      enableSave(subjectId) {
        this.changedGrade.push(subjectId);
      },
      ruleHalf (value) {
        if ((value % 0.5) == 0 || value === '') {
          return true;
        } else {
          return "Proszę podać tylko pełne oceny, lub połówki ocen";
        }
      },
      ruleMax (value) {
        if (value <= 5 || value === '') {
          return true;
        } else {
          return "Ocena powyżej 5 nie dopuszczalna";
        }
      },
      ruleMin (value) {
        if (value >= 2 || value === '') {
          return true;
        } else {
          return "Ocena poniżej 2 nie dopuszczalna";
        }
      },
      newMessage () {
        this.dialogMessage = true
      },
      save(value, esid, sid, eid) {
        if ((value <=5 && value >= 2 && value % 0.5 == 0) || value === '') {
          this.isBusy = true;
          this.saveGrade({ id: esid, eid: eid, sid: sid, grade: value}).then((res) => {
            if (res == 200) {
              this.changedGrade = this.changedGrade.filter(item => item !== sid);
              this.isBusy = false;
            }
          });
        }
      },
      generateAchievementCard (item, all = false) {
        // console.log(item);
        // return;
        var pdfMake = require('pdfmake/build/pdfmake.js');
        if (pdfMake.vfs == undefined){
          var pdfFonts = require('pdfmake/build/vfs_fonts.js');
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        // console.log('geerate');
        let evaluations = [];
        let amountOfSemesters = 1
        if (all === true) {
          evaluations = this.getEvaluationsByUserIdAndCourse(item.cid);
          evaluations.forEach(x => {
            if(x.semester > amountOfSemesters) {
              amountOfSemesters = x.semester
            }
          });
        } else {
          evaluations = this.getEvaluationsByUserIdAndEvaluation(item.eid);
        }

        var tableHeader = [];
        tableHeader.push([
          { text: "L.p.", rowSpan: 2, style: 'header' },
          { text: "Przedmiot", rowSpan: 2, style: 'header' },
          { text: "Egzaminator", rowSpan: 2, style: 'header' },
          { text: "Zaliczenie", colSpan: 2, style: 'header' },
          { text: "" },
          { text: "Egzamin", colSpan: 2, style: 'header' },
          { text: "" },
        ]);
        tableHeader.push([
          { text: "L.p.", style: 'header' },
          { text: "Przedmiot", style: 'header' },
          { text: "Egzaminator", style: 'header' },
          { text: "Ocena", style: 'header' },
          { text: "Data", style: 'header' },
          { text: "Ocena", style: 'header' },
          { text: "Data", style: 'header' },
        ]);

        let evaluationSum = 0;
        let subjectAmount = 0;
        evaluations.forEach(x => {
          if(x.grade) {
            evaluationSum += x.grade;
            subjectAmount++
          }
        });
        let average = Math.round((evaluationSum/subjectAmount + Number.EPSILON) * 100) / 100;


        function examDate(d, semester) {
          let amount = moment(d).format('M') == 10 ? 4 : 3
          let fm = moment(d).add(amount, 'M');
          let fmEnd = moment(fm).endOf('month');
          return fmEnd.format('DD.MM.YYYY');
        }

        // console.log(examDate(moment('2022-03-01'), 3))

        let header = [
            {
              columns: [
                {
                  text: 'Akademia Nauk Stosowanych Wincentego Pola w Lublinie',
                  width: '50%',
                  style: 'contentTop'
                },
                {
                  text: 'Rok akademicki ' + (all === true || moment(evaluations[0].date).format('M') == 10) ? (moment(evaluations[0].date).format('YYYY') + '/' + moment(evaluations[0].date).add(1, 'Y').format('YYYY')) : (moment(evaluations[0].date).subtract(1, "Y").format('YYYY') + '/' + moment(evaluations[0].date).format('YYYY')),
                  style: 'rightTop'
                },
              ]
            },
            {
              stack: [
                'KARTA OKRESOWYCH OSIĄGNIĘĆ STUDENTA ',
              ],
              style: 'title'
            },
            {
              columns: [
                {
                  text: 'Typ studiów:',
                  width: '20%',
                  style: 'contentTop'
                },
                {
                  text: 'studia podyplomowe'
                },
              ]
            },
            {
              columns: [
                {
                  text: 'Zakres:',
                  width: '20%',
                  style: 'contentTop'
                },
                {
                  text: evaluations[0].name,
                  style: 'boldUnderline'
                },
              ]
            },
            {
              columns: [
                {
                  text: 'Słuchacz/sluchaczka (imię i nazwisko):',
                  width: '40%',
                  style: 'contentTop'
                },
                {
                  text: this.evaluationData.username,
                  style: 'titleLeft'
                },
              ]
            }
            ];

        var tableBody = [];
        var content = '';

        for (let semesterNumber = 1 ; semesterNumber <= amountOfSemesters; semesterNumber++) {
          tableBody[semesterNumber] = tableHeader.concat(evaluations.filter(x => x.semester == semesterNumber).map(
              (obj, index) => [
                    {text: index + 1, style: 'content'},
                    {text: obj.subject_name, style: 'contentLeft'},
                    {text: (obj.professor_degree ? obj.professor_degree + ' ' : '') + obj.professor_name, style: 'content'},
                    {
                      text: obj.exam == 0 && obj.grade ? (Number.isInteger(obj.grade) ? obj.grade + '.0' : obj.grade) : '',
                      style: 'content'
                    },
                    {
                      text: obj.exam == 0 && obj.grade ? (examDate(moment(obj.date), obj.semester)) : '',
                      style: 'content'
                    },
                    {
                      text: obj.exam == 1 && obj.grade ? (Number.isInteger(obj.grade) ? obj.grade + '.0' : obj.grade) : '',
                      style: 'content'
                    },
                    {
                      text: obj.exam == 1 && obj.grade ? (examDate(moment(obj.date), obj.semester)) : '',
                      style: 'content'
                    },
                  ]
          ));

          content = [
            header,
            {
              stack: [
                'Semestr I (pierwszy)',
              ],
              style: 'semester'
            },
            {
              table: {
                widths: [15, 180, 95, 25, 55, 25, 55],
                headerRows: 2,
                body: tableBody[1]
              }
            },
            {
              stack: [
                'Zatwierdzam zgodność ocen z protokołami',
              ],
              style: 'sign'
            },
            {
              stack: [
                '.......................................',
              ],
              style: 'sign'
            },
            { text: '', fontSize: 14, bold: true, pageBreak: 'before', margin: [0, 0, 0, 8] },
            JSON.parse(JSON.stringify(header)),
            {
              stack: [
                'Semestr II (drugi)',
              ],
              style: 'semester'
            },
            JSON.parse(JSON.stringify({
              table: {
                widths: [15, 180, 95, 25, 55, 25, 55],
                headerRows: 2,
                body: tableBody[2]
              }
            })),
            {
              stack: [
                'Zatwierdzam zgodność ocen z protokołami',
              ],
              style: 'sign'
            },
            {
              stack: [
                '.......................................',
              ],
              style: 'sign'
            }
          ];

          if (amountOfSemesters == 3) {
            content.push([
                { text: '', fontSize: 14, bold: true, pageBreak: 'before', margin: [0, 0, 0, 8] },
                JSON.parse(JSON.stringify(header)),
              {
                stack: [
                  'Semestr III (trzeci)',
                ],
                style: 'semester'
              },
                JSON.parse(JSON.stringify({
                  table: {
                    widths: [15, 180, 95, 25, 55, 25, 55],
                    headerRows: 2,
                    body: tableBody[3]
                  }
                })),
              {
                stack: [
                  'Zatwierdzam zgodność ocen z protokołami',
                ],
                style: 'sign'
              },
              {
                stack: [
                  '.......................................',
                ],
                style: 'sign'
              },
                ]
            )
          }

        }

        var docDefinition = {
          content: [
              ...content,
            {
              stack: [
                'Średnia: ' + average,
              ],
              style: 'contentBottom'
            }
          ],
          styles: {
            title: {
              fontSize: 15,
              bold: true,
              margin: [0, 20, 0, 20],
              alignment: "center"
            },
            titleLeft: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
              alignment: "left"
            },
            header: {
              fontSize: 8,
              bold: true,
              margin: [0, 0, 0, 0],
              alignment: "center"
            },
            semester: {
              fontSize: 15,
              bold: true,
              margin: [0, 0, 0, 10],
              alignment: "center"
            },
            contentTop: {
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            rightTop: {
              fontSize: 10,
              alignment: "right"
            },
            subtitle: {
              fontSize: 9,
              margin: [0, 0, 0, 10],
            },
            content: {
              fontSize: 8,
              margin: [0, 0, 0, 0],
              alignment: "center"
            },
            boldUnderline: {
              bold: true,
              decoration: 'underline'
            },
            contentLeft: {
              fontSize: 8,
              margin: [0, 0, 0, 10],
              alignment: "left"
            },
            contentBottom: {
              fontSize: 10,
              margin: [0, 10, 0, 10],
            },
            footer: {
              alignment: "right",
              margin: [0, 20, 0, 20]
            },
            sign: {
              alignment: "right",
              margin: [0, 20, 0, 0]
            }
          }
        };

        pdfMake.createPdf(docDefinition).download('unikadra-karta-osiagniec-studenta.pdf')
      },
      closeDialogMessage(val) {
        this.dialogMessage = val;
      },
    },
    computed: {
      ...mapGetters({
        evaluationData: 'evaluations/getChosenEvaluationData',
        getChosenEvaluation: 'evaluations/getChosenEvaluation',
        getFormDataHistory: 'history/getFormDataHistory',
        getStudentEvaluations: 'evaluations/getEvaluationsByUserId',
        getEvaluationsByUserIdUniqueNames: 'evaluations/getEvaluationsByUserIdUniqueNames',
        getEvaluationsByUserIdUniqueSemester: 'evaluations/getEvaluationsByUserIdUniqueSemester',
        getEvaluationsByUserIdUniqueSemesters: 'evaluations/getEvaluationsByUserIdUniqueSemesters',
        getEvaluationsByUserIdAndEvaluation: 'evaluations/getEvaluationsByUserIdAndEvaluation',
        getEvaluationsByUserIdAndCourse: 'evaluations/getEvaluationsByUserIdAndCourse',
        loggedInUser: 'users/getLoggedInUser',
        getSemesterNumberByChosenEvaluation: 'evaluations/getSemesterNumberByChosenEvaluation',
        getEvaluationExpandCourse: 'evaluations/getEvaluationExpandCourse',
        userRole: 'users/userRole',
      }),
      ...mapFields({
        // personal
        surname: 'personal.surname',
        name: 'personal.name',
        secondName: 'personal.secondName',
        placeOfBirth: 'personal.placeOfBirth',
        dayOfBirth: 'personal.dayOfBirth',
        // address
        city: 'address.city',
        street: 'address.street',
        houseNr: 'address.houseNr',
        homeNr: 'address.homeNr',
        zip: 'address.zip',
        // contact
        email: 'contact.email',
        telephoneNr: 'contact.telephoneNr'
      }),
    },
    created() {
      this.studentEvaluations(this.uid).then(response => {
        if(response == 200) {
          if (this.getChosenEvaluation) {
            // console.log(this.evaluationExpandCourse)
            // console.log(this.getSemesterNumberByChosenEvaluation)
            this.tabActive = (this.getSemesterNumberByChosenEvaluation - 1)
          } else {
            this.tabActive = 0;
          }
        }
      });

    },
    beforeDestroy() {
      this.resetChosenEvaluation();
    }
  }
</script>

<style scoped>
.v-text-field{
  width: 100px;
}
.v-input--is-readonly {
  background-color: #EEEEEE;
}
</style>
