import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pl from 'vuetify/es5/locale/pl';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#493DCE',
        secondary: '#9B9B9B',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#493DCE',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#9B9B9B',
        top: '#EEEEEE'
      },
    },
  },
    lang: {
      locales: { pl },
      current: 'pl',
    },
});
