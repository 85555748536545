import notification from '../../services/Notification'

// initial state
const state = {
  items: [],
};

// getters
const getters = {
  getNotificationsForChat: state => state.items.filter(x => x.type == 1),
  getNotificationsNotForChat: state => state.items.filter(x => x.type !== 1),
  getAmountOfNotificationsForChat: state => state.items?.filter(x => x.status == 0 && x.type == 1).length,
  getAmountOfNotificationsNotForChat: state => state.items?.filter(x => x.status == 0 && x.type !== 1).length
};

// actions
const actions = {
  getNotifications ({ commit }) {
    return new Promise((resolve, reject) => {
      notification.getNotifications().then(response => {
        if(response && response.status == 200 && response.data) {
          commit('setNotifications', response.data);
          resolve(200);
        } else {
          reject();
        }
      }).catch((errors) => {
        reject(errors)
      });
    }).catch((error) => { console.log(error) });
  },
  deleteNotifications ({ commit }, deletedNotifications) {
    return new Promise((resolve, reject) => {
      notification.deleteNotifications(deletedNotifications.map(function(item) { return item["id"] })).then(response => {
        if (response.status == 200) {
          commit('deleteNotifications', deletedNotifications)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  setWatched({dispatch, commit}) {
    return new Promise((resolve, reject) => {
      notification.markWatched().then(() => {
        commit('setWatched');
        resolve(200)
      }).catch(() => {
      });
    }).catch((error) => { console.log(error) });
  },
  setWatchedChatUser({commit}, userId) {
    return new Promise((resolve, reject) => {
      notification.markWatchedForUser(userId).then(() => {
        commit('setWatchedByUserId', userId);
        resolve(200)
      }).catch(() => {
      });
    }).catch((error) => { console.log(error) });
  },
};

// mutations
const mutations = {
  setNotifications (state, notification) {
    state.items = notification
  },
  deleteNotifications (state, notification) {
    notification.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setWatched() {
    state.items.forEach(x => {
      x.status = 1;
    })
  },
  setWatchedByUserId(state, userId) {
    state.items.forEach(x => {
      if(x.uid_from == userId) {
        x.status = 1;
      }
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
