import Api from './Api'

export default {
  createReport (report, uId) {
    if (uId == null) {
      return Api().post('/api/reports', report)
    } else {
      return Api().post('/api/reports/user/' + uId, report);
    }
  },
  getReportsAll (period) {
    return Api().get('/api/reports/all?from=' + period.start + '&to=' + period.end, { withCredentials: true })
  },
  getReports (period, userId = null) {
    return Api().get('/api/reports?from=' + period.start + '&to=' + period.end + (userId?'&user='+userId:''), { withCredentials: true })
  },
}
