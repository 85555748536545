const UserRoleDescEnum = Object.freeze({
  0: 'Wykładowca',
  1: 'Administrator',
  2: 'Koordynator',
  3: 'Student',
  4: 'Pracownik administracji',
  5: 'Wykładowca i student',
  6: 'Księgowy i pracownik administracji',
  7: 'Zoom administrator', //( i pracownik administracji)
  8: 'Wykładowca MBA',
  10: 'Wszyscy',
  11: 'SuperAdmin'
});

export default UserRoleDescEnum;
