<template>
    <v-data-table
            :headers="headers"
            :items="places"
            sort-by="name"
            show-select
            :single-select=singleSelect
            class="elevation-1 ma-4"
            v-model="selected"
            v-if="userRole == 1"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Lista wszystkich placówek</v-toolbar-title>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn v-show="showExtraActions" :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone placówki</v-btn>
                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodawanie nowej placówki</v-btn>
                        <v-btn v-else color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodaj</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @blur="$v.editedItem.name.$touch()" label="Miejscowość"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.coordinator_username" :error-messages="coordinator_usernameErrors" @blur="$v.editedItem.coordinator_username.$touch()" label="Koordynator"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.organization" :error-messages="organizationErrors" @blur="$v.editedItem.organization.$touch()" label="Miejsce"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.address" :error-messages="addressErrors" @blur="$v.editedItem.address.$touch()" label="Adres"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.email" :error-messages="emailErrors" @blur="$v.editedItem.email.$touch()" label="Email"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.telephone" :error-messages="telephoneErrors" @blur="$v.editedItem.telephone.$touch()" label="Telefon"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="editedIndex !== -1">
                                  <v-col cols="12" sm="12" md="6">
                                    <v-switch
                                        v-model="editedItem.active"
                                        :false-value="0"
                                        :true-value="1"
                                        :label="`${editedItem.active ? 'Aktywny' : 'Nieaktywny'}`"
                                    ></v-switch>
                                  </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-7">
                            <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do listy wszystkich godzin pracy</span></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="primary" rounded @click="save">{{ editedIndex === -1 ? 'Dodaj placówkę': 'Zapisz zmiany'}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogNewSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span class="headline">Poprawnie dodałeś nową placówkę</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        {{ editedItem.name }}
                                    </v-col>
                                    <v-col sm="12">
                                        {{ editedItem.coordinator_username }}
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich placówek</v-btn>
                                    </v-col>
                                    <v-col sm="12">
                                        lub
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherPlace">Chcę dodać kolejną placówkę</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie placówkę</span>
                                        <span v-else class="headline">Usunąłeś poprawnie placówki</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        <div v-if="selected.length > 0">
                                            <div v-for="item in selected">
                                                {{ item.name }}
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.lp="{ item }">
            {{ item.id }}
        </template>
        <template v-slot:item.active="{ item }">
          <v-icon>{{ (item.active)?'check_circle_outline':'warning' }}</v-icon>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
            >
                edit
            </v-icon>
        </template>
        <template v-slot:no-data>
            Brak placówek
        </template>
    </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],

    validations: {
      editedItem: {
        name: { required },
        coordinator_username: { required },
        organization: { required },
        address: { required },
        email: { required, email },
        telephone: { required }
      }
    },
    name: "Places",
    computed: {
      ...mapGetters({
        places: 'places/getPlaces',
        dialogFromStore: 'places/dialogNew',
        userRole: 'users/userRole',
      }),
      buttonForRemove () {
        if (this.selected.length > 0) {
          return 'primary'
        } else {
          return 'secondary'
        }
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Dodawanie nowej placówki' : 'Edytuj placówkę'
      },
      nameErrors () {
        const errors = [];
        if (!this.$v.editedItem.name.$dirty) return errors;
        !this.$v.editedItem.name.required && errors.push('Podanie placówki jest wymagane');
        return errors
      },
      coordinator_usernameErrors () {
        const errors = [];
        if (!this.$v.editedItem.coordinator_username.$dirty) return errors;
        !this.$v.editedItem.coordinator_username.required && errors.push('Podanie koordynatora jest wymagane');
        return errors
      },
      organizationErrors () {
        const errors = [];
        if (!this.$v.editedItem.organization.$dirty) return errors;
        !this.$v.editedItem.organization.required && errors.push('Podanie miejsca jest wymagane');
        return errors
      },
      addressErrors () {
        const errors = [];
        if (!this.$v.editedItem.address.$dirty) return errors;
        !this.$v.editedItem.address.required && errors.push('Podanie adresu jest wymagane');
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.editedItem.email.$dirty) return errors
        !this.$v.editedItem.email.email && errors.push('Podany adres e-mail jest niepoprawny')
        !this.$v.editedItem.email.required && errors.push('Podanie e-maila jest wymagane')
        return errors
      },
      telephoneErrors () {
        const errors = [];
        if (!this.$v.editedItem.telephone.$dirty) return errors;
        !this.$v.editedItem.telephone.required && errors.push('Podanie telefonu jest wymagane');
        return errors
      },
      showExtraActions () {
        return this.$vuetify.breakpoint.mdAndUp
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          return this.setDialogNew(value)
        }
      },
    },
    data: () => ({
      selected: [],
      menuDate: false,
      dialogNewSuccess: false,
      dialogRemoveSuccess: false,
      headers: [
        { text: 'Widoczny', value: 'active' },
        { text: 'Miasto', value: 'name' },
        { text: 'Koordynator', value: 'coordinator_username' },
        { text: 'Miejsce', value: 'organization' },
        { text: 'Adres', value: 'address' },
        { text: 'Telefon', value: 'telephone' },
        { text: '', value: 'action', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        coordinator_username: '',
        organization: '',
        address: '',
        email: '',
        telephone: '',
        active: 1
      },
      defaultItem: {
        name: '',
        coordinator_username: '',
        organization: '',
        address: '',
        email: '',
        telephone: '',
        active: 1
      },
      singleSelect: false
    }),
    watch: {
      dialogNewSuccess (val) {
        val || setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1
        }, 300)
      },
      dialog (val) {
        val || setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1
        }, 300)
      },
    },
    methods: {
      ...mapActions({
        setDialogNew: 'places/setDialogNew',
        createPlace: 'places/createPlace',
        editPlace: 'places/editPlace',
        deletePlaces: 'places/deletePlaces',
      }),
      editItem (item) {
        this.editedIndex = this.places.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true
      },
      close () {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.date = this.monthSelected;
          this.editedIndex = -1
        });
      },
      closeDialogNewSuccessAndAddAnotherPlace () {
        this.dialogNewSuccess = false;
        this.setDialogNew(true)
      },
      closeDialogRemoveSuccess () {
        this.dialogRemoveSuccess = false;
        this.selected = []
      },
      save () {
        this.$v.$touch();
        if (this.editedIndex > -1) {
          if (!this.$v.$invalid) {
            this.editPlace(this.editedItem).then(response => {
                this.$log.info('Saved edited place', response);
                Object.assign(this.places[this.editedIndex], this.editedItem);
                this.close();
            }).catch(error => {
              this.$log.error('Saving edited place error', error)
            })
          }
        } else {
          if (!this.$v.$invalid) {
            this.createPlace(this.editedItem).then(response => {
                this.$log.info('Saved new place', response);
                this.close();
                this.dialogNewSuccess = true
            }).catch(error => {
              this.$log.error('Saving new place error', error)
            })
          }
        }
      },
      removeSelected () {
        if (this.selected.length == 0) {
          return false
        }
        if(confirm('Czy na pewno chcesz usunąć poniższy czas pracy?')){
          this.deletePlaces(this.selected).then(response => {
            this.$log.info('Deleted place', response);
            this.dialogRemoveSuccess = true
          }).catch(error => {
            this.$log.error('Deleting place error', error)
          })
        }
      },
    },
    created() {
      this.$store.dispatch('places/getPlaces');
    },
  }
</script>

<style scoped>

</style>
