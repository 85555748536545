import Api from './Api'

export default {
  createPlace (place) {
    return Api().post('/api/places', place)
  },
  getPlaces () {
    return Api().get('/api/places', { withCredentials: true })
  },
  getPlacesByUser () {
    return Api().get('/api/places/user', { withCredentials: true })
  },
  editPlace (placeData) {
    return Api().put('/api/places/' + placeData.id, placeData)
  },
  deletePlaces (places) {
    return Api().post('/api/places/delete-multiple', places)
  }
}
