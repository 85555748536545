import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=4dcb0d26&scoped=true"
import script from "./Settings.vue?vue&type=script&lang=js"
export * from "./Settings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dcb0d26",
  null
  
)

export default component.exports