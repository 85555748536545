import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Dashboard from '../views/Dashboard'
import Statistics from '../views/Statistics'
import Settings from '../views/Settings'
import Users from '../views/Users'
import AdministrativeStaff from '../views/AdministrativeStaff'
import Administrative from '../views/Administrative'
import StudentCourses from '../views/StudentCourses'
import Candidates from '../views/Candidates'
import Places from '../views/Places'
import Reports from '../views/Reports'
import AccountantSettlements from '../views/AccountantSettlements'
import AccountantSettlement from '../views/AccountantSettlement'
import AccountantStudents from '../views/AccountantStudents'
import AccountantStudent from '../views/AccountantStudent'
import User from '../views/User'
import Trips from '../views/Trips'
import Evaluations from '../views/Evaluations'
import EvaluationsJoined from '../views/EvaluationsJoined'
import Protocols from '../views/Protocols'
import ProfessorEvaluations from '../views/ProfessorEvaluations'
import ProfessorEvaluationsJoined from '../views/ProfessorEvaluationsJoined'
import Calendar from '../views/Calendar'
import Times from '../views/Times'
import AdminSettings from '../views/AdminSettings'
import Notifications from '../views/Notifications'
import AdminTrips from '../views/AdminTrips'
import AdminTimes from '../views/AdminTimes'
import AdminCalendar from '../views/AdminCalendar'
import RestorePasswordOrEmail from "../views/RestorePasswordOrEmail";
import RestorePassword from "../views/RestorePassword";
import StudentPasswordChange from "../views/StudentPasswordChange";
import RestoreEmail from "../views/RestoreEmail";
import store from "../store";
import StudentCourse from "../views/StudentCourse";
import Candidate from "../views/Candidate";
import Changelog from "../views/Changelog";
import PasswordChange from "../views/Student/PasswordChange";
import StudentPlaces from "../views/Student/StudentPlaces";
import StudentPersonalData from "../views/Student/StudentPersonalData";
import StudentGeneralInformation from "../views/Student/StudentGeneralInformation";
import Chat from "../views/Chat";
import StudentGrades from "../views/Student/StudentGrades";
import AdminCourse from "../views/AdminCourse";
import AdminCourses from "../views/AdminCourses";
import AdminFileGroups from "../views/AdminFileGroups";
import OnlineMeetings from "../views/OnlineMeetings";
import Mentors from "../views/Mentoring/Mentors";
import Mentor from "../views/Mentoring/Mentor";
import Tags from "../views/Mentoring/Tags";
import Issues from "../views/IssueTracker/Issues";
import Issue from "../views/IssueTracker/Issue";
import Error500 from "../views/Error/500";
import Error401 from "../views/Error/401";

Vue.use(VueRouter);

const routes = [
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/",
    name: "Login",
    meta: { layout: "login" },
    component: Login
  },
  {
    path: "/changelog",
    name: "Changelog",
    meta: { layout: "empty" },
    component: Changelog
  },
  {
    path: "/lost-password-email",
    name: "ResorePasswordOrEmail",
    meta: { layout: "login" },
    component: RestorePasswordOrEmail
  },
  {
    path: "/restore-password",
    name: "ResorePassword",
    meta: { layout: "login" },
    component: RestorePassword
  },
  {
    path: "/change-password",
    name: "StudentPasswordChange",
    meta: { layout: "login" },
    component: StudentPasswordChange
  },
  {
    path: "/restore-email",
    name: "ResoreEmail",
    meta: { layout: "login" },
    component: RestoreEmail
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/:id",
    name: "User",
    component: User,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/administrativeStaff/:id",
    name: "Administrative",
    component: Administrative,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/administrativeStaff",
    name: "AdministrativeStaff",
    component: AdministrativeStaff,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/students/:id",
    name: "StudentCourse",
    component: StudentCourse,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/students",
    name: "StudentCourses",
    component: StudentCourses,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/candidates/:id",
    name: "Candidate",
    component: Candidate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/candidates",
    name: "Candidates",
    component: Candidates,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/places",
    name: "Places",
    component: Places,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/accountant-students/:id",
    name: "AccountantStudent",
    component: AccountantStudent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/accountant-students",
    name: "AccountantStudents",
    component: AccountantStudents,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/accountant-settlements/:id",
    name: "AccountantSettlement",
    component: AccountantSettlement,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/accountant-settlements",
    name: "AccountantSettlements",
    component: AccountantSettlements,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/trips",
    name: "Trips",
    component: Trips,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/professor-evaluations/:uid/:evaluationDataVisible",
    name: "ProfessorEvaluations",
    component: ProfessorEvaluations,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/professor-evaluations-joined/:uid/:evaluationDataVisible",
    name: "ProfessorEvaluationsJoined",
    component: ProfessorEvaluationsJoined,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/evaluations",
    name: "Evaluations",
    component: Evaluations,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/evaluations-joined",
    name: "EvaluationsJoined",
    component: EvaluationsJoined,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/protocols",
    name: "Protocols",
    component: Protocols,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/times",
    name: "Times",
    component: Times,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin-settings",
    name: "AdminSettings",
    component: AdminSettings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/trips",
    name: "AdminTrips",
    component: AdminTrips,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/times",
    name: "AdminTimes",
    component: AdminTimes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/calendar",
    name: "AdminCalendar",
    component: AdminCalendar,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/courses/:id",
    name: "AdminCourse",
    component: AdminCourse,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/courses",
    name: "AdminCourses",
    component: AdminCourses,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/files",
    name: "AdminFileGroups",
    component: AdminFileGroups,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  // for Students
  {
    path: "/student-personal-data",
    name: "StudentPersonalData",
    component: StudentPersonalData,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/student-grades",
    name: "StudentGrades",
    component: StudentGrades,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/student-general-information",
    name: "StudentGeneralInformation",
    component: StudentGeneralInformation,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/password-change",
    name: "PasswordChange",
    component: PasswordChange,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/student-places",
    name: "StudentPlaces",
    component: StudentPlaces,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/online-meetings",
    name: "OnlineMeetings",
    component: OnlineMeetings,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/mentors/tags",
    name: "Tags",
    component: Tags,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/mentors/:id",
    name: "Mentor",
    component: Mentor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/mentors",
    name: "Mentors",
    component: Mentors,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/issue-tracker/:id",
    name: "Issue",
    component: Issue,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/issue-tracker",
    name: "Issues",
    component: Issues,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/error-500",
    name: "Error500",
    component: Error500,
    props: true,
    meta: { layout: "error" }
  },
  {
    path: "/error-401",
    name: "Error401",
    component: Error401,
    props: true,
    meta: { layout: "error" }
  },
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['users/getLoggedInUser'] === null) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
});

export default router
