<template>
    <v-container>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="title"
                        label="Inny tytuł do ubezpieczenia"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields } = createHelpers({
    getterType: 'registration/getOtherInsurance',
    mutationType: 'registration/updateOtherInsurance',
  });

  export default {
    name: 'Other',
    computed: {
      ...mapFields([
        'otherInsurance.title',
      ]),
    }
  }
</script>
