import Api from './Api'

export default {
  createContract (contract, userId = null) {
    return Api().post('/api/contracts' + (userId?'?user='+userId:''), contract)
  },
  getContracts (period, userId = null) {
    return Api().get('/api/contracts' + (userId?'?user='+userId:''), { withCredentials: true })
  },
  editContract (contractData) {
    return Api().put('/api/contracts/' + contractData.id, contractData)
  },
  deleteContracts (contracts) {
    return Api().post('/api/contracts/delete-multiple', contracts)
  }
}
