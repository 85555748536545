import Api from './Api'

export default {
  createCar (car, userId = null) {
    return Api().post('/api/cars' + (userId?'?user='+userId:''), car)
  },
  getCars (userId = null) {
    return Api().get('/api/cars' + (userId?'?user='+userId:''), { withCredentials: true })
  },
  editCar (carData) {
    return Api().put('/api/cars/' + carData.id, carData)
  },
  deleteCar (carIds, userId = null) {
    return Api().delete('/api/cars/' + carIds[0] + (userId?'?user='+userId:''))
  }
}
