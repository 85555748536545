<template>
    <v-container>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="email"
                        label="Adres e-mail"
                        dense
                        :error-messages="emailErrors"
                        required
                        :disabled="(student !== undefined && (student.is_admin == 5) ? true : false)"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <v-text-field
                v-model="telephoneNr"
                label="Numer telefonu"
                dense
                :error-messages="telephoneNrErrors"
                required
                :disabled="((student !== undefined && student.is_admin == 5) ? true : false)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="student !== undefined && userRole == 1">
          <v-col sm="6">
            <v-text-field
                v-model="student.bank_account"
                label="Konto bankowe"
                dense
            ></v-text-field>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';
import {mapGetters} from "vuex";

const { mapFields } = createHelpers({
    getterType: 'registrationStudent/getContact',
    mutationType: 'registrationStudent/updateContact',
  });

  export default {
    name: "Contact",
    data: () => ({
    }),
    props: [
      'emailErrors',
      'telephoneNrErrors'
    ],
    computed: {
      ...mapGetters({
        student: 'studentCourses/getChosenStudentCourseData',
        userRole: 'users/userRole',
      }),
      ...mapFields([
        'contact.email',
        'contact.telephoneNr',
        'contact.bank_account',
      ]),
    }
  }
</script>

<style scoped>

</style>
