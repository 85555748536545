<template>
  <v-data-table
      :headers="headers"
      :items="courses"
      sort-by="name"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
      :options.sync="options"
      v-if="userRole == 1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista wszystkich kierunków</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn v-show="showExtraActions" :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone kierunki</v-btn>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodawanie nowego kierunku</v-btn>
            <v-btn v-else color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodaj</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @blur="$v.editedItem.name.$touch()" label="Kierunek"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols ="12">
                    <div v-for="courseSubject in getSubjects" v-bind:key="courseSubject.id">
                      <CourseSubject :data="courseSubject"/>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="12" class="d-flex justify-end">
                    <v-btn primary @click="addCourseSubject">Dodaj kolejny przedmiot</v-btn>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-7">
              <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do listy wszystkich godzin pracy</span></v-btn>
              <v-spacer></v-spacer>
              <v-btn class="primary" rounded @click="save">{{ editedIndex === -1 ? 'Dodaj kierunek': 'Zapisz zmiany'}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Poprawnie dodałeś nowy kierunek</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    {{ editedItem.name }}
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich kierunków</v-btn>
                  </v-col>
                  <v-col sm="12">
                    lub
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherCourse">Chcę dodać kolejny kierunek</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie kierunek</span>
                    <span v-else class="headline">Usunąłeś poprawnie kierunki</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    <div v-if="selected.length > 0">
                      <div v-for="item in selected">
                        {{ item.name }}
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.lp="{ item }">
      {{ item.id }}
    </template>
    <template v-slot:item.action="{ item }" class="text-right">
      <v-icon
          @click="setCourseSelected([item.id]); $router.push('/admin/courses/' + item.id)"
          small
          class="mr-2"
      >
        zoom_in
      </v-icon>
      <v-icon
          small
          class="mr-2 ml-4"
          @click="editItem(item)"
      >
        edit
      </v-icon>
    </template>
    <template v-slot:no-data>
      Brak kierunków
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import CourseSubject from "../components/CourseSubject";
import {ComponentWithPagingEnum} from "../enums";

export default {
  components: { CourseSubject },
  mixins: [validationMixin],

  validations: {
    editedItem: {
      name: {required}
    }
  },
  name: "AdminCourses",
  computed: {
    ...mapGetters({
      courses: 'courses/getCourses',
      dialogFromStore: 'courses/dialogNew',
      getSubjects: 'subjects/getSubjectsBySelectedCourseId',
      getPage: 'paging/getPage',
      userRole: 'users/userRole',
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodawanie nowego kierunku' : 'Edytuj kierunek'
    },
    nameErrors () {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.required && errors.push('Podanie kierunku jest wymagane');
      return errors
    },
    showExtraActions () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
  },
  mounted () {
    this.setSelectedComponent(ComponentWithPagingEnum.courses);
    this.options = this.getPage;
  },
  data: () => ({
    selected: [],
    menuDate: false,
    dialogNewSuccess: false,
    dialogRemoveSuccess: false,
    headers: [
      { text: 'Kierunek', value: 'name' },
      { text: '', value: 'action', sortable: false, align: 'right' },
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
      courseSubjects: []

    },
    defaultItem: {
      name: '',
      courseSubjects: []
    },
    singleSelect: false,
    options: {},
  }),
  watch: {
    dialogNewSuccess (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    dialog (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    options: {
      handler () {
        this.handlePage()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setDialogNew: 'courses/setDialogNew',
      createCourse: 'courses/createCourse',
      editCourse: 'courses/editCourse',
      deleteCourses: 'courses/deleteCourses',
      setCourseSelected: 'courses/setCourseSelected',
      getSubjectsAction: 'subjects/getSubjects',
      addCourseSubject: 'subjects/addCourseSubject',
      setPage: 'paging/setPage',
      setSelectedComponent: 'paging/setSelectedComponent',
      clearPage1: 'paging/clearPage',
    }),
    clearPage () {
      this.clearPage1();
      this.options.page = 1;
    },
    handlePage() {
      let { sortBy, sortDesc, page, itemsPerPage, search } = this.options;
      this.setPage({ sortBy: sortBy, sortDesc: sortDesc, page: page, itemsPerPage: itemsPerPage, search: search })
    },
    editItem (item) {
      this.editedIndex = this.courses.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.setCourseSelected(item.id).then(() => {
        this.getSubjectsAction();
      });
    },
    close () {
      this.dialog = false
    },
    closeDialogNewSuccessAndAddAnotherCourse () {
      this.dialogNewSuccess = false;
      this.setDialogNew(true)
    },
    closeDialogRemoveSuccess () {
      this.dialogRemoveSuccess = false;
      this.selected = []
    },
    save () {
      this.$v.$touch();
      if (this.editedIndex > -1) {
        if (!this.$v.$invalid) {
          this.editCourse(this.editedItem).then(response => {
            this.$log.info('Saved edited course', response);
            Object.assign(this.courses[this.editedIndex], this.editedItem);
            this.close()
          }).catch(error => {
            this.$log.error('Saving edited course error', error)
          })
        }
      } else {
        if (!this.$v.$invalid) {
          this.createCourse(this.editedItem).then(response => {
            this.$log.info('Saved new course', response);
            this.close();
            this.dialogNewSuccess = true
          }).catch(error => {
            this.$log.error('Saving new course error', error)
          })
        }
      }
    },
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć poniższe kierunki?')){
        this.deleteCourses(this.selected).then(response => {
          this.$log.info('Deleted course', response);
          this.dialogRemoveSuccess = true
        }).catch(error => {
          this.$log.error('Deleting course error', error)
        })
      }
    },
    clearEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1
      this.setCourseSelected(null).then(() => {
        this.getSubjectsAction();
      });
    },
  },
  created() {
    this.$store.dispatch('courses/getCourses');
    this.$store.dispatch('users/getUsers');
  },
}
</script>

<style scoped>

</style>
