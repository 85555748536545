import Api from '../Api'

export default {
  createTag (tag) {
    return Api().post('/mentoringapi/tags', tag)
  },
  getTags () {
    return Api().get('/mentoringapi/tags', { withCredentials: true })
  },
  deleteTag (tagId) {
    return Api().delete('/mentoringapi/tags/' + tagId)
  }
}
