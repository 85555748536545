<template>
  <v-data-table
      :headers="headers"
      :items="messages"
      sort-by="name"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista wiadomości</v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      Brak wiadomości
    </template>
    <template v-slot:item.text="{ item }">
      <span v-html="item.text"></span>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Messages",
  computed: {
    ...mapGetters({
      messages: 'messages/getMessages'
    }),
  },
  props: {
    uid: null
  },
  data: () => ({
    selected: [],
    menuDate: false,
    headers: [
      { text: 'Temat', value: 'subject' },
      { text: 'Tekst', value: 'text' },
      { text: 'Data', value: 'date' },
    ],
  }),
  methods: {
    ...mapActions({
    }),
  },
  created() {
    this.$store.dispatch('messages/getMessages');
  },
}
</script>

<style scoped>

</style>
