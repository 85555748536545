import bill from '../../services/Bill'

// initial state
const state = {
  items: [],
  dialogNew: false
};

// getters
const getters = {
  getBills: state => state.items,
  dialogNew: state => state.dialogNew
};

// actions
const actions = {
  getBills ({ commit, rootState }, period) {
    bill.getBills(period, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      commit('setBills', response.data)
    })
  },
  createBill ({ commit, rootState }, newBill) {
    return new Promise((resolve, reject) => {
      bill.createBill(newBill, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
        if (response.status == 200) {
          commit('addBill', response.data)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  editBill ({ commit, rootState }, editedBill) {
    return new Promise((resolve, reject) => {
      bill.editBill(editedBill, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
        if (response.status == 200) {
          commit('editBill', response.data)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  deleteBills ({ commit, rootState }, deletedBills) {
    bill.deleteBills(deletedBills.map(function(item) { return item["id"] }), rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      console.log(response.status);
      if (response.status == 200) {
        commit('deleteBills', deletedBills)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
};

// mutations
const mutations = {
  setBills (state, bills) {
    state.items = bills.items;
  },
  addBill (state, bill) {
    state.items.push(bill)
  },
  editBill (state, bill) {
    console.log(bill)
  },
  deleteBills (state, bills) {
    bills.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
