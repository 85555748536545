import protocol from '../../services/Protocol'

// initial state
const state = {
  protocols: [],
}

// getters
const getters = {
  getProtocols: state => state.protocols.map((item, key) => ({...item, key})),
  getProtocolsBySubjectId: state => subjectId => state.protocols.filter(x => x.sid == subjectId).map((item, key) => ({...item, key})),
  getLastProtocolByType: state => type => state.protocols ? state.protocols.findLast((element) => element.type == type) : []
}

// actions
const actions = {
  getProtocols ({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      protocol.getProtocols(parameters).then(response => {
        if(response.status == 200) {
          commit('setProtocols', response.data);
          resolve();
        }
      }).catch(() => {
        commit('setProtocols', []);
        resolve();
      });
    }).catch((error) => { console.log(error) });
  },
  saveProtocol ({ commit }, data) {
    return new Promise((resolve, reject) => {
      protocol.save(data).then(response => {
        if (response.data) {
          resolve(200);
          // commit('setEvaluationGeneralList', {...evaluationSubject, esid: null, grade: ''})
        } else {
          resolve(200);
          // commit('setEvaluationGeneralList', {...evaluationSubject, esid: response.data.id })
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  deleteProtocols ({ commit }, deletedProtocols) {
    protocol.deleteProtocols(deletedProtocols.map(function(item) { return item["id"] })).then(response => {
      if (response.status == 200) {
        commit('deleteProtocols', deletedProtocols)
      }
    })
  },
  clearProtocols({ commit }) {
    commit('setProtocols', []);
  },
}

// mutations
const mutations = {
  setProtocols (state, protocols) {
    state.protocols = protocols
  },
  deleteProtocols (state, protocols) {
    protocols.map(function(key) {
      const index = state.protocols.indexOf(key);
      state.protocols.splice(index, 1);
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
