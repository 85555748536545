import history from '../../services/History'
import InsuranceTypes from "../../components/RegisterForm/InsuranceForm/InsuranceTypes";

// initial state
const state = {
  items: [],
  users: []
};

// getters
const getters = {
  getFormDataHistory: state => state.items,
  getFormDataHistoryParsed: state => {
    let result = []
    state.items.forEach(function(item) {
      if(item.original !== null) {
        for (let [key, value] of Object.entries(InsuranceTypes)) {
          item.original = item.original.replace('"' + key + '"', '"' + value + '"');
          item.after = item.after.replace('"' + key + '"', '"' + value + '"');
        }
        item.original = JSON.parse(item.original);
        item.after = JSON.parse(item.after);
        result.push(item);
      }
    });
    return result;
  },
  getHistoryRecent: state => userId => state.users.find(x => x.uid == userId)
};

// actions
const actions = {
  getHistoryByUserId ({ commit, rootState }) {
    history.getHistoryByUserId(rootState.users.chosenUser).then(response => {
      commit('setHistory', response.data)
    })
  },
  getHistoryByStudentCourse ({ commit, rootState }) {
    history.getHistoryByUserId(rootState.studentCourses.chosenStudentCourseUser).then(response => {
      commit('setHistory', response.data)
    })
  },
  markAsRead({commit}, checkedItems) {
    return new Promise((resolve, reject) => {
      history.markAsRead(checkedItems.map(function(item) { return item["id"] })).then(response => {
        if (response.status == 200) {
          commit('markAsRead', checkedItems)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  getHistoryRecent ({ commit }) {
    history.getHistoryRecent().then(response => {
      commit('setUsers', response.data)
    })
  }
};

// mutations
const mutations = {
  setHistory (state, history) {
    state.items = history
  },
  setUsers (state, users) {
    state.users = users
  },
  markAsRead (state, items) {
    items.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
