<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <b>Kierunek: {{ item.name.length > 80 ? item.name.substring(0, 80)+'...' : item.name }}</b>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-tabs
          :key="activeSettingsTabFromStore"
          v-model="tabActive"
          background-color="primary"
          dark
      >
        <v-tab
            v-for="itemS in getEvaluationsByUserIdUniqueSemesters(item.cid)"
            :key="itemS.semester"
        >
          semestr {{ itemS.semester }}{{ evaluationDataVisible == 'true' ? '' : ' (' + itemS.date + ')' }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabActive">
        <v-tab-item v-for="item2 in getEvaluationsByUserIdUniqueSemesters(item.cid)" :key="item2.semester">
          <v-card>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Nazwa przedmiotu
                    </th>
                    <th class="text-left">
                      Ocena
                    </th>
                    <th class="text-left">
                      Wykładowca
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="subject in getEvaluationsByUserIdAndEvaluation(item2.eid)"
                      :key="subject"
                  >
                    <td>{{ subject.subject_name }}</td>
                    <td>
                      <ProfessorGrade
                          :id="subject.id"
                          :eid="subject.eid"
                          :grade="subject.grade"
                          :professorId="subject.professor_id"
                          :subjectId="subject.subject_id"
                      />
                    </td>
                    <td>
                      {{ loggedInUser.id == subject.professor_id ? 'JA - ' : '' }} {{ subject.professor_name }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-7">
              <!--                  <v-spacer></v-spacer>-->
              <!--                  <v-btn color="primary" rounded @click="generateAchievementCard(item2, false)">Pobierz kartę okresowych osiągnięć, semestr {{ item2.semester }}</v-btn>-->
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import ProfessorGrade from "../components/ProfessorGrade"
import {mapGetters} from "vuex";

export default {
  name: 'ProfessorEvaluationsCourse',
  components: {ProfessorGrade},
  props: [
    'item',
    'evaluationDataVisible',
    'getEvaluationsByUserIdUniqueSemesters',
    'loggedInUser',
    'tabActive'
  ],
  computed: {
    ...mapGetters({
      getEvaluationsByUserIdAndEvaluation: 'evaluations/getEvaluationsByUserIdAndEvaluation',
    }),
  }
}
</script>
