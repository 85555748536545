import moment from "moment/moment";

const SemesterChoicesEnum = Object.freeze([
  {
    'key': moment('2024-10-01').format('YYYY-MM-DD'),
    'value': moment('2024-10-01').locale('pl').format('MMMM YYYY')
  },
  {
    'key': moment('2024-03-01').format('YYYY-MM-DD'),
    'value': moment('2024-03-01').locale('pl').format('MMMM YYYY')
  },
  {
    'key': moment('2023-10-01').format('YYYY-MM-DD'),
    'value': moment('2023-10-01').locale('pl').format('MMMM YYYY')
  },
  {
    'key': moment('2023-03-01').format('YYYY-MM-DD'),
    'value': moment('2023-03-01').locale('pl').format('MMMM YYYY')
  },
  {
    'key': moment('2022-10-01').format('YYYY-MM-DD'),
    'value': moment('2022-10-01').locale('pl').format('MMMM YYYY')
  },
  {
    'key': moment('2022-03-01').format('YYYY-MM-DD'),
    'value': moment('2022-03-01').locale('pl').format('MMMM YYYY')
  },
  {
    'key': moment('2021-10-01').format('YYYY-MM-DD'),
    'value': moment('2021-10-01').locale('pl').format('MMMM YYYY')
  },
  {
    'key': moment('2021-03-01').format('YYYY-MM-DD'),
    'value': moment('2021-03-01').locale('pl').format('MMMM YYYY')
  },
  {
    'key': moment('2020-10-01').format('YYYY-MM-DD'),
    'value': moment('2020-10-01').locale('pl').format('MMMM YYYY')
  },
]);

export default SemesterChoicesEnum;
