<template>
<div v-if="getLoggedInUser.id != undefined">
  <div>
    <v-list-item>
      <v-list-item-avatar>
        <v-img
            :alt="`${user.name} avatar`"
            :src="avatar(user.uuid)"
        ></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <span v-if="user.is_admin == 1 && getLoggedInUser.is_admin !== 1">Sekretariat</span>
          <span v-else>{{ user.name }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

  </div>
  <v-divider></v-divider>

  <v-container class="fill-height">

    <v-row class="fill-height pb-2" align="end">
      <v-col class="pl-0 pr-0">
        <v-card color="#EFF3F7" height="300px" outlined>
          <v-card-text>
            <v-list color="#EFF3F7">
              <div v-for="(itemHistory, indexHistory) in historyWithUser"
                   :key="indexHistory"
                   :class="itemHistory.uid_from == getLoggedInUser.id ? 'd-flex flex-row align-center my-2 chat-message justify-end' : 'd-flex flex-row align-center my-2 chat-message'"
              >
                <span :class="itemHistory.uid_from == getLoggedInUser.id ? 'my-message black--text pa-4' : 'reply-message black--text pa-4' ">{{ itemHistory.text }} <span class="small">[{{ itemHistory.datetime }}]</span>
                  <v-btn v-if="itemHistory.file" color="dark" rounded @click="getFileByUrl(itemHistory.file)">
                    <v-icon left>file_download</v-icon>Pobierz
                  </v-btn>
                </span>
              </div>
            </v-list>
            <br /><br /><br />
            <span class="bottomOfChat"></span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>

  <v-container v-if="this.getLoggedInUser.is_admin !== 3 || (this.getLoggedInUser.is_admin == 3 && this.getChosenChatUser !== 10)" class="ma-0 pa-0">
      <v-row no-gutters>
        <v-col>
          <div class="d-flex flex-row align-end">
            <v-textarea
                v-model="msg"
                :counter=1000-value
                outlined
                label="Napisz wiadomość"
                :rules="rules"
                :value="value"
            ></v-textarea>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="d-flex flex-row justify-space-between">
          <div>
            <v-btn
                v-if="selectedFile == undefined"
                :loading="isSelecting"
                color="primary"
                depressed
                rounded
                @click="onUploadButtonClick"
            >
              <v-icon left>
                cloud_upload
              </v-icon>
              Dodaj załącznik
            </v-btn>
            <input
                ref="uploader"
                accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                class="d-none"
                type="file"
                @change="onFileChanged"
            >
            <div v-if="this.selectedFile !== undefined">
              Zostanie dołączony: {{ this.selectedFile.name }} ({{ this.selectedFile.type }}, wielkość: {{ (this.selectedFile.size/1000000).toFixed(2) }}MB)
              <v-btn color="error" dark rounded @click="removeSelected">Usuń</v-btn>
            </div>
          </div>
          <div>
            <v-btn :disabled="msg.length == 0 && selectedFile == undefined" class="ml-4" color="primary" dark rounded @click="send">Wyślij<v-icon right>mdi-send</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ChatCurrent",
  data: () => ({
    rules: [v => v.length <= 1000 || 'Maksymalnie wiadomość może zawierać 1000 znaków'],
    value: '',
    chat: [],
    msg: '',
    loading: false,
    selectedFile: undefined,
  }),
  props: [
    'user'
  ],
  computed: {
    ...mapGetters({
      historyWithUser: 'chat/historyWithUser',
      getLoggedInUser: 'users/getLoggedInUser',
      getChosenChatUser: 'chat/getChosenChatUser'
    })
  },
  methods: {
    ...mapActions({
      createChatMessage: 'chat/createChatMessage',
      getFileByUrl: 'chat/getFileByUrl'
    }),
    avatar (uuid) {
      return "/api/users/" + uuid + "/avatarByUuid"
    },
    send: function(){
      this.loading = true;
      let message = {};
      if(this.selectedFile !== undefined) {
        message = new FormData();
        message.append('file', this.selectedFile);
        message.append('msg', this.msg);
        message.append('uid_to', this.user.id);
        message.append('name_to', this.user.name);
      } else {
        message = {msg: this.msg, uid_to: this.user.id, name_to: this.user.name };
      }
      this.scrollToBottom(true);
      this.createChatMessage(message).then(response => {
        if(response == 200) {
          this.$log.info('Msg changed ', response);
          this.loading = false;
          this.msg = '';
          this.selectedFile = undefined;
        }
      }, error => {
        this.error = true
        this.loading = false
        this.$log.error('Msg change error ', error)
      })

    },
    onUploadButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      console.log(this.selectedFile);
      // do something
    },
    removeSelected() {
      this.selectedFile = undefined;
    },
    scrollToBottom(smooth) {
      if(this.$el.getElementsByClassName('bottomOfChat')) {
        const el = this.$el.getElementsByClassName('bottomOfChat')[0]
        if (el) {
          if (smooth == true) {
            el.scrollIntoView({behavior: 'smooth'});
          } else {
            el.scrollIntoView();
          }
          window.scrollTo(0,0);
        }
      }
    }
  },
  created() {
    setTimeout(x => {
      this.scrollToBottom(false);
    }, 1000);

  },
  updated() {
    if(this.getLoggedInUser) {
      this.scrollToBottom(false);
    }
  }
}
</script>

<style scoped>
  span.my-message {
    background-color: #C6E4FF;
    margin-left: 100px;
    border-radius: 15px 0 0 0;
    white-space: pre-wrap;
  }
  span.reply-message {
    background-color: #FFFFFF;
    margin-right: 100px;
    border-radius: 0 15px 0 0;
    white-space: pre-wrap;
  }

  .v-card {
    display: flex !important;
    flex-direction: column;
  }

  .v-card__text {
    flex-grow: 1;
    overflow: auto;
  }

  span.small {
    font-size: 10px;
    color: gray;
  }
</style>
