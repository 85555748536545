<template>
  <div>
    <div class="d-flex justify-center pb-4">
      <v-img src="../assets/logo_logowanie.png" max-width="150"></v-img>
    </div>
    <v-card :width=this.width class="rounded-card">
      <v-card-title>
        <h1>Przypomnienie adresu e-mail</h1>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="!email">
        <h3>W celu przypomnienia adresu e-mail, wypełnij proszę poniższe pole:</h3>
        <v-form @submit.prevent="submit">
          <v-text-field
                  v-model="telephone"
                  :error-messages="telephoneErrors"
                  required
                  @blur="$v.telephone.$touch()"
                  label="Numer telefonu"
                  prepend-icon="account_circle"
          />
        </v-form>
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <div class="green--text text-center">
          <v-icon x-large class="green--text pb-2">check_circle_outline</v-icon>
          <h3>Świetnie, udało się!</h3>
          <h5>Twój adres e-mail to:</h5>
          <h3>{{ this.email }}</h3>
        </div>
      </v-card-text>
      <v-alert dense outlined class="ma-4" type="error" v-if="this.error">Podany numer telefonu nie jest powiązany z żadnym kontem. Sprawdź proszę poprawność. Jeśli masz pytania, zadzwoń: +12 3214 4114141</v-alert>
      <v-card-actions class="d-flex pa-2 justify-end">
        <v-btn v-if="!email" rounded :loading="loading" @click="send" color="secondary" class="text-right mr-2">Potwierdzam</v-btn>
        <v-btn v-else rounded :loading="loading" to="/" color="primary" class="text-right mr-2">Przejdź do logowania</v-btn>
      </v-card-actions>
      <v-divider class="mt-2"></v-divider>
      <p v-if="!email" class="pa-4"><v-icon small left>arrow_back</v-icon><router-link to="/">Powrót do logowania</router-link></p>
      <p v-else class="pa-4"></p>
    </v-card>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

  export default {
    mixins: [validationMixin],

    validations: {
      telephone: { required }
    },
    name: "Login",
    data () {
      return {
        telephone: '',
        email: '',
        error: false,
        loader: null,
        loading: false
      }
    },
    methods: {
      ...mapActions({
        restoreEmail: 'users/restoreEmail'
      }),
      send () {
        this.$v.$touch()
        if (!this.$v.telephone.$invalid) {
          this.loader = 'loading'
          this.loading = true
          this.restoreEmail({telephone: this.telephone}).then(email => {
            this.$log.info('User email is ', email)
            this.email = email
            this.loading = false
            this.error = false
          }, error => {
            this.error = true
            this.loading = false
            this.$log.error('Login error ', error)
          })
        }
      }
    },
    computed: {
      telephoneErrors () {
        const errors = []
        if (!this.$v.telephone.$dirty) return errors
        !this.$v.telephone.required && errors.push('Podanie numeru telefonu jest wymagane')
        return errors
      },
      width () {
        return this.$vuetify.breakpoint.xsOnly ? "350" : "450"
      }
    }
  }
</script>

<style>
  .rounded-card {
      border-radius: 25px !important;
  }
  .error-message {
    font-weight: bold;
    text-decoration: underline;
    color: var(--v-error-base) !important;
  }
</style>
