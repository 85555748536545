<template>
  <v-container>
    <v-card>
      <v-card-title>Koordynatorzy, Ośrodki i Sekretariat</v-card-title>
      <v-card-text>
        <p>W tej sekcji w łatwy sposób odnaleźć koordynatora, dane teleadresowe ośrodka oraz sekretariatu.</p>
        <v-tabs
            v-model="tabActive"
            background-color="primary"
            dark
            :key="activeStudentPlacesTabFromStore"
        >
          <v-tab
              v-for="item in items"
              :key="item.tab"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabActive">
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-text-field v-model="search" placeholder="Wyszukaj miasto" append-icon="search" />
                <v-expansion-panels>
                  <v-expansion-panel v-for="item in filteredPlaces" :key="item.id">
                    <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ul style="list-style-type:none; padding-left:0;">
                      <li v-if="item.organization || item.address"><v-icon small>location_on</v-icon> {{ item.organization }} | {{ item.address }}</li>
                      <li><v-icon small>person</v-icon> {{ item.coordinator_username }}</li>
                      <li v-if="item.email || item.telephone"><v-icon small v-if="item.email">email</v-icon> {{ item.email }} | <v-icon small v-if="item.telephone">phone</v-icon> {{ item.telephone }}</li>
                      </ul>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-text-field v-model="search" placeholder="Wyszukaj koordynatora" append-icon="search" />
                <v-expansion-panels>
                  <v-expansion-panel v-for="item in filteredPlacesCoordinators" :key="item.id">
                    <v-expansion-panel-header>{{ item.coordinator_username }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ul style="list-style-type:none; padding-left:0;">
                        <li v-if="item.organization || item.address"><v-icon small>location_on</v-icon> {{ item.organization }} | {{ item.address }}</li>
                        <li><v-icon small>person</v-icon> {{ item.coordinator_username }}</li>
                        <li v-if="item.email || item.telephone"><v-icon small>email</v-icon> {{ item.email }} | <v-icon small>phone</v-icon> {{ item.telephone }}</li>
                      </ul>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-title>Dane teleadresowe Sekretariatu</v-card-title>
              <v-card-text>
                <ul style="list-style-type:none; padding-left:0;">
                  <li><v-icon small>location_on</v-icon> Akademia Nauk Stosowanych Wincentego Pola w Lublinie | ul. Choiny 2  | 20-816 Lublin</li>
                  <li><v-icon small>email</v-icon> sekretariat@podyplomowestudia.eu</li>
                  <li><v-icon small>phone</v-icon> 733 462 462</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "StudentPlaces",
  data() {
   return {
     items: [
       { id: 1, tab: 'Ośrodki' },
       { id: 2, tab: 'Koordynatorzy' },
       { id: 3, tab: 'Sekretariat' },
     ],
     search: '',
   }
  },
  computed: {
    ...mapGetters({
      activeStudentPlacesTabFromStore: 'getActiveStudentPlacesTab',
      userRole: 'users/userRole',
      places: 'places/getPlaces',
    }),
    filteredPlaces: function() {
      return this.places.filter((place) => {
        return place.name.toLowerCase().match(this.search.toLowerCase());
      });
    },
    filteredPlacesCoordinators: function() {
      return this.places.filter((place) => {
        return place.coordinator_username.toLowerCase().match(this.search.toLowerCase());
      });
    },
    tabActive: {
      get () {
        return this.activeStudentPlacesTabFromStore
      },
      set (value) {
        this.$store.commit('setActiveStudentPlacesTab', value)
      }
    }
  },
  created() {
    this.$store.dispatch('places/getPlaces');
  },
}
</script>

<style scoped>

</style>
