import tag from '../../../services/mentoring/Tag'

// initial state
const state = {
  items: [],
};

// getters
const getters = {
  getTags: state => state.items,
  getTagsSearched: state => search => state.items.filter(x => x.name.includes(search)),
};

// actions
const actions = {
  getTags ({ commit }) {
    tag.getTags().then(response => {
      commit('setTags', response.data)
    })
  },
  downloadBusinessCardByUrl ({ commit }, url) {
    return new Promise((resolve, reject) => {
      tag.getFileByUrl(url).then(response => {
        if(response.status == 200) {
          var blob = new Blob([response.data]);

          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'wiz.' + url.split('.').pop();
          link.click();
          link.remove();

        } else {
          reject()
        }
      })
    }).catch((error) => { console.log(error) });
  },
  createTag ({ commit }, newTag) {
    tag.createTag(newTag).then(response => {
      commit('addTag', response.data)
    })
  },
  deleteTag ({ commit }, deletedTagId) {
    tag.deleteTag(deletedTagId).then(response => {
      if (response.status == 200) {
        commit('deleteTag', deletedTagId)
      }
    })
  }
};

// mutations
const mutations = {
  setTags (state, tags) {
    state.items = tags
  },
  addTag (state, tag) {
    state.items.push(tag)
  },
  deleteTag (state, tagId) {
    const index = state.items.findIndex(x => x.id == tagId);
    state.items.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
