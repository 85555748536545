<template>
    <div>
        <v-row>
            <v-col sm="12">
                <div class="title"><strong>Dane kontaktowe</strong></div>
            </v-col>
        </v-row>
        <v-container>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="email"
                            label="E-mail"
                            dense
                            :error-messages="emailErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="telephoneNr"
                            label="Telefon stacjonarny"
                            dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="mobileNr"
                            label="Telefon komórkowy"
                            dense
                            :error-messages="mobileNrErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields } = createHelpers({
    getterType: 'registration/getContact',
    mutationType: 'registration/updateContact',
  });

  export default {
    name: "Contact",
    data: () => ({
    }),
    props: [
      'emailErrors',
      'mobileNrErrors'
    ],
    computed: {
      ...mapFields([
        'contact.email',
        'contact.telephoneNr',
        'contact.mobileNr',
      ]),
    }
  }
</script>

<style scoped>

</style>
