<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="studentCourses"
      sort-by="date"
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
      :search="options.search"
      :loading="loading"
      loading-text="Wczytuję listę... proszę czekać"
      :options.sync="options"
      dense
      group-by="email"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Słuchacze (rozliczenia)</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="options.search"
            append-icon="search"
            label="Szukaj"
            single-line
            hide-details
        ></v-text-field>
      </v-toolbar>
      <v-toolbar flat color="white">
        <SelectSemesters @semesterChanged="semesterChanged"/>
        <SelectPlaces @placeChanged="placeChanged"/>
        <SelectCourses @courseChanged="courseChanged"/>
        <Message @closeDialogMessage="closeDialogMessage" :showDialog="dialogMessage" :receiver="selectedItems()" />
      </v-toolbar>
    </template>
    <template v-slot:no-data>
        Brak słuchaczy
      </template>
    <template v-slot:group.header="{items, isOpen, toggle}">
        <td colspan="4">
          <b>{{ items[0].username }}</b>
          <a class="ml-10 font-weight-medium text-decoration-none" @click="copyTextToClipboard(items[0].email)">
            <v-icon small>mdi-email</v-icon>
            {{ items[0].email }}
          </a>
          <a class="ml-10 font-weight-medium text-decoration-none" @click="copyTextToClipboard(items[0].telephone)">
            <v-icon small>mdi-phone</v-icon>
            {{ items[0].telephone }}
          </a>
        </td>
        <td>
          {{ sumAllPrice(items) }} PLN
        </td>
        <td>
          {{ sumAllPayments(items, items[0].user_paid) }} PLN
        </td>
        <td>
          <span :class="(countBalanse(items) > 0) ? 'red--text' : 'green--text'" >{{ countBalanse(items) }}&nbsp;PLN</span>
        </td>
        <td>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-if="items[0].bank_account"
                  color="gray"
                  small
                  v-bind="attrs"
                  @click="copyTextToClipboard(items[0].bank_account)"
                  v-on="on"
              >
                account_balance
              </v-icon>
            </template>
            <span>{{ items[0].bank_account }}</span>
          </v-tooltip>
        </td>
        <td>
          <v-icon
              class="mr-2"
              small
              @click="setChosenStudentCourseUser(items[0].uid); $router.push('/accountant-students/' + items[0].uid)"
          >
            zoom_in
          </v-icon>
        </td>
      </template>
    <template v-slot:item.username="{ item }" class="abc">
        <span class="hidden">{{ item.username }}</span>
      </template>
    <template v-slot:item.price="{ item }">
      <v-edit-dialog
          :return-value.sync="item.price"
          @cancel="cancelPriceChange"
          @close="close"
          @open="openPriceChange(item)"
          @save="savePriceChange"
      >
        <div>
          <v-btn class="buttonWithoutBorder" outlined>
            {{ item.price/100 }} PLN&nbsp;
            <v-icon right x-small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </div>
        <template v-slot:input>
          <v-text-field
              v-model="selectedPrice"
              :rules="[digitsOnly]"
              counter
              label="Edit"
              single-line
              suffix="PLN"
          ></v-text-field>
        </template>

      </v-edit-dialog>
    </template>
    <template v-slot:item.paid="{ item }"></template>
    <template v-slot:item.bank_account="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              color="gray"
              small
              v-bind="attrs"
              @click="copyTextToClipboard(item.studentCourseBankAccount ? item.studentCourseBankAccount : item.bank_account)"
              v-on="on"
          >
            account_balance
          </v-icon>
        </template>
        <span>{{ item.studentCourseBankAccount ? item.studentCourseBankAccount : item.bank_account }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.date="{ item }">
      {{ new Date(item.date) | moment }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
    </template>
    <template v-slot:item.action="{ item }">

    </template>
    <template v-slot:footer>
      <v-divider></v-divider>
      <div class="mt-4 mb-4 mr-4">
        <v-row>
          <v-col class="d-flex justify-end" md="12">
<!--            <v-btn :color=buttonForRemove class="mr-4" @click="newMessage()" dark rounded small>Napisz wiadomość</v-btn>-->
            <v-btn class="mr-4" color="primary" dark rounded small @click="generateStudentCoursesReport()">Pobierz listę CSV</v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-data-table>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
  >
    Skopiowano do schowka!
    <template v-slot:action="{ attrs }">
      <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
      >
        Zamknij
      </v-btn>
    </template>
  </v-snackbar>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SelectSemesters from "./SelectSemesters";
import SelectPlaces from "./SelectPlaces";
import SelectCourses from "./SelectCourses";
import moment from "moment";
import Message from "../components/Message";
import {ComponentWithPagingEnum} from "../enums";

export default {
  name: "StudentCourses",
  components: {SelectSemesters, SelectPlaces, SelectCourses, Message},
  filters: {
    moment: function (date) {
      return moment(date).format('MMMM YYYY');
    }
  },
  computed: {
    ...mapGetters({
      studentCourses: 'studentCourses/getStudentCourses',
      dialogFromStore: 'studentCourses/dialogNew',
      courses: 'courses/getCourses',
      coursesWithoutAllOption: 'courses/getCoursesWithoutAllOption',
      places: 'places/getPlaces',
      semesterSelected: 'semesterSelected',
      placeSelected: 'places/getPlaceSelected',
      courseSelected: 'courses/getCourseSelected',
      coordinators: 'places/getCoordinators',
      history: 'history/getHistoryRecent',
      userRole: 'users/userRole',
      getPage: 'paging/getPage',
      getItemSelectedToPriceEdit: 'studentCourses/getItemSelectedToPriceEdit',
      getItemSelectedToPriceEditPrice: 'studentCourses/getItemSelectedToPriceEditPrice'
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodaj nowego słuchacza' : 'Edytuj słuchacza'
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
    semesterChoices: {
      get() {
        return [
          {
            'key': moment('2022-03-01').format('YYYY-MM-DD'),
            'value': moment('2022-03-01').locale('pl').format('MMMM YYYY')
          },
          {
            'key': moment('2021-10-01').format('YYYY-MM-DD'),
            'value': moment('2021-10-01').locale('pl').format('MMMM YYYY')
          },
          {
            'key': moment('2021-03-01').format('YYYY-MM-DD'),
            'value': moment('2021-03-01').locale('pl').format('MMMM YYYY')
          },
          {
            'key': moment('2020-10-01').format('YYYY-MM-DD'),
            'value': moment('2020-10-01').locale('pl').format('MMMM YYYY')
          },

        ]
      },
      set(value){
        return this.setMonthSelected(value)
      }
    },
    selectedPrice: {
      get() {
        return this.getItemSelectedToPriceEditPrice/100
      },
      set(value) {
        if(this.digitsOnly(value)) {
          return this.setItemSelectedToPriceEditPrice(value * 100)
        }
      }
    }
  },
  mounted () {
    // this.$store.dispatch('studentCourses/getStudentCourses')
    this.setSelectedComponent(ComponentWithPagingEnum.accountantStudents);
    this.options = this.getPage;
  },
  data: () => ({
    loading: true,
    selected: [],
    menuDate: false,
    dialogNewSuccess: false,
    dialogNewSuccessUserExists: false,
    dialogNewError: false,
    dialogRemoveSuccess: false,
    dialogMessage: false,
    snack: false,
    snackText: '',
    digitsOnly: v => /^[0-9]+$/.test(v) || '',
    headers: [
      { text: 'Kierunek', value: 'courseName' },
      { text: '', value: 'username', width: '1%'},
      { text: 'Semestr', value: 'date' },
      { text: 'Miasto', value: 'cityName' },
      {
        text: 'Email',
        align: 'left',
        sortable: false,
        value: 'email',
      },
      { text: 'Zobowiązanie', value: 'price' },
      { text: 'Suma wpłat', value: 'paid' },
      { text: 'Bilans końcowy', value: 'balance' },
      { text: 'Konto', value: 'bank_account' },
      { text: '', value: 'action', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      cid: '',
      cid_2: '',
      pid: '',
      price: '',
      comment: '',
      coordinator: '',
      date: ''
    },
    defaultItem: {
      cid: '',
      cid_2: '',
      pid: '',
      price: '',
      comment: '',
      coordinator: '',
      date: ''
    },
    singleSelect: false,
    validate: false,
    report: false,
    options: {},
    snackbar: false
  }),
  watch: {
    dialogNewSuccess (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    dialogNewSuccessUserExists (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    dialog (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    options: {
      handler () {
        this.handlePage()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setDialogNew: 'studentCourses/setDialogNew',
      setChosenStudentCourseUser: 'studentCourses/setChosenStudentCourseUser',
      resetChosenStudentCourseUser: 'studentCourses/resetChosenStudentCourseUser',
      setPage: 'paging/setPage',
      setSelectedComponent: 'paging/setSelectedComponent',
      clearPage1: 'paging/clearPage',
      setItemSelectedToPriceEdit: 'studentCourses/setItemSelectedToPriceEdit',
      setItemSelectedToPriceEditPrice: 'studentCourses/setItemSelectedToPriceEditPrice',
      saveSelectedPrice: 'studentCourses/saveSelectedPrice'
    }),
    savePriceChange () {
      this.saveSelectedPrice().then(response => {
        if (response == 200) {
          this.$log.info('Saved new price', response);
          this.snack = true
          this.snackText = 'Data saved'

        }
      }).catch(error => {
        this.$log.error('Saving new price error', error)
      });
    },
    cancelPriceChange () {
      this.snack = true
      this.snackText = 'Canceled'
    },
    openPriceChange (item) {
      this.setItemSelectedToPriceEdit(item)
      this.snack = true
      this.snackText = 'Dialog opened'
    },
    clearPage () {
      this.clearPage1();
      this.options.page = 1;
    },
    handlePage() {
      let { sortBy, sortDesc, page, itemsPerPage, search } = this.options;
      this.setPage({ sortBy: sortBy, sortDesc: sortDesc, page: page, itemsPerPage: itemsPerPage, search: search })
    },
    placeChanged(placeId) {
      this.loading = true;
      this.$store.dispatch(
          'studentCourses/getStudentCourses',
          {
            'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
            'pid': placeId,
            'cid': this.courseSelected,
            'settlement': true
          }
      ).then(() => {
        this.loading = false;
      });
    },
    semesterChanged(semester) {
      this.loading = true;
      this.$store.dispatch(
          'studentCourses/getStudentCourses',
          {
            'date': semester ? moment(semester).startOf('month').format('YYYY-MM-DD') : '',
            'pid': this.placeSelected,
            'cid': this.courseSelected,
            'settlement': true
          }
      ).then(() => {
        this.loading = false;
      });
    },
    courseChanged(courseId) {
      this.loading = true;
      this.$store.dispatch(
          'studentCourses/getStudentCourses',
          {
            'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
            'pid': this.placeSelected,
            'cid': courseId,
            'settlement': true
          }
      ).then(() => {
        this.loading = false;
      });
    },
    selectedItems() {
      let mapped = Object.assign({}, this.selected.map(x => x.email ));
      return { email: mapped, subject: '' };
    },
    sumAllPayments(items, userPaid) {
      let sum = 0;
      items.forEach(x => {
        sum += x.paid;
      })
      return (sum + userPaid) / 100;
    },
    sumAllPrice(items) {
      let sum = 0;
      items.forEach(x => {
        sum += x.price;
      });
      return sum / 100;
    },
    countBalanse(items) {
      return (this.sumAllPrice(items) - this.sumAllPayments(items, items[0].user_paid));
    },
    async copyTextToClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.snackbar = true;
      } catch($e) {
        alert('Błąd');
      }
    },
    generateStudentCoursesReport () {
      let csvContent = "data:text/csv;charset=utf-8,";

      let studentCoursesGrouped = Object.groupBy(this.studentCourses, ({ email }) => email);
      let reportItems = [];
      Object.keys(studentCoursesGrouped).forEach(i => {
        const items = studentCoursesGrouped[i];
        for (let j = 0; j < items.length; j++) {
          let item = items[j];
          let result = '';
          try {
            JSON.parse(item.registration_form).personal.dayOfBirth;
            result += '"'+item.id + '";"' + JSON.parse(item.registration_form).personal.surname + '";"' + JSON.parse(item.registration_form).personal.name + '";"' + item.telephone + '";"' + item.email + '";"' + item.courseName + '";"' + item.date + '";"' + item.cityName + '";"' + (item.studentCourseBankAccount !== null ? item.studentCourseBankAccount : item.bank_account) + '";"' + (item.album_nr == null ? '' : item.album_nr) + '";"' + moment(JSON.parse(item.registration_form).personal.dayOfBirth).format('LL') + '";"' + JSON.parse(item.registration_form).personal.placeOfBirth + '";"' + JSON.parse(item.registration_form).personal.secondName + '";"';
          } catch (e) {
            result += '"'+item.id + '";"' + item.username + '";"' + item.username + '";"' + item.telephone + '";"' + item.email + '";"' + item.courseName + '";"' + item.date + '";"' + item.cityName + '";"' + (item.studentCourseBankAccount !== null ? item.studentCourseBankAccount : item.bank_account) + '";"' + (item.album_nr == null ? '' : item.album_nr) + '";"';
          }

          if (j == 0) {
            result += items[0].price/100 + '";"'  + this.sumAllPrice(items) + '";"' + this.sumAllPayments(items, items[0].user_paid) + '";"' + this.countBalanse(items) + '";""';
          } else {
            result += item.price/100 + '";"'  + '";"'  + '";"' + '";""';
          }
          reportItems.push(result);
        }
      });

      csvContent += [
        "LP;Nazwisko;Imię;Telefon;Email;Kierunek;Semestr;Miasto;Numer rachunku;Numer albumu;Data urodzenia;Miejsce urodzenia;Drugie imię;Zobowiązanie;Suma zobowiązań;Suma wpłat;Bilans końcowy",
        ...reportItems
      ].join("\n")

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "lista_studentow_" + moment().format('L') +".csv");
      link.click();
    }
  },
  created() {
    this.resetChosenStudentCourseUser();

    this.$store.dispatch(
        'studentCourses/getStudentCourses',
        {
          'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
          'pid': this.placeSelected,
          'cid': this.courseSelected,
          'settlement': true
        }
    ).then(() => {
      this.loading = false;
    });
    this.$store.dispatch('courses/getCourses');
    this.$store.dispatch('places/getPlaces');
    // this.$store.dispatch('history/getHistoryRecent');
  }
}
</script>

<style scoped>
.toPrint {
  display: none;
}
.buttonWithoutBorder {
  border: none;
  font-weight: normal;
}
.hidden {
  display: none;
}
</style>
