<template>
  <div>
    <v-container class="text-center">
      <v-row v-if="userRole == 0 || userRole == 1 || userRole == 5 || userRole == 8" justify="center">
        <v-col md="4">
          <v-card>
            <v-card-text>
              Ilość przepracowanych godzin
              <div class="headline">{{ user.times_total?user.times_total:0 }}h</div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="primary" dark rounded class="mb-4" @click="addTime()">Dodaj nowy czas pracy</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="4">
          <v-card>
            <v-card-text>
              Ilość przejechanych kilometrów
              <div class="headline">{{ user.trips_total?user.trips_total:0 }}km</div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="primary" dark rounded class="mb-4" @click="addTrip()">Dodaj nowy przejazd</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="4">
          <v-card>
            <v-card-text>
              Ilość weekendów na uczelni
              <div class="headline">{{ user.meetings_total?user.meetings_total:0 }}</div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="primary" dark rounded class="mb-4" @click="addCalendar()">Dodaj dostępność w kalendarzu</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogRodo" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <v-container>
            <v-row>
              <span class="headline">Obowiązek informacyjny dla pracowników w związku z przetwarzaniem danych osobowych przez pracodawcę</span>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider class="mb-4"></v-divider>
          <v-container>
            <v-row no-gutters>
              <v-col sm="12">
                1. Administratorem Pani/Pana danych osobowych jest GTE Global Trade And Education  Sp. z o.o. (dalej: „ADMINISTRATOR”), z siedzibą: ul. W. Lachmana 2A lok 4, 09407 Płock. Z Administratorem można się kontaktować pisemnie, za pomocą poczty tradycyjnej na adres:  ul. W. Lachmana 2A lok 4, 09-407 Płock lub drogą e-mailową pod adresem: sekretariat@podyplomowestudia.eu.<br /><br />
                2. Administrator wyznaczył Inspektora Ochrony Danych, z którym można się skontaktować pod adresem mailowym: iodo@rt-net.pl.<br /><br />
                3. Pani/Pana dane osobowe są przetwarzane na podstawie art. 6 ust. 1 lit. a i b Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych  i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), tj. w oparciu o zgodę osoby, której dane dotyczą oraz ustawy z dnia 26 czerwca 1974 r. Kodeks pracy.<br /><br />
                4. Przetwarzanie danych osobowych odbywa się w celu zatrudnienia oraz wykonywania obowiązków określonych dla pracodawcy przez przepisy prawa.<br /><br />
                5. Przetwarzanie danych osobowych nie odbywa się na podstawie prawnie uzasadnionego interesu administratora lub strony trzeciej.<br /><br />
                6. Dane osobowe nie pochodzą od stron trzecich.<br /><br />
                7. Administrator nie zamierza przekazywać danych do państwa trzeciego lub organizacji międzynarodowej.<br /><br />
                8. Administrator będzie przekazywał dane osobowe innym podmiotom, tylko na podstawie przepisów prawa, w tym w szczególności do: Zakładu Ubezpieczeń Społecznych, Urzędu Skarbowego.<br /><br />
                9. Dane osobowe będą przetwarzane przez Administratora przez 10 lat od czasu ustania stosunku pracy, zgodnie z przepisami Kodeksu Pracy.<br /><br />
                10. Osoba, której dane dotyczą ma prawo do żądania od administratora dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania oraz o prawo  do wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych.<br /><br />
                11. Skargę na działania Administratora można wnieść do Prezesa Urzędu Ochrony Danych Osobowych.<br /><br />
                12. Podanie danych osobowych jest wymogiem do wykonania obowiązków pracodawcy.  Ich nie podanie spowoduje brak możliwości nawiązania współpracy.<br /><br />
                13. Administrator nie przewiduje zautomatyzowanego podejmowania decyzji.<br /><br />

                <b>Zgoda na przetwarzanie danych:</b><br />
                <v-checkbox v-model="rodo" label="Wyrażam zgodę na przetwarzanie moich danych osobowych"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="mt-4"></v-divider>
        </v-card-text>
        <v-card-actions class="pa-7">
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="!rodo" rounded @click="saveRodo()">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <v-container>
            <v-row>
              <span class="headline">Witaj, {{ user.name }}</span>
            </v-row>
            <v-row>
              <span class="subtitle-1">Dziękujemy, że zalogowałeś się w systemie rozliczeniowym UniKadra.</span>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider class="mb-4"></v-divider>
          <v-container>
            <v-row no-gutters>
              <v-col sm="1">
                <v-icon>info</v-icon>
              </v-col>
              <v-col sm="11">
                Jeszcze jeden mały krok dzieli Cię od pełnego korzystania. W celu księgowania wszystkich odbytych podróży oraz przepracowanych godzin, niezbędne jest wypełninie kwestionariusza osobowego.
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="mt-4"></v-divider>
          <RegisterForm v-if="isCompany === 0" @registerFormPrinted="registerFormPrinted" @registerFormValidated="validate=false" @registerFormSaved="registerFormSaved" :validate="validate" :report="report" />
          <RegisterFormCompany v-if="isCompany === 1" @registerFormPrinted="registerFormPrinted" @registerFormValidated="validate=false" @registerFormSaved="registerFormSaved" :validate="validate" :report="report"/>

          <v-divider class="mt-4 mb-4"></v-divider>

          <p class="text-center">
            Oświadczam, że dane zawarte w formuarzu są zgodne ze stanem faktycznym i prawnym.<br />
            Jestem świadomy/a odpowiedzialności za podanie nieprawidłowych danych.<br />
            Wyrażam zgodę na przetwarzanie moich danych osobowych dla celów realizacji umowy.<br />
            O wszelkich zmianach w stosunku do danych zawartych w niniejszym kwestionariuszu zobowiązuję się powiadomić zleceniodawcę, w ciągu 7 dni od daty zaistniałej zmiany.
          </p>

          <v-divider class="mt-4"></v-divider>
        </v-card-text>
        <v-card-actions class="pa-7">
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click="save()">
            <span v-if="!isCompany">Zapisz kwestionariusz osobowy</span>
            <span v-if="isCompany">Zapisz dane firmowe</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSuccess" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col md="10">
                <span class="headline">Wypełnienie kwestionariusza</span>
              </v-col>
              <v-col md="2" class="d-flex justify-end">
                <v-icon @click="dialogSuccess = false">close</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider class="mb-4"></v-divider>
          <v-container class="text-center">
            <v-row no-gutters>
              <v-col sm="12">
                <v-icon x-large class="green--text">check_circle_outline</v-icon>
              </v-col>
              <v-col sm="12">
                <span class="green--text">Dziękujemy za wypełnienie</span>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="mt-4"></v-divider>
          <v-container>
            <v-row align="center">
              <v-col md="1">
                <v-icon x-large>picture_as_pdf</v-icon>
              </v-col>
              <v-col md="8">
                Możesz już teraz zapisać formularz jako PDF, wydrukować, czytelnie podpisać i wysłać na adres <strong>dziekanatu</strong> lub doręczyć osobiście. Możesz go także zapisać później.
              </v-col>
              <v-col md="3">
                <v-btn @click="generatePDFReport()" rounded color="primary" outlined>Zapisz i wydrukuj</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="mb-4"></v-divider>
          <v-container class="text-center">
            Zawsze możesz dokonać zmiany, w ustawieniach konta: <router-link to="/settings"><v-icon>settings</v-icon></router-link>
          </v-container>
          <v-divider class="mb-4"></v-divider>
          <v-container class="text-center">
            Okno zamknie się automatycznie po 10 sekundach
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmUpToDate" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Potwierdzenie</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                Czy zmieniły się Twoje dane w kwestionariuszu osobowym?
              </v-col>
              <v-col sm="12">
                <v-btn rounded color="primary" @click="dialogConfirmUpToDate=false;dialogConfirmUpToDate2=true;confirmUpToDate=false">Nie</v-btn>
              </v-col>
              <v-col sm="12">
                <v-btn rounded color="primary" outlined @click="dialogConfirmUpToDate=false;dialogConfirmUpToDate2=true;confirmUpToDate=true">Tak</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmUpToDate2" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <v-container>
            <v-row>
              <span class="headline">{{ confirmUpToDate == true ? 'Zmień te pola które się zmieniły i zatwierdź' : 'Potwierdź z dzisiejszą datą' }} </span>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider class="mb-4"></v-divider>
          <RegisterForm v-if="isCompany === 0" @registerFormPrinted="registerFormPrinted" @registerFormValidated="validate=false" @registerFormSaved="registerFormSaved" :validate="validate" :report="report" :fixedDate="true" />
          <RegisterFormCompany v-if="isCompany === 1" @registerFormPrinted="registerFormPrinted" @registerFormValidated="validate=false" @registerFormSaved="registerFormSaved" :validate="validate" :report="report" :fixedDate="true" />
          <v-divider class="mt-4"></v-divider>
        </v-card-text>
        <v-card-actions class="pa-7">
          <v-btn color="grey" class="overline" text @click="dialogConfirmUpToDate2=false;dialogConfirmUpToDate=true">
            <v-icon small left>arrow_back</v-icon>
            <span>wróć</span></v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary" rounded @click="save()"><span>Potwierdzam</span></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import RegisterForm from "../components/RegisterForm";
import RegisterFormCompany from "../components/RegisterFormCompany";

const moment = require("moment");

export default {
    name: "Dashboard",
    data: () => ({
      dialog: false,
      dialogRodo: false,
      dialogSuccess: false,
      dialogConfirmUpToDate: false,
      dialogConfirmUpToDate2: false,
      confirmUpToDate: undefined,
      validate: false,
      report: false,
      rodo: false
    }),
    components: {RegisterForm, RegisterFormCompany},
    computed: {
      ...mapGetters({
        user: 'users/getLoggedInUser',
        userRole: 'users/userRole',
        isCompany: 'users/isCompany'
      }),
    },
    created() {
      this.$store.dispatch('notifications/getNotifications');
      this.$store.dispatch('subjects/getSubjects');
      // this.$store.dispatch('chat/getLastMessages');
    },
    mounted() {
      if (this.user.rodo != null && this.user.registration_form == null && (this.userRole == 0 || this.userRole == 5)) {
        this.dialog = true
      }
      if (this.user.rodo == null && (this.userRole == 0 || this.userRole == 5)) {
        this.dialogRodo = true
      }
      if (this.user.rodo != null && this.user.registration_form !== null && (this.userRole == 0 || this.userRole == 5)) {
        if(
            (moment().format("yyyy-MM-DD") > moment(moment().format('YYYY')+'-09-01').format("yyyy-MM-DD")) &&
            (moment(JSON.parse(this.user.registration_form).registerFormGeneral.formDate).format("yyyy-MM-DD") <  moment(moment().format('YYYY')+'-09-01').format("yyyy-MM-DD"))
        ){
          this.dialogConfirmUpToDate = true;
        }
      }
    },
    methods: {
      ...mapActions({
        setDialogNewTrips: 'trips/setDialogNew',
        setDialogNewTimes: 'times/setDialogNew',
        setDialogNewMeetings: 'meetings/setDialogNew',
        setUserRodo: 'users/setRodo'
      }),
      close () {
        this.dialog = false;
        this.dialogConfirmUpToDate2 = false;
      },
      addCalendar () {
        this.setDialogNewMeetings(true)
        this.$router.push({ path: 'calendar'})
      },
      addTime () {
        this.setDialogNewTimes(true)
        this.$router.push({ path: 'times'})
      },
      addTrip () {
        this.setDialogNewTrips(true)
        this.$router.push({ path: 'trips'})
      },
      save () {
        this.validate = true
      },
      saveRodo() {
        this.setUserRodo().then(response => {
          if(response == 200) {
            this.dialogRodo = false;
            if (this.user.rodo != null && this.user.registration_form == null && (this.userRole == 0 || this.userRole == 5)) {
              this.dialog = true;
            }
          }
        }).catch(error => {
          this.$log.error('Saving new avatar error', error)
        })
      },
      registerFormSaved() {
        this.$log.info('Saved registration form')
        this.close()
        this.dialogSuccess = true
        setTimeout(() => this.dialogSuccess = false, 10000);
      },
      generatePDFReport() {
        this.report = true
      },
      registerFormPrinted() {
        this.$log.info('Printed registration form')
        this.report = false
      }
    }
  }
</script>

<style>
  a {
    text-decoration: none;
  }
</style>

