<template>
    <v-data-table
            :headers="headers"
            :items="times"
            sort-by="start"
            show-select
            :single-select=singleSelect
            class="elevation-1 ma-4"
            v-model="selected"
            :search="search"
            v-if="userRole == 1"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Lista wszystkich godzin pracy za</v-toolbar-title>
                <SelectMonths :emptyAvailable="true" />
                <SelectYears/>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Szukaj"
                        single-line
                        hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-show="showExtraActions" color="primary" rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone czasy pracy</v-btn>
                <v-dialog v-model="dialog" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-menu
                                                ref="menuDate"
                                                v-model="menuDate"
                                                :close-on-content-click="false"
                                                :return-value.sync="editedItem.date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="new Date(editedItem.date).toISOString().substr(0, 10)"
                                                        label="Data pracy"
                                                        append-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editedItem.date" :first-day-of-week="1" no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menuDate = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.menuDate.save(editedItem.date)">OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6" v-if="!online">
                                        <v-text-field v-model="editedItem.city" :error-messages="cityErrors" required @blur="$v.editedItem.city.$touch()" label="Miejscowość"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-checkbox
                                          v-model="online"
                                          label="on-line"
                                      ></v-checkbox>
                                    </v-col>
                                 </v-row>
                                 <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.hours" :error-messages="hoursErrors" required @blur="$v.editedItem.hours.$touch()" label="Ilość godzin"></v-text-field>
                                    </v-col>
                                 </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-textarea v-model="editedItem.comment" label="Uwagi"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-7">
                            <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">wroc do listy wszystkich godzin pracy</span></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="primary" rounded @click="close">Zamknij</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie przejazd</span>
                                        <span v-else class="headline">Usunąłeś poprawnie czas pracy</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        <div v-if="selected.length > 0">
                                            <div v-for="item in selected">
                                                {{ item.lp }} {{ item.date }} | {{ item.city }}  | {{ item.hours }} godzin
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.lp="{ item }">
            {{ item.id }}
        </template>
        <template v-slot:item.date="{ item }">
            {{ new Date(item.date).toISOString().substr(0, 10) }}
        </template>
        <template v-slot:item.status="{ item }">
            <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
        </template>
        <template v-slot:item.role="{ item }">
          {{ displayUserRoleName(item.role) }}
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
            >
                edit
            </v-icon>
        </template>
        <template v-slot:no-data>
            Brak przejazdow
        </template>
        <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-4 mb-4 mr-4">
                <v-row>
                    <v-col class="d-flex justify-end" md="12">
                        <v-btn @click="generatePDFReport()" color="primary" dark rounded small>Generuj raport</v-btn>
                    </v-col>
                </v-row>
            </div>
        </template>
    </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import moment from "moment";
import SelectMonths from "./SelectMonths";
import SelectYears from "./SelectYears";
import {UserRoleDescEnum,} from '../enums'

export default {
    components: { SelectMonths, SelectYears },
    mixins: [validationMixin],

    validations: {
      editedItem: {
        city: {required},
        hours: {required}
      }
    },
    name: "AdminTimes",
    computed: {
      ...mapGetters({
        times: 'times/getTimes',
        dialogFromStore: 'times/dialogNew',
        monthSelected: 'monthSelected',
        yearSelected: 'yearSelected',
        userRole: 'users/userRole',
        loggedInUser: 'users/getLoggedInUser'
      }),
      formTitle () {
        return this.editedIndex === -1 ? 'Dodawanie nowego czasu pracy' : 'Edytuj czas pracy'
      },
      cityErrors () {
        const errors = [];
        if (!this.$v.editedItem.city.$dirty) return errors;
        !this.$v.editedItem.city.required && errors.push('Podanie miejscowości jest wymagane');
        return errors
      },
      hoursErrors () {
        const errors = [];
        if (!this.$v.editedItem.hours.$dirty) return errors;
        !this.$v.editedItem.hours.required && errors.push('Podanie liczby godzin jest wymagane');
        return errors
      },
      showExtraActions () {
        return this.$vuetify.breakpoint.mdAndUp
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          return this.setDialogNew(value)
        }
      }
    },
    data: () => ({
      selected: [],
      menuDate: false,
      dialogRemoveSuccess: false,
      search: '',
      headers: [
        { text: 'Data', value: 'date' },
        { text: 'Miasto', value: 'city' },
        { text: 'Ilość godzin', value: 'hours' },
        { text: 'Imię i nazwisko', value: 'username' },
        { text: 'Typ', value: 'role' },
        { text: '', value: 'action', sortable: false },
      ],
      editedIndex: -1,
      online: false,
      editedItem: {
        lp: '',
        date: new Date().toISOString().substr(0, 10),
        city: '',
        hours: '',
        comment: '',
      },
      defaultItem: {
        lp: '',
        date: new Date().toISOString().substr(0, 10),
        city: '',
        hours: '',
        comment: '',
      },
      singleSelect: false
    }),
    methods: {
      ...mapActions({
        setDialogNew: 'times/setDialogNew',
        createTime: 'times/createTime',
        editTime: 'times/editTime',
        deleteTimes: 'times/deleteTimes',
        resetChosenUser: 'users/resetChosenUser',
        setMonthSelected: 'setMonthSelected',
      }),
      editItem (item) {
        this.editedIndex = this.times.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true
        if (this.editedItem.city == 'on-line') {
          this.online = true;
          this.editedItem.city = '';
        }
      },
      close () {
        this.dialog = false;
        this.online = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDialogRemoveSuccess () {
        this.dialogRemoveSuccess = false;
        this.selected = []
      },
      removeSelected () {
        if (this.selected.length == 0) {
          return false
        }
        if(confirm('Czy na pewno chcesz usunąć poniższy czas pracy?')){
          this.deleteTimes(this.selected).then(response => {
            this.$log.info('Deleted time', response);
            this.dialogRemoveSuccess = true
          }).catch(error => {
            this.$log.error('Deleting time error', error)
          })
        }
      },
      generatePDFReport () {
        var table = [];
        table.push(["DZIEŃ MIESIĄCA", "LICZBA GODZIN", "MIEJSCOWOŚĆ", "IMIĘ I NAZWISKO", "TYP"]);
        var tableBody = table.concat(this.times.sort((a, b) => {
          let fa = a.username.toLowerCase(),
              fb = b.username.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        }).map(obj => [new Date(obj.date).toISOString().substr(0, 10), obj.hours, obj.city, obj.username, this.displayUserRoleName(obj.role)]
        ));

        var pdfMake = require('pdfmake/build/pdfmake.js');
        if (pdfMake.vfs == undefined){
          var pdfFonts = require('pdfmake/build/vfs_fonts.js');
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          content: [
            {
              stack: [
                'Ewidencja godzin ' + (this.monthSelected ? 'za miesiąc ' + this.monthSelected.substr(0, 7) : 'za rok ' + this.yearSelected.substr(0,4)),
              ],
              style: 'header'
            },
            {
              style: 'tableExample',
              table: {
                body: tableBody
              }
            },
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 80],
              alignment: "center"
            },
            footer: {
              alignment: "right",
              margin: [0, 20, 0, 20]
            }
          }
        };
        pdfMake.createPdf(docDefinition).download('unikadra-czasy-raport-'+this.monthSelected.substr(0, 7)+'.pdf')
      },
      displayUserRoleName(role) {
        return UserRoleDescEnum[role];
      }
    },
    created() {
      this.resetChosenUser()
      if (this.monthSelected) {
        this.$store.dispatch('times/getTimes', { 'start': moment(this.monthSelected).startOf('month').format('YYYY-MM-DD'), 'end': moment(this.monthSelected).endOf('month').format('YYYY-MM-DD')});
      } else {
        this.$store.dispatch('times/getTimes', { 'start': moment(this.yearSelected).startOf('year').format('YYYY-MM-DD'), 'end': moment(this.yearSelected).endOf('year').format('YYYY-MM-DD')});
      }

      this.unwatchMonth = this.$store.watch(
        (state, getters) => getters.monthSelected,
        (newValue) => {
          this.$store.dispatch(
            'times/getTimes',
            {
              'start': moment(newValue?newValue:this.yearSelected).startOf(newValue?'month':'year').format('YYYY-MM-DD'),
              'end': moment(newValue?newValue:this.yearSelected).endOf(newValue?'month':'year').format('YYYY-MM-DD') }
          );
        },
      );

      this.unwatchYear = this.$store.watch(
        (state, getters) => getters.yearSelected,
        (newValue) => {
          this.setMonthSelected('')
          this.$store.dispatch(
            'times/getTimes',
            {
              'start': moment(newValue).startOf('year').format('YYYY-MM-DD'),
              'end': moment(newValue).endOf('year').format('YYYY-MM-DD') }
          );
        },
      );
    },
    // There is one caveat once using Vuex watch: it returns an unwatch function that should be called in your beforeDestroy hook if you want to stop the watcher. If you don't call this function, the watcher will still be invoked which is not the desired behavior.
    beforeDestroy() {
      this.unwatchMonth();
      this.unwatchYear();
    }
  }
</script>

<style scoped>

</style>
