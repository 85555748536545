import Vue from 'vue'
import Vuex from 'vuex'

import users from './modules/users'
import trips from './modules/trips'
import times from './modules/times'
import places from './modules/places'
import courses from './modules/courses'
import meetings from './modules/meetings'
import cars from './modules/cars'
import reports from './modules/reports'
import bills from './modules/bills'
import studentCourses from './modules/studentCourses'
import evaluations from './modules/evaluations'
import protocols from './modules/protocols'
import paging from './modules/paging'
import candidates from './modules/candidates'
import messages from './modules/messages'
import history from './modules/history'
import historySubject from './modules/historySubject'
import contracts from './modules/contracts'
import subjects from './modules/subjects'
import fileGroups from './modules/fileGroups'
import files from './modules/files'
import settlements from './modules/settlements'
import chat from './modules/chat'
import notifications from './modules/notifications'
import onlineMeetings from './modules/onlineMeetings'
import mentors from './modules/mentoring/mentors'
import tags from './modules/mentoring/tags'
import issues from './modules/issueTracker/issues'
import issueFiles from './modules/issueTracker/issueFiles'

import {createHelpers, getField, updateField} from 'vuex-map-fields';
import user from "@/services/User";
import moment from "moment";

function initialRegistrationStateStudent () {
  return {
    personal: {
      surname: '',
      name: '',
      secondName: '',
      placeOfBirth: '',
      dayOfBirth: '',
    },
    address : {
      city: '',
      street: '',
      houseNr: '',
      homeNr: '',
      zip: '',
    },
    contact : {
      email: '',
      telephoneNr: '',
      bank_account: '',
    },
  }
}

function initialRegistrationState () {
  return {
    registerFormGeneral: {
      formDate: '',
    },
    personal: {
      surname: '',
      name: '',
      secondName: '',
      familyName: '',
      mothersName: '',
      fathersName: '',
      placeOfBirth: '',
      dayOfBirth: '',
      pesel: '',
      nip: '',
      education: '',
      degree: '',
      nationality: '',
      idSeries: '',
      idNumber: ''
    },
    address : {
      province: '',
      district: '',
      municipality: '',
      city: '',
      street: '',
      houseNr: '',
      homeNr: '',
      zip: '',
      post: ''
    },
    contact : {
      email: '',
      telephoneNr: '',
      mobileNr: '',
    },
    taxOffice : {
      name: '',
      city: '',
      street: '',
      houseNr: '',
      zip: ''
    },
    NFZ : {
      name: ''
    },
    insurance : {
      insuranceType: [],
      placesOfWork: [
        {
          id: '0',
          name: '',
          city: '',
          zip: '',
          street: '',
          houseNr: ''
        }
      ],
      placesOfTemporaryWork: [
        {
          id: '0',
          name: '',
          city: '',
          zip: '',
          street: '',
          houseNr: '',
          dateFrom: '',
          dateTo: ''
        }
      ]
    },
    pensionerNFZ: {
      number1: '',
      number2: '',
      nameNFZ: '',
      city: '',
      zip: '',
      street: '',
      houseNr: ''
    },
    student: {
      schoolName: '',
      address: '',
      idNumber: '',
    },
    otherInsurance: {
      title: '',
    },
    additionalInsurance : {
      medical: '',
      social: ''
    },
    other : {
      family: '',
      social: '',
      disabled: '',
      level: ''
    },
    salary : {
      nameForBank: '',
      surnameForBank: '',
      bankName: '',
      accountNumber: '',
    },
    company: {
      name: '',
      nip: '',
      accountNumber: '',
      city: '',
      street: '',
      houseNr: '',
      homeNr: '',
      zip: '',
      post: ''
    }
  }
}

const { getRegisterFormGeneral, updateRegisterFormGeneral } = createHelpers({
  getterType: 'getRegisterFormGeneral',
  mutationType: 'updateRegisterFormGeneral',
});

const { getPersonal, updatePersonal } = createHelpers({
  getterType: 'getPersonal',
  mutationType: 'updatePersonal',
});

const { getAddress, updateAddress } = createHelpers({
  getterType: 'getAddress',
  mutationType: 'updateAddress',
});

const { getContact, updateContact } = createHelpers({
  getterType: 'getContact',
  mutationType: 'updateContact',
});

const { getTaxOffice, updateTaxOffice } = createHelpers({
  getterType: 'getTaxOffice',
  mutationType: 'updateTaxOffice',
});

const { getNFZ, updateNFZ } = createHelpers({
  getterType: 'getNFZ',
  mutationType: 'updateNFZ',
});

const { getInsurance, updateInsurance } = createHelpers({
  getterType: 'getInsurance',
  mutationType: 'updateInsurance',
});

const { getPensionerNFZ, updatePensionerNFZ } = createHelpers({
  getterType: 'getPensionerNFZ',
  mutationType: 'updatePensionerNFZ',
});

const { getStudent, updateStudent } = createHelpers({
  getterType: 'getStudent',
  mutationType: 'updateStudent',
});

const { getOtherInsurance, updateOtherInsurance } = createHelpers({
  getterType: 'getOtherInsurance',
  mutationType: 'updateOtherInsurance',
});

const { getAdditionalInsurance, updateAdditionalInsurance } = createHelpers({
  getterType: 'getAdditionalInsurance',
  mutationType: 'updateAdditionalInsurance',
});

const { getOther, updateOther } = createHelpers({
  getterType: 'getOther',
  mutationType: 'updateOther',
});

const { getSalary, updateSalary } = createHelpers({
  getterType: 'getSalary',
  mutationType: 'updateSalary',
});

const { getCompany, updateCompany } = createHelpers({
  getterType: 'getCompany',
  mutationType: 'updateCompany',
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    monthSelected: moment().format('YYYY-MM-DD'),
    yearSelected: moment().format('YYYY-MM-DD'),
    semesterSelected: '',
    multiSemesterSelected: null,
    halfSelected: null,
    activeSettingsTab: null,
    activeStudentCourseTab: null,
    activeStudentPlacesTab: null
  },
  getters: {
    monthSelected: state => state.monthSelected ? state.monthSelected : '',
    yearSelected: state => state.yearSelected,
    semesterSelected: state => state.semesterSelected,
    multiSemesterSelected: state => state.multiSemesterSelected,
    halfSelected: state => state.halfSelected,
    getActiveSettingsTab: state => state.activeSettingsTab,
    getActiveStudentCourseTab: state => state.activeStudentCourseTab,
    getActiveStudentPlacesTab: state => state.activeStudentPlacesTab
  },
  actions: {
    setMonthSelected ({ commit }, value) {
      commit('setMonthSelected', value)
    },
    setYearSelected ({ commit }, value) {
      commit('setYearSelected', value)
    },
    setSemesterSelected ({ commit }, value) {
      commit('setSemesterSelected', value)
    },
    setMultiSemesterSelected ({ commit }, value) {
      commit('setMultiSemesterSelected', value)
    },
    setHalfSelected ({ commit }, value) {
      commit('setHalfSelected', value)
    },
    setActiveSettingsTab ({ commit }, value) {
      commit('setActiveSettingsTab', value)
    },
    setActiveStudentCourseTab ({ commit }, value) {
      commit('setActiveStudentCourseTab', value)
    }
  },
  mutations: {
    setMonthSelected (state, value) {
      state.monthSelected = value
    },
    setYearSelected (state, value) {
      state.yearSelected = value
    },
    setSemesterSelected (state, value) {
      state.semesterSelected = value
    },
    setMultiSemesterSelected (state, value) {
      state.multiSemesterSelected = value
    },
    setHalfSelected (state, value) {
      state.halfSelected = value
    },
    setActiveSettingsTab (state, value) {
      state.activeSettingsTab = value
    },
    setActiveStudentCourseTab (state, value) {
      state.activeStudentCourseTab = value
    },
    setActiveStudentPlacesTab (state, value) {
      state.activeStudentPlacesTab = value
    }
  },
  modules: {
    users,
    trips,
    times,
    places,
    meetings,
    cars,
    reports,
    bills,
    studentCourses,
    evaluations,
    protocols,
    paging,
    courses,
    candidates,
    messages,
    history,
    historySubject,
    contracts,
    subjects,
    fileGroups,
    files,
    settlements,
    chat,
    notifications,
    onlineMeetings,
    mentors,
    tags,
    issues,
    issueFiles,
    registrationStudent: {
      namespaced: true,
      state: initialRegistrationStateStudent,
      getters: {
        getField,
        getPersonal,
        getAddress,
        getContact,
      },
      actions: {
        saveUser({ state, dispatch, rootState }) {
          return new Promise((resolve, reject) => {
            const registrationForm = JSON.stringify(state);
            user.createUser({ email: state.contact.email, name: state.personal.surname + ' ' + state.personal.name, telephone: state.contact.telephoneNr, registration_form: registrationForm, user_role: 3}).then((response) => {
              if(response.status == 200) {
                resolve(response)
              }
            })
              .catch((errors) => {
                reject(errors)
              })
          }).catch((error) => { console.log(error) });
        },
        updateUser({ state, dispatch, rootState }) {
          return new Promise((resolve, reject) => {
            const registrationForm = JSON.stringify(state);
            const userData = {
              uid: rootState.studentCourses.chosenStudentCourseUser,
              registrationForm: registrationForm,
              name: state.personal.surname + ' ' + state.personal.name,
              email: state.contact.email,
              telephone: state.contact.telephoneNr,
              bank_account: rootState.studentCourses.studentCourses.find(item => item.uid == rootState.studentCourses.chosenStudentCourseUser).bank_account,
              is_admin: rootState.studentCourses.studentCourses.find(item => item.uid == rootState.studentCourses.chosenStudentCourseUser).is_admin
            };
            user.saveRegistrationForm(
              userData,
              ).then((response) => {
              if(response.status == 200) {
                resolve({'registration_form': { 'address': state.address, 'contact': state.contact, 'personal': state.personal }, ...response})
              }
            })
              .catch((errors) => {
                reject(errors)
              })
          }).catch((error) => { console.log(error) });
        },
        setRegistrationFromJson({commit}, registrationFormJson) {
          commit('setRegistration', registrationFormJson)
        },
        clearRegistrationForm({commit}) {
          commit('clearRegistration')
        }
      },
      mutations: {
        updateField,
        updatePersonal,
        updateAddress,
        updateContact,
        setRegistration(state, registrationJson) {
          let parsed = JSON.parse(registrationJson);
          state.personal = parsed.personal;
          state.address = parsed.address;
          state.contact = parsed.contact;

          // if student is also a professor (student doesnt have mobile number, just regular number)
          if (parsed.contact.telephoneNr == '') {
            state.contact.telephoneNr = parsed.contact.mobileNr;
          }
        },
        clearRegistration(state) {
          const s = initialRegistrationStateStudent();
          Object.keys(s).forEach(key => {
            state[key] = s[key]
          })
        }
      }
    },
    registration: {
      namespaced: true,
      state: initialRegistrationState,
      getters: {
        getField,
        getRegisterFormGeneral,
        getPersonal,
        getAddress,
        getContact,
        getTaxOffice,
        getNFZ,
        getInsurance,
        getPensionerNFZ,
        getStudent,
        getOtherInsurance,
        getAdditionalInsurance,
        getOther,
        getSalary,
        getCompany
      },
      actions: {
        setInsturancetype ({commit}, insuranceType) {
          commit('setInsuranceType', insuranceType)
        },
        saveAll({ state, dispatch, rootState }) {
          return new Promise((resolve, reject) => {
            const registrationForm = JSON.stringify(state);
            let userUpdatedId = rootState.users.chosenUser != null ?rootState.users.chosenUser:rootState.users.loggedInUser.id;
            let isCompany = rootState.users.chosenUser != null ? rootState.users.users.find(item => item.id == userUpdatedId).is_company : rootState.users.loggedInUser.is_company;
            const userData = {
              uid: userUpdatedId,
              registrationForm: registrationForm,
              email: state.contact.email,
              telephone: state.contact.mobileNr
            };
            if(!isCompany) {
              userData['name'] = state.personal.surname + ' ' + state.personal.name;
            }

            user.saveRegistrationForm(userData).then((response) => {
              // console.log(response)
              if(response.status == 200) {
                dispatch('users/setUserRegistrationForm', userData, {root: true})
                resolve(200);
              } else {
                reject();
              }
            }).catch((errors) => {
              reject(errors)
            });
          }).catch((error) => { console.log(error) });
        },
        setRegistrationFromJson({commit}, registrationFormJson) {
          commit('setRegistration', registrationFormJson)
        },
        clearRegistrationForm({commit}) {
          commit('clearRegistration')
        }
      },
      mutations: {
        updateField,
        updateRegisterFormGeneral,
        updatePersonal,
        updateAddress,
        updateContact,
        updateTaxOffice,
        updateNFZ,
        updateInsurance,
        updatePensionerNFZ,
        updateStudent,
        updateOtherInsurance,
        updateAdditionalInsurance,
        updateOther,
        updateSalary,
        updateCompany,
        setInsuranceType(state, insuranceType) {
          state.insurance.insuranceType = insuranceType
        },
        addPlaceOfWork(state) {
          state.insurance.placesOfWork.push({
            id: state.insurance.placesOfWork.length,
            name: '',
            city: '',
            zip: '',
            street: '',
            houseNr: ''
          });
        },
        addPlaceOfTemporaryWork(state) {
          state.insurance.placesOfTemporaryWork.push({
            id: state.insurance.placesOfTemporaryWork.length,
            name: '',
            city: '',
            zip: '',
            street: '',
            houseNr: '',
            dateFrom: '',
            dateTo: ''
          });
        },
        setRegistration(state, registrationJson) {
          let parsed = JSON.parse(registrationJson);
          if (parsed.insurance != undefined && typeof parsed.insurance.insuranceType !== "object") {
            let insuranceType = [];
            parsed.insurance.insuranceType = insuranceType.push(parsed.insurance.insuranceType);
            parsed.insurance.insuranceType = insuranceType;
          }
          state.registerFormGeneral = parsed.registerFormGeneral ? parsed.registerFormGeneral : {'formDate': null},
          state.personal = parsed.personal;
          state.address = parsed.address;
          state.contact = parsed.contact;
          state.taxOffice = parsed.taxOffice;
          state.NFZ = parsed.NFZ;
          state.insurance = parsed.insurance;
          state.pensionerNFZ = parsed.pensionerNFZ;
          state.student = parsed.student;
          state.additionalInsurance = parsed.additionalInsurance;
          state.other = parsed.other;
          state.salary = parsed.salary;
          state.company = parsed.company;
        },
        clearRegistration(state) {
          const s = initialRegistrationState();
          Object.keys(s).forEach(key => {
            state[key] = s[key]
          })
        }
      }
    }
  }
})
