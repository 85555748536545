<template>
  <v-select v-model="subjectSelected"
            :items="itemSubjects"
            :item-text="item => item.name + (item.semester == undefined ? '' : ' (semestr ' + item.semester + ')')"
            item-value="id"
            menu-props="auto"
            :label=subjectSelected
            placeholder="Wszystkie przedmioty"
            single-line
            chips
            deletable-chips
            class="ml-4"
  >
  </v-select>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'SelectSubjects',
  methods: {
    ...mapActions({
      setSubjectSelected: 'subjects/setSubjectSelected',
    })
  },

  computed: {
    ...mapGetters({
      subjectSelectedFromStore: 'subjects/getSubjectSelected',
      subjects: 'subjects/getSubjectsBySelectedCourseId'
    }),
    itemSubjects() {
      let subjects = Object.assign(this.subjects, this.subjects)
      subjects.push({ 'id': '', 'name': 'Wszystkie przedmioty'})
      return subjects;
    },
    subjectSelected: {
      get() {
        return this.subjectSelectedFromStore
      },
      set(value){
        this.$emit('subjectChanged', value)
        return this.setSubjectSelected(value)
      }
    },
  }
}
</script>
