<template>
  <div>
    <div class="d-flex justify-center pb-4">
      <v-img src="../assets/logo_logowanie.png" max-width="150"></v-img>
    </div>
    <v-card :width=this.width class="rounded-card">
      <v-form @submit.prevent="login">
        <v-card-title>
          <h1>Panel logowania</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    label="Adres e-mail"
                    prepend-icon="account_circle"
                    required
                    @blur="$v.email.$touch()"
            />
            <v-text-field
                    v-model="password"
                    label="Hasło"
                    prepend-icon="lock"
                    type="password"
            />
        </v-card-text>
        <v-alert v-if="this.error" class="ma-4" dense outlined type="error">Podany adres e-mail lub hasło są nieprawidłowe. Sprobuj ponownie lub użyj <router-link class="error-message" to="/lost-password-email">przypomnienia</router-link></v-alert>
        <v-card-actions class="d-flex pa-2 justify-end">
          <v-btn :loading="loading" class="text-right mr-2" color="secondary" rounded type="submit">Zaloguj się</v-btn>
        </v-card-actions>
        <v-divider class="mt-2"></v-divider>
        <p class="pa-4"><router-link to="/lost-password-email">Nie pamiętasz hasła lub adresu e-mail?</router-link></p>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {validationMixin} from 'vuelidate'
import {email, required} from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
      email: { required, email }
    },
    name: "Login",
    data () {
      return {
        email: '',
        password: '',
        error: false,
        loader: null,
        loading: false
      }
    },
    methods: {
      ...mapActions({
        loginUser: 'users/loginUser'
      }),
      login () {
        this.$v.$touch()
        if (!this.$v.email.$invalid) {
          this.loader = 'loading'
          this.loading = true
          this.loginUser({email: this.email, password: this.password}).then(response => {
            this.$log.info('Login', response)
          }, error => {
            if (error.code == 'ERR_BAD_RESPONSE') {
              window.location.href = "/#/error-500";
            }
            this.error = true
            this.loading = false
            this.$log.error('Login error', error)
          })
        }
      }
    },
    computed: {
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Podany adres e-mail jest niepoprawny')
        !this.$v.email.required && errors.push('Podanie e-maila jest wymagane')
        return errors
      },
      width () {
        return this.$vuetify.breakpoint.xsOnly ? "350" : "450"
      }
    }
  }
</script>

<style>
  .rounded-card {
      border-radius: 25px !important;
  }
  .error-message {
    font-weight: bold;
    text-decoration: underline;
    color: var(--v-error-base) !important;
  }
</style>
