<template>
  <v-data-table
      :headers="headers"
      :items="evaluationsToDisplay()"
      sort-by="date"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
      :search="options.search"
      :loading="loading"
      loading-text="Wczytuję listę... proszę czekać"
      item-key="key"
      :options.sync="options"
      v-if="userRole == 0 || userRole == 1 || userRole == 5 || userRole == 8"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Dziennik (stary)</v-toolbar-title>

      </v-toolbar>
      <v-toolbar flat color="white">
        <SelectSemesters @semesterChanged="semesterChanged"/>
        <SelectCourses @courseChanged="courseChanged"/>
        <SelectSubjects @subjectChanged="subjectChanged"/>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="options.search"
            append-icon="search"
            label="Szukaj"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
<!--        <v-btn :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczonych słuchaczy</v-btn>-->
        <v-dialog v-model="evaluationDialog" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Oceny</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Nazwa przedmiotu
                    </th>
                    <th class="text-left">
                      Ocena
                    </th>
                    <th class="text-left">
                      Wykładowca
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="subject in getEvaluationsByUserIdAndEvaluation(getChosenEvaluation)"
                      :key="subject"
                  >
                    <td>{{ subject.subject_name }}</td>
                    <td>
                      {{ subject.grade }}
                    </td>
                    <td>
                      {{ loggedInUser.id == subject.professor_id ? 'JA - ' : '' }} {{ subject.professor_name }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-if="userRole == 1" v-slot:item.changes="{ item }">
      <!-- history -->
      <v-tooltip top v-if="history(item.uid)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              color="red"
              v-bind="attrs"
              v-on="on">
            warning
          </v-icon>
        </template>
        <span>W ciągu ostatniego miesiąca wprowadzono zmiany w kwestionariuszu osobowym</span>
      </v-tooltip>
    </template>
    <template v-slot:item.date="{ item }">
      {{ new Date(item.date) | moment }}
    </template>
    <template v-slot:item.grade="{ item }">
      <ProfessorGrade
          :changed-grade="changedGrade.find(x => x == item.subjectId)"
          :professorId="item.professor_id"
          :grade="item.grade"
          :subjectId="item.subjectId"
          :eid="item.eid"
          :id="item.esid"
          :uid="item.uid"
      />
    </template>
    <template v-slot:item.status="{ item }">
      <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
    </template>
    <template v-slot:item.action="{ item }">
      <a @click="showGrades(item.id, item.uid);">
        <v-icon
            small
            class="mr-8"
        >
        </v-icon>Podgląd
      </a>
      &nbsp;
      <a @click="setChosenEvaluation(item.id); $router.push('/professor-evaluations/' + item.uid + '/true')">
        <v-icon
            small
            class="mr-0"
        >
        </v-icon>Edycja
      </a>
    </template>
    <template v-slot:no-data>
      Brak słuchaczy
    </template>
    <template v-slot:footer>
      <v-divider></v-divider>
      <div class="mt-4 mb-4 mr-4">
        <v-row>
          <v-col class="d-flex" md="3">
              <a class="ml-4" v-if="subjectSelected && getLastProtocolByType(1)" @click="downloadProtocol(JSON.parse(getLastProtocolByType(1).pdfData), getLastProtocolByType(1).title)">
                <v-icon class="gray--text">picture_as_pdf</v-icon> protokół_sesja_{{ getLastProtocolByType(1).type | translateProtocolType }}.pdf
              </a>
          </v-col>
          <v-col class="d-flex justify-start" md="4">
              <a class="ml-10" v-if="subjectSelected && getLastProtocolByType(2)" @click="downloadProtocol(JSON.parse(getLastProtocolByType(2).pdfData), getLastProtocolByType(2).title)">
                <v-icon class="gray--text">picture_as_pdf</v-icon> protokół_sesja_{{ getLastProtocolByType(2).type | translateProtocolType }}.pdf
              </a>
          </v-col>
          <v-col class="d-flex justify-end" md="5">
            <v-toolbar flat color="white">
  <!--            <v-btn :color=buttonForRemove class="mr-4" @click="newMessage()" dark rounded small>Napisz wiadomość</v-btn>-->
              <v-select v-model="protocolTypeSelected"
                        :items="getProtocolTypesForGeneration()"
                        item-text="value"
                        item-value="key"
                        menu-props="auto"
                        :label=protocolTypeSelected
                        single-line
                        placeholder="Wybierz rodzaj protokołu"
                        class="text-right ml-2"
              >
              </v-select>
              <v-spacer></v-spacer>
              <v-tooltip top v-if="(courseSelected == null || courseSelected.length == 0 || subjectSelected == null || subjectSelected.length == 0 || semesterSelected == null || semesterSelected.length == 0 || evaluationsToDisplay().length == 0 || protocolTypeSelected == null)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="mr-4"  color="grey" dark rounded small>Generuj protokół</v-btn>
                </template>
                <span>Aby wygenerować protokół, najpierw użyj filtrów aby wybierać semestr, kierunek i konkretny przedmiot oraz typ protokołu</span>
              </v-tooltip>
              <v-btn class="mr-4" v-else @click="generateProtocol()" color="primary" dark rounded small>Generuj protokół</v-btn>
            </v-toolbar>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SelectSemesters from "./SelectSemesters";
import SelectCourses from "./SelectCourses";
import SelectSubjects from "./SelectSubjects";
import moment from "moment";
import ProfessorGrade from "../components/ProfessorGrade";
import {ComponentWithPagingEnum} from '../enums';

export default {
  name: "Evaluations",
  components: {ProfessorGrade, SelectSemesters, SelectCourses, SelectSubjects },
  filters: {
    moment: function (date) {
      return moment(date).format('MMMM YYYY');
    }
  },
  computed: {
    ...mapGetters({
      // evaluations
      evaluations: 'evaluations/getEvaluations',
      getEvaluationsBySubjectId: 'evaluations/getEvaluationsBySubjectId',
      dialogFromStore: 'evaluations/dialogNew',
      protocolTypeSelectedFromStore: 'evaluations/protocolTypeSelected',
      getChosenEvaluation: 'evaluations/getChosenEvaluation',
      getEvaluationsByUserIdAndEvaluation: 'evaluations/getEvaluationsByUserIdAndEvaluation',

      // courses
      courses: 'courses/getCourses',
      coursesWithoutAllOption: 'courses/getCoursesWithoutAllOption',
      courseSelected: 'courses/getCourseSelected',

      // places
      places: 'places/getPlaces',
      coordinators: 'places/getCoordinators',

      // users
      userRole: 'users/userRole',
      loggedInUser: 'users/getLoggedInUser',
      getNameById: 'users/getNameById',
      semesterSelected: 'semesterSelected',

      getLastProtocolByType: 'protocols/getLastProtocolByType',
      subjectSelected: 'subjects/getSubjectSelected',

      history: 'history/getHistoryRecent',

      getPage: 'paging/getPage'
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodaj nowego słuchacza' : 'Edytuj słuchacza'
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
    semesterChoices: {
      get() {
        return [
          {
            'key': moment('2022-03-01').format('YYYY-MM-DD'),
            'value': moment('2022-03-01').locale('pl').format('MMMM YYYY')
          },
          {
            'key': moment('2021-10-01').format('YYYY-MM-DD'),
            'value': moment('2021-10-01').locale('pl').format('MMMM YYYY')
          },
          {
            'key': moment('2021-03-01').format('YYYY-MM-DD'),
            'value': moment('2021-03-01').locale('pl').format('MMMM YYYY')
          },
          {
            'key': moment('2020-10-01').format('YYYY-MM-DD'),
            'value': moment('2020-10-01').locale('pl').format('MMMM YYYY')
          },

        ]
      },
      set(value){
        return this.setMonthSelected(value)
      }
    },
    protocolTypeSelected: {
      get() {
        return this.protocolTypeSelectedFromStore
      },
      set(value){
        return this.setProtocolTypeSelected(value)
      }
    },

  },
  mounted () {
    this.setCourseSelected(null);
    this.setSelectedComponent(ComponentWithPagingEnum.evaluations);
    this.options = this.getPage;
  },
  data: () => ({
    loading: false,
    ansImage: "",
    selected: [],
    menuDate: false,
    dialogNewSuccess: false,
    dialogNewSuccessUserExists: false,
    dialogNewError: false,
    dialogRemoveSuccess: false,
    dialogMessage: false,
    headers: [
      { text: 'Nazwisko i imię', value: 'username' },
      { text: '', value: 'changes' },
      { text: 'Kierunek', value: 'courseName' },
      { text: 'Przedmiot', value: 'subjectName' },
      { text: 'Semestr', value: 'date' },
      { text: 'Numer semestru', value: 'semester' },
      { text: 'Ocena', value: 'grade' },
      { text: '', value: 'action', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      cid: '',
      cid_2: '',
      pid: '',
      price: '',
      comment: '',
      coordinator: '',
      date: ''
    },
    defaultItem: {
      cid: '',
      cid_2: '',
      pid: '',
      price: '',
      comment: '',
      coordinator: '',
      date: ''
    },
    singleSelect: false,
    validate: false,
    report: false,
    evaluationDialog: false,
    changedGrade: [],
    options: {},
  }),
  watch: {
    dialogNewSuccess (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    dialogNewSuccessUserExists (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    dialog (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    options: {
      handler () {
        this.handlePage()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setDialogNew: 'evaluations/setDialogNew',
      deleteEvaluations: 'evaluations/deleteEvaluations',
      setChosenEvaluation: 'evaluations/setChosenEvaluation',
      resetChosenEvaluation: 'evaluations/resetChosenEvaluation',
      studentEvaluations: 'evaluations/getEvaluationsByUserId',
      clearEvaluations: 'evaluations/clearEvaluations',
      clearSubjects: 'subjects/clearSubjects',
      setPage: 'paging/setPage',
      setSelectedComponent: 'paging/setSelectedComponent',
      clearPage1: 'paging/clearPage',
      saveProtocol: 'protocols/saveProtocol',
      clearProtocols: 'protocols/clearProtocols',
      setProtocolTypeSelected: 'evaluations/setProtocolTypeSelected',
      setCourseSelected: 'courses/setCourseSelected'
    }),
    getProtocolTypesForGeneration() {
      let protocolTypes = [];
      let today = moment();

      let firstFrom1 = moment(new Date().getFullYear() + '-02-26','YYYY-MM-DD');
      let firstTo1 = moment(new Date().getFullYear() + '-03-12','YYYY-MM-DD'); // till end of 11
      let firstFrom2 = moment(new Date().getFullYear() + '-06-28','YYYY-MM-DD');
      let firstTo2 = moment(new Date().getFullYear() + '-07-11','YYYY-MM-DD');

      let secondFrom1 = moment(new Date().getFullYear() + '-03-12','YYYY-MM-DD'); // from begining of 12
      let secondTo1 = moment(new Date().getFullYear() + '-04-04','YYYY-MM-DD');
      let secondFrom2 = moment(new Date().getFullYear() + '-07-11','YYYY-MM-DD');
      let secondTo2 = moment(new Date().getFullYear() + '-08-11','YYYY-MM-DD');

      let monthsFromStart = moment().diff(moment(this.semesterSelected), 'months', true);

      if (this.userRole == 1 || ((this.userRole == 0 || this.userRole == 5 || this.userRole == 8) && (monthsFromStart > 3 && monthsFromStart < 7) && today.isBetween(firstFrom1, firstTo1) || today.isBetween(firstFrom2, firstTo2))) {
        protocolTypes.push({ value: 'Generuj z sesji 1', key: 1});
      }

      if (this.userRole == 1 || ((this.userRole == 0 || this.userRole == 5 || this.userRole == 8) && (monthsFromStart > 3 && monthsFromStart < 7) && today.isBetween(secondFrom1, secondTo1) || today.isBetween(secondFrom2, secondTo2))) {
        protocolTypes.push({value: 'Generuj z sesji poprawkowej', key: 2});
      }
      return protocolTypes;
    },
    clearPage () {
      this.clearPage1();
      this.options.page = 1;
    },
    handlePage() {
      let { sortBy, sortDesc, page, itemsPerPage, search } = this.options;
      this.setPage({ sortBy: sortBy, sortDesc: sortDesc, page: page, itemsPerPage: itemsPerPage, search: search })

    },
    getProtocols(subjectId) {
      if (!this.courseSelected || !this.semesterSelected || !subjectId) {
        this.clearProtocols();
        this.getLastProtocolByType(1);
        this.getLastProtocolByType(2);
      } else {
        this.loading = true;
        this.$store.dispatch(
            'protocols/getProtocols',
            {
              'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
              'cid': this.courseSelected,
              'sid': subjectId
            }
        ).then(() => {
          this.loading = false;
        });
      }
    },
    getBase64ImageFromURL(url) {
      let file = new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          var dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        };

        img.onerror = error => {
          reject(error);
        };

        img.src = url;
      }).catch((error) => { console.log(error) });
      return file;
    },
    evaluationsToDisplay() {
      if(this.subjectSelected !== null) {
        return this.getEvaluationsBySubjectId(this.subjectSelected)
      } else {
        return this.evaluations;
      }
    },
    showGrades(itemId, userId) {
      this.setChosenEvaluation(itemId)
      this.studentEvaluations(userId);
      this.evaluationDialog = true;
    },
    editItem (item) {
      this.setChosenEvaluation(item.id)
      this.editedIndex = this.evaluations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true
    },
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć tego słuchacza?')){
        this.deleteEvaluations(this.selected).then(response => {
          this.$log.info('Deleted user', response);
          this.dialogRemoveSuccess = true
        }).catch(error => {
          this.$log.error('Deleting user error', error)
        })
      }
    },
    newMessage() {
      if (this.selected.length == 0) {
        return false
      }
      this.dialogMessage = true;
    },
    async generateProtocol() {
      var table = [];
      table.push(["l.p.", "Nazwisko", "Imię", "Ocena ostateczna", "Podpis egzaminatora"]);

      function getEvaluationItems() {
        return function (obj, index) {
          const i = obj.username.lastIndexOf(' ');
          return [index + 1, obj.username.slice(0, i), obj.username.slice(i + 1), ((obj.grade == undefined) ? '' : obj.grade), ''];
        }
      }

      let itemsInProtocol = this.evaluationsToDisplay();
      if (this.selected && this.selected.length > 0) {
        itemsInProtocol = this.selected;
      }

      var tableBody = table.concat(itemsInProtocol.sort((a, b) => {
        let fa = a.username.toLowerCase(),
            fb = b.username.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      }).map(getEvaluationItems()
      ));

      let c = [];
      c.push({
        image: await this.getBase64ImageFromURL("api/evaluations/logoForReport"),
        style: 'header'
      });
      c.push({
        stack: [
          '20-816 Lublin, ul. Choiny 2, tel. 81 740 72 40, www.wssp.edu.pl',
        ],
        style: ['center', 'singleLine']
      });
      c.push({
        canvas: [ { type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 } ],
        style: 'header'
      });
      c.push({
        columns: [{
          text: 'Akademia Nauk Stosowanych Wincentego Pola w Lublinie\n',
          style: ['dataLeft', 'bold']
        }, {text: 'PROTOKÓŁ', style: ['bold', 'center']}]
      })
      c.push({
        columns: [{text: '', style: 'dataLeft'}, {
          text: 'Egzamin/Zaliczenie (niepotrzebne skreślić)',
          style: 'data'
        }]
      })
      c.push({
        columns: [
          {
            text: 'Studia podyplomowe',
            style: 'dataLeft'
          },
          {
            text: ['Z przedmiotu: ', { text: this.evaluationsToDisplay()[0].subjectName, style: 'bold'}], style: 'data'
          }
        ]
      });
      c.push({
        columns: [
          {
            text: ['Zakres studiów: ', { text: this.evaluationsToDisplay()[0].courseName, style: 'bold'}],
            style: 'dataLeft'
          },
          {
            text: 'z dnia: ................................................', style: 'data'
          }
        ]
      });
      c.push({
        columns: [
          {
            text: 'Semestr: ' + this.evaluationsToDisplay()[0].semester,
            style: 'dataLeft'
          },
          {
            text: ['Egzaminator: ', { text: (this.userRole == 0 || this.userRole == 5) ? (JSON.parse(this.loggedInUser.registration_form).personal.degree ? JSON.parse(this.loggedInUser.registration_form).personal.degree + ' ' + this.loggedInUser.name : this.loggedInUser.name) : this.getNameById(this.evaluationsToDisplay()[0].professor_id), style: 'bold'}]
          }
          ]
      })
      c.push({
        style: 'tableExample',
        table: {
          body: tableBody
        }
      });
      c.push({text: '..............................', style: 'signature'})
      c.push({text: 'podpis osoby upoważnionej', style: 'signatureLabel'})
      var docDefinition = {
        content: c,
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
            alignment: "center"
          },
          center: {
            alignment: "center"
          },
          dataLeft: {
            fontSize: 12,
            alignment: "justify",
            margin: [0, 0, 20, 20],
          },
          bold: {
            bold: true,
          },
          data: {
            fontSize: 12,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 20],
          },
          footer: {
            alignment: "right",
            margin: [0, 20, 0, 20]
          },
          label: {
            fontSize: 8,
            bold: false,
            alignment: "left"
          },
          singleLine: {
            margin: [0, 0, 0, 20],
          },
          singleLineBold: {
            bold: true,
            margin: [0, 0, 0, 20],
          },
          signature: {
            alignment: "right",
            margin: [20, 30, 30, 0]
          },
          signatureLabel: {
            alignment: "right",
            margin: [0, 0, 30, 0],
            fontSize: 8,
            bold: false,
          }
        }
      };


      let title = 'Protokół dla naboru ' + this.evaluationsToDisplay()[0].subjectName + ' ' + moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') + '.pdf';

      this.saveProtocol({
        pdf_data: JSON.stringify(docDefinition),
        date: this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
        cid: this.courseSelected[0],
        sid: this.subjectSelected,
        uid_professor: (this.userRole == 0 || this.userRole == 5) ? this.loggedInUser.id : this.evaluationsToDisplay()[0].professor_id,
        type: this.protocolTypeSelectedFromStore,
        title: title
      }).then(response => {
        if (response == 200) {
          this.$log.info('Saved protocol ', response);

          this.downloadProtocol(docDefinition, title);

          // refresh list
          this.getProtocols(this.subjectSelected);
        }
      }).catch(error => {
        this.$log.error('Saving protocol error', error)
      })
    },

    downloadProtocol(pdfData, title) {
      var pdfMake = require('pdfmake/build/pdfmake.js');
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js');
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      pdfMake.createPdf(pdfData).download(title);
    },

    closeDialogMessage(val) {
      this.dialogMessage = val;
    },
    semesterChanged(date) {
      if((this.courseSelected !== null && this.courseSelected.length > 0) || date !== '' || (this.userRole == 0 || this.userRole == 5)) {
        this.loading = true;
        this.$store.dispatch(
            'evaluations/getEvaluations',
            {
              'date': date ? moment(date).startOf('month').format('YYYY-MM-DD') : '',
              'cid': this.courseSelected
            }
        ).then(() => {
          this.loading = false;
          this.getProtocols(this.subjectSelected);
        });
      } else {
        this.clearEvaluations();
      }
      this.clearPage();
    },
    courseChanged(courseId) {
      if (courseId.length > 0 || this.semesterSelected !== '' || (this.userRole == 0 || this.userRole == 5)) {
        this.loading = true;
        this.$store.dispatch(
            'evaluations/getEvaluations',
            {
              'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
              'cid': courseId
            }
        ).then(() => {
          this.loading = false;
        });
      } else {
        this.clearEvaluations();
      }
      if (courseId.length == 0) {
        this.clearSubjects()
      }
      this.clearPage();
    },
    subjectChanged(subjectId) {
      if (subjectId == null && this.semesterSelected === '' && this.courseSelected.length === 0) {
        this.clearEvaluations();
      }
      this.clearPage();

      this.getProtocols(subjectId);
    },
    selectedItems() {
      let mapped = Object.assign({}, this.selected.map(x => x.email ));
      return { email: mapped, subject: '' };
    }
  },
  created() {
    this.resetChosenEvaluation();

    if(this.semesterSelected || (this.userRole == 0 || this.userRole == 5)) {
      this.$store.dispatch(
          'evaluations/getEvaluations',
          {
            'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
            'cid': this.courseSelected
          }
      ).then(() => {
        this.loading = false;
      });
    }
    this.$store.dispatch('courses/getCourses');
    this.$store.dispatch('history/getHistoryRecent');
  }
}
</script>

<style scoped>
.toPrint {
  display: none;
}
.evaluationUserDetail {
  white-space: nowrap;
}
</style>
