import historySubject from '../../services/HistorySubject'

// initial state
const state = {
  items: [],
};

// getters
const getters = {
  getFormDataHistory: state => state.items
};

// actions
const actions = {
  getHistoryByCourseId ({ commit, rootState }) {
    historySubject.getHistoryByCourseId(rootState.courses.courseSelected).then(response => {
      commit('setHistory', response.data)
    })
  },
  delete ({ commit }, deletedHistories) {
    return new Promise((resolve, reject) => {
      historySubject.deleteMultiple(deletedHistories.map(function(item) { return item["id"] })).then(response => {
        if (response.status == 200) {
          commit('deleteHistories', deletedHistories)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
};

// mutations
const mutations = {
  setHistory (state, history) {
    state.items = history
  },
  deleteHistories (state, historyItems) {
    historyItems.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
