<template>
    <div>
        <div class="d-flex justify-center pb-4">
            <v-img src="../assets/logo_logowanie.png" max-width="150"></v-img>
        </div>
        <v-card :width=this.width class="rounded-card">
            <v-card-title>
                <h1>Przypomnienie hasła lub e-mail</h1>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <h3>Wybierz element którego nie pamiętasz, pomożemy:</h3>

                <v-form @submit.prevent="login">
                    <v-radio-group v-model="selected" row mandatory>
                        <v-radio
                                :label="`Adres e-mail`"
                                value="email"
                        ></v-radio>
                        <span class="pa-4">lub</span>
                        <v-radio
                                :label="`Hasło`"
                                value="password"
                        ></v-radio>
                    </v-radio-group>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex pa-2 justify-end">
                <v-btn rounded @click="next" color="secondary" class="text-right mr-2">Przejdź dalej</v-btn>
            </v-card-actions>
            <v-divider class="mt-2"></v-divider>
            <p class="pa-4"><v-icon small left>arrow_back</v-icon><router-link to="/">Powrót do logowania</router-link></p>
        </v-card>
    </div>
</template>

<script>
  export default {
    name: "RestorePasswordOrEmail",
    data () {
      return {
        selected: 'email'
      }
    },
    methods: {
      next () {
        if (this.selected == 'password') {
          this.$router.push('/restore-password')
        } else {
          this.$router.push('/restore-email')
        }
      }
    },
    computed: {
      width () {
        return this.$vuetify.breakpoint.xsOnly ? "350" : "450"
      }
    }
  }
</script>

<style scoped>

</style>
