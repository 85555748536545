import mentor from '../../../services/mentoring/Mentor'

// initial state
const state = {
  items: [],
  mentorsTags: [],
  dialogNew: false,
  mentorSelected: null
};

// getters
const getters = {
  getMentors: state => state.items,
  getMentorsTags: state => mentorId => state.mentorsTags.filter(x => x.mid == mentorId),
  getMentorsTagsAll: state => state.mentorsTags,
  dialogNew: state => state.dialogNew,
  getMentorSelected: state => state.mentorSelected,
  getCoordinators: state => {
    let result = []
    state.items.forEach(function(item) {
      if (item.coordinator_username) {
        let splitted = item.coordinator_username.split(',')
        splitted.forEach(function(i) {
          result.push(i);
        });
      }
    })
    return result;
  }
};

// actions
const actions = {
  getMentors ({ commit }) {
    mentor.getMentors().then(response => {
      commit('setMentors', response.data)
    })
  },
  getMentorsTags ({ commit }) {
    mentor.getMentorsTags().then(response => {
      commit('setMentorsTags', response.data)
    })
  },
  downloadBusinessCardByUrl ({ commit }, url) {
    return new Promise((resolve, reject) => {
      mentor.getFileByUrl(url).then(response => {
        if(response.status == 200) {
          var blob = new Blob([response.data]);

          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'wiz.' + url.split('.').pop();
          link.click();
          link.remove();

        } else {
          reject();
        }
      })
    }).catch((error) => { console.log(error) });
  },
  downloadDocumentByUrl ({ commit }, url) {
    return new Promise((resolve, reject) => {
      mentor.getFileByUrl(url).then(response => {
        if(response.status == 200) {
          var blob = new Blob([response.data]);

          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'document.' + url.split('.').pop();
          link.click();
          link.remove();

        } else {
          reject();
        }
      })
    }).catch((error) => { console.log(error) });
  },
  createMentor ({ commit }, newMentor) {
    return new Promise((resolve, reject) => {
      mentor.createMentor(newMentor).then(response => {
        if(response.status == 200) {
          commit('addMentor', response.data);
          resolve(response);
        } else {
          reject();
        }
      });
    }).catch((error) => { console.log(error) });
  },
  editMentor ({ commit }, edited) {
    return new Promise((resolve, reject) => {
      mentor.editMentor(edited.id, edited.formData).then(response => {
        if(response.status == 200) {
          commit('editMentor', response.data);
          resolve(response);
        } else {
          reject();
        }
      });
    }).catch((error) => { console.log(error) });
  },
  deleteMentors ({ commit }, deletedMentors) {
    mentor.deleteMentors(deletedMentors.map(function(item) { return item["id"] })).then(response => {
      if (response.status == 200) {
        commit('deleteMentors', deletedMentors)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setMentorSelected ({ commit }, value) {
    commit('setMentorSelected', value)
  },
};

// mutations
const mutations = {
  setMentors (state, mentors) {
    state.items = mentors
  },
  setMentorsTags (state, mentorsTags) {
    state.mentorsTags = mentorsTags
  },
  addMentor (state, mentor) {
    state.items.push(mentor)
  },
  editMentor (state, mentorE) {
    let mentor = state.items.find(item => item.id == mentorE.id);
    mentor.firstname = mentorE.firstname;
    mentor.lastname = mentorE.lastname;
    mentor.lastname = mentorE.lastname;
    mentor.email = mentorE.email;
    mentor.telephone = mentorE.telephone;
    mentor.description = mentorE.description;
    mentor.tags = mentorE.tags;
    if (mentorE.business_card) {
      mentor.business_card = mentorE.business_card;
    }
  },
  deleteMentors (state, mentors) {
    mentors.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setMentorSelected (state, value) {
    state.mentorSelected = value
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
