<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <p>Przekazać przelewem na konto. Właścicielem rachunku bankowego jest:</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="surnameForBank"
                        label="Nazwisko"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="nameForBank"
                        label="Imię"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="bankName"
                        label="Nazwa banku"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="accountNumber"
                        label="Numer rachunku"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  import {createHelpers} from 'vuex-map-fields';

  const { mapFields } = createHelpers({
    getterType: 'registration/getSalary',
    mutationType: 'registration/updateSalary',
  });

  export default {
    name: 'Salary',
    computed: {
      ...mapFields([
        'salary.nameForBank',
        'salary.surnameForBank',
        'salary.bankName',
        'salary.accountNumber'
      ])
    }
  }
</script>
