import Api from './Api'

export default {
  createMeeting (meeting, userId = null) {
    return Api().post('/api/meetings' + (userId?'?user='+userId:''), meeting)
  },
  getMeetings (period, userId = null) {
    return Api().get('/api/meetings?from=' + period.start + '&to=' + period.end + (userId?'&user='+userId:''), { withCredentials: true })
  },
  editMeeting (meetingData) {
    return Api().put('/api/meetings/' + meetingData.id, meetingData)
  },
  deleteMeeting (meetingId) {
    return Api().delete('/api/meetings/' + meetingId)
  }
}
