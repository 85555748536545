<template>
    <v-select v-model="halfSelected"
              :items="halfs()"
              class="ml-4"
              item-text="value"
              item-value="key"
              menu-props="auto"
              placeholder="Wybierz nabór"
              single-line
    >
    </v-select>
</template>
<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'SelectHalfs',
    methods: {
      ...mapActions({
        setHalfSelected: 'setHalfSelected',
      }),
      halfs () {
        moment.locale('pl');
        let halfs = [];

        const dateEnd = moment(this.semesterSelectedFromStore);
        const dateStart = moment(this.semesterSelectedFromStore).subtract(1, 'year');
        let i = 3;
        while (dateEnd.diff(dateStart, 'semesters') >= 0) {
          halfs.push({ 'key': dateStart.format('YYYY-MM-DD'), 'value': 'Nabór: ' + dateStart.locale('pl').format('MMMM YYYY') + ' (semestr ' + i + ')', 'semester': i});
          if (dateStart.format('M') == 3) {
            dateStart.add(7, 'month')
          } else {
            dateStart.add(5, 'month')
          }
          i--;
        }
        return halfs;
      },
    },
    mounted() {
    },
    computed: {
      ...mapGetters({
        halfSelectedFromStore: 'halfSelected',
        semesterSelectedFromStore: 'semesterSelected',
        courseSelected: 'courses/getCourseSelected'
      }),
      halfSelected: {
        get() {
          return this.halfSelectedFromStore
        },
        set(value){
          this.$emit('halfChanged', value)
          return this.setHalfSelected(value)
        }
      },
    }
  }
</script>
