<template>
    <v-container>
        <v-card>
            <v-toolbar flat color="white">
                <v-toolbar-title>Kalendarz zjazdów</v-toolbar-title>
                <SelectMonths></SelectMonths>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" dark rounded small class="mb-2 ml-4" @click="dialog=true">Dodawanie nowego zjazdu</v-btn>
                <v-btn v-else color="primary" dark rounded small class="mb-2 ml-4" @click="dialog=true">Dodaj</v-btn>
            </v-toolbar>

            <v-dialog v-if="dialog" v-model="dialog" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <v-row>
                                        <v-col cols="12">
                                            <AvailablePlacesSelect @placeChanged="changePlace" :place="editedItem.pid" :placeErrors="placeErrors" />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required  @blur="$v.editedItem.name.$touch()" label="Nazwa (opcjonalnie)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.comment" label="Uwagi"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-date-picker v-model="editedItem.dates" first-day-of-week="1" :allowed-dates="allowedDates" range></v-date-picker>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-7">
                        <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do kalendarza</span></v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="primary" rounded @click="save">Dodaj zjazd</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogNewSuccess" max-width="800px">
                <v-card>
                    <v-card-title>
                        <v-container class="text-center">
                            <v-row>
                                <v-col sm="12">
                                    <span class="headline">Poprawnie dodałeś nowy zjazd</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-card-text>
                        <v-divider></v-divider>
                        <v-container class="text-center">
                            <v-row>
                                <v-col sm="12">
                                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                </v-col>
                                <v-col sm="12">
                                    {{ editedItem.name }} | Od {{ this.editedItem.dates[0] }} do {{ this.editedItem.dates[1] }}
                                </v-col>
                                <v-col sm="12">
                                    <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich zjazdow</v-btn>
                                </v-col>
                                <v-col sm="12">
                                    lub
                                </v-col>
                                <v-col sm="12">
                                    <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherMeeting">Chcę dodać kolejny zjazd</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-row class="fill-height ma-4">
                <v-col>
                    <v-sheet>
                        <p v-if="!monthSelected">
                            ...still loading...
                        </p>
                        <div v-else>
                            <v-calendar
                                    ref="calendar"
                                    v-model="focus"
                                    color="primary"
                                    :events="meetings"
                                    :event-color="getEventColor"
                                    :now="today"
                                    :type="type"
                                    :weekdays="weekdays"
                                    @change="updateRange"
                                    @click:event="showEvent"
                                    @click:date="openNewWithStartSelected"
                                    :start="initDate()"
                            ></v-calendar>
                        </div>
                        <v-menu
                                v-model="selectedOpen"
                                :close-on-content-click="false"
                                :activator="selectedElement"
                                offset-x
                        >
                            <v-card
                                    color="grey lighten-4"
                                    min-width="350px"
                                    flat
                            >
                                <v-toolbar
                                        :color="selectedEvent.color"
                                        dark
                                >
                                    <v-btn icon>
                                        <v-icon
                                            @click="editItem()"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                    <v-toolbar-title v-html="selectedEvent.name + ' (' + this.placeNameById(selectedEvent.pid) + ')'"></v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    {{ selectedEvent.comment }}
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn color="grey" class="overline" text @click="selectedOpen = false"><v-icon small left>arrow_back</v-icon>Powrot</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" rounded small @click="remove">Usuń</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <MeetingsReportStatus :current-month-readable="currentMonthReadable"/>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import AvailablePlacesSelect from "../components/AvailablePlacesSelect";
import moment from "moment";
import SelectMonths from "./SelectMonths";
import MeetingsReportStatus from "./MeetingsReportStatus";

export default {
    mixins: [validationMixin],
    validations: {
      editedItem: {
        dates: {required},
        name: {required},
        pid: {required},
      }
    },
    components: {MeetingsReportStatus, SelectMonths, AvailablePlacesSelect },
    name: 'Calendar',
    data: () => ({
      dialogNewSuccess: false,
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Miesiąc',
        week: 'Tydzien'
      },
      start: null,
      end: null,
      today: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      editedIndex: -1,
      editedItem: {
        name: 'Zjazd',
        dates: [],
        pid: '',
        comment: ''
      },
      defaultItem: {
        name: 'Zjazd',
        dates: [],
        pid: '',
        comment: ''
      },
      menuDate: false,
    }),
    beforeCreate() {
      this.$store.dispatch('places/getPlaces')
    },
    watch: {
      dialogNewSuccess (val) {
        val || setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1
        }, 300)
      },
    },
    computed: {
      ...mapGetters({
        meetings: 'meetings/getMeetings',
        meetingsReport: 'meetings/getMeetingsReport',
        dialogFromStore: 'meetings/dialogNew',
        placeNameById: 'places/getPlaceNameById',
        monthSelected: 'monthSelected',
        userRole: 'users/userRole',
      }),
      nameErrors () {
        const errors = [];
        if (!this.$v.editedItem.name.$dirty) return errors;
        !this.$v.editedItem.name.required && errors.push('Podanie nazwy jest konieczne');
        return errors
      },
      placeErrors () {
        const errors = [];
        if (!this.$v.editedItem.pid.$dirty) return errors;
        !this.$v.editedItem.pid.required && errors.push('Podanie miasta jest konieczne');
        return errors
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          return this.setDialogNew(value)
        }
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Dodawanie nowego czasu pracy' : 'Edytuj czas pracy'
      },
      title () {
        const { start, end } = this;
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start);
        const endMonth = this.monthFormatter(end);
        const suffixMonth = startMonth === endMonth ? '' : endMonth;

        const startYear = start.year;
        const endYear = end.year;
        const suffixYear = startYear === endYear ? '' : endYear;

        const startDay = start.day + this.nth(start.day);
        const endDay = end.day + this.nth(end.day);

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`;
          case 'week':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
      addingPossible () {
        if (this.meetingsReport == 0 || this.userRole == 1) {
          return true
        } else {
          return false
        }
      },
      currentMonthReadable () {
        return moment(this.monthSelected).format('MMMM YYYY')
      }
    },
    created() {
      this.$store.dispatch('meetings/getMeetings', { 'start': moment(this.monthSelected).startOf('month').format('YYYY-MM-DD'), 'end': moment(this.monthSelected).endOf('month').format('YYYY-MM-DD')});

      this.unwatch = this.$store.watch(
        (state, getters) => getters.monthSelected,
        (newValue) => {
          this.$store.dispatch(
            'meetings/getMeetings',
            {
              'start': moment(newValue).startOf('month').format('YYYY-MM-DD'),
              'end': moment(newValue).endOf('month').format('YYYY-MM-DD') }
          );
        },
      );
    },
    methods: {
      ...mapActions({
        setDialogNew: 'meetings/setDialogNew',
        createMeeting: 'meetings/createMeeting',
        editMeeting: 'meetings/editMeeting',
        deleteMeeting: 'meetings/deleteMeeting',
        setMonthSelected: 'setMonthSelected',
        createReport: 'meetings/createReport',
      }),
      initDate () {
        return moment(this.monthSelected).startOf('month').format('YYYY-MM-DD')
      },
      editItem () {
        this.editedIndex = this.meetings.map(function(e) { return e.id }).indexOf(this.selectedEvent.id)
        this.editedItem = Object.assign({}, this.selectedEvent)
        this.editedItem.dates = [this.selectedEvent.start, this.selectedEvent.end]
        this.dialog = true
      },
      close () {
        this.dialog = false;
      },
      closeDialogNewSuccessAndAddAnotherMeeting () {
        this.dialogNewSuccess = false;
        this.setDialogNew(true)
      },
      getEventColor (event) {
        return event.color
      },
      remove () {
        if(confirm('Czy na pewno chcesz usunąć poniższy termin?')){
          this.deleteMeeting(this.selectedEvent.id).then( () => {
            this.$log.info('Deleted meeting ' + this.selectedEvent.id);
            this.selectedOpen = false
          }).catch(error => {
            this.$log.error('Deleting meeting error', error)
          })
        }
      },
      save () {
        this.$v.$touch();
        if (this.editedIndex > -1) {
          if (!this.$v.$invalid) {
            if(this.editedItem.dates.length == 1) {
              this.editedItem.dates[1] = this.editedItem.dates[0]
            }
            this.editMeeting(this.editedItem).then(response => {
              this.$log.info('Saved edited meeting', response);
              Object.assign(this.meetings[this.editedIndex], this.editedItem);
              this.close()
            }).catch(error => {
              this.$log.error('Saving edited meeting error', error)
            })
          }
        } else {
          if (!this.$v.$invalid) {
            if(this.editedItem.dates.length == 1) {
              this.editedItem.dates[1] = this.editedItem.dates[0]
            }
            this.createMeeting(this.editedItem).then(() => {
              this.$log.info('Saved new meeting ' + this.editedItem.dates[0] + ' ' + this.editedItem.dates[1]);
              this.close();
              this.dialogNewSuccess = true
            }).catch(error => {
              this.$log.error('Saving new meeting error', error)
            })
          }
        }
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          setTimeout(() => this.selectedOpen = true, 10)
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      openNewWithStartSelected ({ date }) {
        if (this.allowedDates(date)) {
          this.editedItem.dates[0] = date;
          this.dialog = true
        }
      },
      formatDate (a, withTime) {
        return withTime
          ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
          : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
      },
      updateRange ({ start, end }) {
        this.start = start;
        this.end = end
      },
      nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      allowedDates: val => ![1, 2, 3, 4].includes(new Date(val).getDay()),
      changePlace (value) {
        this.editedItem.pid = value
      }
    },
  }
</script>

<style scoped>

</style>
