<template>
  <v-data-table
      :headers="headers"
      :items="settlements"
      sort-by="date"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista wszystkich raportów</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn v-show="showExtraActions" :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone raporty</v-btn>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodawanie nowego raportu</v-btn>
            <v-btn v-else color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodaj</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-alert
                        border="top"
                        colored-border
                        elevation="2"
                        type="info"
                    >
                      Można wczytać:<br />
                      <ul>
                        <li>pojedynczy raport bankowy</li>
                        <li>zip ze spakowanymi raportami bankowymi (jeden plik .zip)</li>
                        <li>plik .csv w formacie:<br /> numerKontaBankowego(26cyfr);kwota(same cyfry);data(YYYY-MM-DD)</li>
                      </ul>
                    </v-alert>
                    <v-file-input
                        v-model="settlement"
                        :rules="rules"
                        settlementholder="Wczytaj raport bankowy"
                        prepend-icon="attachment"
                        label="Raport bankowy"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-7">
              <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do listy wszystkich raportów</span></v-btn>
              <v-spacer></v-spacer>
              <v-btn class="primary" rounded @click="save">Dodaj raport</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogNewSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Dodawany raport</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-data-table
                        :headers="headersToImport"
                        :items="toImport"
                        :items-per-page="10"
                        class="elevation-1"
                    >
                      <template v-slot:item.amount="{ item }">
                        {{ item.amount/100 }} PLN
                      </template>
                      <template v-slot:item.status="{ item }">
                        <v-icon v-if="item.user" class="green--text">check_circle_outline</v-icon>
                        <v-icon v-else class="red--text">warning</v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col sm="6">
                    <v-btn rounded color="primary" @click="accept">Akceptuj</v-btn>
                  </v-col>
                  <v-col sm="6">
                    <v-btn rounded color="primary" outlined @click="decline">Odrzuć</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogNewSuccessWithoutMapping" max-width="800px" persistent>
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Dodawany raport</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-col sm="12">
                  Zapisano raporty
                </v-col>
                <v-col sm="12">
                  <v-btn color="primary" rounded @click="closeDialogSuccessWithoutMapping">Zamknij</v-btn>
                </v-col>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie raport</span>
                    <span v-else class="headline">Usunąłeś poprawnie raportu</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    <div v-if="selected.length > 0">
                      <div v-for="item in selected">
                        {{ item.name }}
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.lp="{ item }">
      {{ item.id }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
          @click="setSettlementSelected(item.id)"
          small
          class="mr-2"
      >
        zoom_in
      </v-icon>
    </template>
    <template v-slot:no-data>
      Brak raportów
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    editedItem: {
      name: {required}
    }
  },
  name: "AccountantSettlements",
  computed: {
    ...mapGetters({
      settlements: 'settlements/getSettlements',
      dialogFromStore: 'settlements/dialogNew',
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodawanie nowego raportu' : 'Edytuj raport'
    },
    nameErrors () {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.required && errors.push('Podanie raportu jest wymagane');
      return errors
    },
    showExtraActions () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
  },
  data: () => ({
    selected: [],
    menuDate: false,
    dialogNewSuccess: false,
    dialogNewSuccessWithoutMapping: false,
    dialogRemoveSuccess: false,
    headers: [
      { text: 'Data', value: 'timestamp' },
      { text: 'Nazwa', value: 'name' },
      { text: 'Ilość zaimportowanych wpłat w tym raporcie', value: 'amount' },
      { text: '', value: 'action', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
    singleSelect: false,
    rules: [
      value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
    ],
    settlement: null,
    headersToImport: [
      { text: 'Konto', value: 'account' },
      { text: 'Kwota', value: 'amount' },
      { text: 'Data', value: 'date' },
      { text: 'Użytkownik', value: 'user.name' },
      { text: 'Status', value: 'status' }
    ],
    toImport: null
  }),
  methods: {
    ...mapActions({
      setDialogNew: 'settlements/setDialogNew',
      createSettlement: 'settlements/createSettlement',
      deleteSettlements: 'settlements/deleteSettlements',
      getFileByUrl: 'settlements/getSettlementByUrl',
      addSettlementMapping: 'settlements/addSettlementMapping',
      setSettlementSelected: 'settlements/setSettlementSelected',
    }),
    // tempAmount() {
    //   let input = this.getFileByUrl('uploads_settlements/1640615314847').then(response => {
    //     input =  response + '';
    //     let splitted = input.split("\n").filter(n => {
    //       if (n.substring(0,3) == ':61') {
    //         return n;
    //       }
    //     }).map(n => {
    //       return { 'date':n.substring(4,10), 'amount': n.substring(15, n.indexOf(','))*100, 'account': (n.substring(n.indexOf('/')+2)).slice(0, -1) }
    //     });
    //     console.log(splitted);
    //   }).catch(error => {
    //     this.$log.error('Saving new settlement error', error)
    //   });
    //   let output = {};
    //   return output;
    // },
    close () {
      this.dialog = false;
      this.clearEditItem();
    },
    closeDialogNewSuccessAndAddAnotherSettlement () {
      this.dialogNewSuccess = false;
      this.setDialogNew(true)
    },
    accept() {
      this.addSettlementMapping({settlementId: this.selected[0].id, mapping: this.toImport}).then(response => {
        if (response == 200) {
          this.dialogNewSuccess = false;
          this.selected = [];
          this.$store.dispatch('settlements/getSettlements');
        }
      });
    },
    decline() {
      this.removeSelected();
    },
    closeDialogRemoveSuccess () {
      this.dialogRemoveSuccess = false;
      this.selected = []
    },
    closeDialogSuccessWithoutMapping () {
      this.dialogNewSuccessWithoutMapping = false;
      this.$store.dispatch('settlements/getSettlements');
      this.clearEditItem();
    },
    clearEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1
    },
    save () {
      const formData = new FormData();
      formData.append('file', this.settlement)
      this.createSettlement(formData).then(response => {
        this.close();
        if (response.toImport) {
          this.dialogNewSuccess = true
          this.toImport = response.toImport;
          this.selected.push(response);
        } else {
          this.dialogNewSuccessWithoutMapping = true
        }
      }).catch(error => {
        this.$log.error('Saving new settlement error', error)
      });
    },
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć poniższy raport?')){
        this.deleteSettlements(this.selected).then(response => {
          this.$log.info('Deleted settlement', response);
          this.dialogNewSuccess = false;
          this.dialogRemoveSuccess = true;
        }).catch(error => {
          this.$log.error('Deleting settlement error', error)
        })
      }
    },
  },
  created() {
    this.$store.dispatch('settlements/getSettlements');
  },
}
</script>

<style scoped>

</style>
