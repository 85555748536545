<template>
  <v-container>
    <Personal
        :surnameErrors="surnameErrors"
        :nameErrors="nameErrors"
        :placeOfBirthErrors="placeOfBirthErrors"
        :dayOfBirthErrors="dayOfBirthErrors"
    ></Personal>
    <v-divider class="mb-4"></v-divider>
    <Contact
        :emailErrors="emailErrors"
        :telephoneNrErrors="telephoneNrErrors"
    ></Contact>
    <v-divider class="mb-4"></v-divider>
    <Address
        :cityErrors="cityErrors"
        :streetErrors="streetErrors"
        :houseNrErrors="houseNrErrors"
        :zipErrors="zipErrors"
    ></Address>
  </v-container>
</template>

<script>
import Personal from "@/components/RegisterFormStudent/PersonalData/Personal";
import Address from "@/components/RegisterFormStudent/PersonalData/Address";
import Contact from "@/components/RegisterFormStudent/PersonalData/Contact";


export default {
  name: "PersonalData",
  props: [
    // personal
    'surnameErrors',
    'nameErrors',
    'placeOfBirthErrors',
    'dayOfBirthErrors',
    // contact
    'emailErrors',
    'telephoneNrErrors',
    // address
    'cityErrors',
    'streetErrors',
    'houseNrErrors',
    'zipErrors',
  ],
  components: {Personal, Address, Contact},
}
</script>

<style scoped>

</style>
