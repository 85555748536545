<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <template v-slot:default="{ open }">
                <v-row no-gutters>
                    <v-col cols="12" class="title">
                        III. Dane dodatkowe do ubezpieczenia
                    </v-col>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <AdditionalInsurance :medicalErrors="medicalErrors" :socialErrors="socialErrors" />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
  import AdditionalInsurance from "./AdditionalInsurance";

  export default {
    name: "AdditionalInsuranceData",
    components: {AdditionalInsurance},
    data: () => ({
    }),
    props: [
      'medicalErrors',
      'socialErrors'
    ]
  }
</script>

<style scoped>
    >>> .v-label {
        height: unset !important;
    }
</style>
