import axios from 'axios'

const errorHandler = (error) => {
  if (error.response.status === 500) {
    if (error.config.url.substring(0, 16) == '/notificationapi') {
      console.log('ERROR: notifications service is throwing 500');
    } else {
      window.location.href = '/#/error-500';
    }
  }
  if (error.response.status === 401) {
    window.location.href = '/#/error-401';
  }
  return Promise.reject({ ...error })
}

export default($type = 'application/json') => {

  let headers = {
    'Content-Type': $type
  }

  let axiosLocal = axios.create({
    baseURL: process.env.VUE_APP_API_PROXY,
    withCredentials: false,
    headers: headers
  });

  axiosLocal.interceptors.response.use(
    response => response,
    error => errorHandler(error)
  )

  return axiosLocal;
}
