<template>
  <div class="d-flex" v-if="loggedInUser.id == professorId || userRole == 1">
    <v-text-field
        placeholder="Ocena"
        outlined
        v-model="grade"
        single-line
        hide-details="auto"
        type="number"
        :rules="[ruleHalf, ruleMin, ruleMax]"
        v-on:input="enableSave(subjectId)"
    ></v-text-field>
    <v-btn v-if="changedGrade.find(x => x == subjectId)" x-large @click="save(grade, id, subjectId, eid)" :disabled="isBusy">
      zapisz
    </v-btn>
  </div>
  <span v-else>
    {{ grade }}
  </span>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ProfessorGrade',
  computed: {
    ...mapGetters({
      loggedInUser: 'users/getLoggedInUser',
      userRole: 'users/userRole',
    }),
  },
  data () {
    return {
      changedGrade: [],
      isBusy: false
    }
  },
  props: {
    professorId: null,
    grade: null,
    subjectId: null,
    eid: null,
    id: null,
    uid: null
  },
  methods: {
    ...mapActions({
      saveGrade: 'evaluations/saveGrade'
    }),
    enableSave(subjectId) {
      this.changedGrade.push(subjectId);
    },
    ruleHalf(value) {
      if ((value % 0.5) == 0 || value === '') {
        return true;
      } else {
        return "Proszę podać tylko pełne oceny, lub połówki ocen";
      }
    },
    ruleMax(value) {
      if (value <= 5 || value === '') {
        return true;
      } else {
        return "Ocena powyżej 5 nie dopuszczalna";
      }
    },
    ruleMin(value) {
      if (value >= 2 || value === '') {
        return true;
      } else {
        return "Ocena poniżej 2 nie dopuszczalna";
      }
    },
    save(value, esid, sid, eid) {
      if ((value <= 5 && value >= 2 && value % 0.5 == 0) || value === '') {
        this.isBusy = true;
        this.saveGrade({id: esid, eid: eid, sid: sid, grade: value, uid: this.uid}).then((res) => {
          if (res == 200) {
            this.changedGrade = this.changedGrade.filter(item => item !== sid);
            this.isBusy = false;
          }
        });
      }
    }
  }
}
</script>
