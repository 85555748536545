<template>
  <v-card class="pa-4 mb-8">
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
      <v-spacer></v-spacer>
      <v-btn
          icon
          @click="closeRowAction()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-row>
      <v-col cols="12" md="5">
        <v-autocomplete
            v-model="courseSelected"
            :error-messages="courseErrors"
            :items="courses"
            chips
            dense
            item-text="name"
            item-value="id"
            label="Kierunek"
            required
            small-chips
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="5">
        <v-autocomplete
            v-model="editedItem.sid"
            :items="subjects"
            dense
            item-text="name"
            item-value="id"
            label="Przedmiot"
            menu-props="auto"
            small-chips
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
            v-model="editedItem.semester"
            :items="getAvailableSemestersForSelectedCourse"
            dense
            item-text="name"
            item-value="id"
            label="Semestr"
            menu-props="auto"
            @change="toggle"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-menu
            ref="menuDateRef"
            v-model="menuDate"
            :close-on-content-click="true"
            min-width="290px"
            offset-y
            transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="editedItem.date"
                :error-messages="dateErrors"
                append-icon="event"
                label="Data spotkania"
                readonly
                required
                type="date"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="editedItem.date"
              :first-day-of-week="1"
              :min="getTodayDate"
              no-title
              scrollable
              @change="updateTopic"
          >
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="menuDate = false">Anuluj</v-btn>
            <v-btn color="primary" text @click="$refs.menuDateRef.save(editedItem.date)">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6" sm="12" >
        <v-menu
            ref="menuTimeRef"
            v-model="menuTime"
            :close-on-content-click="false"
            :return-value.sync="editedItem.time"
            min-width="290px"
            offset-y
            transition="scale-transition"
            @input="input"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="editedItem.time"
                :error-messages="timeErrors"
                append-icon="query_builder"
                label="Godzina spotkania"
                readonly
                required
                type="time"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
              ref="timepicker"
              v-model="editedItem.time"
              :allowed-minutes="allowedStep"
              class="mt-4"
              format="24hr"
              no-title
              @change="closeAndResetTime()"
          >
            <v-card-actions>
              <v-btn color="primary" text @click="menuTime = false">Anuluj</v-btn>
              <v-btn color="primary" text @click="$refs.menuTimeRef.save(editedItem.time); $refs.timepicker.selectingHour = true">OK</v-btn>
            </v-card-actions>
          </v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
            v-model="editedItem.topic"
            :error-messages="topicErrors"
            dense
            hide-details
            label="Temat spotkania"
            required
            @blur="$v.editedItem.topic.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-autocomplete
            v-model="editedItem.users"
            :error-messages="usersErrors"
            :hint="'Zaznaczono ' + editedItem.users.length + ' z dostępnych ' + getAvailableUsers.length + ' studentów'"
            :items="getAvailableUsers"
            chips
            dense
            item-text="username"
            item-value="uid"
            label="Uczestnicy"
            multiple
            persistent-hint
            required
            small-chips
        >
          <template v-slot:selection="data">
            <v-chip
                :input-value="data.item.username"
                close
                @click:close="removeUser(data.item)"
            >
              {{ data.item.username}}
            </v-chip>
          </template>
          <template v-slot:prepend-item>
            <v-list-item
                v-show="getAvailableUsers.length > 0"
                ripple
                @click="toggle"
            >
              <v-list-item-action>
                <v-icon :color="editedItem.users.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Zaznacz wszyskich</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import moment from "moment/moment";
import {validationMixin} from "vuelidate";

export default {
  mixins: [validationMixin],
  name: "OnlineMeetingCreateGroupRow",
  validations: {
    editedItem: {
      topic: {required},
      date: {required},
      time: {required},
      cid: {required},
      users: {required}
    }
  },
  computed: {
    ...mapGetters({
      courses: 'courses/getCourses',
      getCourseNameById: 'courses/getCourseNameById',
      getSubjectNameById: 'subjects/getSubjectNameById',
      subjects: 'subjects/getSubjectsBySelectedCourseId',
      getAvailableSemestersForSelectedCourse: 'subjects/getAvailableSemestersForSelectedCourse',
      userRole: 'users/userRole',
      getUsersForSelect: 'users/getUsersForSelect',
      userNameById: 'users/getNameById',
    }),
    allUsers () {
      return this.editedItem.users.length === this.getAvailableUsers.length
    },
    icon () {
      if (this.allUsers) return 'mdi-close-box'
      return 'mdi-checkbox-blank-outline'
    },
    getAvailableUsers() {
      // select right date from studentCourse based on selected semester
      let dateSemester = '';
      if (moment().format('M') >= 3 && moment().format('M') < 10) {
        dateSemester = moment().format('YYYY') + '-03-01';
      }
      if (moment().format('M') >= 10) {
        dateSemester = moment().format('YYYY') + '-10-01';
      }
      if (moment().format('M') < 3) {
        dateSemester = moment().subtract('1 years').format('YYYY') + '-10-01';
      }
      if (this.editedItem.semester == 2) {
        dateSemester = moment(dateSemester).subtract(moment(dateSemester).format('M') == 10 ? 7 : 5, 'M').format('YYYY-MM-DD');
      }
      if (this.editedItem.semester == 3) {
        dateSemester = moment(dateSemester).subtract(12, 'M').format('YYYY-MM-DD');
      }
      const seen = new Set();
      return this.$store.getters["studentCourses/getStudentCourses"].filter(x => {
        if (x.date === dateSemester) {
          const duplicate = seen.has(x.uid);
          seen.add(x.uid);
          return !duplicate;
        }
      });
    },
    getTodayDate() {
      return moment().format('YYYY-MM-DD');
    },
    topicErrors () {
      const errors = [];
      if (!this.$v.editedItem.topic.$dirty) return errors;
      !this.$v.editedItem.topic.required && errors.push('Podanie tematu spotkania jest wymagane');
      return errors
    },
    dateErrors () {
      const errors = [];
      if (!this.$v.editedItem.date.$dirty) return errors;
      !this.$v.editedItem.date.required && errors.push('Podanie daty jest wymagane');
      return errors
    },
    timeErrors () {
      const errors = [];
      if (!this.$v.editedItem.time.$dirty) return errors;
      !this.$v.editedItem.time.required && errors.push('Podanie godziny jest wymagane');
      return errors
    },
    courseErrors () {
      const errors = [];
      if (!this.$v.editedItem.cid.$dirty) return errors;
      !this.$v.editedItem.cid.required && errors.push('Podanie kierunku jest wymagane');
      return errors
    },
    usersErrors () {
      const errors = [];
      if (!this.$v.editedItem.users.$dirty) return errors;
      !this.$v.editedItem.users.required && errors.push('Wybranie przynajmniej jednego studenta jest wymagane');
      return errors
    },
    courseSelected: {
      get() {
        return this.editedItem.cid;
      },
      set(value){
        this.editedItem.cid = value;
        this.checkValidation();
        if (value == '') {
          this.$store.dispatch('studentCourses/resetStudentCourses');
          this.editedItem.users = [];
        } else {
          this.$store.dispatch(
              'studentCourses/getStudentCourses',
              {
                'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
                'cid': value
              }
          ).then(() => {
            this.toggle();
          });
        }
        return this.setCourseSelected([value]);
      }
    },
  },
  data: () => ({
    menuTime: false,
    isBusy: false
  }),
  props: {
    editedItem: {},
    closeRow: {},
    keyIndex: null
  },
  methods: {
    ...mapActions({
      setDialogGroupNew: 'onlineMeetings/setDialogGroupNew',
      setCourseSelected: 'courses/setCourseSelected',
      resetStudentCourses: 'studentCourses/resetStudentCourses',
    }),
    allowedHours: v => v % 2,
    allowedMinutes: v => v >= 10 && v <= 50,
    allowedStep: m => m % 15 === 0,
    updateTopic() {
      let dayOfTheWeek = '';
      if (moment(this.editedItem.date).weekday() == 6) {
        dayOfTheWeek = 'sobota';
      }
      if (moment(this.editedItem.date).weekday() == 0) {
        dayOfTheWeek = 'niedziela';
      }
      this.editedItem.topic = this.userNameById(this.editedItem.uid) + (dayOfTheWeek !== '' ? ' - ' + dayOfTheWeek : '');
    },
    checkValidation() {
      this.$v.$touch();
      if (this.editedItem.cid) {
        this.$emit('isValid', [true, this.keyIndex]);
      } else {
        this.$emit('isValid', [false, this.keyIndex]);
      }

    },
    toggle () {
      if (this.allUsers) {
        this.editedItem.users = []
      } else {
        this.editedItem.users = this.getAvailableUsers.slice().map(x => x.uid);
      }
    },
    removeUser(item) {
      const index = this.editedItem.users.indexOf(item.uid)
      if (index >= 0) this.editedItem.users.splice(index, 1)
    },
    closeRowAction() {
      this.$emit('closeRow')
    },
    closeAndResetTime() {
      this.$refs.menuTimeRef.save(this.editedItem.time);
      this.$refs.timepicker.selectingHour = true;
    }
  },
  created() {
    this.toggle();
    this.updateTopic();
    this.checkValidation();
  },
  input (val) {
    !val && (this.$refs.timepicker.selectingHour = true)
  },
}
</script>

<style scoped>

</style>
