<template>
  <v-data-table
      :headers="headers"
      :items="notifications"
      sort-by="name"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista wszystkich powiadomień</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn :color=buttonForRemove class="mb-2 ml-4" dark rounded small @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone powiadomienia</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.text="{ item }">
      <a v-if="item.status == 0" class="font-weight-bold" @click="click()">{{ item.text }}</a>
      <a v-else @click="click(item)">{{ item.text }}</a>
    </template>
    <template v-slot:no-data>
      Brak powiadomień
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Notifications",
  computed: {
    ...mapGetters({
      notifications: 'notifications/getNotificationsNotForChat',
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
  },
  data: () => ({
    selected: [],
    headers: [
      {text: 'Wiadomość', value: 'text'},
      {text: 'Data', value: 'datetime'},
    ],
  }),
  methods: {
    ...mapActions({
      deleteNotifications: "notifications/deleteNotifications"
    }),
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć poniższe notyfikacje?')){
        this.deleteNotifications(this.selected).then(response => {
          this.$log.info('Deleted notifications', response);
        }).catch(error => {
          this.$log.error('Deleting notification error', error)
        })
      }
    },
    click(item) {
      this.$store.dispatch('notifications/setWatched');
      if (item.type == 3) {
        this.$router.push('/online-meetings')
      } else if (item.type == 4) {
        this.$router.push('/issue-tracker')
      } else {
        this.$router.push('/chat')
      }
    }
  },
  created() {
  },
}
</script>

<style scoped>

</style>
