<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <template v-slot:default="{ open }">
                <v-row no-gutters>
                    <v-col cols="12" class="title">
                        I. Kwestionariusz osobowy (zleceniobiorcy) - Dane identyfikacyjne
                    </v-col>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <Personal
                    :surnameErrors="surnameErrors"
                    :nameErrors="nameErrors"
                    :mothersNameErrors="mothersNameErrors"
                    :fathersNameErrors="fathersNameErrors"
                    :placeOfBirthErrors="placeOfBirthErrors"
                    :dayOfBirthErrors="dayOfBirthErrors"
                    :peselErrors="peselErrors"
                    :educationErrors="educationErrors"
                    :nationalityErrors="nationalityErrors"
                    :idSeriesErrors="idSeriesErrors"
                    :idNumberErrors="idNumberErrors"
            ></Personal>
            <v-divider class="mb-4"></v-divider>
            <Address
                    :provinceErrors="provinceErrors"
                    :districtErrors="districtErrors"
                    :municipalityErrors="municipalityErrors"
                    :cityErrors="cityErrors"
                    :streetErrors="streetErrors"
                    :houseNrErrors="houseNrErrors"
                    :zipErrors="zipErrors"
                    :postErrors="postErrors"
            ></Address>
            <v-divider class="mb-4"></v-divider>
            <Contact
                    :emailErrors="emailErrors"
                    :mobileNrErrors="mobileNrErrors"
            ></Contact>
            <v-divider class="mb-4"></v-divider>
            <TaxOffice
                    :taxNameErrors="taxNameErrors"
                    :taxCityErrors="taxCityErrors"
                    :taxStreetErrors="taxStreetErrors"
                    :taxHouseNrErrors="taxHouseNrErrors"
                    :taxZipErrors="taxZipErrors"
            ></TaxOffice>
            <v-divider class="mb-4"></v-divider>
            <NFZ
                    :nfzNameErrors="nfzNameErrors"
            ></NFZ>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
  import Personal from "@/components/RegisterForm/PersonalData/Personal";
  import Address from "@/components/RegisterForm/PersonalData/Address";
  import Contact from "@/components/RegisterForm/PersonalData/Contact";
  import TaxOffice from "@/components/RegisterForm/PersonalData/TaxOffice";
  import NFZ from "@/components/RegisterForm/PersonalData/NFZ";

  export default {
    name: "PersonalData",
    props: [
      // personal
      'surnameErrors',
      'nameErrors',
      'mothersNameErrors',
      'fathersNameErrors',
      'placeOfBirthErrors',
      'dayOfBirthErrors',
      'peselErrors',
      'educationErrors',
      'nationalityErrors',
      'idSeriesErrors',
      'idNumberErrors',
      // address
      'provinceErrors',
      'districtErrors',
      'municipalityErrors',
      'cityErrors',
      'streetErrors',
      'houseNrErrors',
      'zipErrors',
      'postErrors',
      // contact
      'emailErrors',
      'mobileNrErrors',
      // taxOffice
      'taxNameErrors',
      'taxCityErrors',
      'taxStreetErrors',
      'taxHouseNrErrors',
      'taxZipErrors',
      // NFZ
      'nfzNameErrors'
    ],
    components: {Personal, Address, Contact, TaxOffice, NFZ},
  }
</script>

<style scoped>

</style>
