<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-text-field
            v-model="data.name"
            label="Nazwa przedmiotu"
            dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-select v-model="data.uid"
                  :items="users"
                  item-text="name"
                  item-value="id"
                  menu-props="auto"
                  hide-details
                  label="Wybierz wykładowcę"
                  single-line
                  dense
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="2" sm="12">
        <v-select v-model="data.semester"
                  :items="semesters"
                  item-text="value"
                  item-value="key"
                  menu-props="auto"
                  hide-details
                  label="Wybierz semestr"
                  single-line
                  dense
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="2" sm="12">
        <v-select v-model="data.exam"
                  :items="exams"
                  item-text="value"
                  item-value="key"
                  menu-props="auto"
                  hide-details
                  label="Wybierz zaliczenie/egzamin"
                  single-line
                  dense
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="1" sm="12">
        <v-btn v-if="data.id" color="error" dark rounded small class="mb-2 ml-4" @click="deleteCourseSubject(data.id)"><v-icon small>delete_outline</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


export default {
  name: "CourseSubject",
  props: {
    data: { name: '', uid: '', semester: '', exam: ''}
  },
  data: () => ({
    semesters: [{ 'key': '', 'value': '' }, { 'key': 1, 'value': 'Semestr 1' }, { 'key': 2, 'value': 'Semestr 2' }, { 'key': 3, 'value': 'Semestr 3' }],
    exams: [{ 'key': '', 'value': '' }, { 'key': 0, 'value': 'Zaliczenie' }, { 'key': 1, 'value': 'Egzamin' }]
  }),
  computed: {
    ...mapGetters({
      users: 'users/getUsersForSelect',
    })
  },
  methods: {
    ...mapActions({
      deleteCourseSubject: 'subjects/deleteCourseSubject',
    }),
  }
}
</script>

<style scoped>

</style>
