import issue from '../../../services/issueTracker/Issue'

// initial state
const state = {
  items: [],
  dialogNew: false,
  issueSelected: null
};

// getters
const getters = {
  getIssues: state => state.items,
  getIssuesAmountByStatus: state => status => state.items.filter(x => x.status == status).length,
  dialogNew: state => state.dialogNew,
  getIssueSelected: state => state.issueSelected,
  atLeastOneIssueSolved: state => state.items.find(x => x.status == 2)
};

// actions
const actions = {
  getIssues ({ commit }) {
    return new Promise((resolve, reject) => {
      issue.getIssues().then(response => {
        if(response && response.status == 200) {
          commit('setIssues', response.data);
          resolve(response);
        } else {
          reject();
        }
      })
    });
  },
  downloadFileByUrl ({ commit }, url) {
    return new Promise((resolve, reject) => {
      issue.getFileByUrl(url).then(response => {
        if(response.status == 200) {
          var blob = new Blob([response.data]);

          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'document.' + url.split('.').pop();
          link.click();
          link.remove();

        } else {
          reject();
        }
      })
    }).catch((error) => { console.log(error) });
  },
  createIssue ({ commit }, newIssue) {
    return new Promise((resolve, reject) => {
      issue.createIssue(newIssue).then(response => {
        if(response.status == 200) {
          commit('addIssue', response.data);
          resolve(response);
        } else {
          reject();
        }
      });
    }).catch((error) => { console.log(error) });
  },
  editIssue ({ commit }, edited) {
    return new Promise((resolve, reject) => {
      issue.editIssue(edited.id, edited.formData).then(response => {
        if(response.status == 200) {
          commit('editIssue', response.data);
          resolve(response);
        } else {
          reject();
        }
      });
    }).catch((error) => { console.log(error) });
  },
  changeStatus ({ commit }, status) {
    return new Promise((resolve, reject) => {
      issue.editIssue(state.issueSelected.id, { status: status }).then(response => {
        if(response.status == 200) {
          commit('editIssue', response.data);
          resolve(response);
        } else {
          reject();
        }
      });
    }).catch((error) => { console.log(error) });
  },
  deleteIssues ({ commit }, deletedIssues) {
    issue.deleteIssues(deletedIssues.map(function(item) { return item["id"] })).then(response => {
      if (response.status == 200) {
        commit('deleteIssues', deletedIssues)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setIssueSelected ({ commit }, value) {
    commit('setIssueSelected', value)
  },
};

// mutations
const mutations = {
  setIssues (state, issues) {
    state.items = issues
  },
  addIssue (state, issue) {
    state.items.unshift(issue)
  },
  editIssue (state, issueE) {
    let issue = state.items.find(item => item.id == issueE.id);
    issue.title = issueE.title;
    issue.type = issueE.type;
    issue.description = issueE.description;
    issue.status = issueE.status;
    issue.timestamp = issueE.timestamp;
    if (issueE.files) {
      issue.files = issueE.files;
    }
  },
  deleteIssues (state, issues) {
    issues.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setIssueSelected (state, value) {
    state.issueSelected = value
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
