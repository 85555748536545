<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <p>1. {{ displayInsurance('social') }} (dotyczy wyłącznie osób, które w części II zaznaczyły pkt.:2,3,4,6,8,9,12,15)</p>
                <v-radio-group v-model="social" row :error-messages="socialErrors" required>
                    <v-radio value="true" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Tak</p>
                        </template>
                    </v-radio>
                    <v-radio value="false" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Nie</p>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <p>2. {{ displayInsurance('medical') }} (dotyczy wyłącznie osób, które w części II zaznaczyły pkt.:1,5,13,16)</p>
                <v-radio-group v-model="medical" row :error-messages="medicalErrors" required>
                    <v-radio value="true" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Tak</p>
                        </template>
                    </v-radio>
                    <v-radio value="false" class="d-flex align-start">
                        <template v-slot:label>
                            <p>Nie</p>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  import {createHelpers} from 'vuex-map-fields';
  import AdditionalInsuranceTypes from "@/components/RegisterForm/AdditionalInsuranceTypes";

  const { mapFields } = createHelpers({
    getterType: 'registration/getAdditionalInsurance',
    mutationType: 'registration/updateAdditionalInsurance',
  });

  export default {
    name: 'AdditionalInsurance',
    props: [
      'medicalErrors',
      'socialErrors'
    ],
    computed: {
      ...mapFields([
        'additionalInsurance.medical',
        'additionalInsurance.social'
      ])
    },
    methods: {
      displayInsurance (value) {
        return AdditionalInsuranceTypes[value]
      }
    },
  }
</script>
