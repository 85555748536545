import file from '../../services/File'

// initial state
const state = {
  items: []
};

// getters
const getters = {
  getFiles: state => state.items,
  getFilesByGroupId: state => groupId => state.items.filter(p => p.gid == groupId),
  dialogNew: state => state.dialogNew,
  getFileNameById: state => fileId => state.items.find(p => p.id === fileId) != undefined ? state.items.find(p => p.id === fileId).name : '',
  getFileById: state => fileId => state.items.find(p => p.id === fileId) != undefined ? state.items.find(p => p.id === fileId) : '',
  isDuringUpdate: state => state.items.filter(p => p.hasChanged == true).length > 0 ? true : false
};

// actions
const actions = {

  getFiles ({ commit, rootState }) {
    if (rootState.fileGroups.fileGroupSelected !== null) {
      file.getFilesByFileGroupId(rootState.fileGroups.fileGroupSelected).then(response => {
        commit('setFiles', {rootState: rootState, files: response.data})
      })
    } else {
      commit('setFiles', { rootState: rootState, files: { items: [] }});
    }
  },
  getAll ({ commit, rootState }) {
    file.getFiles().then(response => {
      commit('setFiles', { rootState: rootState , files: response.data})
    })
  },
  getFileByUrl (_, id) {
    let fileItem = state.items.find(p => p.id === id) != undefined ? state.items.find(p => p.id === id) : '';
    return new Promise((resolve, reject) => {
      file.getFileByUrl(fileItem.url).then(response => {
        if (response && response.data) {
          var blob = new Blob([response.data]);

          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileItem.name + '.' + fileItem.url.split('.').pop();
          link.click();
          link.remove();
          resolve(200);
        } else {
          reject();
        }
      })
    }).catch((error) => { console.log(error) });
  },
  setHasChanged({commit}, value) {
    commit('setHasChanged', value)
  },
  updateFile ({ commit }, id) {
    return new Promise((resolve, reject) => {
      let fileItem = state.items.find(x => x.id == id);
      let formData = new FormData();
      formData.append('id', fileItem.id);
      formData.append('name', fileItem.name);
      formData.append('description', fileItem.description);
      formData.append('gid', fileItem.gid);
      formData.append('file', fileItem.url);

      if(id) {
        file.editFile(formData, id).then(response => {
          commit('editFile', response.data)
          resolve(200);
        }).catch((errors) => {
          reject(errors)
        });
      } else {
        file.createFile(formData).then(response => {
          commit('editFileId', response.data)
          resolve(200);
        }).catch((errors) => {
          reject(errors)
        });
      }
    }).catch((error) => { console.log(error) });
  },
  createFiles ({ commit }, fileGroupId) {
    return new Promise((resolve, reject) => {
      if (state.items.length > 0) {
        // set group is not exists
        let files = {
          items: state.items.map(item => (item.gid === null || item.gid === undefined) ? {
            ...item,
            gid: fileGroupId
          } : {
            ...item,
          })
        };

        let formData = new FormData();
        for(var i = 0; i < files.items.length; i++ ){
          let file = files.items[i];
          formData.append('name', file.name);
          formData.append('description', file.description);
          formData.append('gid', file.gid);
          formData.append('file', file.url);
        }
        file.createFiles(formData).then(response => {
          resolve(200);
          // commit('addFile', response.data)
        }).catch((errors) => {
          reject(errors)
        })
      } else {
        resolve(200);
      }
    }).catch((error) => { console.log(error) });
  },
  // editFile ({ commit }, editedFile) {
  //   file.editFile(editedFile).then(response => {
  //     commit('editFile', response.data)
  //   })
  // },
  deleteFile ({ commit }, fileId) {
    return new Promise((resolve, reject) => {
      file.deleteFile(fileId).then(response => {
        if (response.status == 200) {
          commit('deleteFile', fileId);
          resolve(200);
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  deleteFiles ({ commit }, deletedFiles) {
    file.deleteFiles(deletedFiles.map(function(item) { return item["id"] })).then(response => {
      if (response.status == 200) {
        commit('deleteFiles', deletedFiles)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  addFile({commit, rootState}, value) {
    value.gid = rootState.fileGroups.fileGroupSelected;
    commit('addFile', value )
  }
};

// mutations
const mutations = {
  setFiles (state, values) {
    state.items = values.files.items.map(obj => ({ ...obj, hasChanged: false }))
    if (values.files.roles !== undefined)
      values.rootState.fileGroups.fileGroupSelectedRoles = values.files.roles.map(obj => ({ ...obj, hasChanged: false }))
    if (values.files.semesters !== undefined)
      values.rootState.fileGroups.fileGroupSemesters = values.files.semesters.map(obj => ({ ...obj, hasChanged: false }))
  },
  editFile (state, file) {
    state.items.find(x => x.id == file.id).hasChanged = false;
  },
  editFileId (state, file) {
    state.items.find(x => x.id == '').id = file.id;
    state.items.find(x => x.id == file.id).hasChanged = false;

  },
  deleteFile (state, fileId) {
    state.items = state.items.filter(x => x.id !== fileId);
  },
  deleteFiles (state, files) {
    files.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setHasChanged(state, value) {
    let item = state.items.find(i => i.id === value);
    item.hasChanged = true;
  },
  addFile(state, value) {
    state.items.push({
      id: '',
      name: '',
      url: null,
      description: '',
      gid: value.gid,
      hasChanged: true
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
