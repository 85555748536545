import Api from './Api'

export default {
  createChatMessage (message) {
    return Api().post('/chatapi', message, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  createMultipleChatMessage (message) {
    return Api().post('/chatapi/multiple', message, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getMessagesByUser (userId) {
    return Api().get('/chatapi/user/' + userId, { withCredentials: true });
  },
  getLastActiveMessages () {
    return Api().get('/chatapi/last', { withCredentials: true }).catch((error) => console.log(error));
  },
  deleteMessages (messages) {
    return Api().post('/chatapi/delete-multiple', messages);
  },
  markUserAsRead (userId) {
    return Api().put('/chatapi/user/' + userId + '/status/1');
  },
  markUserUnread (userId) {
    return Api().put('/chatapi/user/' + userId + '/status/0');
  },
  getFileByUrl (url) {
    return Api().get('/chatapi/url/' + url, { withCredentials: true, responseType: 'arraybuffer' })
  },
  getMessagesByIssue (issueId) {
    return Api().get('/chatapi/issues/' + issueId, { withCredentials: true });
  },
  createIssueChatMessage (message) {
    return Api().post('/chatapi/issues/', message, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getIssueTrackerFileByUrl (url) {
    return Api().get('/chatapi/issues/url/' + url, { withCredentials: true, responseType: 'arraybuffer' })
  },
}
