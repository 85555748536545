<template>
  <div>
    <v-divider></v-divider>
    <div class="mt-4 mb-4 mr-4 d-flex justify-end" v-if="showSendForAcceptance">
      <span
          class="mr-4">Prześlij do akceptacji ewidencję przebiegu za miesiąc: {{ currentMonthReadable }}</span>
      <v-btn @click="createReport()" color="primary" dark rounded small>Prześlij do akceptacji</v-btn>
    </div>
    <div class="mt-4 mb-4 mr-4 d-flex justify-end" v-if="showWaitingForAcceptance">
      <span class="mr-4">Ewidencja przejazdow za miesiąc: {{ currentMonthReadable }} przesłana. Oczekiwanie na akceptację.</span>
    </div>
    <div class="mt-4 mb-4 mr-4" v-else-if="showGenerateReport">
      <v-row>
        <v-col class="d-flex justify-end" md="10">
          <div>
            <div class="mr-4 ml-4">Ewidencja przejazdow za miesiąc: {{ currentMonthReadable }}
              <span class="mr-4 ml-4" v-if="showStatusAcceptedReport">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon class="green--text" v-bind="attrs" v-on="on">checked</v-icon>
                                    </template>
                                    <span>Zaakceptowana</span>
                                  </v-tooltip>
                                </span>
              <span class="mr-4 ml-4" v-else-if="showStatusDeclinedReport">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon class="red--text" v-bind="attrs" v-on="on">warning</v-icon>
                                    </template>
                                    <span>Odrzucona</span>
                                  </v-tooltip>
                                </span>
            </div>
            <div class="mr-4 ml-4" v-if="showAcceptOrDeclineReport">
              <v-btn @click="acceptReport()" rounded class="success">akceptuj</v-btn>
              &nbsp
              <v-btn @click="declineReport()" rounded class="error">odrzuć</v-btn>
            </div>
            <!--                            <div class="mr-4 ml-4 font-weight-thin">Data przesłania do rozliczenia:</div>-->
          </div>
        </v-col>
        <v-col class="d-flex justify-end" md="2">
          <v-btn @click="generatePDFReport" color="primary" dark rounded small>Generuj plik PDF</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'TripsReportStatus',
  data: () => ({
    settingsPersonalChanged: false,
  }),
  props: {
    currentMonthReadable: {},
    username: {},
    personalSurname: {},
    personalName: {},
    addressStreet: {},
    addressHouseNr: {},
    addressHomeNr: {},
    addressZip: {},
    addressCity: {},
    ratePerKm: {}
  },
  computed: {
    ...mapGetters({
      trips: 'trips/getTrips',
      tripsReport: 'trips/getTripsReport',
      userRole: 'users/userRole',
      monthSelected: 'monthSelected',
      ratePerKmFromStore: 'users/getRatePerKm',
      getCarById: 'cars/getCarById'
    }),
    showSendForAcceptance () {
      return (this.trips.length > 0 && this.tripsReport == 0 && (this.userRole == 0 || this.userRole == 5)) ? true : false;
    },
    showWaitingForAcceptance () {
      return  (this.trips.length > 0 && this.tripsReport == 1 && (this.userRole == 0 || this.userRole == 5)) ? true : false;
    },
    showGenerateReport () {
      return (this.trips.length > 0 && (this.tripsReport == 2 || this.userRole == 1)) ? true : false;
    },
    showAcceptOrDeclineReport () {
      return (this.tripsReport == 1 && this.userRole == 1) ? true : false;
    },
    showStatusAcceptedReport () {
      return (this.tripsReport == 2) ? true : false;
    },
    showStatusDeclinedReport () {
      return (this.tripsReport == 0) ? true : false;
    }
  },
  methods: {
    ...mapActions({
      createReport: 'trips/createReport',
      acceptReport: 'trips/acceptReport',
      declineReport: 'trips/declineReport',
    }),
    editSettings () {
      this.$store.commit('setActiveSettingsTab', 0)
      this.$router.push('settings')
    },
    generatePDFReport () {
      var table = [];
      table.push(["Nr", "Data wyjazdu", "Opis trasy wyjazdu (skąd-dokąd)", "Cel wyjazdu", "Liczba faktycznie przeje-\nchanych kilometrów", "Stawka za 1km przebiegu", "Wartość", "Podpis podatnika", "Uwagi"]);

      function passItem(ratePerKm) {
        return function (obj, index) {
          return [index+1, new Date(obj.date).toISOString().substr(0, 10), obj.start + '-' + obj.destination, "", { text: (obj.return_to_start == "1") ? "2 x " + obj.km : obj.km, alignment: "right" }, { text: ratePerKm/100, alignment: "right" }, { text: (((obj.return_to_start == "1") ? 2 * obj.km : obj.km) * ratePerKm)/100, alignment: "right"}, "", obj.comment]
        }
      }
      var body = table.concat(this.trips.map(passItem(this.ratePerKmFromStore)));
      body.push([
        { colSpan: 4, text: "Podsumowanie strony", alignment: "right"},
        {},
        {},
        {},
        { text: this.trips.map(item => (item.return_to_start == "1") ? 2 * item.km : item.km).reduce((prev, next) => parseFloat(prev) + parseFloat(next)) + 'km', alignment: "right"},
        { fillColor: '#CCCCCC', text: '' },
        { text: this.trips.map(item => (item.return_to_start == "1") ? 2 * item.km : item.km).reduce((prev, next) => parseFloat(prev) + parseFloat(next)) * this.ratePerKmFromStore/100 + 'zł', alignment: "right" },
        { fillColor: '#CCCCCC', text: '' }, { fillColor: '#CCCCCC', text: '' }
      ])
      body.push([{ colSpan: 4, text: "Z przeniesienia", alignment: "right"}, {}, {}, {}, {}, { fillColor: '#CCCCCC', text: '' }, {}, { fillColor: '#CCCCCC', text: '' }, { fillColor: '#CCCCCC', text: '' }])
      body.push([{ colSpan: 4, text: "Razem", alignment: "right"}, {}, {}, {}, {}, { fillColor: '#CCCCCC', text: '' }, {}, { fillColor: '#CCCCCC', text: '' }, { fillColor: '#CCCCCC', text: '' }])

      var pdfMake = require('pdfmake/build/pdfmake.js');
      if (pdfMake.vfs == undefined){
        var pdfFonts = require('pdfmake/build/vfs_fonts.js');
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      let carRegistrationNumber = '. . . . . . . . . . . .';
      let carEngine = '. . . . . . . . . . . . .';
      if (this.trips.find(x => x.cid !== this.trips[0].cid) === undefined) {
        carRegistrationNumber = this.getCarById(this.trips[0].cid).registration_nr;
        carEngine = this.getCarById(this.trips[0].cid).engine;
      }


      var docDefinition = {
        content: [
          {
            alignment: 'justify',
            columns: [
              {
                text: this.personalName + ' ' + this.personalSurname + '\n' + this.addressStreet + ' ' + this.addressHouseNr + '' + ((this.addressHomeNr != undefined) ? '/' + this.addressHomeNr : '') + '\n' + this.addressZip + ' ' + this.addressCity + '\nDane podatnika (lub pieczątka)\n',
                margin: [0, 0, 0, 40],
              },
              {
                text: 'Numer rejestracyjny pojazdu: ' + carRegistrationNumber,
                margin: [0, 0, 0, 40],
              }
            ]
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . .',
                margin: [0, 0, 0, 20],
              },
              {
                text: 'Pojemność silnika: ' + carEngine + ' [cm3]'
              }
            ]
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . \nDane osoby używającej pojazd\n(nazwisko, imię, adres zamieszkania)',
                margin: [0, 0, 0, 20],
              },
              {
                text: ''
              }
            ]
          },
          {
            stack: [
              'EWIDENCJA PRZEBIEGU POJAZDU\nza miesiąc ' + this.monthSelected.substr(0, 7),
            ],
            style: 'header'
          },
          {
            style: 'tableExample',
            table: {
              body: body
            }
          }
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 80],
            alignment: "center"
          }
        }
      };
      pdfMake.createPdf(docDefinition).download('unikadra-report-'+this.monthSelected+'.pdf')
    }
  }
}
</script>
