import Api from './Api'

export default {
  createSubject (subject) {
    return Api().post('/api/subjects', subject)
  },
  createSubjects (subjects) {
    return Api().post('/api/subjects/create-multiple', subjects)
  },
  getSubjects () {
    return Api().get('/api/subjects', { withCredentials: true })
  },
  getSubjectsByCourseId (cid) {
    return Api().get('/api/subjects/course/' + cid, { withCredentials: true })
  },
  editSubject (subjectData) {
    return Api().put('/api/subjects/' + subjectData.id, subjectData)
  },
  deleteSubjects (subjects) {
    return Api().post('/api/subjects/delete-multiple', subjects)
  }
}
