<template>
  <v-container>
    <v-card>
      <v-card-title>
        Dziennik
      </v-card-title>
      <v-card-text>
        <p>Dzięki tej funkcjonalności możesz w szybki sposób sprawdzić oceny jakie otrzymałaś/eś.</p>
      </v-card-text>
    </v-card>
    <v-card>
      <v-expansion-panels>
          <StudentGradesCourse
              v-for="item in getEvaluationsByUserIdUniqueNames"
              :key="item.semester"
              :get-evaluations-by-user-id-unique-semesters="getEvaluationsByUserIdUniqueSemesters"
              :item="item"
          />
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import StudentGradesCourse from "./StudentGradesCourse.vue";

export default {
  name: "StudentGrades",
  components: {StudentGradesCourse},
  data () {
    return {
      tab: null,
      validate: false,
      report: false,
    }
  },
  methods: {
    ...mapActions({
      studentEvaluations: 'evaluations/getEvaluationsByUserId',
    }),
  },
  computed: {
    ...mapGetters({
      getEvaluationsByUserId: 'evaluations/getEvaluationsByUserId',
      getEvaluationsByUserIdUniqueNames: 'evaluations/getEvaluationsByUserIdUniqueNames',
      getEvaluationsByUserIdUniqueSemesters: 'evaluations/getEvaluationsByUserIdUniqueSemesters',
      loggedInUser: 'users/getLoggedInUser',
    }),
  },
  created() {
    this.studentEvaluations(this.loggedInUser.id);
  },
}
</script>

<style scoped>
.v-text-field{
  width: 100px;
}
.v-input--is-readonly {
  background-color: #EEEEEE;
}
</style>
