import Api from './Api'

export default {
  createFileGroup (fileGroup) {
    return Api().post('/api/fileGroups', fileGroup)
  },
  getFileGroups () {
    return Api().get('/api/fileGroups', { withCredentials: true })
  },
  editFileGroup (fileGroupData) {
    return Api().put('/api/fileGroups/' + fileGroupData.id, fileGroupData)
  },
  deleteFileGroups (fileGroups) {
    return Api().post('/api/fileGroups/delete-multiple', fileGroups)
  }
}
