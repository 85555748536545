<template>
    <v-container>
        <v-card>
            <v-card-title>{{ username }} <v-btn color="grey" class="overline ml-5" text @click="$router.push('/users')"><v-icon small left>arrow_back</v-icon> powrót do listy wszystkich wykładowców</v-btn></v-card-title>
            <v-tabs
                    v-model="tabActive"
                    background-color="primary"
                    dark
                    :key="activeSettingsTabFromStore"
            >
                <v-tab
                        v-for="item in items"
                        :key="item.tab"
                >
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabActive">
                <v-tab-item>
                    <v-card>
                      <v-card-text>
                          <v-expansion-panels flat v-if="isCompany === 0">
                            <RegisterForm @registerFormValidated="validate=false" @registerFormPrinted="registerFormPrinted" @registerFormSaved="registerFormSaved" :validate="validate" :report="report" />
                          </v-expansion-panels>
                          <RegisterFormCompany v-if="isCompany === 1" @registerFormValidated="validate=false" @registerFormPrinted="registerFormPrinted" @registerFormSaved="registerFormSaved" :validate="validate" :report="report"/>
                        <History />
                      </v-card-text>
                      <v-card-actions class="d-flex justify-end mr-8">
                        <v-btn @click="generatePDFReport()" color="primary" dark rounded class="mb-4">Generuj plik PDF</v-btn>
                        <v-btn color="primary" dark rounded @click="save()" class="mb-4">Edytuj dane</v-btn>
                      </v-card-actions>
                    </v-card>

                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Trips />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Calendar />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Times />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <PasswordChange />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Cars />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <RatePerKm />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Bills />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <Contracts />
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end mr-8">
                    </v-card-actions>
                  </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <v-dialog v-model="dialogEditSuccess" max-width="800px">
            <v-card>
                <v-card-title>
                    <v-container class="text-center">
                        <v-row>
                            <v-col sm="12">
                                <span class="headline">Poprawnie zapisano dane</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-container class="text-center">
                        <v-row>
                            <v-col sm="12">
                                <v-icon x-large class="green--text">check_circle_outline</v-icon>
                            </v-col>
                            <v-col sm="12">
                                <v-btn rounded color="primary" @click="$router.push({ path: '/users'})">Powrót do listy wszystkich wykładowców</v-btn>
                            </v-col>
                            <v-col sm="12">
                                lub
                            </v-col>
                            <v-col sm="12">
                                <v-btn rounded color="primary" outlined @click="dialogEditSuccess = false">Kontynuuj zmiany tego wykładowcy</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import PersonalData from "@/components/RegisterForm/PersonalData";
import InsuranceData from "@/components/RegisterForm/InsuranceData";
import AdditionalInsuranceData from "@/components/RegisterForm/AdditionalInsuranceData";
import OtherData from "@/components/RegisterForm/OtherData";
import SalaryData from "@/components/RegisterForm/SalaryData";
import {mapActions, mapGetters} from "vuex";
import Cars from "../components/Car/Cars";
import Times from "../views/Times";
import Trips from "../views/Trips";
import Calendar from "../views/Calendar";
import Avatar from "../components/Avatar/Avatar";
import PasswordChange from "../components/PasswordChange";
import RatePerKm from "../components/RatePerKm";
import Contracts from "../views/Contracts";
import Bills from "../views/Bills";
import RegisterForm from "../components/RegisterForm";
import RegisterFormCompany from "../components/RegisterFormCompany";
import History from "./History";

export default {
    name: "User",
    components: {
      History,
      RegisterForm, RegisterFormCompany, Avatar, PasswordChange, Calendar, Cars, RatePerKm, Contracts, PersonalData, InsuranceData, AdditionalInsuranceData, OtherData, SalaryData, Times, Trips, Bills},
    data () {
      return {
        items: [
          { id: 1, tab: 'Dane wykładowcy' },
          { id: 2, tab: 'Przejazdy' },
          { id: 3, tab: 'Kalendarz' },
          { id: 4, tab: 'Czas pracy' },
          { id: 5, tab: 'Zmiana hasła' },
          { id: 6, tab: 'Samochody' },
          { id: 7, tab: 'Kilometrówka' },
          { id: 8, tab: 'Rachunki' },
          { id: 9, tab: 'Okres umowy' }
        ],
        dialogNewCar: false,
        tab: null,
        dialogEditSuccess: false,
        validate: false,
        report: false,
      }
    },
    methods: {
      ...mapActions({
        setDialogNew: 'cars/setDialogNew'
      }),
      generatePDFReport() {
        this.report = true
      },
      registerFormPrinted() {
        this.$log.info('Printed registration form')
        this.report = false
      },
      save() {
        this.validate = true;
      },
      registerFormSaved() {
        this.$log.info('Saved registration form')
        this.dialogEditSuccess = true
        setTimeout(() => this.dialogEditSuccess = false, 10000);
      },
    },
    computed: {
      ...mapGetters({
        activeSettingsTabFromStore: 'getActiveSettingsTab',
        users: 'users/getUsers',
        chosenUserId: 'users/getChosenUser',
        getFormDataHistory: 'history/getFormDataHistory',
        isCompany: 'users/isCompany'
      }),
      tabActive: {
        get () {
          return this.activeSettingsTabFromStore
        },
        set (value) {
          if(value == 3) {
            this.$store.dispatch('contracts/getContracts');
          }
          this.$store.commit('setActiveSettingsTab', value)
        }
      },
      username() {
        let user = this.users.find(x => x['id'] === this.chosenUserId)
        if (user) {
          return user['name']
        } else {
          return ''
        }
      }
    }
  }
</script>

<style scoped>

</style>
