const PriceChoicesEnum = Object.freeze([
  {key: 180000, value: '1800zł'},
  {key: 210000, value: '2100zł'},
  {key: 220000, value: '2200zł'},
  {key: 240000, value: '2400zł'},
  {key: 250000, value: '2500zł'},
  {key: 260000, value: '2600zł'},
  {key: 280000, value: '2800zł'},
  {key: 300000, value: '3000zł'},
  {key: 310000, value: '3100zł'},
  {key: 350000, value: '3500zł'},
  {key: 360000, value: '3600zł'},
  {key: 390000, value: '3900zł'},
  {key: 420000, value: '4200zł'},
  {key: 440000, value: '4400zł'},
  {key: 450000, value: '4500zł'},
  {key: 470000, value: '4700zł'},
  {key: 480000, value: '4800zł'},
  {key: 490000, value: '4900zł'},
  {key: 520000, value: '5200zł'},
  {key: 550000, value: '5500zł'},
  {key: 750000, value: '7500zł'},
  {key: 790000, value: '7900zł'},
  {key: 960000, value: '9600zł'},
  {key: 990000, value: '9900zł'}
]);

export default PriceChoicesEnum;
