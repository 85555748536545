<template>
    <v-expansion-panels flat>
        <RegisterFormGeneral
            :formDateErrors="formDateErrors"
            :fixedDate="fixedDate"
        ></RegisterFormGeneral>
        <PersonalData
                :surnameErrors="surnameErrors"
                :nameErrors="nameErrors"
                :mothersNameErrors="mothersNameErrors"
                :fathersNameErrors="fathersNameErrors"
                :placeOfBirthErrors="placeOfBirthErrors"
                :dayOfBirthErrors="dayOfBirthErrors"
                :peselErrors="peselErrors"
                :educationErrors="educationErrors"
                :nationalityErrors="nationalityErrors"

                :provinceErrors="provinceErrors"
                :districtErrors="districtErrors"
                :municipalityErrors="municipalityErrors"
                :cityErrors="cityErrors"
                :streetErrors="streetErrors"
                :houseNrErrors="houseNrErrors"
                :zipErrors="zipErrors"
                :postErrors="postErrors"

                :emailErrors="emailErrors"
                :mobileNrErrors="mobileNrErrors"

                :taxNameErrors="taxNameErrors"
                :taxCityErrors="taxCityErrors"
                :taxStreetErrors="taxStreetErrors"
                :taxHouseNrErrors="taxHouseNrErrors"
                :taxZipErrors="taxZipErrors"
                :nfzNameErrors="nfzNameErrors"
        ></PersonalData>
        <InsuranceData
                :insuranceTypeErrors="insuranceTypeErrors"
        ></InsuranceData>
        <AdditionalInsuranceData
                :medicalErrors="medicalErrors"
                :socialErrors="socialErrors"
        ></AdditionalInsuranceData>
        <OtherData></OtherData>
        <SalaryData></SalaryData>
    </v-expansion-panels>
</template>

<script>
import PersonalData from "@/components/RegisterForm/PersonalData";
import InsuranceData from "@/components/RegisterForm/InsuranceData";
import AdditionalInsuranceData from "@/components/RegisterForm/AdditionalInsuranceData";
import OtherData from "@/components/RegisterForm/OtherData";
import SalaryData from "@/components/RegisterForm/SalaryData";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {createHelpers} from "vuex-map-fields";
import {mapActions} from "vuex";
import InsuranceTypes from "@/components/RegisterForm/InsuranceForm/InsuranceTypes";
import AdditionalInsuranceTypes from "@/components/RegisterForm/AdditionalInsuranceTypes";
import OtherTypes from "@/components/RegisterForm/OtherTypes";
import RegisterFormGeneral from "./RegisterForm/RegisterFormGeneral";

const { mapFields } = createHelpers({
    getterType: 'registration/getPersonal',
    mutationType: 'registration/updatePersonal',
  });

  export default {
    name: 'RegisterForm',
    props: ['validate', 'report', 'fixedDate'],
    components: {RegisterFormGeneral, PersonalData, InsuranceData, AdditionalInsuranceData, OtherData, SalaryData },
    mixins: [validationMixin],
    validations: {
      registerFormDate: {required},
      // personal
      surname: {required},
      name: {required},
      mothersName: {required},
      fathersName: {required},
      placeOfBirth: {required},
      dayOfBirth: {required},
      pesel: {required},
      education: {required},
      nationality: {required},
      //address
      province: {required},
      district: {required},
      municipality: {required},
      city: {required},
      street: {required},
      houseNr: {required},
      zip: {required},
      post: {required},
      // contact
      email: {required},
      mobileNr: {required},
      // taxOffice
      taxName: {required},
      taxCity: {required},
      taxStreet: {required},
      taxHouseNr: {required},
      taxZip: {required},
      // NFZ
      nfzName: {required},
      // insurance
      insuranceType: {required},
      // additional insurance
      medical: {required},
      social: {required},
    },
    computed: {
      ...mapFields({
        // register form general
        registerFormDate: 'registerFormGeneral.formDate',
        // personal
        surname: 'personal.surname',
        name: 'personal.name',
        secondName: 'personal.secondName',
        familyName: 'personal.familyName',
        mothersName: 'personal.mothersName',
        fathersName: 'personal.fathersName',
        placeOfBirth: 'personal.placeOfBirth',
        dayOfBirth: 'personal.dayOfBirth',
        pesel: 'personal.pesel',
        nip: 'personal.nip',
        education: 'personal.education',
        degree: 'personal.degree',
        nationality: 'personal.nationality',
        idSeries: 'personal.idSeries',
        idNumber: 'personal.idNumber',
        // address
        province: 'address.province',
        district: 'address.district',
        municipality: 'address.municipality',
        city: 'address.city',
        street: 'address.street',
        houseNr: 'address.houseNr',
        homeNr: 'address.homeNr',
        zip: 'address.zip',
        post: 'address.post',
        // contact
        email: 'contact.email',
        telephoneNr: 'contact.telephoneNr',
        mobileNr: 'contact.mobileNr',
        // taxOffice
        taxName: 'taxOffice.name',
        taxCity: 'taxOffice.city',
        taxStreet: 'taxOffice.street',
        taxHouseNr: 'taxOffice.houseNr',
        taxZip: 'taxOffice.zip',
        // NFZ
        nfzName: 'NFZ.name',
        // Insurance
        insuranceType: 'insurance.insuranceType',
        placesOfWork: 'insurance.placesOfWork',
        placesOfTemporaryWork: 'insurance.placesOfTemporaryWork',
        // NFZ
        nfzNumber1: 'pensionerNFZ.number1',
        nfzNumber2: 'pensionerNFZ.number2',
        nfzNameNFZ: 'pensionerNFZ.nameNFZ',
        nfzCity: 'pensionerNFZ.city',
        nfzZIP: 'pensionerNFZ.zip',
        nfzStreet: 'pensionerNFZ.street',
        nfzHouseNr: 'pensionerNFZ.houseNr',
        // student
        studentSchoolName: 'student.schoolName',
        studentAddress: 'student.address',
        studentIdNumber: 'student.idNumber',
        // other insurance
        otherInsuranceTitle: 'otherInsurance.title',
        // additional insurance
        medical: 'additionalInsurance.medical',
        social: 'additionalInsurance.social',
        // other
        otherFamily: 'other.family',
        otherSocial: 'other.social',
        otherDisabled: 'other.disabled',
        otherLevel: 'other.level',
        // salary
        salaryNameForBank: 'salary.nameForBank',
        salarySurnameForBank: 'salary.surnameForBank',
        salaryBankName: 'salary.bankName',
        salaryAccountNumber: 'salary.accountNumber',
      }),
      formDateErrors () {
        const errors = [];
        if (!this.$v.registerFormDate.$dirty) return errors;
        !this.$v.registerFormDate.required && errors.push('Podanie daty kiedy formularz został wypełniony jest wymagane');
        return errors
      },
      surnameErrors () {
        const errors = [];
        if (!this.$v.surname.$dirty) return errors;
        !this.$v.surname.required && errors.push('Podanie nazwiska jest konieczne');
        return errors
      },
      nameErrors () {
        const errors = [];
        if (!this.$v.name.$dirty) return errors;
        !this.$v.name.required && errors.push('Podanie imienia jest konieczne');
        return errors
      },
      mothersNameErrors () {
        const errors = [];
        if (!this.$v.mothersName.$dirty) return errors;
        !this.$v.mothersName.required && errors.push('Podanie imienia matki jest konieczne');
        return errors
      },
      fathersNameErrors () {
        const errors = [];
        if (!this.$v.fathersName.$dirty) return errors;
        !this.$v.fathersName.required && errors.push('Podanie imienia ojca jest konieczne');
        return errors
      },
      placeOfBirthErrors () {
        const errors = [];
        if (!this.$v.placeOfBirth.$dirty) return errors;
        !this.$v.placeOfBirth.required && errors.push('Podanie miejsca urodzenia jest konieczne');
        return errors
      },
      dayOfBirthErrors () {
        const errors = [];
        if (!this.$v.dayOfBirth.$dirty) return errors;
        !this.$v.dayOfBirth.required && errors.push('Podanie daty urodzenia jest konieczne');
        return errors
      },
      peselErrors () {
        const errors = [];
        if (!this.$v.pesel.$dirty) return errors;
        !this.$v.pesel.required && errors.push('Podanie numeru PESEL jest konieczne');
        return errors
      },
      educationErrors () {
        const errors = [];
        if (!this.$v.education.$dirty) return errors;
        !this.$v.education.required && errors.push('Podanie wykształcenia jest konieczne');
        return errors
      },
      nationalityErrors () {
        const errors = [];
        if (!this.$v.nationality.$dirty) return errors;
        !this.$v.nationality.required && errors.push('Podanie narodowości jest konieczne');
        return errors
      },
      provinceErrors () {
        const errors = [];
        if (!this.$v.province.$dirty) return errors;
        !this.$v.province.required && errors.push('Podanie województwa jest konieczne');
        return errors
      },
      districtErrors () {
        const errors = [];
        if (!this.$v.district.$dirty) return errors;
        !this.$v.district.required && errors.push('Podanie powiatu jest konieczne');
        return errors
      },
      municipalityErrors () {
        const errors = [];
        if (!this.$v.municipality.$dirty) return errors;
        !this.$v.municipality.required && errors.push('Podanie gminy jest konieczne');
        return errors
      },
      cityErrors () {
        const errors = [];
        if (!this.$v.city.$dirty) return errors;
        !this.$v.city.required && errors.push('Podanie miasta jest konieczne');
        return errors
      },
      streetErrors () {
        const errors = [];
        if (!this.$v.street.$dirty) return errors;
        !this.$v.street.required && errors.push('Podanie ulicy jest konieczne');
        return errors
      },
      houseNrErrors () {
        const errors = [];
        if (!this.$v.houseNr.$dirty) return errors;
        !this.$v.houseNr.required && errors.push('Podanie numeru domu jest konieczne');
        return errors
      },
      zipErrors () {
        const errors = [];
        if (!this.$v.zip.$dirty) return errors;
        !this.$v.zip.required && errors.push('Podanie kodu pocztowego jest konieczne');
        return errors
      },
      postErrors () {
        const errors = [];
        if (!this.$v.post.$dirty) return errors;
        !this.$v.post.required && errors.push('Podanie poczty jest konieczne');
        return errors
      },
      emailErrors () {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.required && errors.push('Podanie adresu email jest konieczne');
        return errors
      },
      mobileNrErrors () {
        const errors = [];
        if (!this.$v.mobileNr.$dirty) return errors;
        !this.$v.mobileNr.required && errors.push('Podanie telefonu komórkowego jest konieczne.');
        return errors
      },
      taxNameErrors () {
        const errors = [];
        if (!this.$v.taxName.$dirty) return errors;
        !this.$v.taxName.required && errors.push('Podanie urzędu skarbowego jest konieczne.');
        return errors
      },
      taxCityErrors () {
        const errors = [];
        if (!this.$v.taxCity.$dirty) return errors;
        !this.$v.taxCity.required && errors.push('Podanie miasta urzędu skarbowego jest konieczne.');
        return errors
      },
      taxStreetErrors () {
        const errors = [];
        if (!this.$v.taxStreet.$dirty) return errors;
        !this.$v.taxStreet.required && errors.push('Podanie ulicy urzędu skarbowego jest konieczne.');
        return errors
      },
      taxHouseNrErrors () {
        const errors = [];
        if (!this.$v.taxHouseNr.$dirty) return errors;
        !this.$v.taxHouseNr.required && errors.push('Podanie numeru budynku urzędu skarbowego jest konieczne.');
        return errors
      },
      taxZipErrors () {
        const errors = [];
        if (!this.$v.taxZip.$dirty) return errors;
        !this.$v.taxZip.required && errors.push('Podanie kodu pocztowego urzędu skarbowego jest konieczne.');
        return errors
      },
      nfzNameErrors () {
        const errors = [];
        if (!this.$v.nfzName.$dirty) return errors;
        !this.$v.nfzName.required && errors.push('Podanie oddziału NFZ jest konieczne.');
        return errors
      },
      insuranceTypeErrors () {
        const errors = [];
        if (!this.$v.insuranceType.$dirty) return errors;
        !this.$v.insuranceType.required && errors.push('Podanie danych do ubezpieczenia jest konieczne.');
        return errors
      },
      medicalErrors () {
        const errors = [];
        if (!this.$v.medical.$dirty) return errors;
        !this.$v.medical.required && errors.push('Proszę o zaznaczenie TAK lub NIE');
        return errors
      },
      socialErrors () {
        const errors = [];
        if (!this.$v.social.$dirty) return errors;
        !this.$v.social.required && errors.push('Proszę o zaznaczenie TAK lub NIE');
        return errors
      },

    },
    watch: {
      validate: function (newValue) {
        if (newValue === true) {
          this.save()
          this.$emit('registerFormValidated',true)
        }
      },
      report: function (newValue) {
        if (newValue === true) {
          this.generatePDFReport()
          this.$emit('registerFormPrinted',true)
        }
      }
    },
    methods: {
      ...mapActions({
        saveAll: 'registration/saveAll',
      }),
      save () {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.saveAll().then(response => {
            if (response) {
              this.$emit('registerFormSaved',true)
            }
          }).catch(error => {
            this.$log.error('Saving registration form error', error)
          })
        }
      },
      insuranceTypeDetail: function (c, item) {
        if (item == 'value-2') {
          for (let i = 0; i < this.placesOfWork.length; i++) {
            if (this.placesOfWork[i].name) {
              c.push({
                columns: [{text: 'Nazwa', style: 'label'}, {
                  text: '',
                  style: 'label'
                }]
              }, {columns: [{text: this.placesOfWork[i].name, style: 'data'}, {text: '', style: 'data'}]})
              c.push({
                columns: [{text: 'Miejscowość', style: 'label'}, {
                  text: 'Kod pocztowy',
                  style: 'label'
                }]
              }, {
                columns: [{text: this.placesOfWork[i].city, style: 'data'}, {
                  text: this.placesOfWork[i].zip,
                  style: 'data'
                }]
              })
              c.push({
                columns: [{text: 'Ulica', style: 'label'}, {
                  text: 'Numer budynku',
                  style: 'label'
                }]
              }, {
                columns: [{text: this.placesOfWork[i].street, style: 'data'}, {
                  text: this.placesOfWork[i].houseNr,
                  style: 'data'
                }]
              })
            }
          }
        }

        if (item == 'value-4') {
          for (let i = 0; i < this.placesOfTemporaryWork.length; i++) {
            if (this.placesOfTemporaryWork[i].name) {
              c.push({
                columns: [{text: 'Nazwa', style: 'label'}, {
                  text: '',
                  style: 'label'
                }]
              }, {columns: [{text: this.placesOfTemporaryWork[i].name, style: 'data'}, {text: '', style: 'data'}]})
              c.push({
                columns: [{text: 'Miejscowość', style: 'label'}, {
                  text: 'Kod pocztowy',
                  style: 'label'
                }]
              }, {
                columns: [{
                  text: this.placesOfTemporaryWork[i].city,
                  style: 'data'
                }, {text: this.placesOfTemporaryWork[i].zip, style: 'data'}]
              })
              c.push({
                columns: [{text: 'Ulica', style: 'label'}, {
                  text: 'Numer budynku',
                  style: 'label'
                }]
              }, {
                columns: [{
                  text: this.placesOfTemporaryWork[i].street,
                  style: 'data'
                }, {text: this.placesOfTemporaryWork[i].houseNr, style: 'data'}]
              })
              c.push({
                columns: [{text: 'Od', style: 'label'}, {
                  text: 'Do',
                  style: 'label'
                }]
              }, {
                columns: [{
                  text: this.placesOfTemporaryWork[i].dateFrom,
                  style: 'data'
                }, {text: this.placesOfTemporaryWork[i].dateTo, style: 'data'}]
              })
            }
          }
        }

        if (item == 'value-10') {
          c.push({
            columns: [{text: 'Numer świadczenia emerytalnego', style: 'label'}, {
              text: '',
              style: 'label'
            }]
          }, {columns: [{text: this.nfzNumber1, style: 'data'}, {text: '', style: 'data'}]})
          c.push({
            columns: [{text: 'Numer świadczenia rentowego', style: 'label'}, {
              text: '',
              style: 'label'
            }]
          }, {columns: [{text: this.nfzNumber2, style: 'data'}, {text: '', style: 'data'}]})
          c.push({
            columns: [{text: 'Nazwa NFZ', style: 'label'}, {
              text: '',
              style: 'label'
            }]
          }, {columns: [{text: this.nfzNameNFZ, style: 'data'}, {text: '', style: 'data'}]})
          c.push({
            columns: [{text: 'Miejscowość', style: 'label'}, {
              text: 'Kod pocztowy',
              style: 'label'
            }]
          }, {columns: [{text: this.nfzCity, style: 'data'}, {text: this.nfzZIP, style: 'data'}]})
          c.push({
            columns: [{text: 'Ulica', style: 'label'}, {
              text: 'Numer budynku',
              style: 'label'
            }]
          }, {columns: [{text: this.nfzStreet, style: 'data'}, {text: this.nfzHouseNr, style: 'data'}]})
        }

        if (item == 'value-14') {
          c.push({
            columns: [{text: 'Nazwa uczelni', style: 'label'}, {
              text: '',
              style: 'label'
            }]
          }, {columns: [{text: this.studentSchoolName, style: 'data'}, {text: '', style: 'data'}]})
          c.push({
            columns: [{text: 'Adres', style: 'label'}, {
              text: '',
              style: 'label'
            }]
          }, {columns: [{text: this.studentAddress, style: 'data'}, {text: '', style: 'data'}]})
          c.push({
            columns: [{text: 'Nr legitymacji', style: 'label'}, {
              text: '',
              style: 'label'
            }]
          }, {columns: [{text: this.studentIdNumber, style: 'data'}, {text: '', style: 'data'}]})
        }

        if (item == 'value-16') {
          c.push({
            columns: [{text: 'Tytuł do ubeczpieczenia', style: 'label'}, {
              text: '',
              style: 'label'
            }]
          }, {columns: [{text: this.otherInsuranceTitle, style: 'data'}, {text: '', style: 'data'}]})
        }
      },
      generatePDFReport () {
        var pdfMake = require('pdfmake/build/pdfmake.js');
        if (pdfMake.vfs == undefined){
          var pdfFonts = require('pdfmake/build/vfs_fonts.js');
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }

        let c = []
        c.push({text: 'Data: ' + this.registerFormDate, style: 'singleLine'})

        c.push({columns: [{text: 'Nazwisko', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.surname, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Imię', style: 'label'}, {text: 'Drugie imię', style: 'label'}] }, {columns: [{text: this.name, style: 'data'}, {text: this.secondName, style: 'data'}] })
        c.push({columns: [{text: 'Nazwisko rodowe', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.familyName, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Imię matki', style: 'label'}, {text: 'Imię ojca', style: 'label'}] }, {columns: [{text: this.mothersName, style: 'data'}, {text: this.fathersName, style: 'data'}] })
        c.push({columns: [{text: 'Miejsce urodzenia', style: 'label'}, {text: 'Data urodzenia', style: 'label'}] }, {columns: [{text: this.placeOfBirth, style: 'data'}, {text: this.dayOfBirth, style: 'data'}] })
        c.push({columns: [{text: 'PESEL', style: 'label'}, {text: 'NIP', style: 'label'}] }, {columns: [{text: this.pesel, style: 'data'}, {text: this.nip, style: 'data'}] })
        c.push({columns: [{text: 'Wykształcenie', style: 'label'}, {text: 'Obywatelstwo', style: 'label'}] }, {columns: [{text: this.education, style: 'data'}, {text: this.nationality, style: 'data'}] })
        c.push({columns: [{text: 'Seria i numer dowodu osobistego', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.idSeries + ' ' + this.idNumber, style: 'data'}, {text: '', style: 'data'}] })

        c.push({text: 'Dane adresowe', style: 'header' })
        c.push({columns: [{text: 'Województwo', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.province, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Powiat', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.district, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Gmina', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.municipality, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Miejscowość', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.city, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Ulica', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.street, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Numer domu', style: 'label'}, {text: 'Numer lokalu', style: 'label'}] }, {columns: [{text: this.houseNr, style: 'data'}, {text: this.homeNr, style: 'data'}] })
        c.push({columns: [{text: 'Kod pocztowy', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.zip, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Poczta', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.post, style: 'data'}, {text: '', style: 'data'}] })

        c.push({text: 'Dane kontaktowe', style: 'header' })
        c.push({columns: [{text: 'Email', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.email, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Telefon', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.telephoneNr, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Telefon komórkowy', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.mobileNr, style: 'data'}, {text: '', style: 'data'}] })

        c.push({text: 'Dane Urząd Skarbowy', style: 'header' })
        c.push({columns: [{text: 'Gmina', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.taxName, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Miejscowość', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.taxCity, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Ulica', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.taxStreet, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Numer budynku', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.taxHouseNr, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Kod pocztowy', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.taxZip, style: 'data'}, {text: '', style: 'data'}] })

        c.push({text: 'Oddział Narodowego Funduszu Zdrowia (NFZ)', style: 'header' })
        c.push({columns: [{text: 'Nazwa', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.nfzName, style: 'data'}, {text: '', style: 'data'}] })

        c.push({text: 'Dane do ubezpieczenia', style: 'header' })

        if(typeof this.insuranceType !== 'object') {
          c.push({text: InsuranceTypes[this.insuranceType], style: 'singleLine' })
          this.insuranceTypeDetail(c, this.insuranceType);
        } else {
          this.insuranceType.forEach(item => {
            c.push({text: InsuranceTypes[item], style: 'singleLine' })
            this.insuranceTypeDetail(c, item);
          });
        }

        c.push({text: 'Dodatkowe dane do ubezpieczenia', style: 'header' })

        if (this.social == 'true') {
          c.push({text: AdditionalInsuranceTypes['social'] })
          c.push({text: 'TAK', style: 'singleLineBold' })
        }
        if (this.social == 'false') {
          c.push({text: AdditionalInsuranceTypes['social'] })
          c.push({text: 'NIE', style: 'singleLineBold' })
        }

        if (this.medical == 'true') {
          c.push({text: AdditionalInsuranceTypes['medical']})
          c.push({text: 'TAK', style: 'singleLineBold' })
        }
        if (this.medical == 'false') {
          c.push({text: AdditionalInsuranceTypes['medical']})
          c.push({text: 'NIE', style: 'singleLineBold' })
        }

        c.push({text: 'Pozostałe dane', style: 'header' })
        if (this.otherFamily == 'true') {
          c.push({text: OtherTypes['family']})
          c.push({text: 'TAK', style: 'singleLineBold' })
        }
        if (this.otherFamily == 'false') {
          c.push({text: OtherTypes['family']})
          c.push({text: 'NIE', style: 'singleLineBold' })
        }

        if (this.otherSocial == 'true') {
          c.push({text: OtherTypes['social'] })
          c.push({text: 'TAK', style: 'singleLineBold' })
        }
        if (this.otherSocial == 'false') {
          c.push({text: OtherTypes['social'] })
          c.push({text: 'NIE', style: 'singleLineBold' })
        }

        if (this.otherDisabled == 'true') {
          c.push({text: OtherTypes['disabled'] + ': ' + this.otherLevel, style: 'singleLine' })
        }
        if (this.otherDisabled == 'false') {
          c.push({text: OtherTypes['disabled'] })
          c.push({text: 'NIE', style: 'singleLineBold' })
        }

        c.push({text: 'Wynagrodzenie przekazać', style: 'header' })
        c.push({text: 'Przelewem na konto:', style: 'singleLine' })
        c.push({columns: [{text: 'Nazwisko', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.salarySurnameForBank, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Imię', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.salaryNameForBank, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Nazwa banku', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.salaryBankName, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Numer rachunku', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.salaryAccountNumber, style: 'data'}, {text: '', style: 'data'}] })
        c.push({text: '..............................', style: 'signature' })
        c.push({text: 'Podpis', style: 'signatureLabel' })


        var docDefinition = {
          content: c,
          styles: {
            header: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 20],
              alignment: "left"
            },
            label: {
              fontSize: 8,
              bold: false,
              alignment: "left"
            },
            data: {
              fontSize: 12,
              bold: false,
              alignment: "justify",
              margin: [0, 0, 0, 20],
            },
            footer: {
              alignment: "right",
              margin: [0, 20, 0, 20]
            },
            singleLine: {
              margin: [0, 0, 0, 20],
            },
            singleLineBold: {
              bold: true,
              margin: [0, 0, 0, 20],
            },
            signature: {
              alignment: "right",
              margin: [20, 0, 0, 0]
            },
            signatureLabel: {
              alignment: "right",
              margin: [0, 0, 30, 0],
              fontSize: 8,
              bold: false,
            }
          }
        };
        pdfMake.createPdf(docDefinition).download('unikadra-formularz-rejestracyjny-raport.pdf')
      },
    }
  }
</script>

<style scoped>

</style>
