<template>
  <v-data-table
      v-if="userRole == 1 || userRole == 8 || userRole == 3"
      v-model="selected"
      :headers="headers"
      :items="filteredMentors"
      :options.sync="options"
      :search="options.search"
      :single-select=singleSelect
      class="elevation-1 ma-4"
      show-select
      sort-by="lastname"
  >
    <template v-slot:top>
      <v-toolbar color="white" flat>
        <v-toolbar-title>Mentorzy</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-autocomplete
            v-model="filter"
            :items="tags('')"
            chips
            hide-details
            item-text="name"
            item-value="id"
            label="Filtruj po tagach"
            multiple
            small-chips
        ></v-autocomplete>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="options.search"
            append-icon="search"
            hide-details
            label="Szukaj"
            single-line
        ></v-text-field>
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="mb-2 ml-4" color="primary" icon rounded small @click="$router.push('/mentors/tags')"><v-icon>local_offer</v-icon></v-btn>
        <v-btn v-show="showExtraActions" :color=buttonForRemove class="mb-2 ml-4" dark rounded small @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń mentorów</v-btn>
        <v-dialog v-model="dialog" max-width="800px" persistent>
          <template v-slot:activator="{ on }">
            <v-btn class="mb-2 ml-4" color="primary" dark rounded small v-on="on">{{ $vuetify.breakpoint.mdAndUp ? 'Dodawanie mentora' : 'Dodaj' }}</v-btn>
          </template>
          <MentorAddEdit :editedIndex="editedIndex" :editedItem="editedItem" :formTitle="formTitle" @dialogClosed="dialogClosed()" />
        </v-dialog>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Poprawnie dodałeś nowego mentora</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon class="green--text" x-large>check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    <v-btn color="primary" rounded @click="dialogNewSuccess = false">Powrót do listy wszystkich mentorów</v-btn>
                  </v-col>
                  <v-col sm="12">
                    lub
                  </v-col>
                  <v-col sm="12">
                    <v-btn color="primary" outlined rounded @click="closeDialogNewSuccessAndAddAnotherMentor">Chcę dodać kolejnego mentora</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie mentora</span>
                    <span v-else class="headline">Usunąłeś poprawnie mentorów</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon class="green--text" x-large>check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    <div v-if="selected.length > 0">
                      <div v-for="item in selected">
                        {{ item.name }}
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="12">
                    <v-btn color="primary" rounded @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.lp="{ item }">
      {{ item.id }}
    </template>
    <template v-slot:item.tags="{ item }">
      {{ displayTag(item) }}
    </template>
    <template v-slot:item.business_card="{ item }">
      <v-icon v-if="item.business_card" class="green--text" @click="downloadBusinessCardByUrl(item.business_card)" >check</v-icon>
      <span v-else>
          <v-icon
              class="red--text"
            >
          dangerous
        </v-icon>
      </span>
    </template>
    <template v-slot:item.active="{ item }">
      <v-icon>{{ (item.active)?'check_circle_outline':'warning' }}</v-icon>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
          v-if="userRole == 1 || userRole == 8"
          class="mr-4"
          small
          @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon
          class="mr-2"
          small
          @click="showMentor(item)"
      >
        person
      </v-icon>
    </template>
    <template v-slot:no-data>
      Brak mentorów
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MentorAddEdit from "./MentorAddEdit.vue";

export default {
  name: "Mentors",
  components: { MentorAddEdit },
  computed: {
    ...mapGetters({
      mentors: 'mentors/getMentors',
      mentorsTags: 'mentors/getMentorsTags',
      mentorsTagsAll: 'mentors/getMentorsTagsAll',
      dialogFromStore: 'mentors/dialogNew',
      userRole: 'users/userRole',
      getPage: 'paging/getPage',
      tags: 'tags/getTagsSearched',
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodawanie nowego mentora' : 'Edytuj mentora'
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
    filteredMentors() {
      let mentors = [];
      mentors = this.mentorsTagsAll.filter(x => this.filter.includes(x.tid)).map(x => x.mid);
      if (mentors.length > 0) {
        return this.mentors.filter(x => mentors.includes(x.id));
      } else {
        return this.mentors;
      }
    },
    showExtraActions () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  data: () => ({
    selected: [],
    menuDate: false,
    dialogNewSuccess: false,
    dialogRemoveSuccess: false,
    headers: [
      { text: 'Imię', value: 'firstname' },
      { text: 'Nazwisko', value: 'lastname' },
      { text: 'Telefon', value: 'telephone' },
      { text: 'Email', value: 'email' },
      { text: 'Wizytówka PDF', value: 'business_card' },
      { text: 'Tagi', value: 'tags', cellClass: 'width-200' },
      { text: '', value: 'action', sortable: false, cellClass: 'width-100' },
    ],
    editedIndex: -1,
    editedItem: {
      id: '',
      firstname: '',
      lastname: '',
      telephone: '',
      email: '',
      description: '',
      business_card: '',
      tags: [],
      active: 1
    },
    defaultItem: {
      firstname: '',
      lastname: '',
      telephone: '',
      email: '',
      description: '',
      business_card: '',
      tags: [],
      active: 1
    },
    singleSelect: false,
    options: {},
    filter: [],
    possibleToAdd: false
  }),
  watch: {
    options: {
      handler () {
        this.handlePage()
      },
      deep: true,
    }
  },
  methods: {
    ...mapActions({
      setDialogNew: 'mentors/setDialogNew',
      deleteMentors: 'mentors/deleteMentors',
      downloadBusinessCardByUrl: 'mentors/downloadBusinessCardByUrl',
      setPage: 'paging/setPage',
      setMentorSelected: 'mentors/setMentorSelected'
    }),
    handlePage() {
      let { sortBy, sortDesc, page, itemsPerPage, search } = this.options;
      this.setPage({ sortBy: sortBy, sortDesc: sortDesc, page: page, itemsPerPage: itemsPerPage, search: search })
    },
    editItem (item) {
      this.editedIndex = this.mentors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.tags = this.mentorsTags(item.id).map(x => x.id);
      this.dialog = true;
    },
    closeDialogNewSuccessAndAddAnotherMentor () {
      this.dialogNewSuccess = false;
      this.setDialogNew(true)
    },
    closeDialogRemoveSuccess () {
      this.dialogRemoveSuccess = false;
      this.selected = []
    },
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć poniższych mentorów?')){
        this.deleteMentors(this.selected).then(response => {
          this.$log.info('Deleted mentor', response);
          this.dialogRemoveSuccess = true
        }).catch(error => {
          this.$log.error('Deleting mentor error', error)
        })
      }
    },
    displayTag(item) {
      let html = '';
      this.mentorsTags(item.id).forEach(x => {
        // html += '<span class="mentor-tag ma-2 pa-2" label>' + x.name + '</span>';
        html += x.name + ', ';
      });
      return html.slice(0, -2);
      // return this.mentorsTags(item.id).map(x => x.name);
    },
    showMentor(item) {
      this.setMentorSelected(item);
      this.$router.push('/mentors/'+ item.id + '');
    },
    dialogClosed() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    }
  },
  created() {
    this.$store.dispatch('mentors/getMentors');
    this.$store.dispatch('mentors/getMentorsTags');
    this.$store.dispatch('tags/getTags');
  },
  mounted () {
    this.options = this.getPage;
  },
}
</script>

<style>
  .mentor-tag {
    background-color: #e0e0e0;
    border-radius: 5px;
  }
  .width-200 {
    width: 200px
  }
  .width-100 {
    width: 100px
  }
</style>

