<template>
  <v-container>
    <v-card>
      <v-card-title>Raport <v-btn color="grey" class="overline ml-5" text @click="$router.push('/accountant-settlements')"><v-icon small left>arrow_back</v-icon> powrót do listy wszystkich raportów</v-btn></v-card-title>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="studentSettlements"
            :items-per-page="10"
            class="elevation-1"
        >
          <template v-slot:item.amount="{ item }">
            {{ item.amount/100 }} PLN
          </template>
          <template v-slot:item.studentCourseBankAccount="{ item }">
            {{ item.studentCourseBankAccount ? item.studentCourseBankAccount : item.bank_account }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AccountantSettlement",
  computed: {
    ...mapGetters({
      studentSettlements: 'settlements/getStudentSettlements',
      getSettlementSelected: 'settlements/getSettlementSelected'
    }),
  },
  data: () => ({
    headers: [
      { text: 'Użytkownik', value: 'name' },
      { text: 'Kwota', value: 'amount' },
      { text: 'Data', value: 'date' },
      { text: 'Kierunek', value: 'courseName' },
      { text: 'Rachunek', value: 'studentCourseBankAccount' },
    ]
  }),
  mounted() {
    this.$store.dispatch('settlements/getStudentSettlementsFromAPI');
  },
}
</script>

<style scoped>

</style>
