<template>
  <v-select v-model="courseSingleSelected"
            :items="itemCourses"
            chips
            class="ml-4"
            deletable-chips
            item-text="name"
            item-value="id"
            menu-props="auto"
            placeholder="Wszystkie kierunki"
            single-line
  >
  </v-select>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'SelectCourse',
  methods: {
    ...mapActions({
      setCourseSingleSelected: 'courses/setCourseSingleSelected',
    })
  },

  computed: {
    ...mapGetters({
      courseSingleSelectedFromStore: 'courses/getCourseSingleSelected',
      courses: 'courses/getCourses'
    }),
    itemCourses() {
      let courses = Object.assign(this.courses, this.courses)
      courses.push({ 'id': '', 'name': 'Wszystkie kierunki'})
      return courses;
    },
    courseSingleSelected: {
      get() {
        return this.courseSingleSelectedFromStore
      },
      set(value){
        this.$emit('courseChanged', value)
        return this.setCourseSingleSelected(value)
      }
    },
  }
}
</script>
<style scoped>
  ::v-deep .v-chip {
    height: auto !important;
  }

  ::v-deep .v-chip .v-chip__content {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  white-space: pre-wrap;
}
</style>

