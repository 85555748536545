import candidate from '../../services/Candidate'

// initial state
const state = {
  candidates: [],
  candidatesReport: 0,
  dialogNew: false,
  chosenCandidate: null
};

// getters
const getters = {
  getCandidates: state => state.candidates,
  dialogNew: state => state.dialogNew,
  getChosenCandidateData: state => state.candidates.find(item => item.id == state.chosenCandidate),
  getChosenCandidate: state => state.chosenCandidate
};

// actions
const actions = {
  getCandidates ({ commit }, parameters) {
    candidate.getCandidates(parameters).then(response => {
      commit('setCandidates', response.data)
    })
  },
  editCandidate ({ commit, rootState }, editedCandidate) {
    return new Promise((resolve, reject) => {
      candidate.editCandidate(editedCandidate).then(response => {
        if (response.status == 200) {
          commit('editCandidate', response.data)
          resolve(200)
        }
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
  deleteCandidates ({ commit, rootState }, deletedCandidates) {
    candidate.deleteCandidates(deletedCandidates.map(function(item) { return item["id"] }), rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      console.log(response.status);
      if (response.status == 200) {
        commit('deleteCandidates', deletedCandidates)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setChosenCandidate({commit}, value) {
    commit('setChosenCandidate', value)
  },
  resetChosenCandidate({dispatch, commit}) {
    commit('setChosenCandidate', null)
  },
};

// mutations
const mutations = {
  setCandidates (state, candidates) {
    state.candidates = candidates;
  },
  editCandidate (state, userData) {
    let candidate = state.candidates.find(item => item.id == state.chosenCandidate);
    candidate.date = userData.date
    candidate.username = userData.username
    candidate.telephone = userData.telephone
    candidate.email = userData.email
    candidate.pid = userData.pid
    candidate.cid = userData.cid
    candidate.cid_2 = userData.cid_2
    candidate.cityName = userData.cityName
    candidate.comment = userData.comment
    candidate.courseName = userData.courseName
    candidate.courseName2 = userData.courseName2
    candidate.question_1 = userData.question_1
    candidate.question_2 = userData.question_2
  },
  deleteCandidates (state, candidates) {
    candidates.map(function(key) {
      const index = state.candidates.indexOf(key);
      state.candidates.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setChosenCandidate (state, user) {
    state.chosenCandidate = user
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
