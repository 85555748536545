<template>
  <BarChart :chartData="chartDataCity"></BarChart>
</template>

<script>
import {mapGetters} from "vuex";
import BarChart from "./BarChart.vue";

export default {
  name: "StudentCourseGroupedByCity",
  data: () => ({}),
  components: { BarChart },
  computed: {
    ...mapGetters({
      multiSemesterSelected: 'multiSemesterSelected',
      courseSelected: 'courses/getCourseSelected',
      placeSelected: 'places/getPlaceSelected',

      getStudentCourseGrouped: 'studentCourses/getStudentCoursesGrouped',
    }),
    chartDataCity() {
      let chartData = {labels: [], datasets: []};
      chartData.labels = this.getStudentCourseGrouped.pid.map(x => x.name);
      chartData.datasets[0] = {
        backgroundColor: '#f87979',
        label: 'Ilość studentów w mieście',
      };
      chartData.datasets[0].data = this.getStudentCourseGrouped.pid.map(x => x.amount);
      return chartData;
    },
  },
  watch: {
    multiSemesterSelected() {
      this.getStudentCourses();
    },
    placeSelected() {
      this.getStudentCourses();
    },
    courseSelected() {
      this.getStudentCourses();
    }
  },
  methods: {
    getStudentCourses() {
      this.loading = true;
      this.$store.dispatch(
          'studentCourses/getStudentCoursesGrouped',
          {
            'date': this.multiSemesterSelected ? this.multiSemesterSelected.join(',') : '',
            'pid': this.placeSelected,
            'cid': this.courseSelected,
            'groupBy': 'pid'
          }
      ).then(() => {
        this.loading = false;
      });
    }
  },
  created() {
    this.getStudentCourses();
  },
}
</script>

<style>
</style>
