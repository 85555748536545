<template>
  <v-container>
    <v-card>
      <v-card-title>Ustawienia</v-card-title>
      <v-card-text>
        <p>W tej sekcji możesz zmienić hasło. Pamiętaj aby nie dzielić się nim z nikim.</p>
        <PasswordChangeOldRequired />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PasswordChangeOldRequired from "../../components/PasswordChangeOldRequired";

export default {
  name: "PasswordChange",
  components: { PasswordChangeOldRequired },

}
</script>

<style scoped>

</style>
