import Api from './Api'

export default {
  createMessage (message) {
    return Api().post('/api/messages', message)
  },
  getMessages () {
    return Api().get('/api/messages', { withCredentials: true })
  },
  getMessagesByUserId (uid) {
    return Api().get('/api/messages/user/' + uid, { withCredentials: true })
  },
  editMessage (messageData) {
    return Api().put('/api/messages/' + messageData.id, messageData)
  },
  deleteMessages (messages) {
    return Api().post('/api/messages/delete-multiple', messages)
  }
}
