<template>
  <div>
    <v-dialog v-model="dialogMessage" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Wiadomość</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <span v-if="message.type == 1">Wiadomość wysyłana do: {{ Object.values(message.email).join(', ') }}</span>
            <v-row>
              <v-col sm="12">
                <v-select
                    v-model="message.type"
                    label="Typ wiadomości"
                    dense
                    :error-messages="typeErrors"
                    :items="messageTypes"
                    @blur="$v.message.type.$touch()"
                    required
                    item-text="name"
                    item-value="value"
                    v-on:change="setSubjectIfEmptyBasedOnType"
                ></v-select>
              </v-col>
            </v-row>

          <v-row v-if="message.type == 1">
            <v-col sm="12">
              <v-text-field
                  v-model="message.subject"
                  label="Tytuł wiadomości"
                  dense
                  :error-messages="subjectErrors"
                  @blur="$v.message.subject.$touch()"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="message.type == 2">
            <v-col cols="12" md="12" sm="12">
              <v-textarea v-model="message.text" label="Wiadomość"></v-textarea>
            </v-col>
          </v-row>
            <v-row no-gutters>
              <v-col>
                <v-btn
                    v-if="selectedFile == undefined"
                    :loading="isSelecting"
                    color="primary"
                    depressed
                    rounded
                    @click="onUploadButtonClick"
                >
                  <v-icon left>
                    cloud_upload
                  </v-icon>
                  Dodaj załącznik
                </v-btn>
                <input
                    ref="uploader"
                    accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                    class="d-none"
                    type="file"
                    @change="onFileChanged"
                >
                <div v-if="this.selectedFile !== undefined">
                  Zostanie dołączony: {{ this.selectedFile.name }} ({{ this.selectedFile.type }}, wielkość: {{ (this.selectedFile.size/1000000).toFixed(2) }}MB)
                  <v-btn color="error" dark rounded @click="removeSelected">Usuń</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-7">
          <v-btn color="grey" class="overline" text @click="close">
            <v-icon small left>arrow_back</v-icon>
            <span>porzuć dodawanie i wróć do listy wszystkich słuchaczy</span></v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary" rounded @click="save">Wyślij wiadomość</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewSuccess" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Poprawnie wysłałeś wiadomość</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon x-large class="green--text">check_circle_outline</v-icon>
              </v-col>
              <v-col sm="12">
                <span v-if="type == 1">{{ Object.values(message.email).join(', ') }}</span>
              </v-col>
              <v-col sm="12">
                <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich słuchaczy
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import Integer from "vuelidate/lib/validators/integer";

export default {
  mixins: [validationMixin],
  name: 'Message',
  data: () => ({
    dialogNewSuccess: false,
    defaultItem: {
      email: '',
      uid: '',
      subject: 'Komunikator',
      type: 2,
      text: ''
    },
    dialogMessage: false,
    message: { email: '', subject: '', uid: '', type: 1 },
    messageTypes: [
        // {'value': 0, 'name': 'Numer konta', 'subject': 'Studia podyplomowe ANS Lublin (nabór III.2022) – numer rachunku'},
        {'value': 1, 'name': 'Pierwsze logowanie (email)', 'subject': 'Studia podyplomowe ANS Lublin - pierwsze logowanie'},
        {'value': 2, 'name': 'Wiadomość dowolna (komunikator)', 'subject': 'Komunikator'}
    ],
    selectedFile: undefined,
  }),
  props: {
    messageForRole: String,
    showDialog: Boolean,
    receiver: {},
  },
  validations: {
    message: {
      type: {required},
      subject: {required}
    }
  },
  computed: {
    emailErrors () {
      const errors = [];
      if (!this.$v.message.email.$dirty) return errors;
      !this.$v.message.email.required && errors.push('Podanie przynajmniej jednego adresu email jest wymagane');
      return errors
    },
    typeErrors () {
      const errors = [];
      if (!this.$v.message.type.$dirty) return errors;
      !this.$v.message.type.required && errors.push('Podanie typu wiadomości jest wymagane');
      return errors
    },
    subjectErrors () {
      const errors = [];
      if (!this.$v.message.subject.$dirty) return errors;
      !this.$v.message.subject.required && errors.push('Podanie tytułu jest wymagane');
      return errors
    },
  },
  methods: {
    ...mapActions({
      createMessage: 'messages/createMessage',
      createMultipleChatMessage: 'chat/createMultipleChatMessage'
    }),
    setSubjectIfEmptyBasedOnType(selectedId) {
      this.message.subject = this.messageTypes.find(x => x.value == selectedId).subject;
    },
    save: async function () {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.message.type == 2) {
          let message = {};
          if(this.selectedFile !== undefined) {
            message = new FormData();
            message.append('file', this.selectedFile);
            message.append('msg', this.message.text);
            message.append('uid', JSON.stringify(this.message.uid));
          } else {
            message = {msg: this.message.text, uid: this.message.uid };
          }
          this.createMultipleChatMessage(message).then(response => {
            if(response == 200) {
              this.$log.info('Msg changed ', response);
              this.loading = false;
              this.msg = '';
              this.selectedFile = undefined;
              this.close();
              this.dialogNewSuccess = true
            }
          }, error => {
            this.error = true
            this.loading = false
            this.$log.error('Msg change error ', error)
          })
        } else {
          this.createMessage(this.message).then(response => {
            if (response == 200) {
              this.$log.info('Saved new message', response);
              this.close();
              this.dialogNewSuccess = true
            }
          }).catch(error => {
            this.$log.error('Saving new message error', error)
          })
        }
      }
    },
    onUploadButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      console.log(this.selectedFile);
      // do something
    },
    removeSelected() {
      this.selectedFile = undefined;
    },
    close() {
      this.dialogMessage = false;
      this.$emit('closeDialogMessage',false)
    },
    clearEditItem() {
      this.message = Object.assign({}, this.defaultItem);
    },
  },
  watch: {
    showDialog(val) {
      if(this.messageForRole == '0') {
        this.messageTypes.shift();
      }
      this.dialogMessage  = val;
    },
    receiver(val) {
      this.message = Object.assign({}, this.defaultItem);
      this.message.email  = val.email;
      this.message.uid  = val.uid;
    },
    dialogNewSuccess (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    }
  },
}
</script>
