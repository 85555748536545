<template>
  <v-container>
    <template>
      <v-dialog v-model="dialogError" max-width="800px" persistent>
        <v-card>
          <v-card-title>
            <v-container class="text-center">
              <v-row>
                <v-col sm="12">
                  <span class="headline">Błąd</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-container class="text-center">
              <v-row>
                <v-col sm="12">
                  <v-icon class="red--text" x-large>warning</v-icon>
                </v-col>
                <v-col sm="12">
                  Nie znaleziono pliku. Spróbuj ponownie lub skonsultuj się z dziekanatem.
                </v-col>
                <v-col sm="12">
                  <v-btn color="primary" rounded @click="dialogError = false">Powrót do plików</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <v-card>
      <v-card-title>Informacje ogólne</v-card-title>
      <v-card-text>
        <p>W tej zakładce znajdziesz wszystkie ważne dokumenty, pliki oraz wszystko co może Ci się przydać podczas studiów.</p>
          <v-expansion-panels>
            <v-expansion-panel v-for="item in fileGroups" :key="item.id">
              <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card v-for="file in getFilesByGroupId(item.id)" :key="file.id" class="mb-4 pa-4">
                  <v-card-text>
                    <v-row align="center" justify="center">
                      <v-col cols="2" sm="2" md="2">
                        <v-badge
                            overlap
                        >
                          <template v-slot:badge>
                            {{ file.mimetype.split('/')[1] }}
                          </template>

                          <v-icon>
                            file_present
                          </v-icon>
                        </v-badge>
                      </v-col>
                      <v-col cols="10" sm="10" md="8"><h4>{{ file.name }}</h4>{{ file.description }}</v-col>
                      <v-col cols="12" sm="12" md="2">
                        <v-btn color="primary" dark rounded target="_blank" @click="handleFileByUrl(file.id)">
                          <v-icon left>
                            file_download
                          </v-icon>
                          Pobierz
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "StudentGeneralInformation",
  computed: {
    ...mapGetters({
      fileGroups: 'fileGroups/getFileGroups',
      getFilesByGroupId: 'files/getFilesByGroupId',
    })
  },
  methods: {
    ...mapActions({
      getFileByUrl: 'files/getFileByUrl'
    }),
    handleFileByUrl(fileId) {
      this.getFileByUrl(fileId).then(response => {
        if(response !== 200) {
          this.dialogError = true;
        }
      });
    }
  },
  data() {
    return {
      search: '',
      dialogError: false
    }
  },
  created() {
    this.$store.dispatch('fileGroups/getFileGroups');
    this.$store.dispatch('files/getAll');
  },
}
</script>

<style scoped>

</style>
