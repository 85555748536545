import time from '../../services/Time'
import moment from "moment";
import report from "../../services/Report";

// initial state
const state = {
  items: [],
  dialogNew: false,
  timesReport: 0,
};

// getters
const getters = {
  getTimes: state => state.items,
  dialogNew: state => state.dialogNew,
  getTimesReport: state => state.timesReport,
};

// actions
const actions = {
  getTimes ({ commit, rootState }, period) {
    time.getTimes(period, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      commit('setTimes', response.data)
    })
  },
  createTime ({ commit, rootState }, newTime) {
    return new Promise((resolve, reject) => {
        time.createTime(newTime, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
          if (response.status == 200) {
            commit('addTime', response.data)
            resolve(200)
          }
        }).catch((response, errors) => {
          reject(errors)
        })
    }).catch((error) => { console.log(error) });
  },
  editTime ({ commit }, editedTime) {
    return new Promise((resolve, reject) => {
        time.editTime(editedTime).then(response => {
          if (response.status == 200) {
            commit('editTime', response.data)
            resolve(200)
          }
        }).catch((errors) => {
          reject(errors)
        })
    }).catch((error) => { console.log(error) });
  },
  deleteTimes ({ commit, rootState }, deletedTimes) {
    time.deleteTimes(deletedTimes.map(function(item) { return item["id"] })).then(response => {
      console.log(response.status);
      if (response.status == 200) {
        commit('deleteTimes', deletedTimes)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  createReport ({ store, commit, rootState }, { statusType: statusType = 1, perUser: perUser = false} = {} ) {
    const startOfMonth = moment(rootState.monthSelected).startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment(rootState.monthSelected).endOf('month').format('YYYY-MM-DD');
    report.createReport({ date_from: startOfMonth, date_to: endOfMonth, status: statusType, type: "czas"}, perUser ? rootState.users.chosenUser : null).then(response => {
      commit('addTimeReport', response.data)
    })
  },
  acceptReport ({ dispatch}) {
    return dispatch('createReport', { statusType: 2, perUser: true });
  },
  declineReport ({ dispatch }) {
    return dispatch('createReport', { statusType: 0, perUser: true });
  }
};

// mutations
const mutations = {
  setTimes (state, times) {
    state.items = times.items,
    state.timesReport = times.time_report
  },
  addTime (state, time) {
    state.items.push(time)
  },
  editTime (state, time) {
    console.log(time)
  },
  deleteTimes (state, times) {
    times.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  addTimeReport (state, timeReportStatus) {
    state.timesReport = timeReportStatus.status
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
