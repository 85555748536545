<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                            v-model="rate"
                            :error-messages="rateErrors"
                            required
                            @blur="$v.rate.$touch()"
                            label="Stawka za km"
                            suffix="zł/km"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="justify-end d-flex">
                    <v-btn color="primary" dark rounded @click="save" class="mb-4">Zapisz stawkę</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
            <v-card>
                <v-card-title>
                    <v-container class="text-center">
                        <v-row>
                            <v-col sm="12">
                                <span class="headline">Stawka zmieniona</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-container class="text-center">
                        <v-row>
                            <v-col sm="12">
                                <v-icon x-large class="green--text">check_circle_outline</v-icon>
                            </v-col>
                            <v-col sm="12">
                                Nowa stawka {{ rate }}/km
                            </v-col>
                            <v-col sm="12">
                                <v-btn rounded color="primary" outlined @click="closeDialogNewSuccess">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
  import {mapActions} from "vuex";
  import {validationMixin} from "vuelidate";
  import {required, helpers} from "vuelidate/lib/validators";

  const currencyRegex = helpers.regex('currency', /^-?(0|[1-9]\d{0,})(\.\d{1,2})?$/)

  export default {
    name: 'RatePerKm',
    data() {
      return {
        loader: null,
        loading: false,
        dialogNewSuccess: false,
        rate: '',
      }
    },
    mixins: [validationMixin],
    validations: {
      rate: {
        required,
        currencyRegex,
      },
    },
    mounted() {
      this.rate = this.$store.getters["users/getRatePerKm"] / 100
    },
    computed: {
      rateErrors () {
        const errors = []

        if (!this.$v.rate.$dirty) return errors
        !this.$v.rate.currencyRegex && errors.push('Stawka musi być wartością liczbową')
        !this.$v.rate.required && errors.push('Podanie nowego hasła jest wymagane')
        return errors
      },
    },
    methods: {
      ...mapActions({
        setRatePerKm: 'users/setRatePerKm'
      }),
      save () {
        this.$v.$touch()
        if (!this.$v.rate.$invalid) {
          this.loader = 'loading'
          this.loading = true
          this.setRatePerKm({rate: this.rate}).then(response => {
            if(response == 200) {
              this.$log.info('Rate per km changed ', response)
              this.loading = false
              this.$v.$reset()
              this.dialogNewSuccess = true
            }
          }, error => {
            this.error = true
            this.loading = false
            this.$log.error('Rate per km change error ', error)
          })
        }
      },
      closeDialogNewSuccess () {
        this.dialogNewSuccess = false
      }
    }
  }
</script>

<style scoped>

</style>
