<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="studentCourses"
      sort-by="date"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
      :search.sync="options.search"
      :loading="loading"
      loading-text="Wczytuję listę... proszę czekać"
      :options.sync="options"
      v-if="userRole == 1 || userRole == 2"
      dense
      group-by="email"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Słuchacze (studenci)</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="options.search"
            append-icon="search"
            label="Szukaj"
            single-line
            hide-details
        ></v-text-field>
      </v-toolbar>
      <v-toolbar flat color="white">
        <MultiSelectSemesters @semesterChanged="semesterChanged"/>
        <SelectPlaces @placeChanged="placeChanged"/>
        <SelectCourses @courseChanged="courseChanged"/>
        <v-spacer></v-spacer>
        <v-btn :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczonych słuchaczy</v-btn>
        <!-- edit user -->
        <UserEdit
            :clear-edit-item="clearEditItem"
            :close-edit-dialog-user="closeEditDialogUser"
            :dialog-user="dialogUser"
            :edited-item="editedItem"
            :form-title="formTitle"
            :report="report"
            :selected="selected"
            :semester-choices="semesterChoices"
            :validate="validate"
            :edited-index="editedIndex"
        />
        <StudentCourseAndUserAdd
            :close-dialog-new-success-and-add-another-user="closeDialogNewSuccessAndAddAnotherUser"
            :close-edit-dialog="closeEditDialog"
            :dialog="dialog"
            :edited-item="editedItem"
            :form-title="formTitle"
            :report="report"
            :validate="validate"
        />
        <Message @closeDialogMessage="closeDialogMessage" :showDialog="dialogMessage" :receiver="selectedItems()" />
        <v-dialog v-model="dialogUploadAlbumNumbers" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Wgrywanie numerów albumów z pliku CSV</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-stepper v-model="stepUploadAlbums">
                <v-stepper-header>
                  <v-stepper-step :complete="stepUploadAlbums > 1" step="1">Wgraj plik .csv z numerami albumów</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepUploadAlbums > 2" step="2">Zweryfikuj/popraw</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="3">Gotowe!</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-container class="text-center">
                      <UploadAlbumNumbers></UploadAlbumNumbers>
                    </v-container>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                      <v-data-table
                          :headers="[
                            { text: 'Nazwisko i imię', value: 'name' },
                            { text: 'Numer albumu 1 kierunku', value: 'album_nr' },
                            { text: 'Numer albumu 2 kierunku', value: 'album_nr_2' },
                            { text: '', value: 'action' },
                            ]"
                          :items="getUploadAlbums">
                        <template v-slot:item.action="{ item }">
                          <v-icon
                              small
                              class="mr-2"
                              @click="deleteFromUpload(item)"
                          >
                            delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    <v-card-actions class="py-4 px-0">
                      <v-btn color="grey" class="overline" text @click="stepUploadAlbums = 1; dialogUploadAlbumNumbers = false">
                        <v-icon small left>arrow_back</v-icon>
                        <span>porzuć dodawanie i wróć do listy studentów</span></v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" rounded @click="addUploadedAlbums()">Dodaj powyższe numery albumów</v-btn>
                    </v-card-actions>

                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-card>
                      <v-card-title>
                        <v-container class="text-center">
                          <v-row>
                            <v-col sm="12">
                              <span class="headline">Poprawnie dodano numery albumów</span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text>
                        <v-divider></v-divider>
                        <v-container class="text-center">
                          <v-row>
                            <v-col sm="12">
                              <v-icon x-large class="green--text">check_circle_outline</v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions class="py-4 px-0">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded @click="stepUploadAlbums = 1; dialogUploadAlbumNumbers = false">Zamknij</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Usunąłeś poprawnie kurs słuchacza</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon class="green--text" x-large>check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    <v-btn color="primary" rounded @click="dialogRemoveSuccess = false">Zamknij</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:group.header="{items, isOpen, toggle}">
<!--      <th>-->
<!--        <v-icon @click="toggle"-->
<!--        >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}-->
<!--        </v-icon>-->
<!--      </th>-->
      <td colspan="4">
        <b>{{ items[0].username }}</b>
        <a class="ml-10 font-weight-medium text-decoration-none" @click="copyTextToClipboard(items[0].email)">
          <v-icon small>mdi-email</v-icon>
          {{ items[0].email }}
        </a>
        <a class="ml-10 font-weight-medium text-decoration-none" @click="copyTextToClipboard(items[0].telephone)">
          <v-icon small>mdi-phone</v-icon>
          {{ items[0].telephone }}
        </a>
      </td>
      <td colspan="3">
      </td>
      <td>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-if="items[0].bank_account"
                color="gray"
                small
                v-bind="attrs"
                @click="copyTextToClipboard(items[0].bank_account)"
                v-on="on"
            >
              account_balance
            </v-icon>
          </template>
          <span>{{ items[0].bank_account }}</span>
        </v-tooltip>
      </td>
      <td>
        <v-icon
            class="mr-4"
            small
            @click="setChosenStudentCourseUser(items[0].uid); $router.push('/students/' + items[0].uid)"
        >
          zoom_in
        </v-icon>
        <v-icon
            small
            @click="editItem(items[0])"
        >
          mdi-pencil
        </v-icon>
      </td>
    </template>
    <template v-slot:item.username="{ item }">
    </template>
    <template v-if="userRole == 1 || userRole == 2" v-slot:item.thesis="{ item }">
      <v-tooltip top v-if="item.thesis == 1">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              color="gray"
              v-bind="attrs"
              v-on="on">
            insert_drive_file
          </v-icon>
        </template>
        <span>Praca końcowa kierunku "{{ item.courseName }}" oddana</span>
      </v-tooltip>
    </template>
    <template v-if="userRole == 1 || userRole == 2" v-slot:item.practice="{ item }">
      <v-tooltip top v-if="item.practice == 1">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              color="gray"
              v-bind="attrs"
              v-on="on">
            work_outline
          </v-icon>
        </template>
        <span>Praktyki kierunku "{{ item.courseName }}" zaliczone</span>
      </v-tooltip>
    </template>
    <template v-if="userRole == 1 || userRole == 2" v-slot:item.bank_account="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              color="gray"
              v-bind="attrs"
              small
              @click="copyTextToClipboard(item.studentCourseBankAccount ? item.studentCourseBankAccount : item.bank_account)"
              v-on="on"
          >
            account_balance
          </v-icon>
        </template>
        <span>{{ item.studentCourseBankAccount ? item.studentCourseBankAccount : item.bank_account }}</span>
      </v-tooltip>
    </template>
    <template v-if="userRole == 1" v-slot:item.changes="{ item }">
      <!-- history -->
      <v-tooltip top v-if="history(item.uid)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              color="red"
              v-bind="attrs"
              v-on="on">
            warning
          </v-icon>
        </template>
        <span>W ciągu ostatniego miesiąca wprowadzono zmiany w kwestionariuszu osobowym</span>
      </v-tooltip>
    </template>
    <template v-slot:item.date="{ item }">
      {{ new Date(item.date) | moment }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
    </template>
    <template v-slot:item.action="{ item }">

    </template>
    <template v-slot:no-data>
      Brak słuchaczy
    </template>
    <template v-slot:footer>
      <v-divider></v-divider>
      <div class="mt-4 mb-4 mr-4">
        <v-row>
          <v-col class="d-flex justify-end" md="12">
            <v-btn :color=buttonForRemove class="mr-4" @click="newMessage()" dark rounded small>Napisz wiadomość</v-btn>
            <v-btn @click="generateStudentCoursesReport()" class="mr-4" color="primary" dark rounded small>Pobierz listę CSV</v-btn>
            <v-btn class="mr-4" color="primary" dark rounded small @click="generateStudentCoursesReportPDF()">Pobierz listę PDF</v-btn>
            <v-btn v-if="userRole == 1" @click="uploadAlbumNumber()" color="primary" dark rounded small>Wprowadź numer albumu</v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-data-table>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
  >
    Skopiowano do schowka!
    <template v-slot:action="{ attrs }">
      <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
      >
        Zamknij
      </v-btn>
    </template>
  </v-snackbar>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MultiSelectSemesters from "./MultiSelectSemesters";
import SelectPlaces from "./SelectPlaces";
import SelectCourses from "./SelectCourses";
import moment from "moment";
import UserEdit from "../components/UserEdit";
import StudentCourseAndUserAdd from "../components/StudentCourseAndUserAdd";
import Message from "../components/Message";
import {ComponentWithPagingEnum, SemesterChoicesEnum} from "../enums";
import UploadAlbumNumbers from "../components/UploadAlbumNumbers/UploadAlbumNumbers";

function displayPDFTitle() {
  let returnString = '';

  // semesters
  if (this.multiSemesterSelected && this.multiSemesterSelected.length > 0) {
    this.multiSemesterSelected.forEach(x => {
      returnString += x.substr(0, 7) + ', ';
    });
    returnString = returnString.slice(0, -2);
  } else {
   returnString = 'Wszystkie nabory';
  }

  returnString += ' | ';

  //places
  if (this.placeSelected && this.placeSelected.length > 0) {
    this.placeSelected.forEach(x => {
      returnString += this.placeNameById(x) + ', ';
    });
    returnString = returnString.slice(0, -2);
  } else {
    returnString += 'Wszystkie miasta';
  }

  return returnString;
}

export default {
  name: "StudentCourses",
  components: {UploadAlbumNumbers, UserEdit, StudentCourseAndUserAdd, MultiSelectSemesters, SelectPlaces, SelectCourses, Message },
  filters: {
    moment: function (date) {
      return moment(date).format('MMMM YYYY');
    }
  },
  computed: {
    ...mapGetters({
      studentCourses: 'studentCourses/getStudentCourses',
      dialogFromStore: 'studentCourses/dialogNew',
      courses: 'courses/getCourses',
      coursesWithoutAllOption: 'courses/getCoursesWithoutAllOption',
      places: 'places/getPlaces',
      placeNameById: 'places/getPlaceNameById',
      semesterSelected: 'semesterSelected',
      multiSemesterSelected: 'multiSemesterSelected',
      placeSelected: 'places/getPlaceSelected',
      courseSelected: 'courses/getCourseSelected',
      coordinators: 'places/getCoordinators',
      history: 'history/getHistoryRecent',
      userRole: 'users/userRole',
      getPage: 'paging/getPage',
      getStepUploadAlbums: 'studentCourses/getStepUploadAlbums',
      getUploadAlbums: 'studentCourses/getUploadAlbums'
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodaj nowego słuchacza' : 'Edytuj słuchacza'
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
    semesterChoices: {
      get() {
        return SemesterChoicesEnum
      },
      set(value){
        return this.setMonthSelected(value)
      }
    },
    stepUploadAlbums: {
      get () {
        return this.getStepUploadAlbums
      },
      set (value) {
        return this.setStepUploadAlbums(value)
      }
    },
  },
  mounted () {
    // this.$store.dispatch('studentCourses/getStudentCourses')
    this.setSelectedComponent(ComponentWithPagingEnum.studentCourses);
    this.options = this.getPage;
  },
  data: () => ({
    loading: true,
    selected: [],
    menuDate: false,
    dialogRemoveSuccess: false,
    dialogMessage: false,
    dialogUploadAlbumNumbers: false,
    headers: [
      { text: '', value: 'username', sortable: false, width: 0 },
      {
        text: 'Email',
        align: 'left',
        sortable: false,
        value: 'email',
      },
      { text: 'Kierunek', value: 'courseName', sortable: false },
      { text: 'Semestr', value: 'date', sortable: false },
      { text: 'Miasto', value: 'cityName', sortable: false },
      { text: 'Praca', value: 'thesis', sortable: false },
      { text: 'Praktyki', value: 'practice', sortable: false },
      { text: 'Konto', value: 'bank_account', sortable: false},
      { text: '', value: 'action', sortable: false, width: 80 },
    ],
    editedIndex: -1,
    editedItem: {
      cid: '',
      cid_2: '',
      pid: '',
      price: '',
      price_2: '',
      comment: '',
      coordinator: '',
      date: ''
    },
    defaultItem: {
      cid: '',
      cid_2: '',
      pid: '',
      price: '',
      price_2: '',
      comment: '',
      coordinator: '',
      date: ''
    },
    singleSelect: false,
    validate: false,
    report: false,
    options: {},
    dialogUser: false,
    snackbar: false
  }),
  watch: {
    dialog (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    options: {
      handler () {
        this.handlePage()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setDialogNew: 'studentCourses/setDialogNew',
      deleteStudentCourses: 'studentCourses/deleteStudentCourses',
      setChosenStudentCourse: 'studentCourses/setChosenStudentCourse',
      setChosenStudentCourseUser: 'studentCourses/setChosenStudentCourseUser',
      resetChosenStudentCourseUser: 'studentCourses/resetChosenStudentCourseUser',
      setPage: 'paging/setPage',
      setSelectedComponent: 'paging/setSelectedComponent',
      clearPage1: 'paging/clearPage',
      setStepUploadAlbums: 'studentCourses/setStepUploadAlbums',
      setAlbumNumbers: 'studentCourses/setAlbumNumbers',
      deleteFromUploadAlbums: 'studentCourses/deleteFromUploadAlbums'
    }),
    clearPage () {
      this.clearPage1();
      this.options.page = 1;
    },
    handlePage() {
      let { sortBy, sortDesc, page, itemsPerPage, search } = this.options;
      this.setPage({ sortBy: sortBy, sortDesc: sortDesc, page: page, itemsPerPage: itemsPerPage, search: search })
    },
    editItem (item) {
      this.setChosenStudentCourseUser(item.uid)
      this.editedIndex = this.studentCourses.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogUser = true
    },
    clearEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1
      this.resetChosenStudentCourseUser()
    },
    closeEditDialog () {
      this.dialog = false
      this.clearEditItem()
    },
    closeEditDialogUser () {
      this.dialogUser = false
      this.clearEditItem()
    },
    closeDialogNewSuccessAndAddAnotherUser () {
      this.setDialogNew(true)
      this.clearEditItem()
    },
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć tego słuchacza?')){
        this.deleteStudentCourses(this.selected).then(response => {
          this.$log.info('Deleted user', response);
          this.selected = [];
          this.dialogRemoveSuccess = true
        }).catch(error => {
          this.$log.error('Deleting user error', error)
        })
      }
    },
    generateStudentCoursesReport () {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        "LP;Nazwisko;Imię;Telefon;Email;Kierunek;Kierunek 2;Semestr;Miasto;Numer rachunku;Numer albumu;Numer albumu 2;Data urodzenia;Miejsce urodzenia;Drugie imię;",
        ...this.studentCourses.map(function(item) {
          try {
            JSON.parse(item.registration_form).personal.dayOfBirth;
            return '"'+item.id + '";"' + JSON.parse(item.registration_form).personal.surname + '";"' + JSON.parse(item.registration_form).personal.name + '";"' + item.telephone + '";"' + item.email + '";"' + item.courseName + '";"' + (item.courseName2 !== null ? item.courseName2 : '') + '";"' + item.date + '";"' + item.cityName + '";"' + (item.studentCourseBankAccount !== null ? item.studentCourseBankAccount : item.bank_account) + '";"' + (item.album_nr == null ? '' : item.album_nr) + '";"' + (item.album_nr_2 == null ? '' : item.album_nr_2) + '";"' + moment(JSON.parse(item.registration_form).personal.dayOfBirth).format('LL') + '";"' + JSON.parse(item.registration_form).personal.placeOfBirth + '";"' + JSON.parse(item.registration_form).personal.secondName + '";""';
          } catch (e) {
            return '"'+item.id + '";"' + item.username + '";"' + item.username + '";"' + item.telephone + '";"' + item.email + '";"' + item.courseName + '";"' + (item.courseName2 !== null ? item.courseName2 : '') + '";"' + item.date + '";"' + item.cityName + '";"' + (item.studentCourseBankAccount !== null ? item.studentCourseBankAccount : item.bank_account) + '";"' + (item.album_nr == null ? '' : item.album_nr) + '";"' + (item.album_nr_2 == null ? '' : item.album_nr_2)+ '";""';
          }
      })
      ].join("\n")

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "lista_studentow_" + moment().format('L') +".csv");
      link.click();
    },
    generateStudentCoursesReportPDF() {
      var table = [];
      table.push(["IMIĘ", "NAZWISKO", "OŚRODEK", "KIERUNEK", "KWOTA CZESNEGO"]);
      var tableBody = table.concat(this.studentCourses.sort((a, b) => {
        let fa = a.username.toLowerCase(),
            fb = b.username.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      }).filter(obj => {
        if (obj.registration_form && JSON.parse(obj.registration_form).personal !== null) {
          return true;
        } else {
          return false;
        }
      }).map((obj, index, array) => {
          if(
              index > 0 &&
              JSON.parse(array[index].registration_form).personal.name == JSON.parse(array[index-1].registration_form).personal.name
          ) {
            return [
              '',
              '',
              '',
              obj.courseName,
              obj.price / 100 + "PLN"
            ]
          } else {
            return [
              JSON.parse(obj.registration_form).personal.name,
              JSON.parse(obj.registration_form).personal.surname,
              obj.cityName,
              obj.courseName,
              obj.price / 100 + "PLN"
            ]
          }
        }
      ));

      var pdfMake = require('pdfmake/build/pdfmake.js');
      if (pdfMake.vfs == undefined){
        var pdfFonts = require('pdfmake/build/vfs_fonts.js');
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var docDefinition = {
        content: [
          {
            stack: [
              displayPDFTitle.call(this),
            ],
            style: 'header'
          },
          {
            style: 'tableExample',
            table: {
              body: tableBody
            }
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 80],
            alignment: "center"
          },
          footer: {
            alignment: "right",
            margin: [0, 20, 0, 20]
          }
        }
      };
      pdfMake.createPdf(docDefinition).download("lista_studentow_" + moment().format('L') +".pdf")
    },
    uploadAlbumNumber () {
      this.dialogUploadAlbumNumbers = true;
    },
    newMessage() {
      if (this.selected.length == 0) {
        return false
      }
      this.dialogMessage = true;
    },
    closeDialogMessage(val) {
      this.dialogMessage = val;
    },
    placeChanged(placeId) {
      this.loading = true;
      this.$store.dispatch(
          'studentCourses/getStudentCourses',
          {
            'date': this.multiSemesterSelected ? this.multiSemesterSelected.join(',') : '',
            'pid': placeId,
            'cid': this.courseSelected
          }
      ).then(() => {
        this.loading = false;
      });
    },
    semesterChanged(semester) {
      this.loading = true;
      this.$store.dispatch(
          'studentCourses/getStudentCourses',
          {
            'date': semester ? semester.join(',') : '',
            'pid': this.placeSelected,
            'cid': this.courseSelected
          }
      ).then(() => {
        this.loading = false;
      });
    },
    courseChanged(courseId) {
      this.loading = true;
      this.$store.dispatch(
          'studentCourses/getStudentCourses',
          {
            'date': this.multiSemesterSelected ? this.multiSemesterSelected.join(',') : '',
            'pid': this.placeSelected,
            'cid': courseId
          }
      ).then(() => {
        this.loading = false;
      });
    },
    selectedItems() {
      let mapped = Object.assign({}, this.selected.map(x => x.email ));
      let mapped_uid = Object.assign({}, this.selected.map(x => x.uid ));
      return { email: mapped, subject: '', uid: mapped_uid };
    },
    deleteFromUpload(item) {
      this.deleteFromUploadAlbums(item);
      if(this.getUploadAlbums.length == 0) {
        this.stepUploadAlbums = 1;
        this.dialogUploadAlbumNumbers = false;
      }
    },
    addUploadedAlbums() {
      this.setAlbumNumbers().then(response => {
        if (response == 200) {
          this.$store.dispatch(
              'studentCourses/getStudentCourses',
              {
                'date': this.multiSemesterSelected ? this.multiSemesterSelected.join(',') : '',
                'pid': this.placeSelected,
                'cid': this.courseSelected
              }
          ).then(() => {
            this.loading = false;
          });
          this.stepUploadAlbums = 3;
        }
      }).catch(error => {
        this.$log.error('Saving uploaded albums fail', error)
      });
    },
    async copyTextToClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.snackbar = true;
      } catch($e) {
        alert('Błąd');
      }
    }
  },
  created() {
    this.$store.dispatch('notifications/getNotifications');

    this.$store.dispatch('users/getUsers');
    // this.$store.dispatch('chat/getLastMessages');
    this.resetChosenStudentCourseUser();

    this.$store.dispatch(
        'studentCourses/getStudentCourses',
        {
          'date': this.multiSemesterSelected ? this.multiSemesterSelected.join(',') : '',
          'pid': this.placeSelected,
          'cid': this.coursesSelected
        }
    ).then(() => {
      this.loading = false;
    });
    this.$store.dispatch('courses/getCourses');
    this.$store.dispatch('places/getPlaces');
    this.$store.dispatch('history/getHistoryRecent');
  }
}
</script>

<style scoped>
.toPrint {
  display: none;
}
</style>
