<template>
    <v-data-table
            :headers="headers"
            :items="bills"
            sort-by="date"
            show-select
            :single-select=singleSelect
            class="elevation-1 ma-4"
            v-model="selected"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Lista wszystkich rachunków za miesiąc</v-toolbar-title>
                <SelectMonths/>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn v-show="$vuetify.breakpoint.mdAndUp" color="primary" :disabled="!selected.length" rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczony rachunek</v-btn>
                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" rounded small class="mb-2 ml-4" v-on="on">Dodaj nowy rachunek</v-btn>
                        <v-btn v-else color="primary" rounded small class="mb-2 ml-4" v-on="on">Dodaj</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-file-input
                                                v-model="file"
                                                :rules="rules"
                                                required
                                                label="Dodaj skan"
                                                accept="image/png, image/jpeg, image/bmp"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-7">
                            <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-show="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do listy wszystkich rachunków</span></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="primary" rounded @click="save">Dodaj rachunek</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogNewSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span class="headline">Poprawnie dodałeś rachunek</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich rachunków</v-btn>
                                    </v-col>
                                    <v-col sm="12">
                                        lub
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherBill">Chcę dodać kolejny rachunek</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie rachunek</span>
                                        <span v-else class="headline">Usunąłeś poprawnie rachunki</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.file="{ item }">
            <a :href="billUrl(item)" target="_blank"><v-img :src="billUrl(item)" aspect-ratio="4" /></a>
        </template>
        <template v-slot:no-data>
            Brak rachunków
        </template>
    </v-data-table>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import SelectMonths from "./SelectMonths";

  export default {
    components: { SelectMonths },
    name: "Bills",
    computed: {
      ...mapGetters({
        bills: 'bills/getBills',
        dialogFromStore: 'bills/dialogNew',
        monthSelected: 'monthSelected',
      }),
      formTitle () {
        return 'Dodaj nowy rachunek'
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          return this.setDialogNew(value)
        }
      },
      currentMonthReadable () {
        return moment(this.monthSelected).format('MMMM YYYY')
      }
    },
    created() {
      this.$store.dispatch('bills/getBills', { 'start': moment(this.monthSelected).startOf('month').format('YYYY-MM-DD'), 'end': moment(this.monthSelected).endOf('month').format('YYYY-MM-DD')});

      this.unwatch = this.$store.watch(
        (state, getters) => getters.monthSelected,
        (newValue) => {
          this.$store.dispatch(
            'bills/getBills',
            {
              'start': moment(newValue).startOf('month').format('YYYY-MM-DD'),
              'end': moment(newValue).endOf('month').format('YYYY-MM-DD') }
          );
        },
      );
    },
    beforeDestroy() {
      this.unwatch();
    },
    data: () => ({
      file: null,
      rules: [
        value => !value || value.size < 10000000 || 'File size should be less than 10 MB!',
      ],
      selected: [],
      dialogNewSuccess: false,
      dialogRemoveSuccess: false,
      headers: [
        {
          text: 'Dokument',
          align: 'left',
          sortable: false,
          value: 'file',
        },
      ],
      singleSelect: false
    }),
    watch: {
      dialogNewSuccess (val) {
        val || setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1
        }, 300)
      },
    },
    methods: {
      ...mapActions({
        setDialogNew: 'bills/setDialogNew',
        createBill: 'bills/createBill',
        editBill: 'bills/editBill',
        deleteBills: 'bills/deleteBills',
      }),
      close () {
        this.dialog = false
      },
      closeDialogNewSuccessAndAddAnotherBill () {
        this.dialogNewSuccess = false;
        this.setDialogNew(true)
      },
      closeDialogRemoveSuccess () {
        this.dialogRemoveSuccess = false;
        this.selected = []
      },
      save () {
        const formData = new FormData()
        formData.append('file', this.file)

        this.createBill(formData).then(response => {
          if (response == 200) {
            this.file = null
            this.$log.info('Saved new bill', response);
            this.close();
            this.dialogNewSuccess = true
          }
        }).catch(error => {
          this.$log.error('Saving new bill error', error)
        })
      },
      removeSelected () {
        if (this.selected.length == 0) {
          return false
        }
        if(confirm('Czy na pewno chcesz usunąć ten rachunek?')){
          this.deleteBills(this.selected).then(response => {
            this.$log.info('Deleted bill', response);
            this.dialogRemoveSuccess = true
          }).catch(error => {
            this.$log.error('Deleting bill error', error)
          })
        }
      },
      billUrl (item) {
        return "/api/bills/" + item.file + "/file"
      }
    }
  }
</script>

<style scoped>

</style>
