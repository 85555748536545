<template>
  <div>
    <v-container v-if="isSuperAdmin">
      <v-card>
        <v-card-title>Dashboard</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <MultiSelectSemesters />
            </v-col>
            <v-col cols="4">
              <SelectPlaces />
            </v-col>
            <v-col cols="4">
              <SelectCourses />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card>
                <h4 class="pa-4">Rozliczenia</h4>
                <v-card-text>
                  <SettlementStatistics></SettlementStatistics>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <h4 class="pa-4">Słuchacze</h4>
                <v-card-text>
                  <StudentCourseGeneral></StudentCourseGeneral>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <StudentCourseGroupedByCity></StudentCourseGroupedByCity>
            </v-col>
            <v-col cols="6">
              <StudentCourseGroupedByCourse></StudentCourseGroupedByCourse>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <hr class="mb-4"/>
              <h3>Procentowa liczba studentów danego kierunku w stosunku do wszystkich studentów z danego naboru (TOP 15)</h3>
              <StudentCourseTrend></StudentCourseTrend>
            </v-col>
          </v-row>
          <UserStatistics></UserStatistics>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import StudentCourseGroupedByCity from "../components/Statistics/StudentCourseGroupedByCity.vue";
import StudentCourseGroupedByCourse from "../components/Statistics/StudentCourseGroupedByCourse.vue";
import StudentCourseGeneral from "../components/Statistics/StudentCourseGeneral.vue";
import StudentCourseTrend from "../components/Statistics/StudentCourseTrend.vue";
import SelectPlaces from "./SelectPlaces.vue";
import MultiSelectSemesters from "./MultiSelectSemesters.vue";
import SelectCourses from "./SelectCourses.vue";
import SettlementStatistics from "../components/Statistics/SettlementStatistics.vue";
import UserStatistics from "../components/Statistics/UserStatistics.vue";


export default {
    name: "Statistics",
    data: () => ({
    }),
    components: {
      SettlementStatistics, UserStatistics, StudentCourseGroupedByCity, StudentCourseGroupedByCourse, StudentCourseGeneral, StudentCourseTrend,
      SelectPlaces, MultiSelectSemesters, SelectCourses
    },
    computed: {
      ...mapGetters({
        isSuperAdmin: 'users/isSuperAdmin',
        getStudentCourseGrouped: 'studentCourses/getStudentCoursesGrouped',

      })
    },
    mounted() {

    },
    methods: {
      ...mapActions({
        setUserRodo: 'users/setRodo'
      }),
    },
  created() {
    this.$store.dispatch('courses/getCourses');
    this.$store.dispatch('places/getPlaces');
    this.$store.dispatch('subjects/getSubjects');
  }
}
</script>

<style>
  a {
    text-decoration: none;
  }
  .v-menu__content {
    max-height: 500px !important;
  }
</style>

