<template>
    <v-container>
        <v-card>
            <v-tabs
                    v-model="tabActive"
                    background-color="primary"
                    dark
                    :key="activeSettingsTabFromStore"
            >
                <v-tab
                        v-for="item in getItems()"
                        :key="item.tab"
                >
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabActive">
                <v-tab-item v-if="userRole == 0 || userRole == 1 || userRole == 5 || userRole == 7 || userRole == 8">
                    <v-card>
                        <v-card-text>
                            <RegisterForm v-if="isCompany === 0" @registerFormValidated="validate=false" @registerFormPrinted="registerFormPrinted" @registerFormSaved="registerFormSaved" :validate="validate" :report="report" />
                            <RegisterFormCompany v-if="isCompany === 1" @registerFormValidated="validate=false" @registerFormPrinted="registerFormPrinted" @registerFormSaved="registerFormSaved" :validate="validate" :report="report"/>

                            <v-btn color="primary" dark rounded @click="switchCompany()" class="ml-4 mb-4 mt-4">
                              <span v-if="!isCompany">Nie jestem Pracownikiem, chcę się rozliczać jako Firma</span>
                              <span v-if="isCompany">Nie jestem Firmą, chcę się rozliczać jako Pracownik</span>
                            </v-btn>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                            <v-btn @click="generatePDFReport()" color="primary" dark rounded class="mb-4">Generuj plik PDF</v-btn>
                            <v-btn color="primary" dark rounded @click="save()" class="mb-4">
                              <span v-if="!isCompany">Zapisz kwestionariusz osobowy</span>
                              <span v-if="isCompany">Zapisz dane firmowe</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <PasswordChangeOldRequired />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-if="userRole == 0 || userRole == 1 || userRole == 5 || userRole == 7 || userRole == 8">
                    <v-card>
                        <v-card-text>
                            <Cars />
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Avatar/>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-if="userRole == 0 || userRole == 1 || userRole == 5 || userRole == 7 || userRole == 8">
                    <v-card>
                        <v-card-text>
                            <BillForms/>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end mr-8">
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <v-dialog v-model="switchCompanyAlert" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Uwaga, zmieniasz ustawienia konta</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="red--text">warning</v-icon><br />
                    Zmiana konta z {{ isCompany ? '"Firmy na "Pracownika"' : '"Pracownika na "Firmę"' }}, powoduje usunięcie danych z formularza {{ isCompany ? 'firmowego' : 'osobowego' }}. Pozostałe dane nie zostaną usunięte.<br /><br />
                    Zostaniesz wylogowany. Zaloguj się ponownie używając tych samych danych - zostaniesz wtedy poproszony o wypełnienie formularza {{ isCompany ? 'osobowego' : 'firmowego' }}.
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="secondary" @click="closeDialogRemoveSuccess" class="mr-4">Anuluj</v-btn>
                    <v-btn rounded color="primary" @click="switchCompanyAccept">Ok</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Cars from "../components/Car/Cars";
import BillForms from "../components/Bill/BillForms";
import Avatar from "../components/Avatar/Avatar";
import PasswordChangeOldRequired from "../components/PasswordChangeOldRequired";
import RegisterForm from "../components/RegisterForm";
import RegisterFormCompany from "../components/RegisterFormCompany";

export default {
    name: "Settings",
    components: { RegisterForm, RegisterFormCompany, Avatar, PasswordChangeOldRequired, Cars, BillForms },
    data () {
      return {
        dialogNewCar: false,
        tab: null,
        validate: false,
        report: false,
        switchCompanyAlert: false
      }
    },
    methods: {
      ...mapActions({
        saveAll: 'registration/saveAll',
        setDialogNew: 'cars/setDialogNew',
        switchCompanyAccept: 'users/switchCompanyAccept'
      }),
      save() {
        this.validate = true
      },
      switchCompany() {
        this.switchCompanyAlert = true;
      },
      closeDialogRemoveSuccess () {
        this.switchCompanyAlert = false;
      },
      getItems() {
        if(this.userRole == 0 || this.userRole == 1 || this.userRole == 5 || this.userRole == 7 || this.userRole == 8) {
          return [
            { id: 1, tab: this.isCompany ? 'Dane firmowe' : 'Kwestionariusz osobowy' },
            { id: 2, tab: 'Zmiana hasła' },
            { id: 3, tab: 'Samochody w ewidencji rozliczeń' },
            { id: 4, tab: 'Zmień zdjęcie' },
            { id: 5, tab: 'Rachunki' },
          ]
        } else {
          return [
            { id: 2, tab: 'Zmiana hasła' },
            { id: 4, tab: 'Zmień zdjęcie' },
          ]
        }
      },
      generatePDFReport() {
        this.report = true
      },
      registerFormSaved() {
        this.$log.info('Saved registration form')
        this.$router.push('dashboard')
      },
      registerFormPrinted() {
        this.$log.info('Printed registration form')
        this.report = false
      }
    },
    computed: {
      ...mapGetters({
        activeSettingsTabFromStore: 'getActiveSettingsTab',
        userRole: 'users/userRole',
        isCompany: 'users/isCompany'
      }),
      tabActive: {
        get () {
          return this.activeSettingsTabFromStore
        },
        set (value) {
          this.$store.commit('setActiveSettingsTab', value)
        }
      }
    },
    created() {
    }
  }
</script>

<style scoped>

</style>
