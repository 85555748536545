import Api from './Api'

export default {
  createFile (file) {
    return Api().post('/api/files', file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  createFiles (files) {
    return Api().post('/api/files/create-multiple', files, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getFileByUrl (url) {
    return Api().get('/api/files/url/' + url, { withCredentials: true, responseType: 'arraybuffer' }).catch((error)=>{console.log(error)})
  },
  getFilesByFileGroupId (gid) {
    return Api().get('/api/files/group/' + gid, { withCredentials: true })
  },
  editFile (fileData, id) {
    return Api().put('/api/files/' + id, fileData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  deleteFiles (files) {
    return Api().post('/api/files/delete-multiple', files)
  },
  deleteFile (fileId) {
    return Api().delete('/api/files/' + fileId)
  },
  getFiles () {
    return Api().get('/api/files', { withCredentials: true })
  }
}
