import contract from '../../services/Contract'

// initial state
const state = {
  items: [],
  dialogNew: false
};

// getters
const getters = {
  getContracts: state => state.items,
  dialogNew: state => state.dialogNew
};

// actions
const actions = {
  getContracts ({ commit, rootState }, period) {
    contract.getContracts(period, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      commit('setContracts', response.data)
    })
  },
  createContract ({ commit, rootState }, newContract) {
    return new Promise((resolve, reject) => {
        contract.createContract(newContract, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
          if (response.status == 200) {
            commit('addContract', response.data)
            resolve(200)
          }
        }).catch((response, errors) => {
          reject(errors)
        })
    }).catch((error) => { console.log(error) });
  },
  editContract ({ commit }, editedContract) {
    return new Promise((resolve, reject) => {
        contract.editContract(editedContract).then(response => {
          if (response.status == 200) {
            commit('editContract', response.data)
            resolve(200)
          }
        }).catch((errors) => {
          reject(errors)
        })
    }).catch((error) => { console.log(error) });
  },
  deleteContracts ({ commit, rootState }, deletedContracts) {
    contract.deleteContracts(deletedContracts.map(function(item) { return item["id"] })).then(response => {
      console.log(response.status);
      if (response.status == 200) {
        commit('deleteContracts', deletedContracts)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  }
};

// mutations
const mutations = {
  setContracts (state, contracts) {
    state.items = contracts.items
  },
  addContract (state, contract) {
    state.items.push(contract)
  },
  editContract (state, contract) {
    console.log(contract)
  },
  deleteContracts (state, contracts) {
    contracts.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
