<template>
    <v-data-table
            :headers="headers"
            :items="reports"
            sort-by="date"
            show-select
            :single-select=singleSelect
            class="elevation-1 ma-4"
            v-model="selected"
            :search="search"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Lista wszystkich raportów za miesiąc</v-toolbar-title>
                <SelectMonths/>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Szukaj"
                        single-line
                        hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
            </v-toolbar>
        </template>
        <template v-slot:item.report_time="{ item }">
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <v-icon v-if="item.report_time" @click="goGoUserTime(item)" v-on="on" :color="item.report_time == 2 ? 'green':'red'">schedule</v-icon>
                    <v-icon v-else-if="!item.report_time" @click="goGoUserTime(item)" v-on="on" color="orange">report_problem</v-icon>
                </template>
                <span v-if="item.report_time">{{ item.report_time == 2 ? 'Zaakceptowany':'Do zaakceptowania' }}</span>
                <span v-else-if="!item.report_time">Brak raportu</span>
            </v-tooltip>
        </template>
        <template v-slot:item.report_trip="{ item }">
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-if="item.report_trip" @click="goGoUserTrip(item)" v-on="on" :color="item.report_trip == 2 ? 'green':'red'">directions_car</v-icon>
                  <v-icon v-else-if="!item.report_trip" @click="goGoUserTrip(item)" v-on="on" color="orange">report_problem</v-icon>
                </template>
                <span v-if="item.report_trip">{{ item.report_trip == 2 ? 'Zaakceptowany':'Do zaakceptowania' }}</span>
                <span v-else-if="!item.report_trip">Brak raportu</span>
            </v-tooltip>
        </template>
        <template v-slot:item.report_meeting="{ item }">
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-if="item.report_meeting" @click="goGoUserMeeting(item)" v-on="on" :color="item.report_meeting == 2 ? 'green':'red'">calendar_today</v-icon>
                  <v-icon v-else-if="!item.report_meeting" @click="goGoUserMeeting(item)" v-on="on" color="orange">report_problem</v-icon>
                </template>
                <span v-if="item.report_meeting">{{ item.report_meeting == 2 ? 'Zaakceptowany':'Do zaakceptowania' }}</span>
                <span v-else-if="!item.report_meeting">Brak raportu</span>
            </v-tooltip>
        </template>
        <template v-slot:no-data>
            Brak raportów
        </template>
        <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-4 mb-4 mr-4">
                <v-row>
                    <v-col class="d-flex justify-end" md="12">
                        <v-btn @click="generateMeetingsReport()" color="primary" dark rounded small>Generuj raport ze zjazdów</v-btn>
                    </v-col>
                </v-row>
            </div>
        </template>
    </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import SelectMonths from "./SelectMonths";
import router from "../router";

export default {
    components: { SelectMonths },
    name: "Reports",
    computed: {
      ...mapGetters({
        reports: 'reports/getReports',
        allMeetingsReport: 'reports/getAllMeetingsReport',
        monthSelected: 'monthSelected',
        userRole: 'users/userRole',
      }),
      showGenerateReport () {
        if (this.reports.length > 0) {
          return true
        } else {
          return false
        }
      },
      currentMonthReadable () {
        return moment(this.monthSelected).format('MMMM YYYY')
      }
    },
    created() {
      this.$store.dispatch('users/getProfessorAndAdministrativeStaff');

      this.$store.dispatch('reports/getReports', { 'start': moment(this.monthSelected).startOf('month').format('YYYY-MM-DD'), 'end': moment(this.monthSelected).endOf('month').format('YYYY-MM-DD')});
      // this.$store.dispatch('setMonthSelected', moment().startOf('month').format('YYYY-MM-DD'));

      this.unwatch = this.$store.watch(
        (state, getters) => getters.monthSelected,
        (newValue) => {
          this.$store.dispatch(
            'reports/getReports',
            {
              'start': moment(newValue).startOf('month').format('YYYY-MM-DD'),
              'end': moment(newValue).endOf('month').format('YYYY-MM-DD') }
          );
        },
      );
    },
    // There is one caveat once using Vuex watch: it returns an unwatch function that should be called in your beforeDestroy hook if you want to stop the watcher. If you don't call this function, the watcher will still be invoked which is not the desired behavior.
    beforeDestroy() {
      this.unwatch();
    },
    data: () => ({
      selected: [],
      headers: [
        { text: 'Wykładowca', value: 'name' },
        { text: 'Czas pracy', value: 'report_time' },
        { text: 'Przejazdy', value: 'report_trip' },
        { text: 'Zjazdy', value: 'report_meeting' },
      ],
      singleSelect: false,
      search: ''
    }),
    methods: {
      ...mapActions({
        reportsAll: 'reports/getReportsAll',
        setChosenUser: 'users/setChosenUser'
      }),
      generateMeetingsReport () {
        this.reportsAll({ 'start': moment(this.monthSelected).startOf('month').format('YYYY-MM-DD'), 'end': moment(this.monthSelected).endOf('month').format('YYYY-MM-DD')}).then(response => {
          let csvContent = "data:text/csv;charset=utf-8,";
          csvContent += [
            Object.keys(response[0]).join(";"),
            ...response.map(item => Object.values(item).join(";"))
          ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

          const data = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", "export.csv");
          link.click();
        }).catch(error => {
          this.$log.error('Generating csv error', error)
        })
      },
      goGoUserTrip (item) {
        this.setChosenUser(item.uid)
        this.$store.commit('setActiveSettingsTab', 1)
        router.push('/users/'+item.uid)
      },
      goGoUserTime (item) {
        this.setChosenUser(item.uid)
        this.$store.commit('setActiveSettingsTab', 3)
        router.push('/users/'+item.uid)
      },
      goGoUserMeeting (item) {
        this.setChosenUser(item.uid)
        this.$store.commit('setActiveSettingsTab', 2)
        router.push('/users/'+item.uid)
      }
    }
  }
</script>

<style scoped>
    .toPrint {
        display: none;
    }
</style>
