<template>
  <div>
    <v-container>
      <v-card v-for="(course, index) in coursesData" v-bind:key="course.id" class="mb-4 pb-4">
        <v-card-title>
          {{ course.courseName }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pt-0 pb-0" cols="12" md="3" sm="6">
              <v-checkbox
                  v-model="course.thesis"
                  :label="`Praca końcowa`"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0" cols="12" md="3" sm="6">
              <v-checkbox
                  v-model="course.practice"
                  :label="`Praktyki`"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" sm="6">
              <v-autocomplete v-model="course.promoter"
                        :items="professors"
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        label="Wybierz promotora"
                        menu-props="auto"
                        single-line
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="justify-end d-flex pt-0 pb-0">
              <v-btn class="mb-4" color="primary" dark rounded @click="save(course, index)">Zapisz</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogNewSuccess" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Zmiany w zaliczeniu wprowadzone</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon x-large class="green--text">check_circle_outline</v-icon>
              </v-col>
              <v-col v-if="indexSaved !== null" sm="12">
                Praca końcowa: {{ coursesData[this.indexSaved].thesis == true ? 'tak' : 'nie' }}, Praktyki: {{ coursesData[this.indexSaved].practice == true ? 'tak' : 'nie' }}, Promotor: {{ proffesorName(coursesData[this.indexSaved].promoter) == '' ? 'brak' : proffesorName(coursesData[this.indexSaved].promoter) }}
              </v-col>
              <v-col sm="12">
                <v-btn rounded color="primary" outlined @click="closeDialogNewSuccess">Ok</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'StudentCourseFinish',
  props: {'firstStudentCourse': Boolean },
  data() {
    return {
      loader: null,
      loading: false,
      dialogNewSuccess: false,
      coursesData: [],
      indexSaved: null
    }
  },
  computed: {
    ...mapGetters({
      professors: 'users/getUsersForSelect',
      proffesorName: 'users/getNameById'
    }),
  },
  created() {
      this.coursesData = this.$store.getters["studentCourses/getChosenStudentCoursesData"]
  },
  methods: {
    ...mapActions({
      setStudentCourseFinish: 'studentCourses/setStudentCourseFinish'
    }),
    save (studentCourse, index) {
      this.loader = 'loading'
      this.loading = true
      this.setStudentCourseFinish({
        id: studentCourse.id,
        thesis: studentCourse.thesis,
        practice: studentCourse.practice,
        promoter: studentCourse.promoter
      }).then(response => {
        if(response == 200) {
          this.$log.info('Student course finish changed ', response)
          this.loading = false;
          this.dialogNewSuccess = true;
          this.indexSaved = index;
        }
      }, error => {
        this.error = true
        this.loading = false
        this.$log.error('Student course finish error ', error)
      })
    },
    closeDialogNewSuccess () {
      this.dialogNewSuccess = false
    }
  }
}
</script>
