<template>
    <v-container>
        <StudentCourseAddAndEdit
            :close-edit-dialog="closeEditDialog"
            :dialog="dialog"
            :edited-index="editedIndex"
            :edited-item="editedItem"
            :validate="validate"
        />
        <v-card>
            <v-card-title>
              {{ studentCourses[0].username }}
              <v-btn color="grey" class="overline ml-5" text @click="$router.push('/students')"><v-icon small left>arrow_back</v-icon> powrót do listy wszystkich studentów</v-btn>
            </v-card-title>
            <v-tabs
                v-model="tabActive"
                background-color="primary"
                dark
                :key="activeStudentCourseTabFromStore"
            >
              <v-tab
                  v-for="item in getItems"
                  :key="item.tab"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabActive">
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <StudentPersonalDataInfo
                      :bank_account=studentCourses[0].bank_account
                      :city=city
                      :dayOfBirth=dayOfBirth
                      :email=email
                      :homeNr=homeNr
                      :houseNr=houseNr
                      :name=name
                      :placeOfBirth=placeOfBirth
                      :secondName=secondName
                      :street=street
                      :surname=surname
                      :telephoneNr=telephoneNr
                      :zip=zip
                    />
                    <v-container>
                      <v-row>
                        <v-col sm="12">
                          <Messages/>
                          <History :role_id="3" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-2 mr-4" color="primary" dark rounded @click="dialog=true">Przypisz słuchacza do nowego kierunku</v-btn>
                  </v-card-actions>
                  <v-card-text>
                    <StudentCourseView
                        v-for="(studentCourse, index) in studentCourses" :key="index"
                        :student-course="studentCourse"
                        :allow-edit="true"
                        @triggerEditStudentCourse="editStudentCourse"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <StudentCourseFinish :first-student-course = true />
<!--                    <StudentCourseFinish v-if="student.courseName2" :first-student-course = false />-->
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <ProfessorEvaluations :evaluationDataVisible="false" :uid="studentCourses[0].uid"></ProfessorEvaluations>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
        </v-card>
      <Message @closeDialogMessage="closeDialogMessage" :showDialog="dialogMessage" :receiver="{ email: email }" />
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {createHelpers} from "vuex-map-fields";
import Message from "../components/Message";
import Messages from "./Messages";
import History from "./History";
import StudentPersonalDataInfo from "../components/StudentPersonalDataInfo";
import StudentCourseFinish from "../components/StudentCourseFinish";
import ProfessorEvaluations from "./ProfessorEvaluations";
import StudentCourseAddAndEdit from "../components/StudentCourseAddAndEdit";
import StudentCourseView from "./StudentCourseView.vue";


const { mapFields } = createHelpers({
  getterType: 'registrationStudent/getPersonal',
  mutationType: 'registrationStudent/updatePersonal',
});

export default {
  name: "StudentCourse",
  components: {
    StudentCourseView,
    ProfessorEvaluations,
    StudentCourseFinish,
    StudentPersonalDataInfo,
    StudentCourseAddAndEdit,
    Messages,
    Message,
    History
  },
  data () {
      return {
        tab: null,
        dialogEditSuccess: false,
        validate: false,
        report: false,
        dialogMessage: false,
        dialog: false,
        editedItem: {
          cid: '',
          pid: '',
          price: '',
          coordinator: '',
          date: ''
        },
        editedIndex: -1,
        defaultItem: {
          cid: '',
          pid: '',
          price: '',
          coordinator: '',
          date: ''
        },
      }
    },
    methods: {
      ...mapActions({
        setChosenEvaluation: 'evaluations/setChosenEvaluation',
        studentEvaluations: 'evaluations/getEvaluationsByUserId',
        setChosenStudentCourse: 'studentCourses/setChosenStudentCourse',
        resetChosenStudentCourse: 'studentCourses/resetChosenStudentCourse',
      }),
      newMessage () {
        this.dialogMessage = true
      },
      generateStudentCourseCSV () {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          "Nazwisko i imię;Miejsce urodzenia;Miejscowość;Kod pocztowy;Ulica;Numer domu;Numer mieszkania;Telefon;Email\n" + this.student.username + ';' + this.placeOfBirth +  ';' + this.city +  ';' + this.zip +  ';' + this.street +  ';' + this.houseNr + ';' + this.homeNr + ';' + this.telephoneNr + ';' + this.email + ';'
        ]

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", this.student.username + "-student-raport.csv");
        link.click();
      },
      generateStudentCoursePDF () {
        var pdfMake = require('pdfmake/build/pdfmake.js');
        if (pdfMake.vfs == undefined){
          var pdfFonts = require('pdfmake/build/vfs_fonts.js');
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }

        let c = []
        c.push({columns: [{text: 'Nazwisko', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.surname, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Imię', style: 'label'}, {text: 'Drugie imię', style: 'label'}] }, {columns: [{text: this.name, style: 'data'}, {text: this.secondName, style: 'data'}] })
        c.push({columns: [{text: 'Miejsce urodzenia', style: 'label'}, {text: 'Data urodzenia', style: 'label'}] }, {columns: [{text: this.placeOfBirth, style: 'data'}, {text: this.dayOfBirth, style: 'data'}] })

        c.push({text: 'Dane adresowe', style: 'header' })
        c.push({columns: [{text: 'Miejscowość', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.city, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Kod pocztowy', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.zip, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Ulica', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.street, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Numer domu', style: 'label'}, {text: 'Numer lokalu', style: 'label'}] }, {columns: [{text: this.houseNr, style: 'data'}, {text: this.homeNr, style: 'data'}] })

        c.push({text: 'Dane kontaktowe', style: 'header' })
        c.push({columns: [{text: 'Email', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.email, style: 'data'}, {text: '', style: 'data'}] })
        c.push({columns: [{text: 'Telefon', style: 'label'}, {text: '', style: 'label'}] }, {columns: [{text: this.telephoneNr, style: 'data'}, {text: '', style: 'data'}] })

        var docDefinition = {
          content: c,
          styles: {
            header: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 20],
              alignment: "left"
            },
            label: {
              fontSize: 8,
              bold: false,
              alignment: "left"
            },
            data: {
              fontSize: 12,
              bold: false,
              alignment: "justify",
              margin: [0, 0, 0, 20],
            },
            footer: {
              alignment: "right",
              margin: [0, 20, 0, 20]
            },
            singleLine: {
              margin: [0, 0, 0, 20],
            },
            singleLineBold: {
              bold: true,
              margin: [0, 0, 0, 20],
            },
            signature: {
              alignment: "right",
              margin: [20, 0, 0, 0]
            },
            signatureLabel: {
              alignment: "right",
              margin: [0, 0, 30, 0],
              fontSize: 8,
              bold: false,
            }
          }
        };
        pdfMake.createPdf(docDefinition).download('unikadra-formularz-rejestracyjny-raport.pdf')
      },

      registerFormPrinted() {
        this.$log.info('Printed registration form')
        this.report = false
      },
      closeDialogMessage(val) {
        this.dialogMessage = val;
      },
      getSemesterForRole() {
        // edit
        return this.semesterChoices;
      },
      editStudentCourse(studentCourse) {
        this.setChosenStudentCourse(studentCourse.id)
        this.editedIndex = this.studentCourses.indexOf(studentCourse);
        this.editedItem = Object.assign({}, studentCourse);
        this.dialog = true
      },
      closeEditDialog () {
        this.dialog = false
        setTimeout(() => {
          this.clearEditItem()
        }, 200)
        this.resetChosenStudentCourse()
      },
      clearEditItem() {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1
      }
    },
    computed: {
      ...mapGetters({
        chosenStudentCourseId: 'studentCourses/getChosenStudentCourse',
        studentCourses: 'studentCourses/getChosenStudentCoursesData',
        getFormDataHistory: 'history/getFormDataHistory',
        activeStudentCourseTabFromStore: 'getActiveStudentCourseTab',
        userRole: 'users/userRole',
      }),
      ...mapFields({
        // personal
        surname: 'personal.surname',
        name: 'personal.name',
        secondName: 'personal.secondName',
        placeOfBirth: 'personal.placeOfBirth',
        dayOfBirth: 'personal.dayOfBirth',
        // address
        city: 'address.city',
        street: 'address.street',
        houseNr: 'address.houseNr',
        homeNr: 'address.homeNr',
        zip: 'address.zip',
        // contact
        email: 'contact.email',
        telephoneNr: 'contact.telephoneNr'
      }),
      tabActive: {
        get () {
          return this.activeStudentCourseTabFromStore
        },
        set (value) {
          this.$store.commit('setActiveStudentCourseTab', value)
        }
      },
      getItems() {
        let tabItems = [
          {id: 1, tab: 'Dane personalne'},
          {id: 2, tab: 'Zarządzanie kierunkami'},
          {id: 3, tab: 'Zaliczenie'},
        ];
        if (this.userRole == 0 || this.userRole == 1 || this.userRole == 5 || this.userRole == 8) {
          tabItems.push({id: 4, tab: 'Dziennik'});
        }
        return tabItems;
      }
    }
  }
</script>

