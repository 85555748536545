<template>
  <v-select v-model="placeSelected"
            :items="itemPlaces"
            item-text="name"
            item-value="id"
            menu-props="auto"
            placeholder="Wszystkie miasta"
            single-line
            multiple
            chips
            deletable-chips
            class="ml-4"
  >
  </v-select>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'SelectPlaces',
  methods: {
    ...mapActions({
      setPlaceSelected: 'places/setPlaceSelected',
    })
  },

  computed: {
    ...mapGetters({
      placeSelectedFromStore: 'places/getPlaceSelected',
      places: 'places/getPlacesAssignedToUser'
    }),
    itemPlaces() {
      if (this.places.length > 0) {
        let places = []
        places = this.places
        places.push({ 'id': '', 'name': 'Wszystkie miasta', 'coordinator_username': null})
        return places;
      } else {
        return this.places
      }
    },
    placeSelected: {
      get() {
        return this.placeSelectedFromStore
      },
      set(value){
        this.$emit('placeChanged', value)
        return this.setPlaceSelected(value)
      }
    },
  }
}
</script>
