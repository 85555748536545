<template>
    <v-container>
        <v-row no-gutters>
            <v-col sm="6">
                <v-text-field
                        v-model="surname"
                        label="Nazwisko"
                        dense
                        :error-messages="surnameErrors"
                        required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="name"
                        label="Imię"
                        dense
                        :error-messages="nameErrors"
                        required
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-text-field
                        v-model="secondName"
                        label="Drugie imię"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="familyName"
                        label="Nazwisko rodowe"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="mothersName"
                        label="Imię matki"
                        dense
                        :error-messages="mothersNameErrors"
                        required
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-text-field
                        v-model="fathersName"
                        label="Imię ojca"
                        dense
                        :error-messages="fathersNameErrors"
                        required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="placeOfBirth"
                        label="Miejsce urodzenia"
                        dense
                        :error-messages="placeOfBirthErrors"
                        required
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-menu
                        ref="menu"
                        v-model="menuDayOfBirth"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="dayOfBirth"
                                label="Data urodzenia"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                                :error-messages="dayOfBirthErrors"
                                required
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            ref="picker"
                            v-model="dayOfBirth"
                            :max="new Date().toISOString().substr(0, 10)"
                            :first-day-of-week="1"
                            min="1940-01-01"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="pesel"
                        label="PESEL"
                        dense
                        :error-messages="peselErrors"
                        required
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-text-field
                        v-model="nip"
                        label="NIP"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-select
                        v-model="education"
                        :items="educationChoices"
                        label="Wykształcenie"
                        :error-messages="educationErrors"
                        required
                ></v-select>
            </v-col>
            <v-col sm="6">
              <v-select
                  v-model="degree"
                  :items="degreeChoices"
                  label="Stopień naukowy"
              ></v-select>

            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
              <v-select
                  v-model="nationality"
                  :error-messages="nationalityErrors"
                  :items="nationalityChoices"
                  label="Obywatelstwo"
                  required
              ></v-select>
            </v-col>
            <v-col sm="2">
                <v-text-field
                        v-model="idSeries"
                        label="Seria"
                        dense
                        required
                ></v-text-field>
            </v-col>
            <v-col sm="4">
                <v-text-field
                        v-model="idNumber"
                        label="numer dowodu osobistego"
                        dense
                        required
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';

const { mapFields } = createHelpers({
    getterType: 'registration/getPersonal',
    mutationType: 'registration/updatePersonal',
  });

  export default {
    name: "Personal",
    props: [
      'surnameErrors',
      'nameErrors',
      'mothersNameErrors',
      'fathersNameErrors',
      'placeOfBirthErrors',
      'dayOfBirthErrors',
      'peselErrors',
      'educationErrors',
      'nationalityErrors',
    ],
    data: () => ({
      menuDayOfBirth: false,
      educationChoices: ['Podstawowe', 'Zawodowe', 'Średnie', 'Wyższe'],
      degreeChoices: ['adw.', 'inż.', 'mgr', 'mgr inż.', 'dr', 'dr hab.', 'dr inż.', 'dr hab.inż', 'prof.', 'prof. dr hab.inż.'],
      nationalityChoices: ['Polskie', 'Inne']
    }),
    computed: {
      ...mapFields([
        'personal.surname',
        'personal.name',
        'personal.secondName',
        'personal.familyName',
        'personal.mothersName',
        'personal.fathersName',
        'personal.placeOfBirth',
        'personal.dayOfBirth',
        'personal.pesel',
        'personal.nip',
        'personal.education',
        'personal.degree',
        'personal.nationality',
        'personal.idSeries',
        'personal.idNumber',
      ]),
    },
  }
</script>

<style scoped>

</style>
