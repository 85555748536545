import Api from '../Api'

export default {
  createFile (file) {
    return Api().post('/issuetrackerapi/files', file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  createFiles (files) {
    return Api().post('/issuetrackerapi/files/create-multiple', files)
  },
  getFileByUrl (url) {
    return Api().get('/issuetrackerapi/files/url/' + url, { withCredentials: true, responseType: 'arraybuffer' })
  },
  getFilesByFileGroupId (gid) {
    return Api().get('/issuetrackerapi/files/group/' + gid, { withCredentials: true })
  },
  editFile (fileData, id) {
    return Api().put('/issuetrackerapi/files/' + id, fileData)
  },
  deleteFiles (files) {
    return Api().post('/issuetrackerapi/files/delete-multiple', files)
  },
  deleteFile (fileId) {
    return Api().delete('/issuetrackerapi/files/' + fileId)
  },
  getFiles () {
    return Api().get('/issuetrackerapi/files', { withCredentials: true })
  }
}
