<template>
    <v-select v-model="semesterSelected"
              :items="semesters()"
              item-text="value"
              item-value="key"
              menu-props="auto"
              :label=semesterSelected.value
              single-line
              placeholder="Wszystkie semestry"
              class="ml-4"
    >
    </v-select>
</template>
<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'SelectSemesters',
    methods: {
      ...mapActions({
        setSemesterSelected: 'setSemesterSelected',
      }),
      semesters () {
        moment.locale('pl');
        const semesters = [];
        semesters.push({ 'key': '', 'value': 'Wszystkie semestry'})
        const dateStart = moment("2020-10-01");
        const dateEnd = moment().add(7, 'month');
        let i = 0;
        while (dateEnd.diff(dateStart, 'semesters') >= 0) {
          semesters.push({ 'key': dateStart.format('YYYY-MM-DD'), 'value': dateStart.locale('pl').format('MMMM YYYY')});
          if (i++ % 2 == 0) {
            dateStart.add(5, 'month')
          } else {
            dateStart.add(7, 'month')
          }
        }
        return semesters.reverse()
      },
    },
    mounted() {
    },
    computed: {
      ...mapGetters({
        semesterSelectedFromStore: 'semesterSelected',
      }),
      semesterSelected: {
        get() {
          return this.semesterSelectedFromStore
        },
        set(value){
          this.$emit('semesterChanged', value)
          return this.setSemesterSelected(value)
        }
      },
    }
  }
</script>
