import report from '../../services/Report'

// initial state
const state = {
  items: [],
  allMeetingsReport: [],
};

// getters
const getters = {
  getReports: state => state.items,
  getAllMeetingsReport: state => state.allMeetingsReport
};

// actions
const actions = {
  getReports ({ commit, rootState }, period) {
    report.getReports(period, rootState.users.chosenUser?rootState.users.chosenUser:null).then(response => {
      commit('setReports', response.data)
    })
  },
  getReportsAll ({ commit }, period) {
    return new Promise((resolve, reject) => {
      report.getReportsAll(period).then(response => {
        let w = state.items.map(function(user) {
          let r = { user: user.name }
          for (var i = 0; i < response.data.dates.length; i++) {
            r[response.data.dates[i].date] = ''
            for (var j = 0; j < response.data.items.length; j++) {
              if (response.data.dates[i].date == response.data.items[j].start && response.data.items[j].username == user.name) {
                r[response.data.dates[i].date] += r[response.data.dates[i].date] ? ', '+response.data.items[j].city:response.data.items[j].city
              }
              if (response.data.dates[i].date == response.data.items[j].end && response.data.items[j].username == user.name) {
                r[response.data.dates[i].date] += r[response.data.dates[i].date] ? ', '+response.data.items[j].city:response.data.items[j].city
              }
            }
          }
          return r
        })
        resolve(w)
      }).catch((errors) => {
        reject(errors)
      })
    }).catch((error) => { console.log(error) });
  },
};

// mutations
const mutations = {
  setReports (state, reports) {
    state.items = reports.items;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
