import onlineMeeting from '../../services/OnlineMeeting'
import time from "../../services/Time";
import user from "../../services/User";

// initial state
const state = {
  meetings: [],
  dialogNew: false,
  dialogGroupNew: false,
  clientCredentials: { clientId: '', clientSecret: '', uid: undefined}
};

// getters
const getters = {
  getMeetings: state => state.meetings,
  dialogNew: state => state.dialogNew,
  dialogGroupNew: state => state.dialogGroupNew,
  getCredentials: state => state.clientCredentials
};

// actions
const actions = {
  getMeetings({commit, rootState}, payload) {
    return new Promise((resolve, reject) => {
      onlineMeeting.getOnlineMeetings(payload !== undefined ? payload.courseIds.join(',') : null, rootState.users.chosenUser ? rootState.users.chosenUser : null).then(response => {
        if(response.status == 200) {
          commit('setMeetings', response.data)
          resolve();
        }
      }).catch(() => {
        commit('setMeetings', []);
        reject();
      });
    }).catch((error) => { console.log(error) });
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setDialogGroupNew({commit}, value) {
    commit('setDialogGroupNew', value)
  },
  createOnlineMeeting ({ dispatch, commit, getters, rootGetters }, newOnlineMeeting) {
    return new Promise((resolve, reject) => {

      let userId = rootGetters['users/getLoggedInUser'].id;
      let username = rootGetters['users/getLoggedInUser'].name;
      let emailAlt = rootGetters['users/getLoggedInUser'].email_alt;

      if (rootGetters['users/getChosenUser'] || newOnlineMeeting.uid !== undefined) {
        if (rootGetters['users/getChosenUser'])
          userId = rootGetters['users/getChosenUser'];

        if (newOnlineMeeting.uid !== undefined)
          userId = newOnlineMeeting.uid

        username = rootGetters['users/getNameById'](userId)
        emailAlt = rootGetters['users/getEmailAltById'](userId)
      }

      newOnlineMeeting = {...newOnlineMeeting, username: username, email: emailAlt}

      onlineMeeting.createOnlineMeeting(newOnlineMeeting, userId).then(response => {
        if (response.status == 200) {
          commit('addOnlineMeeting', response.data)
          resolve(200)
        }
      }).catch((response, errors) => {
        reject(response)
      })
    }).catch((error) => { console.log(error) });
  },
  createOnlineMeetings ({ dispatch, commit, getters, rootGetters }, newOnlineMeetings) {
    return new Promise((resolve, reject) => {

      let userId = rootGetters['users/getLoggedInUser'].id;
      let username = rootGetters['users/getLoggedInUser'].name;
      let emailAlt = rootGetters['users/getLoggedInUser'].email_alt;

      if (rootGetters['users/getChosenUser'] || newOnlineMeetings[0].uid !== undefined) {
        if (rootGetters['users/getChosenUser'])
          userId = rootGetters['users/getChosenUser'];

        if (newOnlineMeetings[0].uid !== undefined)
          userId = newOnlineMeetings[0].uid

        username = rootGetters['users/getNameById'](userId)
        emailAlt = rootGetters['users/getEmailAltById'](userId)
      }

      newOnlineMeetings = newOnlineMeetings.map(newOnlineMeeting => {
        return {...newOnlineMeeting, username: username, email: emailAlt}
      });

      onlineMeeting.createOnlineMeetings(newOnlineMeetings, userId).then(response => {
        if (response.status == 200) {
          commit('addOnlineMeetings', response.data)
          resolve(200)
        }
      }).catch((response, errors) => {
        reject(response)
      })
    }).catch((error) => { console.log(error) });
  },
  resetCredentials({commit}) {
    commit('setClientCredentials', { clientId: '', clientSecret: '', uid: undefined})
  },
  deleteOnlineMeetings ({ commit }, deletedOnlineMeetings) {
    return new Promise((resolve, reject) => {
      onlineMeeting.deleteOnlineMeetings(deletedOnlineMeetings.map(function(item) { return { id: item["id"], meetingId: item.settings["id"]} })).then(response => {
        if (response.status == 200) {
          commit('deleteOnlineMeetings', deletedOnlineMeetings)
          resolve(200)
        }
      }).catch((response, errors) => {
        reject(response)
      })
    }).catch((error) => { console.log(error) });
  },
};

// mutations
const mutations = {
  setMeetings(state, meetings) {
    state.meetings = [];
    meetings.forEach(meeting => {
      state.meetings.push({...meeting, 'settings': JSON.parse(meeting.settings)});
    });
  },
  setClientCredentials(state, credentials) {
    state.clientCredentials = { clientId: credentials.client_id, clientSecret: credentials.client_secret, uid: credentials.uid }
  },
  addOnlineMeeting(state, meeting) {
    state.meetings.unshift({...meeting, 'settings': JSON.parse(meeting.settings)})
  },
  addOnlineMeetings(state, meetings) {
    meetings.forEach(meeting => {
      state.meetings.unshift({...meeting, 'settings': JSON.parse(meeting.settings)})
    });
  },
  setDialogNew(state, value) {
    state.dialogNew = value;
  },
  setDialogGroupNew(state, value) {
    state.dialogGroupNew = value;
  },
  deleteOnlineMeetings (state, onlineMeetings) {
    onlineMeetings.map(function(key) {
      const index = state.meetings.indexOf(key);
      state.meetings.splice(index, 1);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
