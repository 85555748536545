<template>
    <div>
        <v-row>
            <v-col sm="12">
                <div class="title"><strong>Oddział Narodowego Funduszu Zdrowia (NFZ)</strong></div>
            </v-col>
        </v-row>
        <v-container>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="name"
                            label="Nazwa"
                            dense
                            :error-messages="nfzNameErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields } = createHelpers({
    getterType: 'registration/getNFZ',
    mutationType: 'registration/updateNFZ',
  });

  export default {
    name: "NFZ",
    data: () => ({
    }),
    props: [
      'nfzNameErrors'
    ],
    computed: {
      ...mapFields([
        'NFZ.name'
      ]),
    }
  }
</script>

<style scoped>

</style>
