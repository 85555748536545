<template>
    <v-select v-model="monthSelected"
              :items="months()"
              item-text="value"
              item-value="key"
              menu-props="auto"
              hide-details
              :label=monthSelected.value
              single-line
              class="ml-4"
    >
    </v-select>
</template>
<script>
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: 'SelectMonths',
    props: ['emptyAvailable'],
    methods: {
      ...mapActions({
        setMonthSelected: 'setMonthSelected',
      }),
      months () {
        moment.locale('pl');
        const months = [];
        if (this.emptyAvailable) {
            months.push({ 'key': '', 'value': 'Cały rok'})
        }
        const dateStart = moment("2020-02-01");
        const dateEnd = moment();
        while (dateEnd.diff(dateStart, 'months') >= 0) {
          months.push({ 'key': dateStart.format('YYYY-MM-DD'), 'value': dateStart.locale('pl').format('MMMM YYYY')});
          dateStart.add(1, 'month')
        }
        return months.reverse()
      },
    },
    mounted() {
      if (!this.emptyAvailable && !this.monthSelectedFromStore) {
        this.setMonthSelected(moment().format('YYYY-MM-DD'))
      }
    },
    computed: {
      ...mapGetters({
        monthSelectedFromStore: 'monthSelected',
      }),
      monthSelected: {
        get() {
          if (this.monthSelectedFromStore.key == undefined && this.monthSelectedFromStore) {
            return { 'key': moment(this.monthSelectedFromStore).format('YYYY-MM-DD'), 'value': moment(this.monthSelectedFromStore).locale('pl').format('MMMM YYYY')}
          } else {
            return this.monthSelectedFromStore
          }
        },
        set(value){
          return this.setMonthSelected(value)
        }
      },
    }
  }
</script>
