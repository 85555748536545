<template>
  <v-container>
    <div class="border_all">
      <v-row>
        <v-col class="pa-0" cols="8" md="8" sm="12">
          <v-file-input
              v-if="file.url === null || file.url === ''"
              v-model="file.url"
              :rules="rules"
              accept="image/*"
              dense
              label="Załącz plik z dysku"
              placeholder="Prześlij plik"
              prepend-icon="file_upload"
              show-size
              @change="startUploadFile(file.url)"
          ></v-file-input>
          <div v-else>
            <v-btn color="dark" rounded @click="getFileByUrl(file.id)">
              <v-icon left>check_circle_outline</v-icon> {{ file.name }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="4">
          <v-btn v-if="file.url !== null && file.url !== ''" depressed @click="removeFile(file.id)">
            <v-icon left>delete</v-icon> usuń załącznik
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="dialogNewError" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Błąd podczas dodawania/edycji pliku</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon class="red--text" x-large>warning</v-icon>
              </v-col>
              <v-col sm="12">
                Wygląda na to, że nie obsługujemy takiego rozszerzenia pliku.
              </v-col>
              <v-col sm="12">
                <v-btn color="primary" rounded @click="dialogNewError = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "IssueFile",
  props: [ 'file', 'editedIndex' ],
  data: () => ({
    dialogNewError: false,
    rules: [
      value => !value || value.size < 3000000 || 'Maksymalna wielkość pliku to 3 MB!'
    ]
  }),
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    ...mapActions({
      getFileByUrl: 'files/getFileByUrl',
      uploadFile: 'issueFiles/uploadFile',
      deleteFile: 'issueFiles/deleteFile'
    }),
    updateFileData(fileId) {
      this.updateFile(fileId).then(response => {
        if (response == 200) {
          this.$log.info('Saved new file ', response);
        }
      }).catch(error => {
        this.$log.error('Saving edited file error', error);
        if(error.response && error.response.status == 422) {
          this.dialogNewError = true;
        }
      });
    },
    startUploadFile(file) {
      this.uploadFile(file).then(response => {
        if (response.status == 200) {

        }
      }).catch(error => {
        this.$log.error('Deleting file error', error);
      });
    },
    removeFile(fileId) {
      if(confirm("Czy na pewno chcesz usunąć ten plik?")) {
        this.deleteFile(fileId).then(response => {
          if (response == 200) {
            this.$log.info('Deleting file ', response);
          }
        }).catch(error => {
          this.$log.error('Deleting file error', error);
        });
      }
    },
    clearFile() {
      this.file.url = null;
    }
  }
}
</script>

<style scoped>

</style>
