<template>
  <v-dialog v-model="dialogNewSuccess" max-width="800px">
  <v-card>
    <v-card-title>
      <v-container class="text-center">
        <v-row>
          <v-col sm="12">
            <span class="headline">Poprawnie dodałeś spotkanie online</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-divider></v-divider>
      <v-container class="text-center">
        <v-row>
          <v-col sm="12">
            <v-icon class="green--text" x-large>check_circle_outline</v-icon>
          </v-col>
          <v-col sm="12">
            <v-btn color="primary" rounded @click="$emit('closedDialog')">Powrót do listy wszystkich spotkań online</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({

  }),
  props: [
    'dialogNewSuccess'
  ]
}
</script>

<style scoped>

</style>
