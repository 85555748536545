<template>
    <v-data-table
            :headers="headers"
            :items="contracts"
            sort-by="start"
            show-select
            :single-select=singleSelect
            class="elevation-1 ma-4"
            v-model="selected"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Lista wszystkich kontraktów</v-toolbar-title>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn v-show="showExtraActions" color="primary" :disabled="!selected.length" rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone kontrakty</v-btn>
                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="primary" rounded small class="mb-2 ml-4" v-on="on">Dodawanie nowego kontraktu</v-btn>
                        <v-btn v-else color="primary" rounded small class="mb-2 ml-4" v-on="on">Dodaj</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="4" sm="12" md="4">
                                  <v-menu
                                      ref="menuDateFrom"
                                      v-model="menuDateFrom"
                                      :close-on-content-click="false"
                                      :return-value.sync="editedItem.start"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                          v-model="editedItem.start"
                                          label="Od"
                                          append-icon="event"
                                          readonly
                                          v-on="on"
                                          :error-messages="startErrors"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker ref="pickerDateFrom" v-model="editedItem.start" :first-day-of-week="1" no-title scrollable @change="$refs.menuDateFrom.save(editedItem.start)">
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="4" sm="12" md="4">
                                  <v-menu
                                      ref="menuDateTo"
                                      v-model="menuDateTo"
                                      :close-on-content-click="false"
                                      :return-value.sync="editedItem.end"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                          v-model="editedItem.end"
                                          label="Do"
                                          append-icon="event"
                                          readonly
                                          v-on="on"
                                          :error-messages="endErrors"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker ref="pickerDateTo" v-model="editedItem.end" :first-day-of-week="1" no-title scrollable @change="$refs.menuDateTo.save(editedItem.end)">
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="4" sm="6" md="4">
                                  <v-text-field
                                      v-model="editedItem.number"
                                      :error-messages="numberErrors"
                                      required
                                      @blur="$v.editedItem.number.$touch()"
                                      label="Numer umowy"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-7">
                            <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">porzuc dodawanie i wroc do listy wszystkich kontraktów</span></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="primary" rounded @click="save">Dodaj kontrakt</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogNewSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span class="headline">Poprawnie dodałeś nowy kontrakt</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                      {{editedItem.start }} {{editedItem.end }} | {{ editedItem.number }}
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich kontraktów</v-btn>
                                    </v-col>
                                    <v-col sm="12">
                                        lub
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherContract">Chcę dodać kolejny kontrakt</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie kontrakt</span>
                                        <span v-else class="headline">Usunąłeś poprawnie kontrakt</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-container class="text-center">
                                <v-row>
                                    <v-col sm="12">
                                        <v-icon x-large class="green--text">check_circle_outline</v-icon>
                                    </v-col>
                                    <v-col sm="12">
                                        <div v-if="selected.length > 0">
                                            <div v-for="item in selected">
                                              {{ item.start }} | {{ item.end }}  | {{ item.number }}
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col sm="12">
                                        <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.lp="{ item }">
            {{ item.id }}
        </template>
        <template v-slot:item.date="{ item }">
            {{ new Date(item.date).toISOString().substr(0, 10) }}
        </template>
        <template v-slot:item.status="{ item }">
            <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
            >
                edit
            </v-icon>
        </template>
        <template v-slot:no-data>
            Brak kontraków
        </template>
    </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import moment from "moment";

export default {
    mixins: [validationMixin],

    validations: {
      editedItem: {
        start: {required},
        end: {required},
        number: {required}
      }
    },
    name: "Contracts",
    computed: {
      ...mapGetters({
        contracts: 'contracts/getContracts',
        dialogFromStore: 'contracts/dialogNew',
        userRole: 'users/userRole',
        loggedInUser: 'users/getLoggedInUser',
        chosenUserId: 'users/getChosenUser',
        users: 'users/getUsers'
      }),
      formTitle () {
        return this.editedIndex === -1 ? 'Dodawanie nowego kontraktu' : 'Edytuj kontrakt'
      },
      startErrors () {
        const errors = [];
        if (!this.$v.editedItem.start.$dirty) return errors;
        !this.$v.editedItem.start.required && errors.push('Podanie daty OD jest wymagane');
        return errors
      },
      endErrors () {
        const errors = [];
        if (!this.$v.editedItem.end.$dirty) return errors;
        !this.$v.editedItem.end.required && errors.push('Podanie daty DO jest wymagane');
        return errors
      },
      numberErrors () {
        const errors = [];
        if (!this.$v.editedItem.number.$dirty) return errors;
        !this.$v.editedItem.number.required && errors.push('Podanie numeru umowy jest wymagane');
        return errors
      },
      showExtraActions () {
        return this.$vuetify.breakpoint.mdAndUp
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          return this.setDialogNew(value)
        }
      },
    },
    data: () => ({
      selected: [],
      menuDate: false,
      dialogNewSuccess: false,
      dialogRemoveSuccess: false,
      headers: [
        { text: 'Data od', value: 'start' },
        { text: 'Data do', value: 'end' },
        { text: 'Numer', value: 'number' },
        { text: '', value: 'action', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        start: new Date().toISOString().substr(0, 10),
        end: new Date().toISOString().substr(0, 10),
        number: '',
      },
      defaultItem: {
        start: new Date().toISOString().substr(0, 10),
        end: new Date().toISOString().substr(0, 10),
        number: '',
      },
      singleSelect: false
    }),
    watch: {
      dialogNewSuccess (val) {
        val || setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1
        }, 300)
      }
    },
    methods: {
      ...mapActions({
        setDialogNew: 'contracts/setDialogNew',
        createContract: 'contracts/createContract',
        editContract: 'contracts/editContract',
        deleteContracts: 'contracts/deleteContracts',
        createReport: 'contracts/createReport',
        acceptReport: 'contracts/acceptReport',
        declineReport: 'contracts/declineReport',
      }),
      allowedDates(val) {
        return new Date(val).getMonth() === parseInt(moment(this.monthSelected).format("M"))-1 && new Date(val).getFullYear() === parseInt(moment(this.monthSelected).format("YYYY"))
      },
      editItem (item) {
        this.editedIndex = this.contracts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true
      },
      close () {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.date = this.monthSelected;
          this.editedIndex = -1
        })
      },
      closeDialogNewSuccessAndAddAnotherContract () {
        this.dialogNewSuccess = false;
        this.setDialogNew(true)
      },
      closeDialogRemoveSuccess () {
        this.dialogRemoveSuccess = false;
        this.selected = []
      },
      save () {
        this.$v.$touch();
        if (this.editedIndex > -1) {
          if (!this.$v.$invalid) {
            this.editContract(this.editedItem).then(response => {
                this.$log.info('Saved edited contract', response);
                Object.assign(this.contracts[this.editedIndex], this.editedItem);
                this.close()
            }).catch(error => {
              this.$log.error('Saving edited contract error', error)
            })
          }
        } else {
          if (!this.$v.$invalid) {
            this.createContract(this.editedItem).then(response => {
                this.$log.info('Saved new contract', response);
                this.close();
                this.dialogNewSuccess = true
            }).catch(error => {
              this.$log.error('Saving new contract error', error)
            })
          }
        }
      },
      removeSelected () {
        if (this.selected.length == 0) {
          return false
        }
        if(confirm('Czy na pewno chcesz usunąć poniższy kontrakt?')){
          this.deleteContracts(this.selected).then(response => {
            this.$log.info('Deleted contract', response);
            this.dialogRemoveSuccess = true
          }).catch(error => {
            this.$log.error('Deleting contract error', error)
          })
        }
      }
    },
    created() {
      this.$store.dispatch('contracts/getContracts');
    }
  }
</script>

