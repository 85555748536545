<template>
  <v-data-table
      :headers="headers"
      :items="history"
      sort-by="date"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista zmian</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-show="$vuetify.breakpoint.mdAndUp" color="primary" rounded small class="mb-2 ml-4" @click="markAsReadAction()"><v-icon small class="mr-2">done</v-icon>Oznacz jako przeczytane</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      Brak zmian
    </template>
    <template v-slot:item.change="{ item }">
      <vue-json-compare v-if="item.original !== null" :oldData="item.original" :newData="item.after"></vue-json-compare>
      <span v-else>Inicjacja formularza</span>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueJsonCompare from "vue-json-compare";

export default {
  name: "History",
  components: {
    VueJsonCompare
  },
  computed: {
    ...mapGetters({
      history: 'history/getFormDataHistoryParsed'
    }),
  },
  props: {
    uid: null,
    role_id: null
  },
  data: () => ({
    selected: [],
    headers: [
      { text: 'Data', value: 'date' },
      { text: 'Zmiana', value: 'change' },
    ],
  }),
  methods: {
    ...mapActions({
      markAsRead: 'history/markAsRead'
    }),

    markAsReadAction () {
      if (this.selected.length == 0) {
        return false
      }
      this.markAsRead(this.selected).then(response => {
        this.$log.info('Deleted item', response);
      }).catch(error => {
        this.$log.error('Deleting item error', error)
      })
    },

  },
  created() {
    if(this.role_id == 3) {
      this.$store.dispatch('history/getHistoryByStudentCourse');
    } else {
      this.$store.dispatch('history/getHistoryByUserId');
    }
  }
}
</script>

<style>
.alpaca-p > .alpaca-line,
.alpaca-p > .alpaca-k,
.alpaca-p > .alpaca-string,
.alpaca-p > .alpaca-f {
  display: none;
}

.alpaca-upd > .alpaca-k,
.alpaca-upd > .alpaca-string,
.alpaca-add > .alpaca-k,
.alpaca-add > .alpaca-string,
.alpaca-del > .alpaca-k,
.alpaca-del > .alpaca-string {
  display: block;
}
</style>
