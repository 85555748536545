import Api from './Api'

export default {
  getCandidates (parameters = null) {
    return Api().get('/api/candidates?' + (parameters.pid ? '&pid=' + parameters.pid : '') + (parameters.cid ? '&cid=' + parameters.cid : ''), { withCredentials: true })
  },
  editCandidate (studentData) {
    return Api().put('/api/candidates/' + studentData.id, studentData)
  },
  deleteCandidate (studentId) {
    return Api().delete('/api/candidates/' + studentId)
  },
  deleteCandidates (studentCourses) {
    return Api().post('/api/candidates/delete-multiple', studentCourses)
  }
}
