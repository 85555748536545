<template>
    <div>
        <v-row>
            <v-col sm="12">
                <div class="title"><strong>Dane Urząd Skarbowy</strong></div>
            </v-col>
        </v-row>
        <v-container>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="name"
                            label="Gmina"
                            dense
                            :error-messages="taxNameErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="city"
                            label="Miejscowość"
                            dense
                            :error-messages="taxCityErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="street"
                            label="Ulica"
                            dense
                            :error-messages="taxStreetErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="houseNr"
                            label="Numer budynku"
                            dense
                            :error-messages="taxHouseNrErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <v-text-field
                            v-model="zip"
                            label="Kod pocztowy"
                            dense
                            :error-messages="taxZipErrors"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields } = createHelpers({
    getterType: 'registration/getTaxOffice',
    mutationType: 'registration/updateTaxOffice',
  });

  export default {
    name: "TaxOffice",
    data: () => ({
    }),
    props: [
      'taxNameErrors',
      'taxCityErrors',
      'taxStreetErrors',
      'taxHouseNrErrors',
      'taxZipErrors'
    ],
    computed: {
      ...mapFields([
        'taxOffice.name',
        'taxOffice.city',
        'taxOffice.street',
        'taxOffice.houseNr',
        'taxOffice.zip'
      ]),
    }
  }
</script>

<style scoped>

</style>
