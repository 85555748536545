<template>
  <v-data-table
      :headers="headers"
      :items="protocolsToDisplay()"
      sort-by="date"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
      :search="options.search"
      :loading="loading"
      loading-text="Wczytuję listę... proszę czekać"
      item-key="key"
      :options.sync="options"
      v-if="userRole == 1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Protokoły</v-toolbar-title>
      </v-toolbar>
      <v-toolbar flat color="white">
        <SelectSemesters @semesterChanged="semesterChanged"/>
        <SelectCourses @courseChanged="courseChanged"/>
        <SelectSubjects @subjectChanged="subjectChanged"/>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="options.search"
            append-icon="search"
            label="Szukaj"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczone protokoły</v-btn>
      </v-toolbar>
    </template>

    <template v-slot:item.created="{ item }">
      {{ new Date(item.created) | momentTime }}
    </template>
    <template v-slot:item.type="{ item }">
      {{ item.type | translateProtocolType }}
    </template>
    <template v-slot:item.action="{ item }" class="text-right">
      <v-icon @click="download(item);" small class="mr-2">file_download</v-icon>
<!--      <v-icon-->
<!--          @click="deleteProtocols(item.id)"-->
<!--          small-->
<!--          class="mr-0 ml-4"-->
<!--      >delete-->
<!--      </v-icon>-->
    </template>
    <template v-slot:no-data>
      Brak protokołów
    </template>
    <template v-slot:footer>
      <v-divider></v-divider>
      <div class="mt-4 mb-4 mr-4">
        <v-row>
          <v-col class="d-flex justify-end" md="12">
            <v-btn :color=buttonForRemove class="mr-4" @click="downloadSelected()" dark rounded small>Pobierz wybrane</v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SelectSemesters from "./SelectSemesters";
import SelectCourses from "./SelectCourses";
import SelectSubjects from "./SelectSubjects";
import moment from "moment";
import {ComponentWithPagingEnum} from '../enums';

export default {
  name: "Protocols",
  components: {SelectSemesters, SelectCourses, SelectSubjects },
  filters: {
    moment: function (date) {
      return moment(date).format('MMMM YYYY');
    },
    momentTime: function (date) {
      return moment(date).format('DD.MM.YYYY H:mm');
    }
  },
  computed: {
    ...mapGetters({
      protocols: 'protocols/getProtocols',
      getProtocolsBySubjectId: 'protocols/getProtocolsBySubjectId',
      courses: 'courses/getCourses',
      coursesWithoutAllOption: 'courses/getCoursesWithoutAllOption',
      places: 'places/getPlaces',
      semesterSelected: 'semesterSelected',
      courseSelected: 'courses/getCourseSelected',
      subjectSelected: 'subjects/getSubjectSelected',
      coordinators: 'places/getCoordinators',
      userRole: 'users/userRole',
      getChosenProtocol: 'protocols/getChosenProtocol',
      getProtocolsByUserIdAndProtocol: 'protocols/getProtocolsByUserIdAndProtocol',
      loggedInUser: 'users/getLoggedInUser',
      getPage: 'paging/getPage',
      getNameById: 'users/getNameById'
    }),
    buttonForRemove () {
      if (this.selected.length > 0) {
        return 'primary'
      } else {
        return 'secondary'
      }
    },
    dialog: {
      get(){
        return this.dialogFromStore
      },
      set(value){
        return this.setDialogNew(value)
      }
    },
    semesterChoices: {
      get() {
        return [
          {
            'key': moment('2022-03-01').format('YYYY-MM-DD'),
            'value': moment('2022-03-01').locale('pl').format('MMMM YYYY')
          },
          {
            'key': moment('2021-10-01').format('YYYY-MM-DD'),
            'value': moment('2021-10-01').locale('pl').format('MMMM YYYY')
          },
          {
            'key': moment('2021-03-01').format('YYYY-MM-DD'),
            'value': moment('2021-03-01').locale('pl').format('MMMM YYYY')
          },
          {
            'key': moment('2020-10-01').format('YYYY-MM-DD'),
            'value': moment('2020-10-01').locale('pl').format('MMMM YYYY')
          },

        ]
      },
      set(value){
        return this.setMonthSelected(value)
      }
    },

  },
  mounted () {
    this.setSelectedComponent(ComponentWithPagingEnum.protocols);
    this.options = this.getPage;
  },
  data: () => ({
    loading: false,
    ansImage: "",
    selected: [],
    menuDate: false,
    dialogNewError: false,
    dialogRemoveSuccess: false,
    dialogMessage: false,
    headers: [
      { text: 'Nazwa', value: 'title' },
      { text: 'Data wygenerowania', value: 'created' },
      { text: 'Kto wygenerował', value: 'usernameCreated' },
      { text: 'Kierunek', value: 'courseName' },
      { text: 'Przedmiot', value: 'subjectName' },
      { text: 'Wykładowca', value: 'professorName' },
      { text: 'Semestr', value: 'date' },
      { text: 'Sesja', value: 'type' },
      { text: '', value: 'action', sortable: false },
    ],
    singleSelect: false,
    validate: false,
    report: false,
    protocolDialog: false,
    changedGrade: [],
    options: {},
  }),
  watch: {
    dialog (val) {
      val || setTimeout(() => {
        this.clearEditItem()
      }, 300)
    },
    options: {
      handler () {
        this.handlePage()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setDialogNew: 'protocols/setDialogNew',
      deleteProtocols: 'protocols/deleteProtocols',
      clearProtocols: 'protocols/clearProtocols',
      clearSubjects: 'subjects/clearSubjects',
      setPage: 'paging/setPage',
      setSelectedComponent: 'paging/setSelectedComponent',
      clearPage1: 'paging/clearPage',
      saveProtocol: 'protocols/saveProtocol'
    }),
    clearPage () {
      this.clearPage1();
      this.options.page = 1;
    },
    handlePage() {
      let { sortBy, sortDesc, page, itemsPerPage, search } = this.options;
      this.setPage({ sortBy: sortBy, sortDesc: sortDesc, page: page, itemsPerPage: itemsPerPage, search: search })

    },
    protocolsToDisplay() {
      if(this.subjectSelected !== null) {
        return this.getProtocolsBySubjectId(this.subjectSelected)
      } else {
        return this.protocols;
      }
    },
    removeSelected () {
      if (this.selected.length == 0) {
        return false
      }
      if(confirm('Czy na pewno chcesz usunąć te protokoły?')){
        this.deleteProtocols(this.selected).then(response => {
          this.$log.info('Deleted protocol ', response);
          this.dialogRemoveSuccess = true;
          this.selected = [];
        }).catch(error => {
          this.$log.error('Deleting protocol error ', error)
        })
      }
    },
    newMessage() {
      if (this.selected.length == 0) {
        return false
      }
      this.dialogMessage = true;
    },

    downloadSelected() {
      this.selected.forEach(x => {
        this.download(x);
      })
    },

    async download(docDefinition) {
      var pdfMake = require('pdfmake/build/pdfmake.js');
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js');
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      pdfMake.createPdf(JSON.parse(docDefinition.pdfData)).download(docDefinition.title);
    },

    closeDialogMessage(val) {
      this.dialogMessage = val;
    },
    semesterChanged(date) {
      if((this.courseSelected !== null && this.courseSelected.length > 0) || date !== '' || (this.userRole == 0 || this.userRole == 5)) {
        this.loading = true;
        this.$store.dispatch(
            'protocols/getProtocols',
            {
              'date': date ? moment(date).startOf('month').format('YYYY-MM-DD') : '',
              'cid': this.courseSelected
            }
        ).then(() => {
          this.loading = false;
        });
      } else {
        this.clearProtocols();
      }
      this.clearPage();
    },
    courseChanged(courseId) {
      if (courseId.length > 0 || this.semesterSelected !== '' || (this.userRole == 0 || this.userRole == 5)) {
        this.loading = true;
        this.$store.dispatch(
            'protocols/getProtocols',
            {
              'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
              'cid': courseId
            }
        ).then(() => {
          this.loading = false;
        });
      } else {
        this.clearProtocols();
      }
      if (courseId.length == 0) {
        this.clearSubjects()
      }
      this.clearPage();
    },
    subjectChanged(subjectId) {
      if (subjectId == null && this.semesterSelected === '' && this.courseSelected.length === 0) {
        this.clearProtocols();
      }
      this.clearPage();
    },
    selectedItems() {
      let mapped = Object.assign({}, this.selected.map(x => x.email ));
      return { email: mapped, subject: '' };
    }
  },
  created() {
    if(this.semesterSelected || (this.userRole == 0 || this.userRole == 5)) {
      this.$store.dispatch(
          'protocols/getProtocols',
          {
            'date': this.semesterSelected ? moment(this.semesterSelected).startOf('month').format('YYYY-MM-DD') : '',
            'cid': this.courseSelected
          }
      ).then(() => {
        this.loading = false;
      });
    }
    this.$store.dispatch('courses/getCourses');
  }
}
</script>

<style scoped>
.toPrint {
  display: none;
}
.protocolUserDetail {
  white-space: nowrap;
}
</style>
