<template>
    <v-container>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="number1"
                        label="Numer świadczenia emerytalnego"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="number2"
                        label="Numer świadczenia rentowego"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <v-text-field
                        v-model="nameNFZ"
                        label="Nazwa NFZ"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="city"
                        label="Miejscowość"
                        dense
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-text-field
                        v-model="zip"
                        label="Kod pocztowy"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <v-text-field
                        v-model="street"
                        label="Ulica"
                        dense
                ></v-text-field>
            </v-col>
            <v-col sm="6">
                <v-text-field
                        v-model="houseNr"
                        label="Numer budynku"
                        dense
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields } = createHelpers({
    getterType: 'registration/getPensionerNFZ',
    mutationType: 'registration/updatePensionerNFZ',
  });

  export default {
    name: 'PensionerNFZ',
    computed: {
      ...mapFields([
        'pensionerNFZ.number1',
        'pensionerNFZ.number2',
        'pensionerNFZ.nameNFZ',
        'pensionerNFZ.city',
        'pensionerNFZ.zip',
        'pensionerNFZ.street',
        'pensionerNFZ.houseNr',
      ]),
    }
  }
</script>
