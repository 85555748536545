<template>
  <v-container>
    <v-text-field v-model="bankAccount" :label="text" append-icon="content_copy" dense
                  readonly @click:append="copyBankAccountToClipboard(bankAccount)"></v-text-field>
    <v-snackbar
        v-model="snackbar"
        timeout="2000"
    >
      Skopiowano numer rachunku do schowka!
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
  name: 'StudentCourseBankAccount',
  props: {
    bankAccount: {},
    text: {},
  },
  data() {
    return {
      snackbar: false
    }
  },
  methods: {
    async copyBankAccountToClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.snackbar = true;
      } catch($e) {
        alert('Błąd');
      }
    }
  }
}
</script>
