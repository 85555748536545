<template>
  <v-data-table
          :headers="headers"
          :items="administrativeStaff"
          sort-by="date"
          show-select
          :single-select=singleSelect
          class="elevation-1 ma-4"
          v-model="selected"
          :search="options.search"
          :options.sync="options"
          v-if="userRole == 1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista wszystkich pracowników adm</v-toolbar-title>
        <v-divider
                class="mx-4"
                inset
                vertical
        ></v-divider>
        <v-text-field
            v-model="options.search"
            append-icon="search"
            label="Szukaj"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn :color=buttonForRemove dark rounded small class="mb-2 ml-4" @click="removeSelected()"><v-icon small>delete_outline</v-icon>Usuń zaznaczonych pracowników administracyjnych</v-btn>
        <v-dialog v-model="dialog" max-width="800px" @input="v => v || close()">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodaj nowego pracownika administracyjnego</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.email" :error-messages="emailErrors" required @blur="$v.editedItem.email.$touch()" label="Email"></v-text-field>
                    <span v-if="errorEmailUserExists" class="error--text">Taki email już istnieje</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @blur="$v.editedItem.name.$touch()" label="Imie i nazwisko"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.telephone" :error-messages="telephoneErrors" required @blur="$v.editedItem.telephone.$touch()" label="Telefon"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-7">
              <v-btn color="grey" class="overline" text @click="close"><v-icon small left>arrow_back</v-icon> <span>porzuc dodawanie i wroc do listy</span></v-btn>
              <v-spacer></v-spacer>
              <v-btn class="primary" rounded @click="save">{{ formTitle }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Poprawnie dodałeś użytkownika</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    {{ editedItem.name }} {{ editedItem.email }} {{editedItem.telephone }}
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="dialogNewSuccess = false">Powrót do listy wszystkich pracowników administracyjnych</v-btn>
                  </v-col>
                  <v-col sm="12">
                    lub
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherAdministrative">Chcę dodać kolejnego użytkownika</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemoveSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span v-if="selected.length == 1" class="headline">Usunąłeś poprawnie pracownika administracyjnego</span>
                    <span v-else class="headline">Usunąłeś poprawnie pracowników administracyjnych</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon x-large class="green--text">check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    <div v-if="selected.length > 0">
                      <div v-for="item in selected">
                        {{ item.name }} {{ item.email }} {{ item.telephone }}
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="12">
                    <v-btn rounded color="primary" @click="closeDialogRemoveSuccess">Zamknij</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.contract="{ item }">
      <v-tooltip top v-if="!contractExists(item)">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
                   color="red accent-4"
                   icon="error"
          >
            <v-icon
                v-bind="attrs"
                v-on="on">
              event
            </v-icon>
          </v-badge>
        </template>
        <span>Brak umowy</span>
      </v-tooltip>
      <v-tooltip top v-if="contractExpiried(item)">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
                   color="orange accent-4"
                   icon="warning"
          >
            <v-icon
                v-bind="attrs"
                v-on="on">
              event
            </v-icon>
          </v-badge>
        </template>
        <span>{{ daysTillEndOfContract(item) }}.</span>
      </v-tooltip>
      <v-tooltip top v-else-if="contracts.filter(c => c.uid == item.id).length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
              color="green accent-0"
              dot
              overlap
          >
            <v-icon
                v-bind="attrs"
                v-on="on">
              event
            </v-icon>
          </v-badge>
        </template>
        <span>Umowa od: {{ startOfContractDate(item) }} do: {{ endOfContractDate(item) }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.date="{ item }">
      {{ new Date(item.date).toISOString().substr(0, 10) }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-icon>{{ (item.status)?'check_circle_outline':'warning' }}</v-icon>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
              @click="setChosenAdministrative(item.id)"
              small
              class="mr-2"
      >
        zoom_in
      </v-icon>
      <v-btn icon>
        <v-icon
            @click="editItem(item)"
            small
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:no-data>
      Brak użytkownikow
    </template>
    <template v-slot:footer>
      <v-divider></v-divider>
      <div class="mt-4 mb-4 mr-4">
        <v-row>
          <v-col class="d-flex justify-end" md="12">
            <v-btn @click="generateContractsReport()" class="mr-4" color="primary" dark rounded small>Generuj raport - zakres umów</v-btn>
            <v-btn @click="generateAdministrativeStaffReport()" color="primary" dark rounded small>Generuj raport wszystkich pracowników administracyjnych</v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {numeric, required} from "vuelidate/lib/validators";
import moment from 'moment';
import {ComponentWithPagingEnum, UserRoleEnum,} from '../enums'

export default {
    mixins: [validationMixin],

    validations: {
      editedItem: {
        email: {required},
        name: {required},
        telephone: {required, numeric}
      }
    },
    name: "AdministrativeStaff",
    computed: {
      ...mapGetters({
        administrativeStaff: 'users/getUsers',
        dialogFromStore: 'users/dialogNew',
        loggedInAdministrative: 'users/getLoggedInUser',
        contracts: 'contracts/getContracts',
        getPage: 'paging/getPage',
        userRole: 'users/userRole',
      }),
      buttonForRemove () {
        if (this.selected.length > 0) {
          return 'primary'
        } else {
          return 'secondary'
        }
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Dodaj nowego pracownika administracyjnego' : 'Edytuj pracownika administracyjnego'
      },
      emailErrors () {
        const errors = [];
        if (!this.$v.editedItem.email.$dirty) return errors;
        !this.$v.editedItem.email.required && errors.push('Podanie emaila jest wymagane');
        return errors
      },
      nameErrors () {
        const errors = [];
        if (!this.$v.editedItem.name.$dirty) return errors;
        !this.$v.editedItem.name.required && errors.push('Podanie imienia i nazwiska jest wymagane');
        return errors
      },
      telephoneErrors () {
        const errors = [];
        if (!this.$v.editedItem.telephone.$dirty) return errors;
        !this.$v.editedItem.telephone.required && errors.push('Podanie numeru telefonu jest wymagane');
        !this.$v.editedItem.telephone.numeric && errors.push('Proszę podać wyłącznie cyfry, bez przerw, myślników i numeru kierunkowego');
        return errors
      },
      dialog: {
        get(){
          return this.dialogFromStore
        },
        set(value){
          return this.setDialogNew(value)
        }
      }
    },
    mounted () {
      this.setSelectedComponent(ComponentWithPagingEnum.administrativeStaff);
      this.options = this.getPage;
    },
    created() {
      this.$store.dispatch('users/getAdministrativeStaff');
    },
    data: () => ({
      selected: [],
      dialogNewSuccess: false,
      dialogRemoveSuccess: false,
      showContractTooltip: false,
      headers: [
        { text: 'Imię i nazwisko', value: 'name' },
        { text: '', value: 'contract' },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          value: 'email',
        },
        { text: 'Telefon', value: 'telephone' },
        { text: '', value: 'action', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        email: '',
        telephone: '',
        user_role: UserRoleEnum.Administrative
      },
      defaultItem: {
        name: '',
        email: '',
        telephone: '',
        user_role: UserRoleEnum.Administrative
      },
      singleSelect: false,
      errorEmailUserExists: false,
      options: {},
    }),
    watch: {
      dialogNewSuccess (val) {
        val || setTimeout(() => {
          this.clearEditItem()
        }, 300)
      },
      options: {
        handler () {
          this.handlePage()
        },
        deep: true,
      },
    },
    methods: {
      ...mapActions({
        setDialogNew: 'users/setDialogNew',
        createAdministrative: 'users/createUser',
        editAdministrative: 'users/editUser',
        deleteAdministrativeStaff: 'users/deleteUsers',
        setChosenAdministrativeBasic: 'users/setChosenUserBasic',
        setChosenAdministrative: 'users/setChosenAdministrative',
        setPage: 'paging/setPage',
        setSelectedComponent: 'paging/setSelectedComponent',
        clearPage1: 'paging/clearPage',
      }),
      clearPage () {
        this.clearPage1();
        this.options.page = 1;
      },
      handlePage() {
        let { sortBy, sortDesc, page, itemsPerPage, search: search } = this.options;
        this.setPage({ sortBy: sortBy, sortDesc: sortDesc, page: page, itemsPerPage: itemsPerPage, search: search })
      },
      contractExists(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        if (contracts.length > 0) {
          return true;
        }
        return false;
      },
      contractExpiried(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        if (contracts.length) {
          return ((moment().add(1, 'M') > moment(Math.max(...contracts.map(e => new Date(e.end))))) ? true : false);
        } else {
          return false;
        }
      },
      daysTillEndOfContract(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        item.contract_to = Math.max(...contracts.map(e => new Date(e.end)));

        return moment(item.contract_to).diff(moment(), 'days') < 0 ? 'Skończyła się ' + moment().diff(moment(item.contract_to), 'days') + ' dni temu' : 'Kończy się za ' + moment(item.contract_to).diff(moment(), 'days') + ' dni';
      },

      startOfContractDate(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        return moment(Math.max(...contracts.map(e => new Date(e.start)))).format('DD.MM.YYYY');
      },
      endOfContractDate(item) {
        let contracts = this.contracts.filter(c => c.uid == item.id);
        return moment(Math.max(...contracts.map(e => new Date(e.end)))).format('DD.MM.YYYY');
      },
      editItem (item) {
        this.setChosenAdministrativeBasic(item.id)
        this.editedIndex = this.administrativeStaff.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true
      },
      clearEditItem() {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1
      },
      close () {
        this.dialog = false
        this.clearEditItem()
      },
      closeDialogNewSuccessAndAddAnotherAdministrative () {
        this.dialogNewSuccess = false;
        this.setDialogNew(true)
        this.clearEditItem()
      },
      closeDialogRemoveSuccess () {
        this.dialogRemoveSuccess = false;
        this.selected = []
        this.clearEditItem()
      },
      save () {
        this.$v.$touch();
        if (this.editedIndex > -1) {
          if (!this.$v.$invalid) {
            this.editAdministrative(this.editedItem).then(response => {
              if (response == 200) {
                this.$log.info('Saved edited administrative', response);
                Object.assign(this.administrativeStaff[this.editedIndex], this.editedItem);
                this.close()
              }
            }).catch(error => {
              this.$log.error('Saving edited administrative error', error)
            })
          }
        } else {
          if (!this.$v.$invalid) {
            this.createAdministrative(this.editedItem).then(response => {
              this.$log.info('Saved new administrative', response);
              this.close();
              this.dialogNewSuccess = true
            }).catch(error => {
              if(error == 'User exists') {
                this.errorEmailUserExists = true;
              }
              this.$log.error('Saving new administrative error', error)
            })
          }
        }
      },
      removeSelected () {
        if (this.selected.length == 0) {
          return false
        }
        if(confirm('Czy na pewno chcesz usunąć tego pracownika administracyjnego?')){
          this.deleteAdministrativeStaff(this.selected).then(response => {
            this.$log.info('Deleted administrative', response);
            this.dialogRemoveSuccess = true
          }).catch(error => {
            this.$log.error('Deleting administrative error', error)
          })
        }
      },
      generateContractsReport () {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          "LP;Imię i nazwisko;Umowa od;Umowa do",
          ...this.administrativeStaff.map(item => item.id + ';' + item.name + ';' + this.startOfContractDate(item) + ';' + this.endOfContractDate(item))
        ].join("\n")

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "wykladowcy-umowy-raport.csv");
        link.click();
      },
      generateAdministrativeStaffReport () {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          "LP;Imię i nazwisko;Telefon;Email",
          ...this.administrativeStaff.map(item => item.id + ';' + item.name + ';' + item.telephone + ';' + item.email)
        ].join("\n")

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "wykladowcy-raport.csv");
        link.click();
      },
    }
  }
</script>

<style scoped>
  .toPrint {
    display: none;
  }
</style>

