export default Object.freeze({
  'value-1': "Jestem zatrudniony na umowę o pracę w innym zakładzie pracy i otrzymuję wynagrodzenie niższe od wynagrodzenia minimalnego.",
  'value-2': "Jestem zatrudniony na umowę o pracę w innym zakładzie pracy i otrzymuję wynagrodzenie wyższe od wynagrodzenia minimalnego.",
  'value-3': "Jestem zatrudniony na umowę zlecenie w innym zakładzie pracy i otrzymuję wynagrodzenie niższe od wynagrodzenia minimalnego.",
  'value-4': "Jestem zatrudniony na umowę zlecenie w innym zakładzie pracy i otrzymuję wynagrodzenie wyższe od wynagrodzenia minimalnego/równe wynagrodzeniu minimalnemu. Podaj proszę nazwę, adres zakładu/zakładów pracy.",
  'value-5': "Prowadzę pozarolniczą działalność gospodarczą",
  'value-6': "Jestem emerytem i jest to moje jedyne źródło utrzymania",
  'value-7': "Jestem emerytem zatrudnionym na umowę zlecenie z innym pracodawcą",
  'value-8': "Jestem rencistą i jest to moje jedyne źródło utrzymania",
  'value-9': "Jestem rencistą zatrudnionym na umowę o pracę z innym pracodawcą",
  'value-10': "Jestem rencistą zatrudnionym na umowę zlecenie z innym pracodawcą",
  'value-11': "Jestem bezrobotnym zarejestrowanym w Urzędzie Pracy",
  'value-12': "Przebywam na uropie macierzyńskim",
  'value-13': "Jestem rolnikiem i jest to moje jedyne źródło utrzymania",
  'value-14': "Jestem uczniem szkoły ponadpodstawowej lub studentem (do ukończenia 26 lat)",
  'value-15': "Pozostaję w stosunku służby policyjnej, wojskowej, więziennej (z wyłączeniem celników)",
  'value-16': "Żadne z powyższych (wpisać inny tytuł do ubezpieczenia)",
})
