<template>
    <v-container>
        <v-card>
          <v-card-title>{{ candidate.username }} <v-btn color="grey" class="overline ml-5" text @click="$router.push('/candidates')"><v-icon small left>arrow_back</v-icon> powrót do listy wszystkich kandydatów</v-btn></v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col sm="6">
                  <div><b>Nazwisko</b></div>
                  {{ candidate.username }}
                </v-col>
                <v-col sm="6">
                  <div><b>Email</b></div>
                  {{ candidate.email }}
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <div><b>Imię</b></div>
                  <br />
                </v-col>
                <v-col sm="6">
                  <div><b>Telefon</b></div>
                  {{ candidate.telephone }}
                </v-col>
              </v-row>
            </v-container>
            <hr />
            <v-container>
              <v-row>
                <v-col sm="6">
                  <div><b>Kierunek studiów</b></div>
                  {{ candidate.courseName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <div><b>Drugi kierunek</b></div>
                  {{ candidate.courseName2 }}
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <div><b>Ośrodek</b></div>
                  {{ candidate.cityName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <div><b>Darmowe kursy</b></div>
                  <v-icon small left>{{ candidate.question_1 ? 'check_circle_outline': 'not_interested' }}</v-icon> Kierownik wycieczek szkolnych <br />
                  <v-icon small left>{{ candidate.question_2 ? 'check_circle_outline': 'not_interested' }}</v-icon> Zarządzanie projektami współfinansowanymi ze środków UE<br />
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <div><b>Uwagi/Informacje</b></div>
                  {{ candidate.comment }}
                </v-col>
              </v-row>
            </v-container>
            <hr />
            <v-container>
              <v-row>
                <v-col sm="6">
                  <div><b><v-icon left>local_phone</v-icon> Kontakty z potencjalnym słuchaczem</b></div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Candidate",
    data () {
      return {
        dialogNewCar: false,
        tab: null,
        dialogEditSuccess: false,
        validate: false,
        report: false,
      }
    },
    methods: {
      ...mapActions({
      }),
      generatePDFReport() {
        this.report = true
      },
      registerFormPrinted() {
        this.$log.info('Printed registration form')
        this.report = false
      }
    },
    computed: {
      ...mapGetters({
        chosenCandidateId: 'candidates/getChosenCandidate',
        candidate: 'candidates/getChosenCandidateData',
      }),
    },
    created() {
    }
  }
</script>

<style scoped>

</style>
