const ComponentWithPagingEnum = Object.freeze({
    evaluations: 'evaluations',
    protocols: 'protocols',
    studentCourses: 'studentCourses',
    users: 'users',
    administrativeStaff: 'administrativeStaff',
    courses: 'courses',
    accountantStudents: 'accountantStudents',
    mentors: 'mentors',
});

export default ComponentWithPagingEnum;

