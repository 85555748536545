import Api from './Api'

export default {
  getProtocols (parameters = null) {
    return Api().get('/api/protocols?' + (parameters.date ? '&date=' + parameters.date : '') + (parameters.cid ? '&cid=' + parameters.cid : '') + (parameters.sid ? '&sid=' + parameters.sid : ''), { withCredentials: true })
  },
  save (pdfData) {
    return Api().post('/api/protocols/save', pdfData)
  },
  deleteProtocols (protocols) {
    return Api().post('/api/protocols/delete-multiple', protocols)
  }
}
